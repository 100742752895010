import { put, call, takeEvery, select } from 'redux-saga/effects'
import { saveAs } from 'file-saver'
import api from 'api'

import { GET_LOGS_TYPES, EXPORT_LOGS_CSV } from 'constants/actions-constants'
import {
  setLogsTypes,
  startFetchingLogs,
  stopFetchingLogs,
} from 'actions/logs-actions'
import {
  getFromDateSelector,
  getToDateSelector,
  getSelectedLogsTypes,
} from 'redusers/logs'

function* getLogsTypesSagaWorker() {
  try {
    yield put(startFetchingLogs())

    const URL = 'logs/types/'
    const res = yield call([api, 'getLogsApi'], URL)

    const { data } = res
    if (data) {
      yield put(setLogsTypes(data))
    }
  } catch (error) {
    //to do
  } finally {
    yield put(stopFetchingLogs())
  }
}

function* exportLogsCsvSagaWorker() {
  try {
    yield put(startFetchingLogs())
    const fromDate = yield select(getFromDateSelector)
    const toDate = yield select(getToDateSelector)
    const selectedTypes = yield select(getSelectedLogsTypes)
    const URL = `logs/csv-export/?from_date=${encodeURIComponent(
      fromDate
    )}&to_date=${encodeURIComponent(toDate)}&event_types=${encodeURIComponent(
      selectedTypes.join(',')
    )}`
    const res = yield call([api, 'getCsvApi'], URL)
    const { data, headers } = res

    saveAs(
      new Blob([data], { type: headers['content-type'] }),
      `logs_${fromDate}_to_${toDate}.csv`
    )
  } catch (error) {
    // to do
  } finally {
    yield put(stopFetchingLogs())
  }
}

export function* logsSagaWatcher() {
  yield takeEvery(GET_LOGS_TYPES, getLogsTypesSagaWorker)
  yield takeEvery(EXPORT_LOGS_CSV, exportLogsCsvSagaWorker)
}
