import React, { memo, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import isEqual from 'lodash/isEqual'

import { useShallowSelector } from 'custom-hooks/useShallowSelector'
import { UPDATE_PAGINATION_CONSTANT } from 'constants/pagination-constants'
import { CLIENT_ROLE } from 'constants/roles-constant'
import {
  INVOICES_TITLE_LN,
  YOU_DONT_HAVE_INVOICES_INFO_MESSAGE_LN,
  QB_DISCONNECT_MESSAGE_ON_BATCHES_PAGE_LN,
  CSI_DISCONNECT_MESSAGE_ON_BATCHES_PAGE_LN,
} from 'constants/language-key-constants'
import {
  getProfileQuickbooksEnabledSelector,
  getRoleUserSelector,
  getProfileCsiEnabledSelector,
} from 'redusers/profile'

import { getInvoices, clearAllInvoicesState } from 'actions/invoices'
import {
  openInvoiceDetailModal,
  openInvoiceTaxSummaryModal,
} from 'actions/modals-state-actions'

import {
  getIsFetchingInvoicesSelector,
  getDestructureInvoicesSelector,
} from 'redusers/invoices'

import Title from 'components/ui/title-component'
import Spinner from 'components/ui/spinner'
import InfoBox from 'components/ui/infobox-component'
import { Table } from 'components/ui/table'
import InvoicesTableHeader from './InvoicesTableHeader'
import InvoiceTableBody from './InvoiceTableBody'

let _prevScrollHeight = 0

const InvoicesComponent = ({ realmId }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const isFetchingInvoices = useShallowSelector(getIsFetchingInvoicesSelector)
  const invoices = useShallowSelector(getDestructureInvoicesSelector)
  const isQbConnected = useShallowSelector(getProfileQuickbooksEnabledSelector)
  const isCsiConnected = useShallowSelector(getProfileCsiEnabledSelector)
  const roleUser = useShallowSelector(getRoleUserSelector)

  const isClient = roleUser === CLIENT_ROLE

  const handleClickInvoiceDetail = useCallback(
    (id) => {
      dispatch(openInvoiceDetailModal(id))
    },
    [dispatch]
  )

  const handlerClickTaxSummary = useCallback(
    (id) => {
      dispatch(openInvoiceTaxSummaryModal(id))
    },
    [dispatch]
  )

  const handleUpdate = (value) => {
    if (
      value?.clientHeight >= value?.scrollHeight - value?.scrollTop &&
      _prevScrollHeight !== value?.scrollTop
    ) {
      dispatch(
        getInvoices({
          realm_id: realmId,
          isPaggination: UPDATE_PAGINATION_CONSTANT,
        })
      )
    }
    _prevScrollHeight = value?.scrollTop
  }

  useEffect(() => {
    dispatch(
      getInvoices({
        realm_id: realmId,
      })
    )
    return () => {
      _prevScrollHeight = 0
      dispatch(clearAllInvoicesState())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="invoices_container">
      <Title
        title={t(INVOICES_TITLE_LN)}
        extraClassName="invoices_title"
        clasNameContainer="invoices_title_container"
      />
      {(!isQbConnected || !isCsiConnected) && isClient && (
        <div className="infoboxes_container">
          {!isQbConnected && (
            <InfoBox
              textContent={t(QB_DISCONNECT_MESSAGE_ON_BATCHES_PAGE_LN)}
              extraClassNameContainer="piu_infobox_container show-animated qb_infobox_container"
              styleType={'warning'}
            />
          )}
          {!isCsiConnected && (
            <InfoBox
              textContent={t(CSI_DISCONNECT_MESSAGE_ON_BATCHES_PAGE_LN)}
              extraClassNameContainer="piu_infobox_container show-animated"
              styleType={'warning'}
            />
          )}
        </div>
      )}
      <div className="invoices_table_section">
        {invoices.length ? (
          <Table
            className="batch_table_container"
            headerComponent={InvoicesTableHeader}
            bodyTableComponent={InvoiceTableBody}
            autoHeightMin={25}
            autoHeightMax={'57vh'}
            data={invoices}
            bodyProps={{
              handleClickInvoiceDetail: handleClickInvoiceDetail,
              handlerClickTaxSummary: handlerClickTaxSummary,
            }}
            isSpinner={isFetchingInvoices && invoices?.length}
            onScrollFrame={handleUpdate}
          />
        ) : (
          !isFetchingInvoices && (
            <InfoBox
              textContent={t(YOU_DONT_HAVE_INVOICES_INFO_MESSAGE_LN)}
              extraClassNameContainer="piu_infobox_container show-animated"
              //styleType={}
            />
          )
        )}
      </div>
      {isFetchingInvoices && !invoices?.length && (
        <Spinner className="invoices_loader_spiner" />
      )}
    </div>
  )
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps, nextProps)
}

export default memo(InvoicesComponent, areEqual)
