import React, { useEffect, useState, memo, useCallback } from 'react'
import { connect } from 'react-redux'
import { formValueSelector, clearFields } from 'redux-form'
import { useTranslation } from 'react-i18next'

import Title from 'components/ui/title-component'
import LoadingScreen from 'components/loading-screen'
import Button from 'components/ui/button-component'
import InfoBox from 'components/ui/infobox-component'
import ProductsFormWrap from './ProductsFormWrap'
import ProductsForm from './ProductsForm'
import CsiProductsForm from './CsiProductsForm'
import {
  getAllProducts,
  clearProducts,
  getCsiProducts,
  clearCsiProducts,
  mapPropduct,
  pullProductsServicesFromQuickBooks,
  showMappedProductAlert,
} from 'actions/products-actions'
import { showConfirmationModal } from 'actions/modals-state-actions'
import {
  getUnmappedProducstSelector,
  getIsFetchingProductsSelector,
  getIsfetchingCsiProductsSelector,
  getCsiProductsSelector,
  getIsShowMappedProductAlert,
} from 'redusers/products'
import { getProfileQuickbooksEnabledSelector } from 'redusers/profile'
import {
  MAP_PRODUCT_TITLE_1_LN,
  MAP_PRODUCT_TITLE_2_LN,
  MAP_PRODUCT_TITLE_TOOLTIP_LN,
  MAP_PRODUCT_MESSAGE_1_LN,
  MAP_PRODUCT_MESSAGE_2_LN,
  MAP_PRODUCT_MESSAGE_3_LN,
  MAP_PRODUCT_MESSAGE_4_LN,
  MAP_PRODUCT_SECTION_TITLE_1_LN,
  MAP_PRODUCT_SECTION_PLACEHOLDER_1_LN,
  MAP_PRODUCT_SECTION_TITLE_2_LN,
  MAP_PRODUCT_SECTION_PLACEHOLDER_2_LN,
  MAP_PRODUCT_BUTTON_NAME_1_LN,
  MAP_PRODUCT_BUTTON_NAME_2_LN,
  MAP_PRODUCT_CONFIRMATION_TITLE_LN,
  MAP_PRODUCT_TITLE_TOOLTIP_2_LN,
} from 'constants/language-key-constants'

const csiProductRadioForm = formValueSelector('csiProductsForm')

const MapProducts = (props) => {
  const {
    classNameContainer,
    getAllProducts,
    clearProducts,
    unmappedProducts = [],
    isFetchingProducts,
    isFetchingCsiProducts,
    getCsiProducts,
    clearCsiProducts,
    csiProducts = [],
    csiProductSelectedValue,
    mapPropduct,
    pullProductsServicesFromQuickBooks,
    clearFields,
    quickbooksEnabled,
    isCreateBatch = false,
    batchInvoicesProductData = [],
    isShowMappedProductAlert,
    showMappedProductAlert,
    showConfirmationModal,
  } = props

  const { t } = useTranslation()

  const [searchProducts, setsearchProducts] = useState('')

  const [searchCsiProducts, setsearchCsiProducts] = useState('')

  const [productSelectedValues, setProductSelectedValue] = useState([])

  const _data = isCreateBatch ? batchInvoicesProductData : unmappedProducts

  const productsIds = _data.map((i) => i._id)

  const productSearchData = _data.filter(
    (item) =>
      item.quickbooks_data.Name?.toLocaleLowerCase()?.indexOf(
        searchProducts?.toLocaleLowerCase()
      ) !== -1
  )

  const handleChangePproductCheckBox = useCallback(
    (event) => {
      if (event.target.checked) {
        setProductSelectedValue([
          ...productSelectedValues,
          event.target.dataset.id,
        ])
      } else {
        const _filteredProductSelectedValues = productSelectedValues.filter(
          (i) => i !== event.target.dataset.id
        )
        setProductSelectedValue(_filteredProductSelectedValues)
      }
    },
    [productSelectedValues]
  )

  const handleClickMapProduct = () => {
    showConfirmationModal({
      cb: handleSubmit,
      title: t(MAP_PRODUCT_CONFIRMATION_TITLE_LN),
    })
  }

  const handleSubmit = useCallback(() => {
    const [productCode, serviceCode] = csiProductSelectedValue.split(';')
    mapPropduct({
      body: {
        products_ids: productSelectedValues,
        product_code: productCode,
        service_code: serviceCode,
      },
      isNeedToUpdateProducts: !isCreateBatch,
    })

    clearFields('csiProductsForm', false, false, 'csiProductsRadioButton')
    setProductSelectedValue([])
  }, [
    clearFields,
    csiProductSelectedValue,
    isCreateBatch,
    mapPropduct,
    productSelectedValues,
  ])

  const handleClickPullProductsFromQb = useCallback(() => {
    pullProductsServicesFromQuickBooks()
  }, [pullProductsServicesFromQuickBooks])

  const handleOnChangeSciProduct = useCallback((event) => {
    setsearchCsiProducts(event.target.value?.toLocaleLowerCase())
  }, [])

  const handleOnChangeProduct = useCallback((event) => {
    setsearchProducts(event.target.value?.toLocaleLowerCase())
  }, [])

  const handleChangleAllSelectProducts = useCallback(
    (event) => {
      if (event.target.checked) {
        setProductSelectedValue(productsIds)
      } else {
        setProductSelectedValue([])
      }
      setsearchProducts('')
    },
    [productsIds]
  )

  useEffect(() => {
    let _t = null
    if (isShowMappedProductAlert) {
      _t = setTimeout(() => {
        showMappedProductAlert(false)
      }, 5000)
    }
    return () => {
      clearTimeout(_t)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowMappedProductAlert])

  useEffect(() => {
    getCsiProducts()
    !isCreateBatch && getAllProducts()
    return () => {
      clearProducts()
      clearCsiProducts()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const createBatchClassName = isCreateBatch ? 'create_batch' : ''

  const searchDataCsi = csiProducts.reduce((result, item) => {
    const newItem = { ...item }

    const newServices = item.services.filter(
      (service) =>
        service.description?.toLocaleLowerCase()?.indexOf(searchCsiProducts) !==
        -1
    )

    if (newServices.length) {
      newItem.services = newServices
      result.push(newItem)
    } else if (
      item.code?.toLocaleLowerCase()?.indexOf(searchCsiProducts) !== -1 ||
      item.description?.toLocaleLowerCase()?.indexOf(searchCsiProducts) !== -1
    ) {
      result.push(item)
    }

    return result
  }, [])

  let isProductSelected = !!productSelectedValues?.length

  // eslint-disable-next-line no-unused-vars

  return (
    <div
      className={`map_products_container ${classNameContainer} ${createBatchClassName}`}
    >
      <div className="map_products_title_container">
        <Title
          title={
            isCreateBatch
              ? t(MAP_PRODUCT_TITLE_1_LN)
              : t(MAP_PRODUCT_TITLE_2_LN)
          }
          extraClassName={'settings_sub_title'}
          component={'h3'}
          withTooltip
          textTooltip={
            isCreateBatch
              ? t(MAP_PRODUCT_TITLE_TOOLTIP_2_LN)
              : t(MAP_PRODUCT_TITLE_TOOLTIP_LN)
          }
          placementTooltip="top"
        >
          {(isShowMappedProductAlert || !quickbooksEnabled) &&
            !isCreateBatch && (
              <InfoBox
                textContent={
                  !quickbooksEnabled
                    ? t(MAP_PRODUCT_MESSAGE_1_LN)
                    : t(MAP_PRODUCT_MESSAGE_2_LN)
                }
                extraClassNameContainer="piu_infobox_container show-animated"
                styleType={!quickbooksEnabled ? 'warning' : 'success'}
              />
            )}
        </Title>
        {isCreateBatch && (
          <span className="infobox_section">
            <InfoBox
              textContent={
                _data.length
                  ? t(MAP_PRODUCT_MESSAGE_3_LN)
                  : t(MAP_PRODUCT_MESSAGE_4_LN)
              }
              extraClassNameContainer="info_box_unmaped_products"
              extraClassNameText={'info_box_unmaped_products_text'}
              styleType={_data.length ? 'warning' : 'success'}
            />
          </span>
        )}
      </div>
      {(!isCreateBatch || (isCreateBatch && !!_data.length)) && (
        <>
          <div className="map_products_tables_container">
            <ProductsFormWrap
              classNameContainer="map_products_table"
              title={t(MAP_PRODUCT_SECTION_TITLE_1_LN)}
              searchPlaceholder={t(MAP_PRODUCT_SECTION_PLACEHOLDER_1_LN)}
              searchValue={searchProducts}
              searchClassName="left"
              onChange={handleOnChangeProduct}
              onChangeCheckBoxAll={handleChangleAllSelectProducts}
              notEmptyCheckBoxState={
                productsIds.length &&
                productSelectedValues.length &&
                productSelectedValues.length !== productsIds.length
              }
              isAllSelected={
                productsIds.length &&
                productSelectedValues.length &&
                productSelectedValues.length === productsIds.length
              }
            >
              {isFetchingProducts ? (
                <LoadingScreen isCenter />
              ) : (
                <ProductsForm
                  data={productSearchData}
                  onChange={handleChangePproductCheckBox}
                  values={productSelectedValues}
                />
              )}
            </ProductsFormWrap>
            <ProductsFormWrap
              classNameContainer="map_products_table"
              title={t(MAP_PRODUCT_SECTION_TITLE_2_LN)}
              searchPlaceholder={t(MAP_PRODUCT_SECTION_PLACEHOLDER_2_LN)}
              searchClassName="right"
              onChange={handleOnChangeSciProduct}
            >
              {isFetchingCsiProducts ? (
                <LoadingScreen isCenter />
              ) : (
                <CsiProductsForm
                  data={searchDataCsi}
                  csiProductSelectedValue={csiProductSelectedValue}
                />
              )}
            </ProductsFormWrap>
          </div>
          <div className="map_product_button_group">
            <Button
              name={t(MAP_PRODUCT_BUTTON_NAME_1_LN)}
              styletype="green"
              classNameButton="map_product_button first"
              classNameContainer="button_wrapp first"
              onClick={handleClickMapProduct}
              disabled={
                isFetchingProducts ||
                isFetchingCsiProducts ||
                !(isProductSelected && csiProductSelectedValue)
              }
              isSpinner={isFetchingProducts || isFetchingCsiProducts}
            />
            {!isCreateBatch && (
              <Button
                name={t(MAP_PRODUCT_BUTTON_NAME_2_LN)}
                styletype="outline_blue"
                classNameButton="map_product_button"
                classNameContainer="button_wrapp"
                onClick={handleClickPullProductsFromQb}
                disabled={
                  isFetchingProducts ||
                  isFetchingCsiProducts ||
                  !quickbooksEnabled
                }
              />
            )}
          </div>
        </>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  unmappedProducts: getUnmappedProducstSelector(state),
  csiProducts: getCsiProductsSelector(state),
  isFetchingProducts: getIsFetchingProductsSelector(state),
  isFetchingCsiProducts: getIsfetchingCsiProductsSelector(state),
  csiProductSelectedValue: csiProductRadioForm(state, 'csiProductsRadioButton'),
  quickbooksEnabled: getProfileQuickbooksEnabledSelector(state),
  isShowMappedProductAlert: getIsShowMappedProductAlert(state),
})

const actions = {
  getAllProducts,
  clearProducts,
  getCsiProducts,
  clearCsiProducts,
  mapPropduct,
  pullProductsServicesFromQuickBooks,
  clearFields,
  showMappedProductAlert,
  showConfirmationModal,
}

export default connect(mapStateToProps, actions)(memo(MapProducts))
