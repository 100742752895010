import React, { memo, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { useShallowSelector } from 'custom-hooks/useShallowSelector'
import { getLogsTypes } from 'actions/logs-actions'
import LogsModal from 'components/logs-modal'
import Button from 'components/ui/button-component'
import { getIsShowLogsModalSelector } from 'redusers/modalsState'
import { showLogsModal } from 'actions/modals-state-actions'
import { REPORT_EXPORT_TO_CSV_BUTTON_LN } from 'constants/language-key-constants'

const LogsPage = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isShowLogsModal = useShallowSelector(getIsShowLogsModalSelector)

  const handleClickExportCsv = useCallback(() => {
    dispatch(showLogsModal())
  }, [dispatch])

  useEffect(() => {
    dispatch(getLogsTypes())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="container logs_page_container">
      <div className="logs_button_container">
        <Button
          name={t(REPORT_EXPORT_TO_CSV_BUTTON_LN)}
          styletype="green"
          classNameButton={'new_batches_btn'}
          onClick={handleClickExportCsv}
        />
      </div>
      {isShowLogsModal && <LogsModal />}
    </div>
  )
}

export default memo(LogsPage)
