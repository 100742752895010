export const FIREBASE_ALLOW_STATUS_CONSTANT = 'allow'
export const FIREBASE_BLOCK_STATUS_CONSTANT = 'block'
export const FIREBASE_ASK_STATUS_CONSTANT = 'ask'

export const firebaseConfig = {
  apiKey: 'AIzaSyBHE8tIjEfkFE3FpecClriTcm1jf9EqJm0',
  authDomain: 'nav-solutions.firebaseapp.com',
  databaseURL: 'https://nav-solutions.firebaseio.com',
  projectId: 'nav-solutions',
  storageBucket: 'nav-solutions.appspot.com',
  messagingSenderId: '753263353116',
  appId: '1:753263353116:web:596a59dfb337f7e38b2ed9',
  measurementId: 'G-9VZ1PZ4F8W',
}
