import React, { memo } from 'react'

function FielsWithTitle(props) {
  const {
    title = '',
    text = '',
    rightAlign,
    isLink,
    classNameContainer = '',
    isCurrency,
  } = props
  const usdFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    minimumFractionDigits: 2,
    currency: 'USD',
  })
  const _text = isCurrency ? usdFormat.format(text) : text
  return (
    <span
      className={`field_container ${
        rightAlign ? 'right_align' : ''
      } ${classNameContainer}`}
    >
      <span className="field_title">{title}</span>
      {isLink ? (
        <a href={`mailto:${text}`} className="field_link">
          <span className="field_text">{text}</span>
        </a>
      ) : (
        <span className="field_text">{_text}</span>
      )}
    </span>
  )
}

FielsWithTitle.propTypes = {}

export default memo(FielsWithTitle)
