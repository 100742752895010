import moment from 'moment'

import {
  SET_ISSUE_DATE_TIME_FROM,
  SET_ISSUE_DATE_TIME_TO,
  CLEAR_REPORTS_DATE_RANGE_TIME,
  SET_OFFSET_TAX_SUMMARY_REPORT,
  SET_TAX_SUMMARY_REPORTS,
  CLEAR_TAX_SUMMARY_REPORTS,
  STAR_FETCHING_REPORTS,
  STOP_FETCHING_REPORTS,
  UPDATE_TAX_SUMMARY_REPORTS,
  SET_QB_PORTAL_COMPARISON_REPORTS,
  UPDATE_QB_PORTAL_COMPARISON_REPORTS,
  CLEAR_QB_PORTAL_COMPARISON_REPORTS,
  SET_PROCESSED_DATE_TIME_FROM,
  SET_PROCESSED_DATE_TIME_TO,
  SET_IS_SHOW_INFOBOX_IF_EMPTY_DATA_TABLE,
  START_FETCHING_EXPORT_CSV,
  STOP_FETCHING_EXPORT_CSV,
  SET_SEARCH_VALUE_QB_PORTAL_COMPARISON_REPORTS,
  SET_SEARCH_VALUE_TAX_SUMMARY_REPORTS,
  CLEAR_SERCH_VALUES_REPORTS,
  SET_SUM_TOTAL_FOR_REPORTS,
  SET_ERROR_MESSAGE_FOR_MTS_REPORT,
  CLEAR_ERROR_MESSAGE_FOR_MTS_REPORT,
  SET_HAS_ZERO_TAX_QB_REPORT,
} from 'constants/actions-constants'

const startOfMonth = moment()
  .month(moment().get('month') - 1)
  .startOf('month')
  .format()

const endOfMonth = moment()
  .month(moment().get('month') - 1)
  .endOf('month')
  .format()

const initialState = {
  taxSummaryReport: {
    offset: 0,
    reports: [],
    issueDatetimeFrom: startOfMonth,
    issueDatetimeTo: endOfMonth,
    errorMessage: null,
  },
  qbPortalComparisonReport: {
    offset: 0,
    reports: [],
    processedDatetimeFrom: startOfMonth,
    processedDatetimeTo: endOfMonth,
    statuses: [],
    has_zero_tax: false,
  },
  isFetchingReports: false,
  isFetchingExportCsv: false,
  isShowInfoboxIfEmptyDataTable: false,
  searchValueQbPortalCompanyReporst: '',
  searchValueTaxSummaryReports: '',
  sumTotalTaxAmount: null,
  sumBillableTotalTaxAmount: null,
}

export const reports = (state = initialState, actions) => {
  switch (actions.type) {
    case SET_SUM_TOTAL_FOR_REPORTS:
      return {
        ...state,
        sumTotalTaxAmount: actions.payload.sum_total_tax_amount,
        sumBillableTotalTaxAmount:
          actions.payload.sum_billable_total_tax_amount,
      }
    case CLEAR_SERCH_VALUES_REPORTS:
      return {
        ...state,
        searchValueQbPortalCompanyReporst:
          initialState.searchValueQbPortalCompanyReporst,
        searchValueTaxSummaryReports: initialState.searchValueTaxSummaryReports,
      }
    case SET_SEARCH_VALUE_QB_PORTAL_COMPARISON_REPORTS:
      return { ...state, searchValueQbPortalCompanyReporst: actions.payload }

    case SET_SEARCH_VALUE_TAX_SUMMARY_REPORTS:
      return { ...state, searchValueTaxSummaryReports: actions.payload }

    case SET_IS_SHOW_INFOBOX_IF_EMPTY_DATA_TABLE:
      return { ...state, isShowInfoboxIfEmptyDataTable: actions.payload }

    case START_FETCHING_EXPORT_CSV:
      return { ...state, isFetchingExportCsv: true }

    case STOP_FETCHING_EXPORT_CSV:
      return { ...state, isFetchingExportCsv: false }

    case STAR_FETCHING_REPORTS:
      return { ...state, isFetchingReports: true }

    case STOP_FETCHING_REPORTS:
      return { ...state, isFetchingReports: false }

    case SET_ISSUE_DATE_TIME_FROM:
      return {
        ...state,
        taxSummaryReport: {
          ...state.taxSummaryReport,
          issueDatetimeFrom: actions.payload,
        },
      }

    case SET_ISSUE_DATE_TIME_TO:
      return {
        ...state,
        taxSummaryReport: {
          ...state.taxSummaryReport,
          issueDatetimeTo: actions.payload,
        },
      }

    case CLEAR_REPORTS_DATE_RANGE_TIME:
      return {
        ...state,
        taxSummaryReport: {
          ...state.taxSummaryReport,
          issueDatetimeFrom: initialState.taxSummaryReport.issueDatetimeFrom,
          issueDatetimeTo: initialState.taxSummaryReport.issueDatetimeTo,
        },
        qbPortalComparisonReport: {
          ...state.qbPortalComparisonReport,
          processedDatetimeFrom:
            initialState.qbPortalComparisonReport.processedDatetimeFrom,
          processedDatetimeTo:
            initialState.qbPortalComparisonReport.processedDatetimeTo,
        },
      }

    case SET_OFFSET_TAX_SUMMARY_REPORT:
      return {
        ...state,
        taxSummaryReport: {
          ...state.taxSummaryReport,
          offset: actions.payload,
        },
      }

    case SET_ERROR_MESSAGE_FOR_MTS_REPORT:
      return {
        ...state,
        taxSummaryReport: {
          ...state.taxSummaryReport,
          reports: initialState.taxSummaryReport.reports,
          errorMessage: actions.payload,
        },
        sumTotalTaxAmount: null,
        sumBillableTotalTaxAmount: null,
      }

    case CLEAR_ERROR_MESSAGE_FOR_MTS_REPORT:
      return {
        ...state,
        taxSummaryReport: {
          ...state.taxSummaryReport,
          errorMessage: initialState.taxSummaryReport.errorMessage,
        },
      }

    case SET_TAX_SUMMARY_REPORTS:
      return {
        ...state,
        taxSummaryReport: {
          ...state.taxSummaryReport,
          reports: actions.payload,
          offset: initialState.taxSummaryReport.offset,
          errorMessage: initialState.taxSummaryReport.errorMessage,
        },
      }

    case UPDATE_TAX_SUMMARY_REPORTS:
      return {
        ...state,
        taxSummaryReport: {
          ...state.taxSummaryReport,
          reports: [
            ...state.taxSummaryReport.reports,
            ...actions.payload.reports,
          ],
          offset: actions.payload.offset,
        },
      }

    case CLEAR_TAX_SUMMARY_REPORTS:
      return {
        ...state,
        isShowInfoboxIfEmptyDataTable: false,
        taxSummaryReport: {
          ...state.taxSummaryReport,
          reports: [],
          offset: 0,
        },
      }

    case SET_HAS_ZERO_TAX_QB_REPORT:
      return {
        ...state,
        qbPortalComparisonReport: {
          ...state.qbPortalComparisonReport,
          has_zero_tax: actions.payload,
        },
      }

    case SET_QB_PORTAL_COMPARISON_REPORTS:
      return {
        ...state,
        qbPortalComparisonReport: {
          ...state.qbPortalComparisonReport,
          reports: actions.payload,
        },
      }
    case UPDATE_QB_PORTAL_COMPARISON_REPORTS:
      return {
        ...state,
        qbPortalComparisonReport: {
          ...state.qbPortalComparisonReport,
          reports: [
            ...state.qbPortalComparisonReport.reports,
            ...actions.payload.reports,
          ],
          offset: actions.payload.offset,
        },
      }

    case CLEAR_QB_PORTAL_COMPARISON_REPORTS:
      return {
        ...state,
        isShowInfoboxIfEmptyDataTable: false,
        qbPortalComparisonReport: {
          ...state.qbPortalComparisonReport,
          reports: [],
          offset: 0,
        },
      }

    case SET_PROCESSED_DATE_TIME_FROM:
      return {
        ...state,
        qbPortalComparisonReport: {
          ...state.qbPortalComparisonReport,
          processedDatetimeFrom: actions.payload,
        },
      }

    case SET_PROCESSED_DATE_TIME_TO:
      return {
        ...state,
        qbPortalComparisonReport: {
          ...state.qbPortalComparisonReport,
          processedDatetimeTo: actions.payload,
        },
      }

    default:
      return state
  }
}

export const getIsFetchinReportsSelector = (state) =>
  state.reports.isFetchingReports

export const getOffsetTaxSummaryReportSelector = (state) =>
  state.reports.taxSummaryReport.offset

export const getIssueDatetimeFromSelector = (state) =>
  state.reports.taxSummaryReport.issueDatetimeFrom

export const getIssueDatetimeToSelector = (state) =>
  state.reports.taxSummaryReport.issueDatetimeTo

export const getTaxSummaryReportsSelector = (state) =>
  state.reports.taxSummaryReport.reports

export const getQbPortalComparisonReportOffsetSelector = (state) =>
  state.reports.qbPortalComparisonReport.offset

export const getQbPortalComparisonReportsSelector = (state) =>
  state.reports.qbPortalComparisonReport?.reports?.map((report) => {
    const _report = { ...report }
    if (!_report?.last_batch_id) {
      _report.last_batch_id = ''
    }
    return _report
  })

export const getQbPortalComparisonReportProcessedDatetimeFromSelector = (
  state
) => state.reports.qbPortalComparisonReport.processedDatetimeFrom

export const getQbPortalComparisonReportProcessedDatetimeToSelector = (state) =>
  state.reports.qbPortalComparisonReport.processedDatetimeTo

export const getIsFetchinExportCsvSelector = (state) =>
  state.reports.isFetchingExportCsv

export const getIsShowInfoboxIfEmptyDataTable = (state) =>
  state.reports.isShowInfoboxIfEmptyDataTable

export const getSearchValueQbPortalCompanyReportsSelector = (state) =>
  state.reports.searchValueQbPortalCompanyReporst

export const getSearchValueTaxSummaryReportsSelector = (state) =>
  state.reports.searchValueTaxSummaryReports

export const getSumTotalsTaxAmountSelector = (state) =>
  state.reports.sumTotalTaxAmount

export const getSumBillableTotalTaxAmountSelector = (state) =>
  state.reports.sumBillableTotalTaxAmount

export const getMTSreportErrorMessageSelector = (state) =>
  state.reports.taxSummaryReport.errorMessage

export const getHasZeroTaxSelector = (state) =>
  state.reports.qbPortalComparisonReport.has_zero_tax
