import React, { memo } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  REMORT_NAV_MENU_NAME_1_LN,
  REMORT_NAV_MENU_NAME_2_LN,
} from 'constants/language-key-constants'

const ReportNavMenu = () => {
  const { t } = useTranslation()
  return (
    <nav className="report_nav_menu_container">
      <NavLink
        className="header_menu_item"
        activeClassName="active"
        to="/reports/qb_to_portal_comparison"
      >
        {t(REMORT_NAV_MENU_NAME_1_LN)}
      </NavLink>
      <NavLink
        className="header_menu_item"
        activeClassName="active"
        to="/reports/monthly_tax_summary"
      >
        {t(REMORT_NAV_MENU_NAME_2_LN)}
      </NavLink>
    </nav>
  )
}

export default memo(ReportNavMenu)
