import firebase from 'firebase/app'
import 'firebase/messaging'

import { firebaseConfig } from 'constants/firebase-constant'

firebase.initializeApp(firebaseConfig)

let messaging = {}

if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging()
  // Add the public key generated from the console
  messaging.usePublicVapidKey(
    'BBGxG2FRrO41eUIohKYnZe6wBGM6APmtAtRWgS2fmnnVV7GLPzSlq5eqUOLEUBxyPKL6EGIrAKdZMNtgGzD0w90'
  )
}

export const requestFirebaseNotificationPermission = async () => {
  if (!firebase.messaging.isSupported()) {
    return
  }
  try {
    await messaging.requestPermission()
    const token = await messaging.getToken()
    if (token) {
      return token
    } else {
      throw new Error()
    }
  } catch (error) {
    //console.dir(error)
    if (error.code === 'messaging/token-unsubscribe-failed') {
      requestFirebaseNotificationPermission()
    }
    throw error
  }
}

export { messaging }
