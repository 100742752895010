import React, { memo, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useLocation } from 'react-router-dom'

import { useShallowSelector } from 'custom-hooks/useShallowSelector'
import CheckBoxStyled from 'components/ui/checkbox-styled'
import Modal from 'components/ui/modal-component'
import Title from 'components/ui/title-component'
import CalendarStyled from 'components/ui/calendar-component'

import Button from 'components/ui/button-component'
import { hideReportFilterModal } from 'actions/modals-state-actions'
import {
  setIssueDateTimeFrom,
  setIssueDateTimeTo,
  getTaxSummaryReports,
  getQbPortalComparisonReports,
  setProcessedDateTimeFrom,
  setProcessedDateTimeTo,
  setHasZeroTaxQbReport,
} from 'actions/reports-actions'
import {
  getIssueDatetimeFromSelector,
  getIssueDatetimeToSelector,
  getQbPortalComparisonReportProcessedDatetimeFromSelector,
  getQbPortalComparisonReportProcessedDatetimeToSelector,
  getIsFetchinReportsSelector,
  getHasZeroTaxSelector,
} from 'redusers/reports'
import { MONTHLY_TAX_SUMMARY_PATH } from 'constants/reports-sub-path-constants'
import {
  SELECT_DATE_RANGE_REPORT_LN,
  SELECT_FROM_DATE_REPORT_LN,
  SELECT_TO_DATE_REPORT_LN,
  GET_REPORT_BUTTON_NAME_LN,
  DEFAULT_CANCEL_BUTTON_LN,
  NEW_BATCH_MODAL_DATE_TO_MESSAGE_LN,
} from 'constants/language-key-constants'

const ReportFilterModal = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const [reportSubPath] = location?.pathname.split('/').reverse()
  const isFetching = useShallowSelector(getIsFetchinReportsSelector)
  const [isError, setisError] = useState(false)
  const fromDateSelector =
    reportSubPath === MONTHLY_TAX_SUMMARY_PATH
      ? getIssueDatetimeFromSelector
      : getQbPortalComparisonReportProcessedDatetimeFromSelector

  const toDateSelector =
    reportSubPath === MONTHLY_TAX_SUMMARY_PATH
      ? getIssueDatetimeToSelector
      : getQbPortalComparisonReportProcessedDatetimeToSelector

  const fromDate = useShallowSelector(fromDateSelector)
  const toDate = useShallowSelector(toDateSelector)

  const hasZeroTax = useShallowSelector(getHasZeroTaxSelector)

  const handleFromDate = useCallback(
    (date) => {
      if (reportSubPath === MONTHLY_TAX_SUMMARY_PATH) {
        dispatch(setIssueDateTimeFrom(moment(date).format()))
      } else {
        dispatch(setProcessedDateTimeFrom(moment(date).format()))
      }
    },
    [dispatch, reportSubPath]
  )

  const handleToDate = useCallback(
    (date) => {
      if (reportSubPath === MONTHLY_TAX_SUMMARY_PATH) {
        dispatch(setIssueDateTimeTo(moment(date).format()))
      } else {
        dispatch(setProcessedDateTimeTo(moment(date).format()))
      }
    },
    [dispatch, reportSubPath]
  )

  const handleErrorDate = (e) => {
    if (e && !isError) {
      setisError(true)
    }
    if (!e && isError) {
      setisError(false)
    }
  }

  const handelCloseModal = useCallback(() => {
    dispatch(hideReportFilterModal())
  }, [dispatch])

  const handleClickGetReport = useCallback(() => {
    if (reportSubPath === MONTHLY_TAX_SUMMARY_PATH) {
      dispatch(getTaxSummaryReports())
    } else {
      dispatch(getQbPortalComparisonReports())
    }
  }, [dispatch, reportSubPath])

  const handleChangeZeroTax = useCallback(
    (e) => {
      dispatch(setHasZeroTaxQbReport(e.target.checked))
    },
    [dispatch]
  )

  const maxDate = new Date()
  const _maxDate = maxDate.setMonth(maxDate.getMonth())

  return (
    <Modal
      classNameWrap={'new_batch_modal_container'}
      classNameContainer={'new_batch'}
      isCloseButton={true}
      handleClickClose={handelCloseModal}
    >
      <span className="new_batch_modal_title_container">
        <Title
          title={t(SELECT_DATE_RANGE_REPORT_LN)}
          extraClassName="new_batch_title"
        />
      </span>
      <span className="new_batch_modal_calendar_container">
        <CalendarStyled
          placeholder={t(SELECT_FROM_DATE_REPORT_LN)}
          className="left_calendar"
          value={fromDate}
          maxDate={_maxDate}
          onChange={handleFromDate}
        />
        <CalendarStyled
          placeholder={t(SELECT_TO_DATE_REPORT_LN)}
          disableFuture={false}
          value={toDate}
          onChange={handleToDate}
          minDate={fromDate}
          // maxDate={_maxDate}
          minDateMessage={t(NEW_BATCH_MODAL_DATE_TO_MESSAGE_LN)}
          onError={handleErrorDate}
        />
      </span>
      {reportSubPath !== MONTHLY_TAX_SUMMARY_PATH && (
        <span className="zero_tax_form_section">
          <form name="zero_tax_form" className="zero_tax_form_container">
            <CheckBoxStyled
              label={'Show zero tax invoices'}
              //name={'zeroTax'}
              checked={hasZeroTax}
              className="zero_tax_checkbox"
              onChange={handleChangeZeroTax}
            />
          </form>
        </span>
      )}
      <span className="new_batch_modal_buttons_container">
        <Button
          name={t(GET_REPORT_BUTTON_NAME_LN)}
          styletype="green"
          classNameButton={'new_batch_modal_buttons'}
          classNameContainer={'new_batch_modal_buttons_wrap left_button'}
          disabled={isFetching || isError}
          onClick={handleClickGetReport}
          isSpinner={isFetching}
        />
        <Button
          name={t(DEFAULT_CANCEL_BUTTON_LN)}
          styletype="outline_blue"
          classNameButton={'new_batch_modal_buttons'}
          classNameContainer={'new_batch_modal_buttons_wrap'}
          onClick={handelCloseModal}
          //disabled={isFetchingBatch}
        />
      </span>
    </Modal>
  )
}

export default memo(ReportFilterModal)
