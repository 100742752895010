import React, { memo } from 'react'
import PropTypes from 'prop-types'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import Tooltip from '@material-ui/core/Tooltip'

function TooltipStyled({ text = '', placement = 'top', children }) {
  return (
    <span className="tool_tip_container">
      <Tooltip arrow title={text} placement={placement}>
        <span>{children ? children : <InfoOutlinedIcon />}</span>
      </Tooltip>
    </span>
  )
}

TooltipStyled.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  placement: PropTypes.string,
}

export default memo(TooltipStyled)
