import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import AuthRoute from 'routes/auth-route'
import { authCheckState } from 'actions/profile-actions'
import { getIsLoadingSelector } from 'redusers/profile'
import LoadingScreen from 'components/loading-screen'

import { useShallowSelector } from 'custom-hooks/useShallowSelector'

const App = () => {
  const dispatch = useDispatch()
  const isLoading = useShallowSelector(getIsLoadingSelector)

  useEffect(() => {
    dispatch(authCheckState())
  }, [dispatch])

  return isLoading ? <LoadingScreen /> : <AuthRoute />
}

export default App
