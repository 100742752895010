import { put, call, takeEvery, select, debounce } from 'redux-saga/effects'

import api from 'api'
import {
  setIsFetchingBatch,
  setBatches,
  setOffsetCountForGettingBatch,
  updateBatches,
  setIsPaggination,
} from 'actions/batches-actions'
import { getProfileInfo } from 'actions/profile-actions'
import {
  getOffsetCountForGettingBatchesSelector,
  getIsPagginatioForGettingBatchesSelector,
} from 'redusers/batches'
import {
  GET_COMPANIES,
  GET_BATCHES_BY_COMPANY,
  SET_SEARCH_BATCH_FROM_COMPANY_VALUE,
} from 'constants/actions-constants'
import { UPDATE_PAGINATION_CONSTANT } from 'constants/pagination-constants'
import {
  setCompaniesList,
  startCompaniesFetching,
  stopCompaniesFetching,
} from 'actions/companies'
import {
  getSearchValueForBatchFromCompanySelector,
  getChosenRealMIdSelector,
} from 'redusers/companies'

function* getCompaniesSagaWorker() {
  try {
    yield put(startCompaniesFetching())
    const URL = 'companies/?limit=300'
    const res = yield call([api, 'getCompaniesApi'], URL)

    const { data } = res

    yield put(setCompaniesList(data))
  } catch (error) {
    //to do
  } finally {
    yield put(stopCompaniesFetching())
  }
}

function* getBatchesByCompanySagasWorker({ payload }) {
  try {
    const searchValue = yield select(getSearchValueForBatchFromCompanySelector)
    let realmId = payload.realmId
    if (!realmId) {
      realmId = yield select(getChosenRealMIdSelector)
    }

    yield put(setIsFetchingBatch(true))
    if (payload.isUpdate === UPDATE_PAGINATION_CONSTANT) {
      const offsetCount = yield select(getOffsetCountForGettingBatchesSelector)

      const isPaggination = yield select(
        getIsPagginatioForGettingBatchesSelector
      )

      if (!isPaggination) {
        return
      }

      const URL = searchValue
        ? `batches/by-company/${realmId}/?offset=${offsetCount}&limit=5&search=${searchValue}`
        : `batches/by-company/${realmId}/?offset=${offsetCount}&limit=5`
      const res = yield call([api, 'getBatchesApi'], URL)
      const { data } = res

      if (data) {
        yield put(updateBatches(data))
        if (!data.length) {
          yield put(setIsPaggination(false))
          return
        }

        yield put(setOffsetCountForGettingBatch(offsetCount + 5))
      }
    } else {
      const URL = searchValue
        ? `batches/by-company/${realmId}/?limit=5&search=${searchValue}`
        : `batches/by-company/${realmId}/?limit=5`
      const res = yield call([api, 'getBatchesApi'], URL)
      const { data } = res

      if (data) {
        yield put(setBatches(data))

        if (!data.length) {
          yield put(setIsPaggination(false))
          return
        }
        yield put(setOffsetCountForGettingBatch(5))
      }
    }
  } catch (error) {
    if (error?.response?.status === 400 && error?.response?.data?.detail) {
      yield put(getProfileInfo())
      return
    }
  } finally {
    yield put(setIsFetchingBatch(false))
  }
}

export function* companiesSagaWatcher() {
  yield takeEvery(GET_COMPANIES, getCompaniesSagaWorker)
  yield takeEvery(GET_BATCHES_BY_COMPANY, getBatchesByCompanySagasWorker)
  yield debounce(
    500,
    SET_SEARCH_BATCH_FROM_COMPANY_VALUE,
    getBatchesByCompanySagasWorker
  )
}
