import {
  START_USERS_FETCHING,
  STOP_USERS_FETCHING,
  SET_USERS,
  SET_SELECTED_USER_ID,
  CLEAR_SELECTED_USER_ID,
  SET_IS_SIGN_IN_AS_USER,
  SET_SEARCH_VALUE_USERS,
  CLEAR_SEARCH_VALUE_USERS,
} from 'constants/actions-constants'
import { ADMIN_ROLE, CLIENT_ROLE } from 'constants/roles-constant'

const initialState = {
  usersList: [],
  isFetchingUsersList: false,
  selectedUserId: '',
  isSignInAsUser: false,
  serchValue: '',
}

export const users = (state = initialState, actions) => {
  switch (actions.type) {
    case SET_SEARCH_VALUE_USERS:
      return { ...state, serchValue: actions.payload }
    case CLEAR_SEARCH_VALUE_USERS:
      return { ...state, serchValue: initialState.serchValue }
    case START_USERS_FETCHING:
      return { ...state, isFetchingUsersList: true }
    case STOP_USERS_FETCHING:
      return { ...state, isFetchingUsersList: false }
    case SET_USERS:
      return { ...state, usersList: actions.payload }
    case SET_SELECTED_USER_ID:
      return { ...state, selectedUserId: actions.payload }
    case CLEAR_SELECTED_USER_ID:
      return { ...state, selectedUserId: initialState.selectedUserId }
    case SET_IS_SIGN_IN_AS_USER:
      return { ...state, isSignInAsUser: actions.payload }
    default:
      return state
  }
}

export const getSelectedUserIdSelector = (state) => state.users.selectedUserId

export const getSelectedStatusActivateUserSelector = (state) => {
  const selectedId = getSelectedUserIdSelector(state)
  return state.users.usersList.find((user) => user._id === selectedId)
    ?.is_active
}

export const getUserProfileSigniningUserSelector = (state) => {
  const selectedId = getSelectedUserIdSelector(state)
  return state.users.usersList.find((user) => user._id === selectedId)
}

export const getSelectedUserSelector = (state) => {
  const selectedId = getSelectedUserIdSelector(state)
  return state.users.usersList.reduce((result, user) => {
    if (user._id === selectedId) {
      result.userId = user.email
      result.firstName = user.profile?.first_name || ''
      result.lastName = user.profile?.last_name || ''
      result.companyName = user.profile?.company_name || ''
      result.phone = user.profile?.phone || ''
      result.address = user.profile?.address?.address_line || ''
      result.city = user.profile?.address?.city || ''
      result.stateRegion = user.profile?.address?.state || ''
      result.zipCode = user.profile?.address?.zip_code || ''
    }
    return result
  }, {})
}

export const getIsUsersFetchingSelector = (state) =>
  state.users.isFetchingUsersList

export const getUsersListSelector = (state) => {
  const profileId = state.profile._id

  return state.users.usersList.reduce((userList, user) => {
    const { _id, email, is_active, roles = [], profile = {}, last_login } =
      user || {}

    let roleUser = CLIENT_ROLE

    if (roles.includes(ADMIN_ROLE)) {
      roleUser = ADMIN_ROLE
    }

    const name = `${profile?.first_name || ''} ${profile?.last_name || ''}`

    const companyName = profile?.company_name || ''

    const companyId =
      roleUser === ADMIN_ROLE ? 'N/A' : profile?.quickbooks?.realm_id || ''

    const quickbooks_enabled = profile?.quickbooks_enabled

    const csiAccessToken =
      roleUser === ADMIN_ROLE ? 'N/A' : profile?.csi?.access || ''

    const csi_enabled = profile?.csi_enabled

    if (profileId !== _id) {
      userList.push({
        _id,
        email,
        is_active,
        roleUser,
        name,
        companyName,
        companyId,
        quickbooks_enabled,
        csiAccessToken,
        csi_enabled,
        last_login: last_login ?? '',
      })
    }
    return userList
  }, [])
}

export const getSearchValueUsersSelector = (state) => state.users.serchValue

export const getSelectedUserNameSelector = (state) => {
  const selectedUSerID = getSelectedUserIdSelector(state)
  const usersList = getUsersListSelector(state)
  return usersList.find((user) => user._id === selectedUSerID)?.name || ''
}

export const getSelectedUserCsiConnectedStatusSelector = (state) => {
  const selectedUSerID = getSelectedUserIdSelector(state)
  const usersList = getUsersListSelector(state)
  return (
    usersList.find((user) => user._id === selectedUSerID)?.csi_enabled || ''
  )
}
