import React, { memo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'

import CustomRadioButton from 'components/ui/custom-radio-button'
import CalendarStyled from 'components/ui/calendar-component'
import {
  setFromDateToNewBatch,
  setToDateToNewBatch,
  setSelectedTransactionsType,
} from 'actions/batches-actions'
import {
  NEW_BATCH_MODAL_DATE_FROM_LN,
  NEW_BATCH_MODAL_DATE_TO_LN,
  NEW_BATCH_MODAL_DATE_TO_MESSAGE_LN,
} from 'constants/language-key-constants'
import { transactionsTypes } from 'constants/transactions-type-constants'

const RadioGroupTransactionType = ({ value, disabled }) => {
  const dispatch = useDispatch()

  const _handleChange = useCallback(
    (e) => {
      dispatch(setSelectedTransactionsType(e.target.value))
    },
    [dispatch]
  )

  return (
    <FormControl
      component="fieldset"
      className="transaction_type_radiogroup_container"
    >
      <FormLabel component="h5" className={'transaction_type_radiogroup_title'}>
        Transaction Type
      </FormLabel>
      <RadioGroup
        aria-label="transactions-type"
        name="transactions-type"
        value={value}
        onChange={_handleChange}
        className="transaction_type_radiobutton_container"
      >
        <CustomRadioButton
          value={transactionsTypes.INVOICES}
          label={'Invoice'}
          className="piu_radio_button"
          disabled={disabled}
        />
        <CustomRadioButton
          value={transactionsTypes.SALES_RECEIPT}
          label={'Sales Receipt'}
          disabled={disabled}
        />
        <CustomRadioButton
          value={transactionsTypes.CREDIT_MEMO}
          label={'Credit Memo'}
          disabled={disabled}
        />
      </RadioGroup>
    </FormControl>
  )
}

const DatesChoose = (props) => {
  const { fromDate, toDate, handleErrorDate, disabled, transactioType } = props

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleFromDate = useCallback(
    (date) => {
      dispatch(setFromDateToNewBatch(date))
    },
    [dispatch]
  )

  const handleToDate = useCallback(
    (date) => {
      dispatch(setToDateToNewBatch(date))
    },
    [dispatch]
  )

  var maxDate = new Date()
  var _maxDate = maxDate.setMonth(maxDate.getMonth() + 1)

  return (
    <>
      <div className="new_batch_modal_calendar_container">
        <CalendarStyled
          placeholder={t(NEW_BATCH_MODAL_DATE_FROM_LN)}
          className="left_calendar"
          value={fromDate}
          maxDate={_maxDate}
          onChange={handleFromDate}
          disabled={disabled}
        />
        <CalendarStyled
          placeholder={t(NEW_BATCH_MODAL_DATE_TO_LN)}
          disableFuture={false}
          value={toDate}
          onChange={handleToDate}
          minDate={fromDate}
          maxDate={_maxDate}
          minDateMessage={t(NEW_BATCH_MODAL_DATE_TO_MESSAGE_LN)}
          onError={handleErrorDate}
          disabled={disabled}
        />
      </div>
      <RadioGroupTransactionType value={transactioType} disabled={disabled} />
    </>
  )
}

export default memo(DatesChoose)
