import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  memo,
} from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import {
  SIGN_IN_LN,
  CANT_ACCESS_YOUR_ACCOUNT_LN,
} from 'constants/language-key-constants'
import {
  getIsAutsSelector,
  getProfileErrorMessageSelector,
  getProfileFetchingStatusSelector,
} from 'redusers/profile'
import { signInAction, clearProfileErrorMessage } from 'actions/profile-actions'

import Title from 'components/ui/title-component'
import InfoBox from 'components/ui/infobox-component'
import Logo from 'components/ui/Logo'
import Form from './Form'
import Button from 'components/ui/button-component'
import { endAnimation } from 'utils'

function SignIn(props) {
  const {
    isAuth,
    signInAction,
    errorMessage,
    clearProfileErrorMessage,
    isProfileFetching,
  } = props
  const { t } = useTranslation()
  const infoRef = useRef(null)

  const [isValidFields, setIsValidFields] = useState(true)
  const [isShowInfoBox, setIsShowInfoBox] = useState(false)

  const _handlerOnSubmit = (formData) => {
    signInAction({ email: formData.login, password: formData.password })
  }

  const getValidMessages = () => {
    if (errorMessage) {
      return errorMessage
    }

    return ''
  }

  useEffect(() => {
    if (errorMessage && isValidFields) {
      setIsValidFields(false)
    }
  }, [errorMessage, isValidFields])

  useEffect(() => {
    return () => {
      clearProfileErrorMessage()
    }
  }, [clearProfileErrorMessage])

  useLayoutEffect(() => {
    if (!isValidFields && !isShowInfoBox) {
      setIsShowInfoBox(true)
    }
    if (isValidFields && isShowInfoBox) {
      endAnimation(infoRef, 'closing', setIsShowInfoBox.bind(this, false))
    }
  }, [isShowInfoBox, isValidFields])

  if (isAuth) {
    return <Redirect to={{ pathname: '/' }} />
  }

  return (
    <div className="container login_container">
      {/* <div className={'login_logo_container'}></div> */}
      <Logo
        classNameContainer={'login_logo_container'}
        classNameImage="login_logo"
      />
      <div className="login_form_container">
        <Title title={t(SIGN_IN_LN)} extraClassName="login_form__title" />
        {isShowInfoBox && (
          <InfoBox
            textContent={getValidMessages()}
            extraClassNameContainer="login_form__infobox_container"
            extraClassNameText="login_form__infobox_text"
            ref={infoRef}
          />
        )}
        <Form
          isProfileFetching={isProfileFetching}
          onSubmit={_handlerOnSubmit}
        />
        <Button
          styletype="link"
          classNameButton={'login_form_access_acc'}
          name={t(CANT_ACCESS_YOUR_ACCOUNT_LN)}
          href={
            'mailto:support@csilongwood.com?subject=Customer support request'
          }
        />
      </div>
    </div>
  )
}

SignIn.propTypes = {
  isAuth: PropTypes.bool.isRequired,
  signInAction: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  clearProfileErrorMessage: PropTypes.func.isRequired,
  isProfileFetching: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
  isAuth: getIsAutsSelector(state),
  errorMessage: getProfileErrorMessageSelector(state),
  isProfileFetching: getProfileFetchingStatusSelector(state),
})

const actions = {
  signInAction,
  clearProfileErrorMessage,
}

export default connect(mapStateToProps, actions)(memo(SignIn))
