import React, { memo, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import Button from 'components/ui/button-component'
import Modal from 'components/ui/modal-component'
import Title from 'components/ui/title-component'
import MessageBlock from 'components/ui/message-block'
import {
  EXPORT_CONFIRMATION_MODAL_TITLE_LN,
  EXPORT_CONFIRMATION_MODAL_MESSAGE_LN,
  NEW_BATCH_BUTTON_NAME_LN,
  CLOSE_BUTTON_NAME_LN,
} from 'constants/language-key-constants'
import { getErrorMessageForNewBatchSelector } from 'redusers/batches'
import { clearErrorMessageForNewBatch } from 'actions/batches-actions'
import { useShallowSelector } from 'custom-hooks/useShallowSelector'

function ExportConfirmationModal(props) {
  const { handleCloseExportConfirmationModal, handleOpenNewBatch } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const errorMessage = useShallowSelector(getErrorMessageForNewBatchSelector)

  const clearErrorMessage = useCallback(() => {
    dispatch(clearErrorMessageForNewBatch())
  }, [dispatch])

  const handleClickCreateNewBatch = useCallback(() => {
    handleCloseExportConfirmationModal()
    handleOpenNewBatch()
  }, [handleCloseExportConfirmationModal, handleOpenNewBatch])

  useEffect(() => {
    return () => {
      clearErrorMessage()
    }
  }, [clearErrorMessage, errorMessage])

  const _errorMessage = (
    <span>
      Invoices without errors have been successfully updated in QuickBooks.
      <br />
      <br />
      Following invoice(s) have NOT been updated in QuickBooks. Invoice version
      has been outdated due to changes made in QuickBooks. Please reprocess
      these invoice(s) to update the Portal version.
      <br />
      <br />
      {errorMessage}
    </span>
  )

  return (
    <Modal
      classNameWrap={'export_confirmation_modal'}
      classNameContainer={''}
      isCloseButton={true}
      handleClickClose={handleCloseExportConfirmationModal}
    >
      <span className="message_section">
        <Title
          title={t(EXPORT_CONFIRMATION_MODAL_TITLE_LN)}
          extraClassName="export_confirmation_title"
        />
        <MessageBlock
          message={
            errorMessage
              ? _errorMessage
              : t(EXPORT_CONFIRMATION_MODAL_MESSAGE_LN)
          }
          type={errorMessage ? 'warning' : ''}
          isScroll
        />
      </span>
      <span className="buttons_section">
        <Button
          name={t(NEW_BATCH_BUTTON_NAME_LN)}
          styletype="green"
          classNameButton={''}
          classNameContainer={
            'export_confirmation_modal_button_container left_button'
          }
          onClick={handleClickCreateNewBatch}
        />
        <Button
          name={t(CLOSE_BUTTON_NAME_LN)}
          styletype="outline_blue"
          classNameButton={'new_batch_modal_buttons'}
          classNameContainer={'export_confirmation_modal_button_container'}
          onClick={handleCloseExportConfirmationModal}
        />
      </span>
    </Modal>
  )
}

export default memo(ExportConfirmationModal)
