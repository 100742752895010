export const _provider_types_data = [
  {
    name: 'ILEC',
    value: '00',
  },
  {
    name: 'IXC',
    value: '01',
  },
  {
    name: 'CLEC',
    value: '02',
  },
  {
    name: 'VOIP',
    value: '03',
  },
  {
    name: 'ISP',
    value: '04',
  },
  {
    name: 'Wireless',
    value: '05',
  },
]

export const provider_types_data = {
  ILEC: '00',
  IXC: '01',
  CLEC: '02',
  VOIP: '03',
  ISP: '04',
  Wireless: '05',
}
