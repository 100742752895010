import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { useTranslation } from 'react-i18next'

import Modal from 'components/ui/modal-component'
import Title from 'components/ui/title-component'
import Button from 'components/ui/button-component'
import Input from 'components/ui/input-component'
import InfoBox from 'components/ui/infobox-component'
import {
  getProfileFetchingStatusSelector,
  getProfileErrorMessageSelector,
} from 'redusers/profile'
import {
  closeConnectCsiFormModal,
  hideCsiFormModal,
} from 'actions/modals-state-actions'
import { clearProfileErrorMessage } from 'actions/profile-actions'
import {
  SIGN_IN_CSI_MODAL_TITLE_LN,
  SIGN_IN_CSI_MODAL_INPUT_PLACEHOLDER_LN,
  SIGN_IN_CSI_MODAL_BUTTON_NAME_1_LN,
  DEFAULT_CANCEL_BUTTON_LN,
} from 'constants/language-key-constants'

const SignInCsiFormModal = (props) => {
  const {
    isProfileFetching,
    handleSubmit,
    errorMessage,
    clearProfileErrorMessage,
    hideCsiFormModal,
  } = props

  const { t } = useTranslation()

  const handleClickScip = () => {
    hideCsiFormModal()
  }

  useEffect(() => {
    return () => {
      clearProfileErrorMessage()
    }
  }, [clearProfileErrorMessage])

  return (
    <Modal classNameWrap="signin_connect_modal_container form">
      <Title
        title={t(SIGN_IN_CSI_MODAL_TITLE_LN)}
        extraClassName="signin_connect_modal_title form"
      />
      {errorMessage && (
        <InfoBox
          textContent={errorMessage}
          extraClassNameContainer="show-animated"
          extraClassNameText=""
          styleType={'warning'}
        />
      )}
      <form
        className="login_form__inputs_container signin_connect_modal_form_container"
        onSubmit={handleSubmit}
      >
        <Input
          type="text"
          name="signInCsi"
          classNameContainer="login_form__input_email_container input_csi"
          classNameInput="login_form__input email"
          placeholder={t(SIGN_IN_CSI_MODAL_INPUT_PLACEHOLDER_LN)}
        />
        <Button
          styletype="green"
          classNameButton="signin_connect_modal_button login_form_sign_button form"
          disabled={isProfileFetching}
          name={t(SIGN_IN_CSI_MODAL_BUTTON_NAME_1_LN)}
          type="submit"
          isSpinner={isProfileFetching}
        />
      </form>
      {/* <Button
        name={t(SIGN_IN_CSI_MODAL_BUTTON_NAME_2_LN)}
        styletype="link"
        classNameButton="signin_connect_modal_button_link first"
        href={'mailto:support@csilongwood.com?subject=Customer support request'}
      /> */}
      <Button
        name={t(DEFAULT_CANCEL_BUTTON_LN)}
        styletype="link"
        classNameButton="signin_connect_modal_button_link"
        onClick={handleClickScip}
      />
    </Modal>
  )
}

SignInCsiFormModal.propTypes = {
  closeConnectCsiFormModal: PropTypes.func.isRequired,
  isProfileFetching: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  isProfileFetching: getProfileFetchingStatusSelector(state),
  errorMessage: getProfileErrorMessageSelector(state),
})

const actions = {
  closeConnectCsiFormModal,
  clearProfileErrorMessage,
  hideCsiFormModal,
}

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: 'connectCsi',
  })(SignInCsiFormModal)
)
