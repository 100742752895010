import React, { memo } from 'react'

import MapProducts from 'components/map-products'

function UnmappedProducts({ batchInvoicesProductData }) {
  return (
    <div className="new_batch_modal_availeble_invoices_container unmapped_products">
      <MapProducts
        isCreateBatch={true}
        batchInvoicesProductData={batchInvoicesProductData}
      />
    </div>
  )
}

export default memo(UnmappedProducts)
