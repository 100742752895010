/***MODAL STATE ACTIONS */
export const SET_PREV_SHOWN_MODAL_NAME = 'SET_PREV_SHOWN_MODAL_NAME'
export const CLEAR_PREV_SHOWN_MODAL_NAME = 'CLEAR_PREV_SHOWN_MODAL_NAME'
export const SHOW_QB_CONNECT_MODAL = 'SHOW_QB_CONNECT_MODAL'
export const HIDE_QB_CONNECT_MODAL = 'HIDE_QB_CONNECT_MODAL'
export const SHOW_CSI_CONNECT_MODAL = 'SHOW_CSI_CONNECT_MODAL'
export const HIDE_CSI_CONNECT_MODAL = 'HIDE_CSI_CONNECT_MODAL'
export const SHOW_CSI_FORM_MODAL = 'SHOW_CSI_FORM_MODAL'
export const HIDE_CSI_FORM_MODAL = 'HIDE_CSI_FORM_MODAL'
export const SHOW_CREATE_BATCH_MODAL = 'SHOW_CREATE_BATCH_MODAL'
export const HIDE_CREATE_BATCH_MODAL = 'HIDE_CREATE_BATCH_MODAL'
export const SHOW_CREATE_BATCH_AVILABLE_INVOICES_MODAL =
  'SHOW_CREATE_BATCH_AVILABLE_INVOICES_MODAL'
export const SHOW_PROCESSED_BATCH_MODAL = 'SHOW_PROCESSED_BATCH_MODAL'
export const HIDE_PROCESSED_BATCH_MODAL = 'HIDE_PROCESSED_BATCH_MODAL'
export const SHOW_EXPORT_CONFIRMATION_MODAL = 'SHOW_EXPORT_CONFIRMATION_MODAL'
export const HIDE_EXPORT_CONFIRMATION_MODAL = 'HIDE_EXPORT_CONFIRMATION_MODAL'
export const SHOW_INVOICE_DETAIL_MODAL = 'SHOW_INVOICE_DETAIL_MODAL'
export const HIDE_INVOICE_DETAIL_MODAL = 'HIDE_INVOICE_DETAIL_MODAL'
export const OPEN_INVOICE_DETAIL_MODAL = 'OPEN_INVOICE_DETAIL_MODAL'
export const CLOSE_INVOICE_DETAIL_MODAL = 'CLOSE_INVOICE_DETAIL_MODAL'
export const SHOW_INVOICE_TAX_SUMMARY_MODAL = 'SHOW_INVOICE_TAX_SUMMARY_MODAL'
export const HIDE_INVOICE_TAX_SUMMARY_MODAL = 'HIDE_INVOICE_TAX_SUMMARY_MODAL'
export const OPEN_INVOICE_TAX_SUMMARY_MODAL = 'OPEN_INVOICE_TAX_SUMMARY_MODAL'
export const CLOSE_INVOICE_TAX_SUMMARY_MODAL = 'CLOSE_INVOICE_TAX_SUMMARY_MODAL'
export const SHOW_PROFILE_MENU_MODAL = 'SHOW_PROFILE_MENU_MODAL'
export const HIDE_PROFILE_MENU_MODAL = 'HIDE_PROFILE_MENU_MODAL'
export const OPEN_CONNECT_CSI_FORM_MODAL = 'OPEN_CONNECT_CSI_FORM_MODAL'
export const CLOSE_CONNECT_CSI_FORM_MODAL = 'CLOSE_CONNECT_CSI_FORM_MODAL'
export const SHOW_SETTINGS_MENU_MODAL = 'SHOW_SETTINGS_MENU_MODAL'
export const HIDE_SETTINGS_MENU_MODAL = 'HIDE_SETTINGS_MENU_MODAL'
export const SHOW_CONFIRMATION_MODAL = 'SHOW_CONFIRMATION_MODAL'
export const HIDE_CONFIRMATION_MODAL = 'HIDE_CONFIRMATION_MODAL'
export const SHOW_PROCESSING_INVOICES_MODAL = 'SHOW_PROCESSING_INVOICES_MODAL'
export const HIDE_PROCESSING_INVOICES_MODAL = 'HIDE_PROCESSING_INVOICES_MODAL'
export const CLEAR_CURRENT_SHOWN_MODAL_NAME = 'CLEAR_CURRENT_SHOWN_MODAL_NAME'
export const SET_PROFILE_MODAL_STATE = 'SET_PROFILE_MODAL_STATE'
export const SHOW_REPORT_FILTER_MODAL = 'SHOW_REPORT_FILTER_MODAL'
export const HIDE_REPORT_FILTER_MODAL = 'HIDE_REPORT_FILTER_MODAL'
export const SHOW_LOGS_MODAL = 'SHOW_LOGS_MODAL'
export const HIDE_LOGS_MODAL = 'HIDE_LOGS_MODAL'
export const SHOW_SUCCES_CONNECTED_CSI_MODAL = 'SHOW_SUCCES_CONNECTED_CSI_MODAL'
export const HIDE_SUCCES_CONNECTED_CSI_MODAL = 'HIDE_SUCCES_CONNECTED_CSI_MODAL'
export const SHOW_INVOICES_FILTER_MODAL = 'SHOW_INVOICES_FILTER_MODAL'
export const HIDE_INVOICES_FILTER_MODAL = 'HIDE_INVOICES_FILTER_MODAL'

/***CHECKBOX STATE ACTIONS */
export const SET_SELECTED_STATE = 'SET_SELECTED_STATE'
export const SET_ONLY_SELECTED_ID = 'SET_ONLY_SELECTED_ID'
export const CLEAR_SELECTED_STATE = 'CLEAR_SELECTED_STATE'
export const SET_NEW_VALUE_IN_SELECTED_STATE = 'SET_NEW_VALUE_IN_SELECTED_STATE'

/***ALERT */
export const SHOW_ALERT = 'SHOW_ALERT'
export const HIDE_ALERT = 'HIDE_ALERT'
export const START_SHOW_ALERT = 'START_SHOW_ALERT'
export const SET_MESSAGE = 'SET_MESSAGE'

/***PROFILE */
export const SET_IS_LOADING = 'SET_IS_LOADING'
export const SET_IS_AUTS = 'SET_IS_AUTS'
export const CHECK_AUTH_STATUS = 'CHECK_AUTH_STATUS'
export const SIGN_IN = 'SIGN_IN'
export const GET_PROFILE_INFO = 'GET_PROFILE_INFO'
export const SET_PROFILE_INFO = 'SET_PROFILE_INFO'
export const REFRESH = 'REFRESH'
export const LOGOUT = 'LOGOUT'
export const SET_PROFILE_ERROR_MESSAGE = 'SET_PROFILE_ERROR_MESSAGE'
export const CLEAR_PROFILE_ERROR_MESSAGE = 'CLEAR_PROFILE_ERROR_MESSAGE'
export const SET_PROFILE_FETCHING = 'SET_PROFILE_FETCHING'
export const OPEN_PROFILE_MENU = 'OPEN_PROFILE_MENU'
export const CLEAR_USER_PROFILE_DATA = 'CLEAR_USER_PROFILE_DATA'
export const UPDATE_PROFILE_DATA = 'UPDATE_PROFILE_DATA'
export const SET_SUCCESS_MESSAGE = 'SET_SUCCESS_MESSAGE'
export const CLEAR_SUCCESS_MESSAGE = 'CLEAR_SUCCESS_MESSAGE'
export const LOGOUT_AFTER_IDLE = 'LOGOUT_AFTER_IDLE'

/***QB */
export const GET_QB_AUTH_URL = 'GET_QB_AUTH_URL'
export const SET_QB_AUTH_URL = 'SET_QB_AUTH_URL'
export const LOGOUT_QB = 'LOGOUT_QB'
export const QB_AUTH_LOGIN = 'QB_AUTH_LOGIN'
export const SET_QB_CONNECT_STATUS = 'SET_QB_CONNECT_STATUS'
export const SET_FETCHING_QB_CONNECT = 'SET_FETCHING_QB_CONNECT'
export const LOGOUT_QB_BY_USER_ID = 'LOGOUT_QB_BY_USER_ID'

/***batches */
export const SET_OFFSET_COUNT_FOR_GETTING_BATCH =
  'SET_OFFSET_COUNT_FOR_GETTING_BATCH'
export const SET_FROM_DATE_TO_NEW_BATCH = 'SET_FROM_DATE_TO_NEW_BATCH'
export const SET_TO_DATE_TO_NEW_BATCH = 'SET_TO_DATE_TO_NEW_BATCH'
export const CLERA_DATE_TO_NEW_BATCH = 'CLERA_DATE_TO_NEW_BATCH'
export const RETRIEVE_UPDATED_INVOICES_AND_PRODUCTS_FOR_BATCH =
  'RETRIEVE_UPDATED_INVOICES_AND_PRODUCTS_FOR_BATCH'
export const SET_UPDATED_INVOICES_AND_PRODUCTS_FOR_BATCH =
  'SET_UPDATED_INVOICES_AND_PRODUCTS_FOR_BATCH'
export const SET_IS_FETCHING_BATCH = 'SET_IS_FETCHING_BATCH'
export const CREATE_BATCH = 'CREATE_BATCH'
export const SET_ERROR_MESSAGE_IN_CREATE_BATCHES =
  'SET_ERROR_MESSAGE_IN_CREATE_BATCHES'
export const CLEAR_ERROR_MESSAGE_IN_ERROR_BATCHES =
  'CLEAR_ERROR_MESSAGE_IN_ERROR_BATCHES'
export const GET_BATCHES = 'GET_BATCHES'
export const SET_BATCHES = 'SET_BATCHES'
export const UPDATE_BATCHES = 'UPDATE_BATCHES'
export const GET_INVOICE_DETAIL_FROM_BATCH = 'GET_INVOICE_DETAIL_FROM_BATCH'
export const SET_IS_PAGGINATION = 'SET_IS_PAGGINATION'
export const SEND_BATCH = 'SEND_BATCH'
export const GET_BATCH_DETAIL_BY_ID = 'GET_BATCH_DETAIL_BY_ID'
export const SET_BATCH_DETAIL = 'SET_BATCH_DETAIL'
export const CLEAR_BATCH_DETAIL = 'CLEAR_BATCH_DETAIL'
export const EXPORT_TO_QB = 'EXPORT_TO_QB'
export const CLEAR_ALL_DATA_BATCHES = 'CLEAR_ALL_DATA_BATCHES'
export const SET_IS_PROCESSED_BATCH_ID = 'SET_IS_PROCESSED_BATCH_ID'
export const START_FETCHING_CREATE_BATCH = 'START_FETCHING_CREATE_BATCH'
export const STOP_FETCHING_CREATE_BATCH = 'STOP_FETCHING_CREATE_BATCH'

export const GET_BATCH_INVOICES = 'GET_BATCH_INVOICES'
export const SET_BATCH_INVOICES = 'SET_BATCH_INVOICES'
export const UPDATE_INVOICES_IN_BATCH_DETAIL = 'UPDATE_INVOICES_IN_BATCH_DETAIL'
export const GET_BATCH_INVOICES_IN_BATCH_DETAIL =
  'GET_BATCH_INVOICES_IN_BATCH_DETAIL'
export const START_FETCHING_GET_BATCH_INVOICES_IN_BATCH_DETAIL =
  'START_FETCHING_GET_BATCH_INVOICES_IN_BATCH_DETAIL'
export const STOP_FETCHING_GET_BATCH_INVOICES_IN_BATCH_DETAIL =
  'STOP_FETCHING_GET_BATCH_INVOICES_IN_BATCH_DETAIL'
export const START_FETCHING_GET_BATCH_INVOICES_IN_BATCH =
  'START_FETCHING_GET_BATCH_INVOICES_IN_BATCH'
export const STOP_FETCHING_GET_BATCH_INVOICES_IN_BATCH =
  'STOP_FETCHING_GET_BATCH_INVOICES_IN_BATCH'
export const SET_SEARCH_VALUE_BATCHES = 'SET_SEARCH_VALUE_BATCHES'
export const CLEAR_SEARCH_VALUE_BACHES = 'CLEAR_SEARCH_VALUE_BACHES'
export const SET_SELECTED_TRANSACTIONS_TYPE = 'SET_SELECTED_TRANSACTIONS_TYPE'
export const GET_TAX_TYPE = 'GET_TAX_TYPE'
export const SET_TAX_TYPE = 'SET_TAX_TYPE'

/***invoice detail */
export const SET_INVOICE_DETAIL = 'SET_INVOICE_DETAIL'
export const CLEAR_INVOICE_DETAIL = 'CLEAR_INVOICE_DETAIL'
export const GET_INVOICE_DETAIL_FROM_AVAILABLE_INVOICES =
  'GET_INVOICE_DETAIL_FROM_AVAILABLE_INVOICES'
export const GET_INVOICES_DETAIL_FROM_PROCESSED_INVOICES =
  'GET_INVOICES_DETAIL_FROM_PROCESSED_INVOICES'
export const CLEAR_BATCHES = 'CLEAR_BATCHES'
export const DELETE_INVOICE = 'DELETE_INVOICE'
export const GET_CSV_REPORT_INVOICE_DETAIL = 'GET_CSV_REPORT_INVOICE_DETAIL'
export const GET_CSV_REPORT_INVOICE_TAX_SUMMARY =
  'GET_CSV_REPORT_INVOICE_TAX_SUMMARY'
export const GET_INVOICE_DETAIL_FROM_BACKEND = 'GET_INVOICE_DETAIL_FROM_BACKEND'
export const GET_INVOICE_CSI_OPERATIONS = 'GET_INVOICE_CSI_OPERATIONS'
export const SET_INVOICE_CSI_OPERATIONS = 'SET_INVOICE_CSI_OPERATIONS'

/***products */
export const SET_IS_PRODUCTS_FETCHING = 'SET_IS_PRODUCTS_FETCHING'
export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS'
export const SET_PRODUCTS = 'SET_PRODUCTS'
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS'
export const SET_IS_FETCHING_CSI_PRODUCTS = 'SET_IS_FETCHING_CSI_PRODUCTS'
export const GET_CSI_PRODUCTS = 'GET_CSI_PRODUCTS'
export const SET_CSI_PRODUCTS = 'SET_CSI_PRODUCTS'
export const CLEAR_CSI_PRODUCTS = 'CLEAR_CSI_PRODUCTS'
export const MAP_PRODUCT = 'MAP_PRODUCT'
export const PULL_PRODUCTS_SERVICES_FROM_QUICKBOOKS =
  'PULL_PRODUCTS_SERVICES_FROM_QUICKBOOKS'
export const UNMAP_PRODUCTS = 'UNMAP_PRODUCTS'
export const SET_IS_FETCHING_UNMAP_PRODUCTS = 'SET_IS_FETCHING_UNMAP_PRODUCTS'
export const UPDATE_PIU = 'UPDATE_PIU'
export const SET_IS_PIU_FETCHING = 'SET_IS_PIU_FETCHING'
export const SHOW_INFOBOX_ABOUT_PIU = 'SHOW_INFOBOX_ABOUT_PIU'
export const SHOW_MAPPED_PRODUCT_ALERT = 'SHOW_MAPPED_PRODUCT_ALERT'
export const SHOW_UNMAPPED_PRODUCTS_ALERT = 'SHOW_UNMAPPED_PRODUCTS_ALERT'
export const UPDATE_PRODUCTS_DATA_AFTER_MAPPED =
  'UPDATE_PRODUCTS_DATA_AFTER_MAPPED'
export const GET_TAX_EXEMPTIONS_LIST = 'GET_TAX_EXEMPTIONS_LIST'
export const SET_TAX_EXEMPTIONS_LIST = 'SET_TAX_EXEMPTIONS_LIST'
export const UPDATE_CUSTOMER_TAX_EXEMPTION = 'UPDATE_CUSTOMER_TAX_EXEMPTION'
export const UPDATE_DELETED_INVOICE_IN_BATCHES =
  'UPDATE_DELETED_INVOICE_IN_BATCHES'

/***confirmation */
export const CALL_YES_FUNCTION_CONFIRMATION = 'CALL_YES_FUNCTION_CONFIRMATION'

/*** firebase notification */
export const START_GETTING_FIREBASE_NOTIFICATIONS =
  'START_GETTING_FIREBASE_NOTIFICATIONS'
export const SET_FIREBASE_TOKEN = 'SET_FIREBASE_TOKEN'
export const SET_FIREBASE_ERROR = 'SET_FIREBASE_ERROR'
export const SET_FIREBASE_PERMISSION_STATUS = 'SET_FIREBASE_PERMISSION_STATUS'
export const GET_FIREBASE_NOTIFICATION = 'GET_FIREBASE_NOTIFICATION'
export const HANDLE_FIREBASE_NOTIFICATION = 'HANDLE_FIREBASE_NOTIFICATION'

/*** back end notification */
export const GET_NOTIFICATION_FROM_BACK_END = 'GET_NOTIFICATION_FROM_BACK_END'
export const SET_NOTIFICATIONS_FROM_BACK_END = 'SET_NOTIFICATIONS_FROM_BACK_END'
export const MARK_AS_READ = 'MARK_AS_READ'

/*** users  */
export const START_USERS_FETCHING = 'START_USERS_FETCHING'
export const STOP_USERS_FETCHING = 'STOP_USERS_FETCHING'
export const GET_USERS = 'GET_USERS'
export const SET_USERS = 'SET_USERS'
export const CREATE_USER = 'CREATE_USER'
export const EDIT_USER = 'EDIT_USER'
export const DELETE_USER = 'DELETE_USER'
export const SET_SELECTED_USER_ID = 'SET_SELECTED_USER_ID'
export const CLEAR_SELECTED_USER_ID = 'CLEAR_SELECTED_USER_ID'
export const TOGGLE_ACTIVATE_STATUS_USER = 'TOGGLE_ACTIVATE_STATUS_USER'
export const SET_IS_SIGN_IN_AS_USER = 'SET_IS_SIGN_IN_AS_USER'
export const SIGN_IN_AS_USER = 'SIGN_IN_AS_USER'
export const SET_SEARCH_VALUE_USERS = 'SET_SEARCH_VALUE_USERS'
export const CLEAR_SEARCH_VALUE_USERS = 'CLEAR_SEARCH_VALUE_USERS'
export const CONNECT_CSI_ACCOUNT = 'CONNECT_CSI_ACCOUNT'
export const DISCONNECT_CSI_ACCOUNT = 'DISCONNECT_CSI_ACCOUNT'
/*** companies  */
export const GET_COMPANIES = 'GET_COMPANIES'
export const SET_COMPANIES = 'SET_COMPANIES'
export const START_COMPANIES_FETCHING = 'START_COMPANIES_FETCHING'
export const STOP_COMPANIES_FETCHING = 'STOP_COMPANIES_FETCHING'
export const CLEAR_COMPANIES_LIST = 'CLEAR_COMPANIES_LIST'
export const GET_BATCHES_BY_COMPANY = 'GET_BATCHES_BY_COMPANY'
export const SET_SEARCH_BATCH_FROM_COMPANY_VALUE =
  'SET_SEARCH_BATCH_FROM_COMPANY_VALUE'
export const SET_CHOSEN_REALM_ID_COMPANY = 'SET_CHOSEN_REALM_ID_COMPANY'
export const CLEAR_SEARCH_VALUE_COMPANIES = 'CLEAR_SEARCH_VALUE_COMPANIES'

/*** reports  */
export const SET_ISSUE_DATE_TIME_FROM = 'SET_ISSUE_DATE_TIME_FROM'
export const SET_ISSUE_DATE_TIME_TO = 'SET_ISSUE_DATE_TIME_TO'
export const CLEAR_REPORTS_DATE_RANGE_TIME = 'CLEAR_REPORTS_DATE_RANGE_TIME'
export const SET_OFFSET_TAX_SUMMARY_REPORT = 'SET_OFFSET_TAX_SUMMARY_REPORT'
export const SET_TAX_SUMMARY_REPORTS = 'SET_TAX_SUMMARY_REPORTS'
export const CLEAR_TAX_SUMMARY_REPORTS = 'CLEAR_TAX_SUMMARY_REPORTS'
export const STAR_FETCHING_REPORTS = 'STAR_FETCHING_REPORTS'
export const STOP_FETCHING_REPORTS = 'STOP_FETCHING_REPORTS'
export const GET_TAX_SUMMARY_REPORTS = 'GET_TAX_SUMMARY_REPORTS'
export const UPDATE_TAX_SUMMARY_REPORTS = 'UPDATE_TAX_SUMMARY_REPORTS'
export const EXPORT_CSV_TAX_SUMMARY_REPORTS = 'EXPORT_CSV_TAX_SUMMARY_REPORTS'
export const SET_QB_PORTAL_COMPARISON_REPORTS =
  'SET_QB_PORTAL_COMPARISON_REPORTS'
export const UPDATE_QB_PORTAL_COMPARISON_REPORTS =
  'UPDATE_QB_PORTAL_COMPARISON_REPORTS'
export const CLEAR_QB_PORTAL_COMPARISON_REPORTS =
  'CLEAR_QB_PORTAL_COMPARISON_REPORTS'
export const SET_PROCESSED_DATE_TIME_FROM = 'SET_PROCESSED_DATE_TIME_FROM'
export const SET_PROCESSED_DATE_TIME_TO = 'SET_PROCESSED_DATE_TIME_TO'
export const GET_QB_PORTAL_COMPARISON_REPORTS =
  'GET_QB_PORTAL_COMPARISON_REPORTS'
export const EXPORT_CSV_QB_PORTAL_COMPARISON_REPORTS =
  'EXPORT_CSV_QB_PORTAL_COMPARISON_REPORTS'
export const SET_IS_SHOW_INFOBOX_IF_EMPTY_DATA_TABLE =
  'SET_IS_SHOW_INFOBOX_IF_EMPTY_DATA_TABLE'
export const START_FETCHING_EXPORT_CSV = 'START_FETCHING_EXPORT_CSV'
export const STOP_FETCHING_EXPORT_CSV = 'STOP_FETCHING_EXPORT_CSV'
export const SET_SEARCH_VALUE_QB_PORTAL_COMPARISON_REPORTS =
  'SET_SEARCH_VALUE_QB_PORTAL_COMPARISON_REPORTS'
export const SET_SEARCH_VALUE_TAX_SUMMARY_REPORTS =
  'SET_SEARCH_VALUE_TAX_SUMMARY_REPORTS'
export const CLEAR_SERCH_VALUES_REPORTS = 'CLEAR_SERCH_VALUES_REPORTS'
export const GET_SUM_TOTAL_FOR_REPORTS = 'GET_SUM_TOTAL_FOR_REPORTS'
export const SET_SUM_TOTAL_FOR_REPORTS = 'SET_SUM_TOTAL_FOR_REPORTS'
export const SET_ERROR_MESSAGE_FOR_MTS_REPORT =
  'SET_ERROR_MESSAGE_FOR_MTS_REPORT'
export const CLEAR_ERROR_MESSAGE_FOR_MTS_REPORT =
  'CLEAR_ERROR_MESSAGE_FOR_MTS_REPORT'
export const SET_HAS_ZERO_TAX_QB_REPORT = 'SET_HAS_ZERO_TAX_QB_REPORT'

/*** logs */
export const START_FETCHING_LOGS = 'START_FETCHING_LOGS'
export const STOP_FETCHING_LOGS = 'STOP_FETCHING_LOGS'
export const GET_LOGS_TYPES = 'GET_LOGS_TYPES'
export const SET_LOGS_TYPES = 'SET_LOGS_TYPES'
export const GET_LOGS = 'GET_LOGS'
export const SET_LOGS = 'SET_LOGS'
export const EXPORT_LOGS_CSV = 'EXPORT_LOGS_CSV'
export const SET_TO_DATE_LOGS = 'SET_TO_DATE_LOGS'
export const SET_FROM_DATE_LOGS = 'SET_FROM_DATE_LOGS'
export const CLEAR_LOGS = 'CLEAR_LOGS'
export const SET_SELECTED_TYPES = 'SET_SELECTED_TYPES'

/** INVOICES */
export const GET_INVOICES = 'GET_INVOICES'
export const SET_INVOICES = 'SET_INVOICES'
export const SET_SEARCH_VALUE_FOR_INVOICES = 'SET_SEARCH_VALUE_FOR_INVOICES'
export const SET_ORDER_BY_FOR_INVOICES_SORT = 'SET_ORDER_BY_FOR_INVOICES_SORT'
export const SET_QB_CONNECT_STATUS_FOR_INVOICES_FILTERING =
  'SET_QB_CONNECT_STATUS_FOR_INVOICES_FILTERING'
export const SET_CSI_STATUS_FOR_INVOICES_FILTERING =
  'SET_CSI_STATUS_FOR_INVOICES_FILTERING'
export const SET_FROM_DATE_FOR_INVOICES_FILTERING =
  'SET_FROM_DATE_FOR_INVOICES_FILTERING'
export const SET_TO_DATE_FOR_INVOICES_FILTERING =
  'SET_TO_DATE_FOR_INVOICES_FILTERING'
export const SET_OFFSET_GETTING_INVOICES = 'SET_OFFSET_GETTING_INVOICES'
export const CLEAR_ALL_INVOICES_STATE = 'CLEAR_ALL_INVOICES_STATE'
export const CLEAR_INVOICES = 'CLEAR_INVOICES'
export const CLEAR_SORT_PARAM = 'CLEAR_SORT_PARAM'
export const CLEAR_FILTER_PARAM = 'CLEAR_FILTER_PARAM'
export const CLEAR_SEARCH_VALUE = 'CLEAR_SEARCH_VALUE'
export const START_FETCHING_INVOICES = 'START_FETCHING_INVOICES'
export const STOP_FETCHING_INVOICES = 'STOP_FETCHING_INVOICES'
export const SET_INVOICES_FROM_PAGGINATION = 'SET_INVOICES_FROM_PAGGINATION'
export const UPDATE_INVOICES = 'UPDATE_INVOICES'
