import React, { useState, useEffect, memo, useCallback } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { useShallowSelector } from 'custom-hooks/useShallowSelector'
import InfoBox from 'components/ui/infobox-component'
import Modal from 'components/ui/modal-component'
import Title from 'components/ui/title-component'
import GetInvoicesButtons from './components/GetInvoicesButtons'
import DatesChoose from './components/DatesChoose'
import AvailableInvoices from './components/AvailableInvoices'
import GenerateInvoicesButtons from './components/GenerateInvoicesButtons'
import UnmappedProducts from './components/UnmappedProducts'
import {
  getFromDateToCreatingBatchSelector,
  getToDateToCreatingBatchSelector,
  getIsFetchingCreateBatch,
  getErrorMessageForNewBatchSelector,
  getBatchInvoicesUnmappenProduct,
  //getTaxExemptionListSelector,
  getTransactionsTypeCreatingBatchSelector,
} from 'redusers/batches'
import { getIsShowAvailableInvoicesSelector } from 'redusers/modalsState'
import {
  clearDateToNewBatch,
  getRetriveUpdatedInvoicesAndProductsForBatch,
  clearErrorMessageForNewBatch,
  createBatch,
  getTaxExemptionList,
  getTaxType,
} from 'actions/batches-actions'
import {
  showConfirmationModal,
  clearCurrentShownModalName,
} from 'actions/modals-state-actions'
import { clearSelectedState } from 'actions/checkbox-actions'
import { getDataSelectedStateSelector } from 'redusers/checkbox-state'
import {
  NEW_BATCH_MODAL_TOOLTIP_LN,
  NEW_BATCH_MODAL_INFOBOX_MESSAGE_1_LN,
  NEW_BATCH_MODAL_INFOBOX_MESSAGE_2_LN,
  NEW_BATCH_MODAL_TITLE_LN,
  NEW_BATCH_MODAL_INFOBOX_MESSAGE_3_LN,
  NEW_BATCH_CONFIRMATION_TITLE_1_LN,
  NEW_BATCH_CONFIRMATION_TITLE_2_LN,
  NEW_BATCH_CONFIRMATION_TITLE_3_LN,
} from 'constants/language-key-constants'

const NewBatchCreateModal = (props) => {
  const {
    handleCloseNewBatch,
    clearDateToNewBatch,
    getRetriveUpdatedInvoicesAndProductsForBatch,
    clearErrorMessageForNewBatch,
    handleClickInvoiceDetail,
    createBatch,
    showConfirmationModal,
    clearCurrentShownModalName,
    clearSelectedState,
    getTaxExemptionList,
    getTaxType,
  } = props

  const fromDate = useShallowSelector(getFromDateToCreatingBatchSelector)
  const toDate = useShallowSelector(getToDateToCreatingBatchSelector)
  const isFetchingBatch = useShallowSelector(getIsFetchingCreateBatch)
  const isShownAvailableInvoices = useShallowSelector(
    getIsShowAvailableInvoicesSelector
  )
  const errorMessage = useShallowSelector(getErrorMessageForNewBatchSelector)
  const unmappedProduct = useShallowSelector(getBatchInvoicesUnmappenProduct)
  const selectedInvoices = useShallowSelector(getDataSelectedStateSelector)

  //const taxExemptionList = useShallowSelector(getTaxExemptionListSelector)

  const transactioType = useShallowSelector(
    getTransactionsTypeCreatingBatchSelector
  )

  const { t } = useTranslation()
  const [isValid, setIsValid] = useState(true)

  const handleCloseModal = () => {
    if (isShownAvailableInvoices) {
      showConfirmationModal({
        cb: _handleCloseModal,
        title: t(NEW_BATCH_CONFIRMATION_TITLE_1_LN),
      })
    } else {
      showConfirmationModal({
        cb: _handleCloseModal,
        title: t(NEW_BATCH_CONFIRMATION_TITLE_3_LN),
      })
    }
    return
  }

  const _handleCloseModal = useCallback(() => {
    clearDateToNewBatch()
    handleCloseNewBatch()
    clearCurrentShownModalName()
    clearSelectedState()
  }, [
    clearCurrentShownModalName,
    clearDateToNewBatch,
    clearSelectedState,
    handleCloseNewBatch,
  ])

  const handleErrorDate = useCallback(
    (message) => {
      if (message && isValid) {
        setIsValid(false)
        return
      }
      if (!isValid && !message) {
        setIsValid(true)
        return
      }
    },
    [isValid]
  )

  const handleGetInvoices = useCallback(() => {
    const body = {
      fromDate,
      toDate,
      transaction_type: transactioType,
    }
    getRetriveUpdatedInvoicesAndProductsForBatch(body)
  }, [
    fromDate,
    getRetriveUpdatedInvoicesAndProductsForBatch,
    toDate,
    transactioType,
  ])

  const handleGenerateInvoices = useCallback(() => {
    const selectedInvoicesId = selectedInvoices.reduce((selected, item) => {
      if (
        item.selected &&
        !item.is_deleted &&
        item.zipCode &&
        !item.withoutQuantity &&
        !item.isUnmapProductService
      ) {
        selected.push({
          qb_transaction_id: item._id,
          zip_code: item.zipCode,
        })
      }
      return selected
    }, [])

    showConfirmationModal({
      cb: createBatch,
      arg: selectedInvoicesId,
      title: t(NEW_BATCH_CONFIRMATION_TITLE_2_LN),
    })
  }, [createBatch, selectedInvoices, showConfirmationModal, t])

  useEffect(() => {
    return () => {
      clearErrorMessageForNewBatch()
    }
  }, [clearErrorMessageForNewBatch])

  useEffect(() => {
    getTaxExemptionList()
    getTaxType()
    if (!isShownAvailableInvoices) {
      clearDateToNewBatch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getIsNoneSelected = () => {
    const _selectedInvoices = selectedInvoices.filter(
      (item) => !item.is_deleted && item.zipCode
    )
    return _selectedInvoices.every((item) => !item.selected)
  }

  const _textToolTip = t(NEW_BATCH_MODAL_TOOLTIP_LN)

  const infoBoxMessage = isFetchingBatch
    ? t(NEW_BATCH_MODAL_INFOBOX_MESSAGE_1_LN)
    : errorMessage || t(NEW_BATCH_MODAL_INFOBOX_MESSAGE_2_LN)

  return (
    <Modal
      classNameWrap={'new_batch_modal_container'}
      classNameContainer={'new_batch'}
      isCloseButton={!isFetchingBatch}
      handleClickClose={handleCloseModal}
    >
      <span className="new_batch_modal_title_container">
        <Title
          title={t(NEW_BATCH_MODAL_TITLE_LN)}
          extraClassName="new_batch_title"
          withTooltip={true}
          textTooltip={_textToolTip}
          placementTooltip={'top'}
        />
        {(!!errorMessage || isFetchingBatch) && !isShownAvailableInvoices ? (
          <InfoBox
            textContent={infoBoxMessage}
            extraClassNameContainer="info_box_new_batch show-animated"
            extraClassNameText={'info_box_unmaped_products_text'}
          />
        ) : (
          !isShownAvailableInvoices && (
            <InfoBox
              textContent={t(NEW_BATCH_MODAL_INFOBOX_MESSAGE_3_LN)}
              extraClassNameContainer="info_box_new_batch_date_range show-animated"
              extraClassNameText={'info_box_unmaped_products_text'}
            />
          )
        )}
      </span>
      <DatesChoose
        fromDate={fromDate}
        toDate={toDate}
        handleErrorDate={handleErrorDate}
        disabled={isShownAvailableInvoices}
        transactioType={transactioType}
      />
      {isShownAvailableInvoices ? (
        <>
          <UnmappedProducts batchInvoicesProductData={unmappedProduct} />
          <AvailableInvoices
            handleClickInvoiceDetail={handleClickInvoiceDetail}
            maxHeightInvoicesTable={unmappedProduct.length ? 125 : 225}
          />
          <GenerateInvoicesButtons
            isFetchingBatch={isFetchingBatch}
            clearDateToNewBatch={handleCloseModal}
            handleGenerateInvoices={handleGenerateInvoices}
            isNoneSelected={getIsNoneSelected()}
            //disabledButtonGenerate={!!unmappedProduct.length}
          />
        </>
      ) : (
        <GetInvoicesButtons
          isValid={isValid}
          isFetchingBatch={isFetchingBatch}
          handleGetInvoices={handleGetInvoices}
          clearDateToNewBatch={handleCloseModal}
        />
      )}
    </Modal>
  )
}

const actions = {
  clearDateToNewBatch,
  getRetriveUpdatedInvoicesAndProductsForBatch,
  clearErrorMessageForNewBatch,
  createBatch,
  showConfirmationModal,
  clearCurrentShownModalName,
  clearSelectedState,
  getTaxExemptionList,
  getTaxType,
}

export default connect(null, actions)(memo(NewBatchCreateModal))
