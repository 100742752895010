import React, { memo } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ADMIN_ROLE, CLIENT_ROLE } from 'constants/roles-constant'
import {
  //HEADER_BATCHES_ITEM_MENU_LN,
  HEADER_REPORTS_ITEM_MENU_LN,
  HEADER_INVOICES_ITEM_MENU_LN,
  HEADER_LOGS_ITEM_MENU_LN,
  HEADER_USERS_ITEM_MENU_LN,
  COMPANIES_NAV_MENU_LN,
} from 'constants/language-key-constants'

const NavMenu = (props) => {
  const { roleUser } = props
  const { t } = useTranslation()
  return (
    <nav className="header_section header_dashboard_menu_section">
      {/* {roleUser === CLIENT_ROLE && (
        <NavLink
          className="header_menu_item"
          activeClassName="active"
          to="/batches"
        >
          {t(HEADER_BATCHES_ITEM_MENU_LN)}
        </NavLink>
      )} */}
      {roleUser === CLIENT_ROLE && (
        <NavLink
          className="header_menu_item"
          activeClassName="active"
          to="/transactions"
        >
          {t(HEADER_INVOICES_ITEM_MENU_LN)}
        </NavLink>
      )}
      {roleUser === CLIENT_ROLE && (
        <NavLink
          className="header_menu_item"
          activeClassName="active"
          to="/reports"
        >
          {t(HEADER_REPORTS_ITEM_MENU_LN)}
        </NavLink>
      )}
      {roleUser === ADMIN_ROLE && (
        <NavLink
          className="header_menu_item"
          activeClassName="active"
          to="/companies"
        >
          {t(COMPANIES_NAV_MENU_LN)}
        </NavLink>
      )}
      {roleUser === ADMIN_ROLE && (
        <NavLink
          className="header_menu_item"
          activeClassName="active"
          to="/logs"
        >
          {t(HEADER_LOGS_ITEM_MENU_LN)}
        </NavLink>
      )}
      {roleUser === ADMIN_ROLE && (
        <NavLink
          className="header_menu_item"
          activeClassName="active"
          to="/users"
        >
          {t(HEADER_USERS_ITEM_MENU_LN)}
        </NavLink>
      )}
    </nav>
  )
}

export default memo(NavMenu)
