import React, { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import PowerSettingsNewRoundedIcon from '@material-ui/icons/PowerSettingsNewRounded'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined'
import { CLIENT_ROLE } from 'constants/roles-constant'

import {
  showConfirmationModal,
  showCsiFormModal,
} from 'actions/modals-state-actions'
import {
  setSelectedUserId,
  signInAsUser,
  deleteUser,
  disconnectCsiAcc,
} from 'actions/users'
import { logoutQbByUserId } from 'actions/qb-actions'
import { useToggleMenu } from 'custom-hooks/useToggleMenu'
import {
  USERS_PAGE_USER_MENU_CONFIRMATION_TITLE_1_LN,
  USERS_PAGE_USER_MENU_CONFIRMATION_BUTTON_1_LN,
  DEFAULT_CANCEL_BUTTON_LN,
  USERS_PAGE_USER_MENU_CONFIRMATION_MESSAGE_1_LN,
  USERS_PAGE_USER_MENU_ITEM_1_LN,
  USERS_PAGE_USER_MENU_ITEM_2_LN,
  USERS_PAGE_USER_MENU_ITEM_3_LN,
  USERS_PAGE_USER_MENU_ITEM_4_LN,
  USER_MENU_CONFIRMATION_TITLE_WORD_1_LN,
  USER_MENU_CONFIRMATION_TITLE_WORD_2_LN,
  CONNECT_CSI_BTN_LN,
  DISCONNECT_CSI_BTN_LN,
} from 'constants/language-key-constants'
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined'

const UsersMenu = (props) => {
  const {
    usersId,
    handleClickEditUser,
    userName,
    quickbooks_enabled,
    role,
    csi_enabled,
  } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useToggleMenu(false)

  const handleClickMenu = useCallback(() => {
    if (!isOpen) {
      dispatch(setSelectedUserId(usersId))
    }
    setIsOpen(!isOpen)
  }, [dispatch, isOpen, setIsOpen, usersId])

  const handleClickQbDisconect = useCallback(() => {
    const _toggleActivateStatusUser = dispatch.bind(
      this,
      logoutQbByUserId(usersId)
    )
    dispatch(
      showConfirmationModal({
        cb: _toggleActivateStatusUser,
        title: `${t(USER_MENU_CONFIRMATION_TITLE_WORD_1_LN)} ${userName} ${t(
          USER_MENU_CONFIRMATION_TITLE_WORD_2_LN
        )}`,
        yesButtonName: t(USER_MENU_CONFIRMATION_TITLE_WORD_1_LN),
        noButtonName: t(DEFAULT_CANCEL_BUTTON_LN),
      })
    )
  }, [dispatch, t, userName, usersId])

  const handleClickSignInAsUser = useCallback(() => {
    const _signInAsUser = dispatch.bind(this, signInAsUser())
    dispatch(
      showConfirmationModal({
        cb: _signInAsUser,
        title: t(USERS_PAGE_USER_MENU_CONFIRMATION_TITLE_1_LN),
        yesButtonName: t(USERS_PAGE_USER_MENU_CONFIRMATION_BUTTON_1_LN),
        noButtonName: t(DEFAULT_CANCEL_BUTTON_LN),
        middleMessage: `${t(
          USERS_PAGE_USER_MENU_CONFIRMATION_MESSAGE_1_LN
        )} ${userName}.`,
        isCloseIconButton: true,
      })
    )
  }, [dispatch, t, userName])

  const handleClickDeleteUser = useCallback(() => {
    dispatch(
      showConfirmationModal({
        cb: removeUser,
        title: `Delete ${userName}? This cannot be undone.`,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const removeUser = useCallback(() => {
    dispatch(deleteUser())
  }, [dispatch])

  const handleConnectCsiUser = useCallback(() => {
    dispatch(showCsiFormModal())
  }, [dispatch])

  const _handleDisconnectCsiAcc = useCallback(() => {
    dispatch(disconnectCsiAcc())
  }, [dispatch])

  const handleDisconnectCsiAcc = useCallback(() => {
    dispatch(
      showConfirmationModal({
        cb: _handleDisconnectCsiAcc,
        title: `Disconnect ${userName} from CSI?`,
      })
    )
  }, [_handleDisconnectCsiAcc, dispatch, userName])

  const activeClassName = isOpen ? 'active' : ''

  return (
    <div className="users_menu_container">
      <button
        className={`users_menu_button ${activeClassName}`}
        onClick={handleClickMenu}
        data-user-id={usersId}
      >
        <MoreHorizIcon className="users_menu_button_icon" />
      </button>
      {isOpen && (
        <div className="users_menu_wrap">
          <span className="menu_item">
            <button
              className="menu_item_button"
              onClick={handleClickSignInAsUser}
            >
              <ExitToAppIcon className={'menu_item_button_icon'} />
              <span>{t(USERS_PAGE_USER_MENU_ITEM_3_LN)}</span>
            </button>
          </span>
          <span className="menu_item">
            <button className="menu_item_button" onClick={handleClickEditUser}>
              <EditOutlinedIcon className={'menu_item_button_icon'} />
              <span>{t(USERS_PAGE_USER_MENU_ITEM_1_LN)}</span>
            </button>
          </span>
          {quickbooks_enabled && (
            <span className="menu_item">
              <button
                className="menu_item_button"
                onClick={handleClickQbDisconect}
              >
                <PowerSettingsNewRoundedIcon
                  className={'menu_item_button_icon'}
                />
                <span>{t(USERS_PAGE_USER_MENU_ITEM_2_LN)}</span>
              </button>
            </span>
          )}
          {role === CLIENT_ROLE && (
            <span className="menu_item">
              <button
                className="menu_item_button"
                onClick={
                  csi_enabled ? handleDisconnectCsiAcc : handleConnectCsiUser
                }
              >
                <VpnKeyOutlinedIcon className={'menu_item_button_icon'} />
                <span>
                  {csi_enabled
                    ? t(DISCONNECT_CSI_BTN_LN)
                    : t(CONNECT_CSI_BTN_LN)}
                </span>
              </button>
            </span>
          )}
          {role === CLIENT_ROLE ? (
            <span className="menu_item">
              <button
                className="menu_item_button red-item"
                onClick={handleClickDeleteUser}
              >
                <DeleteForeverOutlinedIcon
                  className={'menu_item_delete_icon'}
                />
                <span>{t(USERS_PAGE_USER_MENU_ITEM_4_LN)}</span>
              </button>
            </span>
          ) : null}
        </div>
      )}
    </div>
  )
}

export default memo(UsersMenu)
