import React, { memo } from 'react'
import PropTypes from 'prop-types'

import TooltipStyled from 'components/ui/tooltip-component'
import CopyToClipboardButton from 'components/ui/copy-to-clipboar-button'

const Title = (props) => {
  const {
    title = '',
    extraClassName = '',
    clasNameContainer = '',
    component = 'h2',
    withTooltip = false,
    textTooltip,
    placementTooltip,
    isCopy = false,
    copyContent = '',
    toolTipContent = '',
    children,
    ...rest
  } = props
  const Component = `${component}`
  return (
    <span className={`title_container ${clasNameContainer}`}>
      <Component
        className={`title ${extraClassName} ${
          withTooltip ? 'margin-right' : ''
        }`}
        {...rest}
      >
        {title}
        <span className="title_info_message_container">{children}</span>
      </Component>
      {isCopy && (
        <TooltipStyled text={toolTipContent}>
          <CopyToClipboardButton copyContent={copyContent} />
        </TooltipStyled>
      )}
      {withTooltip && (
        <TooltipStyled text={textTooltip} placement={placementTooltip} />
      )}
    </span>
  )
}

Title.propTypes = {
  title: PropTypes.string,
  extraClassName: PropTypes.string,
  component: PropTypes.string,
  withTooltip: PropTypes.bool,
  textTooltip: PropTypes.string,
  placementTooltip: PropTypes.string,
}

export default memo(Title)
