import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import FilterIconButton from './FilterIconButton'
import { FILTER_BUTTON_NAME_LN } from 'constants/language-key-constants'

function FilterButton(props) {
  const { text, iconComponent, ...rest } = props
  const { t } = useTranslation()
  const IconComponent = iconComponent || FilterIconButton

  return (
    <button type="button" className="filter_button" {...rest}>
      <IconComponent />
      <span className="filter_button_text">
        {text || t(FILTER_BUTTON_NAME_LN)}
      </span>
    </button>
  )
}

FilterButton.propTypes = {}

export default memo(FilterButton)
