import { useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'

export const useShallowSelector = (selector) => {
  if (typeof selector !== 'function') {
    return undefined
  }
  
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useSelector(selector, isEqual)
}