import { put, call, takeEvery } from 'redux-saga/effects'

import api from 'api'
import {
  QB_AUTH_CONNECT_SUCCSES,
  QB_AUTH_CONNECT_DENIED,
  QB_AUTH_CONNECT_NO,
} from 'constants/qb-connect-constant'
import {
  GET_QB_AUTH_URL,
  LOGOUT_QB,
  QB_AUTH_LOGIN,
  LOGOUT_QB_BY_USER_ID,
} from 'constants/actions-constants'
import { WAS_SHOWN_QB_MODAL_LS_KEY } from 'constants/local-storage-key-constants'
import {
  setQbAuthUrl,
  setQbConnectStatus,
  setFetchingQbConnect,
} from 'actions/qb-actions'
import { setProfileInfo, getProfileInfo } from 'actions/profile-actions'
import { clearBatches } from 'actions/batches-actions'
import { getUsersList } from 'actions/users'

function* getQbAuthUrl() {
  try {
    yield put(setFetchingQbConnect(true))
    const URL = 'quickbooks/auth/get-auth-url/'
    const res = yield call([api, 'getQBAuthUrlApi'], URL)

    const { data } = res
    if (data && data.auth_url) {
      yield put(setQbAuthUrl(data.auth_url))
    }
  } catch (error) {
    //to do
  } finally {
    yield call([localStorage, 'setItem'], WAS_SHOWN_QB_MODAL_LS_KEY, true)
    yield put(setFetchingQbConnect(false))
  }
}

function* loginQb({ payload }) {
  try {
    yield put(setFetchingQbConnect(true))
    const URL = 'quickbooks/auth/login/'
    const res = yield call([api, 'loginAuthQb'], URL, payload)

    const { data } = res

    yield put(setQbConnectStatus(QB_AUTH_CONNECT_SUCCSES))
    yield put(setProfileInfo(data))
  } catch (error) {
    yield put(setQbConnectStatus(QB_AUTH_CONNECT_DENIED))
  } finally {
    yield put(setFetchingQbConnect(false))
  }
}

function* logoutQb() {
  try {
    yield put(setFetchingQbConnect(true))
    const URL = 'quickbooks/auth/logout/'
    yield call([api, 'logoutQb'], URL)
    yield put(setQbConnectStatus(QB_AUTH_CONNECT_NO))
    yield put(getProfileInfo())
    yield put(clearBatches())
  } catch (error) {
    //to do
  } finally {
    yield put(setFetchingQbConnect(false))
  }
}

function* logoutQbByUserIdSagaWorker({ payload }) {
  try {
    const URL = `quickbooks/auth/logout/${payload}/`
    yield call([api, 'logoutQb'], URL)

    yield put(getUsersList())
  } catch (error) {
    //
  }
}

export function* watchQBWorker() {
  yield takeEvery(GET_QB_AUTH_URL, getQbAuthUrl)
  yield takeEvery(QB_AUTH_LOGIN, loginQb)
  yield takeEvery(LOGOUT_QB, logoutQb)
  yield takeEvery(LOGOUT_QB_BY_USER_ID, logoutQbByUserIdSagaWorker)
}
