import { createSelector } from 'reselect'
import moment from 'moment'

import {
  N_TAX_EXEMPT_CONST,
  defaultCustomerTypeValue,
  customer_data_constants,
} from 'constants/tax-exempt-constants'

import {
  SET_INVOICES,
  SET_SEARCH_VALUE_FOR_INVOICES,
  SET_ORDER_BY_FOR_INVOICES_SORT,
  SET_QB_CONNECT_STATUS_FOR_INVOICES_FILTERING,
  SET_CSI_STATUS_FOR_INVOICES_FILTERING,
  SET_FROM_DATE_FOR_INVOICES_FILTERING,
  SET_TO_DATE_FOR_INVOICES_FILTERING,
  SET_OFFSET_GETTING_INVOICES,
  CLEAR_ALL_INVOICES_STATE,
  CLEAR_INVOICES,
  CLEAR_SORT_PARAM,
  CLEAR_FILTER_PARAM,
  CLEAR_SEARCH_VALUE,
  START_FETCHING_INVOICES,
  STOP_FETCHING_INVOICES,
  SET_INVOICES_FROM_PAGGINATION,
  UPDATE_INVOICES,
} from 'constants/actions-constants'

import { transactionsTypes } from 'constants/transactions-type-constants'

const startOfMonth = moment().startOf('month').format('YYYY-MM-DD 00:00')
const endOfMonth = moment().endOf('month').format('YYYY-MM-DD 23:59')

const initialState = {
  invoicesData: [],
  searchValue: '',
  orderBy: '-csi_data.processed_datetime',
  QBStatus: [], //['TAXES UPDATE REQUIRED', 'OUTDATED', 'TAXES UPDATED', 'ERROR'],
  CSIStatus: [], //['CALCULATIONS REQUIRED', 'OUTDATED', 'CALCULATED', 'ERROR'],
  fromDate: startOfMonth,
  toDate: endOfMonth,
  limit: 100,
  offset: 0,
  isFetchingInvoices: false,
}

export const invoicesState = (state = initialState, actions) => {
  switch (actions.type) {
    case SET_INVOICES:
      return {
        ...state,
        invoicesData: actions.payload,
        offset: initialState.offset,
      }
    case UPDATE_INVOICES:
      return {
        ...state,
        invoicesData: actions.payload,
      }
    case SET_INVOICES_FROM_PAGGINATION:
      return {
        ...state,
        invoicesData: [...state.invoicesData, ...actions.payload.invoices],
        offset: actions.payload.offset,
      }
    case SET_SEARCH_VALUE_FOR_INVOICES:
      return { ...state, searchValue: actions.payload }
    case SET_ORDER_BY_FOR_INVOICES_SORT:
      return { ...state, orderBy: actions.payload }
    case SET_QB_CONNECT_STATUS_FOR_INVOICES_FILTERING:
      return { ...state, QBStatus: actions.payload }
    case SET_CSI_STATUS_FOR_INVOICES_FILTERING:
      return { ...state, CSIStatus: actions.payload }
    case SET_FROM_DATE_FOR_INVOICES_FILTERING:
      return { ...state, fromDate: actions.payload }
    case SET_TO_DATE_FOR_INVOICES_FILTERING:
      return { ...state, toDate: actions.payload }
    case SET_OFFSET_GETTING_INVOICES:
      return { ...state, offset: actions.payload }
    case CLEAR_ALL_INVOICES_STATE:
      return initialState
    case CLEAR_INVOICES:
      return { ...state, invoicesData: initialState.invoicesData }
    case CLEAR_SORT_PARAM:
      return { ...state, orderBy: initialState.orderBy }
    case CLEAR_FILTER_PARAM:
      return {
        ...state,
        QBStatus: initialState.QBStatus,
        CSIStatus: initialState.CSIStatus,
        fromDate: initialState.fromDate,
        toDate: initialState.toDate,
      }
    case CLEAR_SEARCH_VALUE:
      return { ...state, searchValue: initialState.searchValue }
    case START_FETCHING_INVOICES:
      return { ...state, isFetchingInvoices: true }
    case STOP_FETCHING_INVOICES:
      return { ...state, isFetchingInvoices: false }

    default:
      return state
  }
}

export const getSearchValueInvoicesSelector = (state) =>
  state.invoicesState.searchValue

export const getFromDateInvoicesFilterSelector = (state) =>
  state.invoicesState.fromDate

export const getToDateInvoicesFilterSelector = (state) =>
  state.invoicesState.toDate

export const getOffsetInvoicesSelector = (state) => state.invoicesState.offset

export const getIsFetchingInvoicesSelector = (state) =>
  state.invoicesState.isFetchingInvoices

export const getInvoiceQbStatusSelector = (state) =>
  state.invoicesState.QBStatus

export const getInvoiceCsiStatusSelector = (state) =>
  state.invoicesState.CSIStatus

export const getOrderByInvoicesSelector = (state) => state.invoicesState.orderBy

export const getInvoicesSelector = (state) => state.invoicesState.invoicesData

export const getDestructureInvoicesSelector = createSelector(
  getInvoicesSelector,
  (invoices) =>
    invoices?.reduce((res, invoice) => {
      res.push({
        _id: invoice?._id,
        realmId: invoice?.realm_id,
        processDate: invoice?.csi_data?.processed_datetime,
        batchId: invoice?.last_batch_id,
        isDeleted: invoice?.is_deleted,
        docNumber: invoice?.quickbooks_data?.DocNumber,
        customer: invoice?.quickbooks_data?.CustomerRef?.name,
        invoiceDate: invoice?.quickbooks_data?.TxnDate,
        postalCode: invoice?.zip_code,
        revenue:
          invoice?.type === transactionsTypes.CREDIT_MEMO
            ? -invoice?.revenue || 0
            : invoice?.revenue || 0,
        type: invoice?.type,
        exemptCode: invoice?.exemption_data?.exempt_code || N_TAX_EXEMPT_CONST,
        customer_type:
          customer_data_constants.customer_type[invoice?.customer_type] ??
          defaultCustomerTypeValue,
        billableTaxes:
          invoice?.type === transactionsTypes.CREDIT_MEMO
            ? -invoice?.csi_data?.tax_summary?.billable_total_tax_amount || 0
            : invoice?.csi_data?.tax_summary?.billable_total_tax_amount || 0,
        isErrorExists: invoice?.csi_status === 'ERROR',
        qbStatus: invoice?.qb_status === 'TAXES UPDATED' ? 'N' : 'Y',
        is_qb_update_needed:
          invoice?.csi_status !== 'CALCULATED' ||
          invoice?.qb_status === 'OUTDATED',
      })
      return res
    }, [])
)
