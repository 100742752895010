import {
  GET_QB_AUTH_URL,
  SET_QB_AUTH_URL,
  LOGOUT_QB,
  QB_AUTH_LOGIN,
  SET_QB_CONNECT_STATUS,
  SET_FETCHING_QB_CONNECT,
  LOGOUT_QB_BY_USER_ID,
} from 'constants/actions-constants'

export const getQbAuthUrl = () => ({
  type: GET_QB_AUTH_URL,
})

export const setQbAuthUrl = (payload) => ({
  type: SET_QB_AUTH_URL,
  payload,
})

export const logoutQb = () => ({
  type: LOGOUT_QB,
})

export const logoutQbByUserId = (payload) => ({
  type: LOGOUT_QB_BY_USER_ID,
  payload,
})

export const qBAuthLogin = (payload) => ({
  type: QB_AUTH_LOGIN,
  payload,
})

export const setQbConnectStatus = (payload) => ({
  type: SET_QB_CONNECT_STATUS,
  payload,
})

export const setFetchingQbConnect = (payload) => ({
  type: SET_FETCHING_QB_CONNECT,
  payload,
})
