import {
  SET_QB_AUTH_URL,
  SET_QB_CONNECT_STATUS,
  SET_FETCHING_QB_CONNECT,
} from 'constants/actions-constants'
import { QB_AUTH_CONNECT_NO } from 'constants/qb-connect-constant'

const initialState = {
  qbAuthUrl: '',
  qbAuthConnectStatus: QB_AUTH_CONNECT_NO,
  isQbFetching: false,
}

export const qbAuth = (state = initialState, action) => {
  switch (action.type) {
    case SET_QB_AUTH_URL:
      return { ...state, qbAuthUrl: action.payload }

    case SET_QB_CONNECT_STATUS:
      return { ...state, qbAuthConnectStatus: action.payload }

    case SET_FETCHING_QB_CONNECT:
      return { ...state, isQbFetching: action.payload }

    default:
      return state
  }
}

/** SELECTORS */

export const getQbAuthUrlSelector = (state) => state.qbAuth.qbAuthUrl

export const getQbAuthConnectStatusSelector = (state) =>
  state.qbAuth.qbAuthConnectStatus

export const getIsQbFetching = (state) => state.qbAuth.isQbFetching
