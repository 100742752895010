import React, { useEffect, useCallback, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { formValueSelector, clearFields } from 'redux-form'
import isEqual from 'lodash/isEqual'
import isNaN from 'lodash/isNaN'

import Modal from 'components/ui/modal-component'
import Title from 'components/ui/title-component'
import Form from './Form'
import Button from 'components/ui/button-component'
import CopyToClipboardButton from 'components/ui/copy-to-clipboar-button'
import { getIsQbFetching } from 'redusers/quickbooks-auth'
import {
  getCsiTokenKeySelector,
  getProfileCsiEnabledSelector,
  getProfileEmailSelector,
  getProfileFirstNameSelector,
  getProfileLastNameSelector,
  getProfileCompanyNameSelector,
  getProfilePhoneSelector,
  getProfileAdressSelector,
  getProfileCitySelector,
  getProfileStateRegionSelector,
  getProfileZipCodeSelector,
  getProfileFetchingStatusSelector,
  getProfileQuickbooksEnabledSelector,
  getProfileErrorMessageSelector,
  getProfileConnectedCompanyInfoSelector,
  getRoleUserSelector,
} from 'redusers/profile'
import { getModalProfileUserStateSelector } from 'redusers/modalsState'
import { getSelectedUserSelector } from 'redusers/users'
import {
  updateProfileData,
  clearProfileErrorMessage,
} from 'actions/profile-actions'
import { createUser, editUser } from 'actions/users'
import { getQbAuthUrl, logoutQb } from 'actions/qb-actions'
import {
  hideProfileModal,
  showConfirmationModal,
} from 'actions/modals-state-actions'
import {
  ADD_NEW_USER_MODAL_STATE,
  EDIT_USER_MODAL_STATE,
  USER_PROFILE_MODAL_STATE,
} from 'constants/profile-modal-state-constants'
import { ADMIN_ROLE, CLIENT_ROLE } from 'constants/roles-constant'
import {
  PROFILE_CONFIRMATION_TITLE_1_LN,
  PROFILE_MODAL_TITLE_1_LN,
  PROFILE_MODAL_TITLE_2_LN,
  PROFILE_MODAL_TITLE_3_LN,
  PROFILE_MODAL_BUTTON_1_LN,
  PROFILE_MODAL_BUTTON_2_LN,
  PROFILE_MODAL_SUB_TITLE_1_LN,
  PROFILE_MODAL_QB_BUTTON_1_LN,
  PROFILE_MODAL_QB_BUTTON_2_LN,
  PROFILE_MODAL_COPY_TITLE_1_LN,
  PROFILE_MODAL_COPY_TITLE_2_LN,
  PROFILE_MODAL_COPY_TITLE_3_LN,
  ADD_NEW_USER_CONFIRM_MODAL_TITLE,
  ADD_NEW_USER_CONFIRM_MODAL_1_TITLE,
} from 'constants/language-key-constants'

const formSelector = formValueSelector('userProfile')

let UserProfile = (props) => {
  const {
    hideProfileModal,
    getQbAuthUrl,
    logoutQb,
    isQbConnected,
    isQbFetching,
    csiAccessToken,
    csi_enabled,
    initialValues,
    updateProfileData,
    currentValues,
    isProfileFetching,
    clearFields,
    showConfirmationModal,
    companyInfo = {},
    modalProfileState = '',
    role,
    createUser,
    initialValueForEditingUser,
    editUser,
    errorMessage = '',
    clearProfileErrorMessage,
  } = props

  const { t } = useTranslation()

  const [isMakeAdmin, setIsMakeAdmin] = React.useState(false)

  const handleChangeSwitch = useCallback(
    (event) => {
      if (!isMakeAdmin) {
        showConfirmationModal({
          cb: setIsMakeAdmin,
          arg: event.target.checked,
          title: t(PROFILE_CONFIRMATION_TITLE_1_LN),
        })
      } else {
        setIsMakeAdmin(event.target.checked)
      }
    },
    [isMakeAdmin, showConfirmationModal, t]
  )

  const handleClickClose = useCallback(() => {
    hideProfileModal()
  }, [hideProfileModal])

  const getFucnctionForSubmit = useCallback(
    (modalProfileState) => {
      switch (modalProfileState) {
        case USER_PROFILE_MODAL_STATE:
          return updateProfileData
        case ADD_NEW_USER_MODAL_STATE:
          return createUser
        case EDIT_USER_MODAL_STATE:
          return editUser
        default:
          return () => null
      }
    },
    [createUser, editUser, updateProfileData]
  )

  const handleSubmit = (reduxFormData) => {
    const data = {
      email: reduxFormData.userId,
      profile: {
        first_name: reduxFormData.firstName,
        last_name: reduxFormData.lastName,
        company_name: reduxFormData.companyName,
        phone: reduxFormData.phone,
        address: {
          address_line: reduxFormData.address,
          city: reduxFormData.city,
          state: reduxFormData.stateRegion,
          zip_code: reduxFormData.zipCode,
        },
      },
    }
    if (modalProfileState === ADD_NEW_USER_MODAL_STATE) {
      const role = isMakeAdmin ? ADMIN_ROLE : CLIENT_ROLE
      data.roles = [role]
    }
    if (
      reduxFormData.password &&
      modalProfileState === USER_PROFILE_MODAL_STATE
    ) {
      data.password = reduxFormData.password
    } else {
      data.password = reduxFormData.password
    }
    showConfirmationModal({
      cb: getFucnctionForSubmit(modalProfileState),
      arg: data,
      title:
        modalProfileState === ADD_NEW_USER_MODAL_STATE
          ? t(ADD_NEW_USER_CONFIRM_MODAL_TITLE)
          : t(ADD_NEW_USER_CONFIRM_MODAL_1_TITLE),
    })
  }

  const handleClickQBButton = useCallback(() => {
    if (isQbConnected) {
      //logoutQb
      showConfirmationModal({ cb: logoutQb })
    } else {
      getQbAuthUrl()
    }
  }, [getQbAuthUrl, isQbConnected, logoutQb, showConfirmationModal])

  const handleOnSubmitSuccess = useCallback(() => {
    if (modalProfileState !== ADD_NEW_USER_MODAL_STATE) {
      clearFields('userProfile', false, false, 'password', 'confirmPassword')
    }
  }, [clearFields, modalProfileState])

  const getTitle = (modalProfileState) => {
    switch (modalProfileState) {
      case USER_PROFILE_MODAL_STATE:
        return t(PROFILE_MODAL_TITLE_1_LN)
      case ADD_NEW_USER_MODAL_STATE:
        return t(PROFILE_MODAL_TITLE_2_LN)
      case EDIT_USER_MODAL_STATE:
        return t(PROFILE_MODAL_TITLE_3_LN)
      default:
        return t(PROFILE_MODAL_TITLE_1_LN)
    }
  }

  const getButtonSaveChangeName = (modalProfileState) => {
    switch (modalProfileState) {
      case USER_PROFILE_MODAL_STATE:
        return t(PROFILE_MODAL_BUTTON_1_LN)
      case ADD_NEW_USER_MODAL_STATE:
        return t(PROFILE_MODAL_BUTTON_2_LN)
      case EDIT_USER_MODAL_STATE:
        return t(PROFILE_MODAL_BUTTON_1_LN)
      default:
        return t(PROFILE_MODAL_BUTTON_1_LN)
    }
  }

  const getInitialValuesValueForForm = useCallback(
    (modalProfileState) => {
      switch (modalProfileState) {
        case USER_PROFILE_MODAL_STATE:
          return initialValues
        case ADD_NEW_USER_MODAL_STATE:
          return {}
        case EDIT_USER_MODAL_STATE:
          return initialValueForEditingUser
        default:
          return {}
      }
    },
    [initialValueForEditingUser, initialValues]
  )

  const _currentValueWithPArsedPhoneField = {
    ...currentValues,
    phone: currentValues?.phone
      ?.split('')
      .filter((i) => typeof +i === 'number' && !isNaN(+i))
      .join(''),
  }

  const _isDisabledUpdateButton = isEqual(
    getInitialValuesValueForForm(modalProfileState),
    _currentValueWithPArsedPhoneField
  )

  useEffect(() => {
    return () => {
      clearProfileErrorMessage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal
      classNameContainer="profile_modal"
      classNameWrap={'user_profile_conatainer'}
      isCloseButton={true}
      handleClickClose={handleClickClose}
    >
      <div className="user_profile_header">
        <Title
          title={getTitle(modalProfileState)}
          extraClassName={'user_profile_header__title'}
        />
      </div>
      <div className="user_profile_form_section">
        <Form
          handleClickClose={handleClickClose}
          onSubmit={handleSubmit}
          initialValues={getInitialValuesValueForForm(modalProfileState)}
          currentValues={currentValues}
          isDisabledUpdateButton={_isDisabledUpdateButton}
          isProfileFetching={isProfileFetching}
          onSubmitSuccess={handleOnSubmitSuccess}
          buttonSaveChangeName={getButtonSaveChangeName(modalProfileState)}
          modalProfileState={modalProfileState}
          errorMessage={errorMessage}
          switchValue={isMakeAdmin}
          handleChangeSwitch={handleChangeSwitch}
          clearProfileErrorMessage={clearProfileErrorMessage}
        />
        {modalProfileState === USER_PROFILE_MODAL_STATE &&
          role === CLIENT_ROLE && (
            <div className="user_profile_form__section_account user_profile_form__sections order_3">
              <Title
                title={t(PROFILE_MODAL_SUB_TITLE_1_LN)}
                extraClassName={'user_profile_form__title account'}
                component="h3"
              />
              {!isQbConnected && (
                <Button
                  name={
                    isQbConnected
                      ? t(PROFILE_MODAL_QB_BUTTON_1_LN)
                      : t(PROFILE_MODAL_QB_BUTTON_2_LN)
                  }
                  classNameContainer="user_profile_form_buttons_container"
                  classNameButton="user_profile_form_buttons"
                  type="button"
                  styletype={isQbConnected ? 'outline_blue' : 'green'}
                  onClick={handleClickQBButton}
                  isSpinner={isQbFetching}
                  disabled={isQbFetching}
                />
              )}
              {isQbConnected && (
                <>
                  <div className="user_profile_form__inputs_row">
                    <CopyToClipboardButton
                      copyContent={companyInfo.company_name}
                      classNameTitle="csi_key_title"
                      titleText={`${t(PROFILE_MODAL_COPY_TITLE_1_LN)} ${
                        companyInfo.company_name || ''
                      }`}
                    />
                  </div>
                  <div className="user_profile_form__inputs_row">
                    <CopyToClipboardButton
                      copyContent={companyInfo.realm_id}
                      classNameTitle="csi_key_title"
                      titleText={`${t(PROFILE_MODAL_COPY_TITLE_2_LN)} ${
                        companyInfo.realm_id || ''
                      }`}
                    />
                  </div>
                </>
              )}
              {csi_enabled && (
                <div className="user_profile_form__inputs_row">
                  <CopyToClipboardButton
                    copyContent={csiAccessToken}
                    classNameTitle="csi_key_title"
                    titleText={`${t(
                      PROFILE_MODAL_COPY_TITLE_3_LN
                    )} ${csiAccessToken}`}
                  />
                </div>
              )}
            </div>
          )}
      </div>
    </Modal>
  )
}

UserProfile.propTypes = {
  hideProfileModal: PropTypes.func.isRequired,
  getQbAuthUrl: PropTypes.func.isRequired,
  logoutQb: PropTypes.func.isRequired,
  isQbConnected: PropTypes.bool.isRequired,
  isQbFetching: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
  role: getRoleUserSelector(state),
  isProfileFetching: getProfileFetchingStatusSelector(state),
  isQbFetching: getIsQbFetching(state),
  isQbConnected: getProfileQuickbooksEnabledSelector(state),
  csiAccessToken: getCsiTokenKeySelector(state),
  csi_enabled: getProfileCsiEnabledSelector(state),
  initialValues: {
    userId: getProfileEmailSelector(state),
    firstName: getProfileFirstNameSelector(state),
    lastName: getProfileLastNameSelector(state),
    companyName: getProfileCompanyNameSelector(state),
    phone: getProfilePhoneSelector(state),
    address: getProfileAdressSelector(state),
    city: getProfileCitySelector(state),
    stateRegion: getProfileStateRegionSelector(state),
    zipCode: getProfileZipCodeSelector(state),
  },
  currentValues: formSelector(
    state,
    'userId',
    'password',
    'confirmPassword',
    'firstName',
    'lastName',
    'companyName',
    'phone',
    'address',
    'city',
    'stateRegion',
    'zipCode'
  ),
  companyInfo: getProfileConnectedCompanyInfoSelector(state),
  modalProfileState: getModalProfileUserStateSelector(state),
  initialValueForEditingUser: getSelectedUserSelector(state),
  errorMessage: getProfileErrorMessageSelector(state),
})

const actions = {
  hideProfileModal,
  updateProfileData,
  clearFields,
  showConfirmationModal,
  createUser,
  editUser,
  clearProfileErrorMessage,
  getQbAuthUrl,
  logoutQb,
}

UserProfile = connect(mapStateToProps, actions)(memo(UserProfile))

export default UserProfile
