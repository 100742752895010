import { put, call, takeEvery, select, debounce } from 'redux-saga/effects'

import api from 'api'
import {
  RETRIEVE_UPDATED_INVOICES_AND_PRODUCTS_FOR_BATCH,
  CREATE_BATCH,
  GET_BATCHES,
  SEND_BATCH,
  GET_BATCH_DETAIL_BY_ID,
  EXPORT_TO_QB,
  GET_TAX_EXEMPTIONS_LIST,
  UPDATE_CUSTOMER_TAX_EXEMPTION,
  GET_BATCH_INVOICES_IN_BATCH_DETAIL,
  SET_SEARCH_VALUE_BATCHES,
  GET_TAX_TYPE,
} from 'constants/actions-constants'
import { DANGER_ALERT } from 'constants/alert-constant'
import { UPDATE_PAGINATION_CONSTANT } from 'constants/pagination-constants'
import {
  PROCESSING_INVOICES_MODAL_NAME,
  INVOICE_DETAIL_MODAL_NAMES,
} from 'constants/modal-names-constants'
import { ADMIN_ROLE } from 'constants/roles-constant'
import { showAler } from 'actions/alert'
import {
  setUpdatedInvoicesAndProductsForBatch,
  setIsFetchingBatch,
  setErrorMessageForNewBatches,
  clearErrorMessageForNewBatch,
  setBatches,
  setOffsetCountForGettingBatch,
  updateBatches,
  setIsPaggination,
  sendBatch,
  setBatchDetail,
  setTaxExemptionList,
  getTaxExemptionList,
  startFetchingCreateBatch,
  stopFetchingCreateBatch,
  updateInvoicesInBatchDetail,
  getBatchInvoicesInBatchDetail,
  startFetchingGetInvoicesInBatchDetail,
  stopFetchingGetInvoicesInBatchDetail,
  setTaxType,
} from 'actions/batches-actions'
import { getProfileInfo } from 'actions/profile-actions'
import {
  hideNewBatchModal,
  showNewBatchAvailableInvoicesModal,
  showProcessedBatchModal,
  hideProcessedBatchModal,
  showExportConfirmationModal,
  showProcessingInvoicesModal,
  hideProcessingInvoicesModal,
  hideInvoiceDetailModal,
} from 'actions/modals-state-actions'
import { clearSelectedState } from 'actions/checkbox-actions'
import {
  getOffsetCountForGettingBatchesSelector,
  getIsPagginatioForGettingBatchesSelector,
  getOffsetCountForInvoicesBatchDetailSelector,
  getBatchInvoicesAllSizeSelector,
  getSearchValueForBatchSelector,
  getTransactionsTypeCreatingBatchSelector,
} from 'redusers/batches'
import { getCurrentShownModalNameSelector } from 'redusers/modalsState'
import { getRoleUserSelector } from 'redusers/profile'
import { getChosenRealMIdSelector } from 'redusers/companies'
import { destructureResInvoicesForBatches } from 'service/destructureResponce'
import { getInvoices } from 'actions/invoices'
import { transactionsTypes } from 'constants/transactions-type-constants'

function* getBatchesSaga({ payload }) {
  try {
    yield put(setIsFetchingBatch(true))
    const searchValue = yield select(getSearchValueForBatchSelector)
    if (payload === UPDATE_PAGINATION_CONSTANT) {
      const currentShownModalName = yield select(
        getCurrentShownModalNameSelector
      )

      if (currentShownModalName) return

      const offsetCount = yield select(getOffsetCountForGettingBatchesSelector)

      const isPaggination = yield select(
        getIsPagginatioForGettingBatchesSelector
      )

      if (!isPaggination) {
        return
      }

      const URL = searchValue
        ? `batches/?offset=${encodeURIComponent(
            offsetCount
          )}&limit=5&search=${encodeURIComponent(searchValue)}`
        : `batches/?offset=${encodeURIComponent(offsetCount)}&limit=5`

      const res = yield call([api, 'getBatchesApi'], URL) // api.getBatchesApi(URL)
      const { data } = res

      if (data) {
        yield put(updateBatches(data))
        if (!data.length) {
          yield put(setIsPaggination(false))
          return
        }

        yield put(setOffsetCountForGettingBatch(offsetCount + 5))
      }
    } else {
      const URL = searchValue
        ? `batches/?offset=0&limit=5&search=${searchValue}`
        : 'batches/?offset=0&limit=5'
      const res = yield call([api, 'getBatchesApi'], URL)
      const { data } = res

      if (data) {
        yield put(setBatches(data))

        if (!data.length) {
          yield put(setIsPaggination(false))
          return
        }
        yield put(setOffsetCountForGettingBatch(5))
      }
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      yield put(getProfileInfo())
    }
  } finally {
    yield put(setIsFetchingBatch(false))
  }
}

function* getBatchInvoicesInBatchDetailSagaWorker({ payload = {} }) {
  const { batchId, batchDetailData = {}, isUpdate = false } = payload
  const LIMIT_GETTING_INVOICES = 800
  try {
    yield put(startFetchingGetInvoicesInBatchDetail())
    const offsetCount = yield select(
      getOffsetCountForInvoicesBatchDetailSelector
    )
    const roleUser = yield select(getRoleUserSelector)

    if (isUpdate) {
      const invoicesLength = yield select(getBatchInvoicesAllSizeSelector)

      if (invoicesLength <= offsetCount + 10) {
        return
      }

      let URL = `batches/${batchId}/transactions/?limit=${LIMIT_GETTING_INVOICES}&offset=${
        offsetCount + 10
      }`

      if (roleUser === ADMIN_ROLE) {
        const realmId = yield select(getChosenRealMIdSelector)
        URL = URL + `&realmId=${realmId}`
      }

      const res = yield call([api, 'getBatchInvoicesApi'], URL)
      const { data } = res

      if (data) {
        const _data = destructureResInvoicesForBatches(data)
        yield put(
          updateInvoicesInBatchDetail({
            invoices: _data,
            newOffsetCount: offsetCount + 10,
            isPaggination: !(invoicesLength <= offsetCount + 20),
          })
        )
      }
    } else {
      let URL = `batches/${batchId}/transactions/?limit=${LIMIT_GETTING_INVOICES}`

      if (roleUser === ADMIN_ROLE) {
        const realmId = yield select(getChosenRealMIdSelector)
        URL = URL + `&realmId=${realmId}`
      }

      const res = yield call([api, 'getBatchInvoicesApi'], URL)
      const { data } = res

      const _data = destructureResInvoicesForBatches(data)
      if (data) {
        yield put(
          setBatchDetail({
            ...batchDetailData,
            invoices: _data,
            offsetCount: 0,
            isPaggination: false,
          })
        )
      }
    }
  } catch (error) {
    // to do
  } finally {
    yield put(stopFetchingGetInvoicesInBatchDetail())
  }
}

function* retrieveUpdatedInvoicesAndProductsForBatch({ payload }) {
  try {
    yield put(startFetchingCreateBatch())
    const { fromDate, toDate, transaction_type } = payload
    const URL = `transactions/retrieve-transactions-and-unmapped-products-for-batch/?fromDate=${encodeURIComponent(
      fromDate
    )}&toDate=${encodeURIComponent(
      toDate
    )}&transaction_type=${transaction_type}`
    const res = yield call(
      [api, 'retrieveUpdatedInvoicesAndProductsForBatch'],
      URL
    )

    const { data } = res

    yield put(clearErrorMessageForNewBatch())
    yield put(setUpdatedInvoicesAndProductsForBatch(data))

    if (Array.isArray(data.transactions) && data.transactions.length) {
      yield put(showNewBatchAvailableInvoicesModal())
    } else {
      const _message = () => {
        switch (transaction_type) {
          case transactionsTypes.CREDIT_MEMO:
            return 'No credit memos found for the selected date range'
          case transactionsTypes.INVOICES:
            return 'No invoices found for the selected date range'
          case transactionsTypes.SALES_RECEIPT:
            return 'No sales receipt found for the selected date range'
          default:
            return 'No invoices found for the selected date range'
        }
      }

      yield put(setErrorMessageForNewBatches(_message()))
    }
  } catch (error) {
    if (error?.response?.status === 400 && error?.response?.data?.detail) {
      yield put(setErrorMessageForNewBatches(error?.response?.data?.detail))
      yield put(getProfileInfo())
    }
  } finally {
    yield put(stopFetchingCreateBatch())
  }
}

function* getBatchDetailByIdSagas({ payload: batchId }) {
  try {
    const currentModalName = yield select(getCurrentShownModalNameSelector)
    const roleUser = yield select(getRoleUserSelector)
    let URL = `batches/${batchId}/`
    if (roleUser === ADMIN_ROLE) {
      const realmId = yield select(getChosenRealMIdSelector)
      URL = URL + `?realmId=${realmId}`
    }
    const res = yield call([api, 'getBatchDetailByIdApi'], URL)

    const { data } = res
    if (data) {
      if (currentModalName === PROCESSING_INVOICES_MODAL_NAME) {
        yield put(hideProcessingInvoicesModal())
      }
      yield put(
        getBatchInvoicesInBatchDetail({
          batchId,
          batchDetailData: data,
        })
      )
      if (currentModalName !== INVOICE_DETAIL_MODAL_NAMES) {
        yield put(showProcessedBatchModal())
      }
    }
  } catch (error) {
    // to do
  }
}

function* createBatchSaga({ payload }) {
  try {
    const transactions_type = yield select(
      getTransactionsTypeCreatingBatchSelector
    )
    yield put(startFetchingCreateBatch())
    const URL = `batches/?transaction_type=${transactions_type}`

    const res = yield call([api, 'createBatch'], URL, payload)
    const { data = {} } = res
    const { inserted_id } = data

    if (data && inserted_id) {
      yield put(sendBatch(inserted_id))
    }

    yield put(hideNewBatchModal())
    yield put(hideInvoiceDetailModal())
    yield put(clearSelectedState())
    yield put(showProcessingInvoicesModal())
  } catch (error) {
    yield put(
      showAler({
        type: DANGER_ALERT,
        message: error?.response?.data?.detail || 'Something went wrong',
        delay: 10000,
      })
    )
    yield put(getProfileInfo())
  } finally {
    yield put(stopFetchingCreateBatch())
  }
}

function* sendBatchSaga({ payload }) {
  try {
    const URL = `batches/${payload}/send-batch/`
    yield call([api, 'sendBatchApi'], URL)
  } catch (error) {
    //to do
  }
}

function* updateTaxLinesSagas({ payload }) {
  try {
    yield put(startFetchingCreateBatch(true))
    const URL = 'transactions/update-tax-lines-for-transactions/'
    const res = yield call([api, 'updateTaxLinesApi'], URL, payload)

    const { data } = res

    if (data?.some((i) => i.qb_status !== 'TAXES UPDATED')) {
      const unupdated = data.reduce((res, item) => {
        if (item.qb_status !== 'TAXES UPDATED') {
          res = res
            ? `${res}, ${item?.quickbooks_data?.DocNumber || ''}`
            : item?.quickbooks_data?.DocNumber || ''
        }
        return res
      }, '')
      yield put(setErrorMessageForNewBatches(unupdated))
    }

    yield put(hideProcessedBatchModal())
    yield put(clearSelectedState())
    yield put(showExportConfirmationModal())
    yield put(getInvoices())
  } catch (error) {
    //to do
  } finally {
    yield put(stopFetchingCreateBatch(false))
  }
}

function* getTaxExemptionListSaga() {
  try {
    const URL = 'customers/?limit=800'
    const res = yield call([api, 'getCustomersTaxExemptApi'], URL)
    const { data } = res
    if (data.length) {
      yield put(setTaxExemptionList(data))
    }
  } catch (error) {
    // to do
  }
}

function* updateCustomersTaxExemptionSaga({ payload }) {
  try {
    yield put(startFetchingCreateBatch(true))
    const URL = `customers/${payload.customerId}/`
    yield call([api, 'updateCustomersTaxExemptApi'], URL, payload.body)

    yield put(getTaxExemptionList())
  } catch (error) {
    //to do
  } finally {
    yield put(stopFetchingCreateBatch(false))
  }
}

function* getTaxTypeWorker() {
  try {
    const URL = 'csi/tax-types/'
    const res = yield call([api, 'get'], URL)
    yield put(setTaxType(res?.data || []))
  } catch (error) {
    // to do
  }
}

export function* watchBatchesWorker() {
  yield debounce(500, GET_BATCHES, getBatchesSaga)
  yield debounce(500, SET_SEARCH_VALUE_BATCHES, getBatchesSaga)
  yield takeEvery(
    RETRIEVE_UPDATED_INVOICES_AND_PRODUCTS_FOR_BATCH,
    retrieveUpdatedInvoicesAndProductsForBatch
  )
  yield takeEvery(SEND_BATCH, sendBatchSaga)
  yield takeEvery(GET_BATCH_DETAIL_BY_ID, getBatchDetailByIdSagas)
  yield takeEvery(CREATE_BATCH, createBatchSaga)
  yield takeEvery(EXPORT_TO_QB, updateTaxLinesSagas)
  yield takeEvery(GET_TAX_EXEMPTIONS_LIST, getTaxExemptionListSaga)
  yield takeEvery(
    UPDATE_CUSTOMER_TAX_EXEMPTION,
    updateCustomersTaxExemptionSaga
  )
  // yield takeEvery(GET_BATCH_INVOICES, getBatchInvoicesSagaWorker)
  yield takeEvery(
    GET_BATCH_INVOICES_IN_BATCH_DETAIL,
    getBatchInvoicesInBatchDetailSagaWorker
  )
  yield takeEvery(GET_TAX_TYPE, getTaxTypeWorker)
}
