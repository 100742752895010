import { createStore, compose, applyMiddleware, combineReducers } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { reducer as formReducer } from 'redux-form'

import { profile } from 'redusers/profile'
import { qbAuth } from 'redusers/quickbooks-auth'
import { batches } from 'redusers/batches'
import { invoiceDetail } from 'redusers/invoicesDetail'
import { modalState } from 'redusers/modalsState'
import { alert } from 'redusers/alert'
import { products } from 'redusers/products'
import { firebaseNotification } from 'redusers/firebase-notification'
import { notifications } from 'redusers/notifications'
import { confirmationModal } from 'redusers/confirmation-modal'
import { checkBoxState } from 'redusers/checkbox-state'
import { users } from 'redusers/users'
import { companies } from 'redusers/companies'
import { reports } from 'redusers/reports'
import { logs } from 'redusers/logs'
import { invoicesState } from 'redusers/invoices'
import { rootSagas } from 'sagas/root-sagas'

const rootReducer = combineReducers({
  profile,
  qbAuth,
  batches,
  invoiceDetail,
  modalState,
  alert,
  products,
  firebaseNotification,
  notifications,
  confirmationModal,
  checkBoxState,
  users,
  companies,
  reports,
  logs,
  invoicesState,
  form: formReducer,
})

const sagaMiddleware = createSagaMiddleware()

const middleware = [sagaMiddleware]

const actionSanitizer = (action) =>
  action.type === 'CALL_YES_FUNCTION_CONFIRMATION' && action.payload
    ? { ...action, payload: '<<LONG_BLOB>>' }
    : action

const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      actionSanitizer,
      stateSanitizer: (state) =>
        state.payload ? { ...state, payload: '<<LONG_BLOB>>' } : state,
    })) ||
  compose

const _composeEnhancers =
  // eslint-disable-next-line no-undef
  process.env.REACT_APP_CUSTOM_NODE_ENV !== 'development'
    ? applyMiddleware(...middleware)
    : composeEnhancers(applyMiddleware(...middleware))

const store = createStore(rootReducer, _composeEnhancers)

sagaMiddleware.run(rootSagas)

export default store
