import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import Button from 'components/ui/button-component'

import {
  NEW_BATCH_MODAL_BUTTON_3_LN,
  NEW_BATCH_MODAL_BUTTON_2_LN,
} from 'constants/language-key-constants'

function GetInvoicesButtons(props) {
  const { handleGetInvoices, isFetchingBatch, clearDateToNewBatch, isValid } =
    props
  const { t } = useTranslation()

  return (
    <span className="new_batch_modal_buttons_container">
      <Button
        name={t(NEW_BATCH_MODAL_BUTTON_3_LN)}
        styletype="green"
        classNameButton={'new_batch_modal_buttons'}
        classNameContainer={'new_batch_modal_buttons_wrap left_button'}
        disabled={!isValid || isFetchingBatch}
        onClick={handleGetInvoices}
        isSpinner={isFetchingBatch}
      />
      <Button
        name={t(NEW_BATCH_MODAL_BUTTON_2_LN)}
        styletype="outline_blue"
        classNameButton={'new_batch_modal_buttons'}
        classNameContainer={'new_batch_modal_buttons_wrap'}
        onClick={clearDateToNewBatch}
        disabled={isFetchingBatch}
      />
    </span>
  )
}

export default memo(GetInvoicesButtons)
