import { SET_NOTIFICATIONS_FROM_BACK_END } from 'constants/actions-constants'

const initialState = {
  notificationsData: {
    notificationsList: [],
    limit: 100,
    offset: 0,
  },
}

export const notifications = (state = initialState, actions) => {
  switch (actions.type) {
    case SET_NOTIFICATIONS_FROM_BACK_END:
      return {
        ...state,
        notificationsData: {
          ...state.notificationsData,
          notificationsList: actions.payload,
        },
      }

    default:
      return state
  }
}

export const getNotificationsListSelector = (state) =>
  state.notifications.notificationsData.notificationsList

export const getNotificationsLimitSelector = (state) =>
  state.notifications.notificationsData.limit

export const getNotificationsOffsetSelector = (state) =>
  state.notifications.notificationsData.offset

export const getNewNotificationStatusSelector = (state) =>
  state.notifications.notificationsData.notificationsList.some(
    (item) => !item.is_read
  )
export const getUnreadIdNotificationSelector = (state) =>
  state.notifications?.notificationsData?.notificationsList?.reduce(
    (res, item) => {
      if (!item?.is_read) {
        res.push(item?._id)
      }
      return res
    },
    []
  )
