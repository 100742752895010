import React, { memo, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { useShallowSelector } from 'custom-hooks/useShallowSelector'
import Button from 'components/ui/button-component'
import SearchInput from 'components/ui/search-input'
import InvoicesComponent from 'components/invoices-component'
//import { FilterButton } from 'components/ui/filter-component'
import {
  showNewBatchModal,
  //showInvoicesFilterModal,
} from 'actions/modals-state-actions'
import {
  getProfileQuickbooksEnabledSelector,
  getProfileCsiEnabledSelector,
} from 'redusers/profile'
//import { getIsShowInvoicesFilterModalSelector } from 'redusers/modalsState'
import {
  setSearchValueInvoices,
  clearSearchInvoicesValue,
  //clearFilterParams,
} from 'actions/invoices'
import {
  PLACEHOLDER_BY_CLIENT_BATCHES_SEARCH_INPUT_LN,
  NEW_BATCH_BUTTON_NAME_LN,
} from 'constants/language-key-constants'

const InvoicesPage = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const isQbEnabled = useShallowSelector(getProfileQuickbooksEnabledSelector)
  // const isShowInvoicesModal = useShallowSelector(getIsShowInvoicesFilterModalSelector)
  const isCsiConnected = useShallowSelector(getProfileCsiEnabledSelector)
  const handleClickNewBatch = useCallback(() => {
    dispatch(showNewBatchModal())
  }, [dispatch])

  const handleChangeSearchInput = (e) => {
    const value = e.target.value
    // if (value.length > 3) {
    //   dispatch(setSearchValueInvoices(value))
    // }
    // if (!value) {
    //   dispatch(setSearchValueInvoices(''))
    // }
    dispatch(setSearchValueInvoices(value))
  }

  // const handleClickOpenFilterModal = () => {
  //   dispatch(showInvoicesFilterModal())
  // }

  const handleClickClear = () => {
    dispatch(setSearchValueInvoices(''))
  }

  useEffect(() => {
    return () => {
      dispatch(clearSearchInvoicesValue(''))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="batches_page_container container">
      <div className="batches_search_section">
        <div className="search_container">
          <SearchInput
            placeholder={t(PLACEHOLDER_BY_CLIENT_BATCHES_SEARCH_INPUT_LN)}
            classNameContainer="batches_page_search_input_container"
            onChange={handleChangeSearchInput}
            handleClickClear={handleClickClear}
            isClearButton={true}
          />
        </div>
        <div className="batches_filter_group">
          {/* {isQbEnabled && <FilterButton onClick={handleClickOpenFilterModal} />} */}
          <Button
            name={t(NEW_BATCH_BUTTON_NAME_LN)}
            styletype="green"
            classNameButton={'new_batches_btn'}
            onClick={handleClickNewBatch}
            disabled={!isQbEnabled || !isCsiConnected}
          />
        </div>
      </div>
      <div className="invoices_section">
        <InvoicesComponent />
      </div>
      {/* {isShowInvoicesModal && <InvoicesFilterModal />} */}
    </div>
  )
}

export default memo(InvoicesPage)
