export const QB_CONNECT_MODAL_NAME = 'qb_connect_modal'
export const CSI_CONNECT_MODAL_NAME = 'csi_connect_modal'
export const CSI_CONNECT_FORM_MODAL_NAME = 'csi_connect_form_modal'

export const NEW_BATCH_MODAL_NAME = 'new_batch_date_range_modal_name'

export const PROCESSED_BATCH_MODAL_NAME = 'processed_batch_modal_name'

export const EXPORT_CONFIRMATION_MODAL_NAME = 'export_confirmation_modal_name'

export const INVOICE_DETAIL_MODAL_NAMES = 'invoices_detail_modal_name'

export const TAX_SUMMARY_MODAL_NAMES = 'tax_summary_modal_name'

export const PROFILE_MENU_MODAL_NAMES = 'profile_memu_modal_name'

export const SETTINGS_MENU_MODAL_NAMES = 'settings_menu_modal_names'

export const CONFIRMATION_MODAL_NAMES = 'confirmation_modal_names'

export const PROCESSING_INVOICES_MODAL_NAME = 'processing_invoices_modal_name'

export const REPORT_FILTER_MODAL_NAME = 'report_filter_modal_name'

export const LOGS_MODAL_NAME = 'logs_modal_name'

export const SUCCES_CONNECTED_CSI_MODAL_NAME = 'succes_connected_sci_modal_name'

export const INVOICES_FILTER_MODAL_NAME = 'invoices_filter_modal_name'
