import { put, call, takeEvery, select } from 'redux-saga/effects'

import api from 'api'

import cloneDeep from 'lodash/cloneDeep'

import {
  GET_ALL_PRODUCTS,
  GET_CSI_PRODUCTS,
  MAP_PRODUCT,
  PULL_PRODUCTS_SERVICES_FROM_QUICKBOOKS,
  UNMAP_PRODUCTS,
} from 'constants/actions-constants'
import {
  setIsFetchingProducts,
  setProducts,
  setIsFetchingCsiProducts,
  setCsiProducts,
  setIsFetchingUnmapProducts,
  showMappedProductAlert,
  showUnmappedProductAlert,
} from 'actions/products-actions'
import { setUpdatedInvoicesAndProductsForBatch } from 'actions/batches-actions'

function* getAllProducts() {
  try {
    yield put(setIsFetchingProducts(true))
    const URL = 'products/?limit=1000'
    const res = yield call([api, 'getProducts'], URL)
    const { data } = res

    yield put(setProducts(data))
  } catch (error) {
    //to do getProducts
  } finally {
    yield put(setIsFetchingProducts(false))
  }
}

function* getCsiProducts() {
  try {
    yield put(setIsFetchingCsiProducts(true))
    const URL = 'products/csi-products/?limit=1000'
    const res = yield call([api, 'getProducts'], URL)
    const { data } = res

    yield put(setCsiProducts(data))
  } catch (error) {
    //to do getProducts
  } finally {
    yield put(setIsFetchingCsiProducts(false))
  }
}

function* mapProduct({ payload }) {
  try {
    yield put(setIsFetchingProducts(true))
    const { body, isNeedToUpdateProducts } = payload
    const URL = 'products/map/'
    yield call([api, 'mapProductApi'], URL, body)

    yield put(showMappedProductAlert(true))

    if (isNeedToUpdateProducts) {
      yield call(getAllProducts)
    } else {
      const qbProducts = yield select((state) => state.batches.updatedInvoices)
      const _newQbProducts = cloneDeep(qbProducts)

      _newQbProducts.products = _newQbProducts.products.filter(
        (product) => !body.products_ids.includes(product._id)
      )

      yield put(setUpdatedInvoicesAndProductsForBatch(_newQbProducts))
    }
  } catch (error) {
    //to do
  } finally {
    yield put(setIsFetchingProducts(false))
  }
}

function* pullProductsFromQb() {
  try {
    yield put(setIsFetchingProducts(true))
    const URL = 'products/quickbooks-sync/'
    yield call([api, 'pullProductsFromQbApi'], URL)

    yield call(getAllProducts)
  } catch (error) {
    //to do
  } finally {
    yield put(setIsFetchingProducts(false))
  }
}

function* unmapProducts({ payload }) {
  try {
    yield put(setIsFetchingUnmapProducts(true))
    const URL = 'products/unmap/'
    yield call([api, 'unmapProductApi'], URL, payload)

    yield put(showUnmappedProductAlert(true))
    yield call(getAllProducts)
  } catch (error) {
    //to do
  } finally {
    yield put(setIsFetchingUnmapProducts(false))
  }
}

export function* watchProductsWorker() {
  yield takeEvery(GET_ALL_PRODUCTS, getAllProducts)
  yield takeEvery(GET_CSI_PRODUCTS, getCsiProducts)
  yield takeEvery(MAP_PRODUCT, mapProduct)
  yield takeEvery(PULL_PRODUCTS_SERVICES_FROM_QUICKBOOKS, pullProductsFromQb)
  yield takeEvery(UNMAP_PRODUCTS, unmapProducts)
}
