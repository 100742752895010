import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'

import Modal from 'components/ui/modal-component'
import Title from 'components/ui/title-component'
import Button from 'components/ui/button-component'

const SignInConnectModal = (props) => {
  const {
    handleCloseModal,
    handleClickConnect,
    title,
    topMiddleText,
    bottomMiddleText,
    connectButtonText,
    skipButtonText,
  } = props

  const handleClickScipQuickbooks = useCallback(() => {
    handleCloseModal(false)
  }, [handleCloseModal])

  return (
    <Modal classNameWrap={'signin_connect_modal_container'}>
      <Title title={title} extraClassName="signin_connect_modal_title" />
      <span className="signin_connect_modal_text first_line">
        {topMiddleText}
      </span>
      <span className="signin_connect_modal_text">{bottomMiddleText}</span>
      <Button
        name={connectButtonText}
        styletype="green"
        classNameButton="signin_connect_modal_button"
        onClick={handleClickConnect}
      />
      <Button
        name={skipButtonText}
        styletype="link"
        classNameButton="signin_connect_modal_button_link"
        onClick={handleClickScipQuickbooks}
      />
    </Modal>
  )
}

SignInConnectModal.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  handleClickConnect: PropTypes.func.isRequired,
  title: PropTypes.string,
  topMiddleText: PropTypes.string,
  bottomMiddleText: PropTypes.string,
  connectButtonText: PropTypes.string,
  skipButtonText: PropTypes.string,
}

export default memo(SignInConnectModal)
