import React, { useEffect, memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import SearchInput from 'components/ui/search-input'
import { getCompanies, clearCompaniesList } from 'actions/companies'
import { getCompaniesListSelector } from 'redusers/companies'
import Company from './Company'
import { SEARCH_PLACEHOLDER_FOR_COMPANIES_LN } from 'constants/language-key-constants'
import { useShallowSelector } from 'custom-hooks/useShallowSelector'

const CompaniesList = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [searchValue, setsearchValue] = useState('')
  const companiesList = useShallowSelector(getCompaniesListSelector)

  const handleChangeSearhInput = (event) => {
    const value = event.target.value
    setsearchValue(value)
  }

  const _searchCompaniesList = companiesList.filter((company) => {
    const { companyName, realmId } = company
    if (
      companyName.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
      realmId.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
    ) {
      return true
    }
    return false
  })

  useEffect(() => {
    dispatch(getCompanies())
    return () => {
      dispatch(clearCompaniesList())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="batches_search_section">
        <div className="search_container">
          <SearchInput
            placeholder={t(SEARCH_PLACEHOLDER_FOR_COMPANIES_LN)}
            classNameContainer="batches_page_search_input_container"
            onChange={handleChangeSearhInput}
          />
        </div>
      </div>
      <div className="companies_list_container">
        {_searchCompaniesList.map((company) => {
          const { _id, companyName, realmId } = company
          return (
            <Company
              key={_id}
              companyName={companyName}
              id={_id}
              realmId={realmId}
            />
          )
        })}
      </div>
    </>
  )
}

export default memo(CompaniesList)
