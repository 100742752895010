import React, { useState, memo, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useShallowSelector } from 'custom-hooks/useShallowSelector'
import { reduxForm } from 'redux-form'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { useTranslation, getI18n } from 'react-i18next'

import { USState } from 'constants/US-state-constant'
import Title from 'components/ui/title-component'
import Input from 'components/ui/input-component'
import Button from 'components/ui/button-component'
import Select from 'components/ui/select'
import InfoBox from 'components/ui/infobox-component'
import { ADD_NEW_USER_MODAL_STATE } from 'constants/profile-modal-state-constants'
import { getSuccessMessageSelector } from 'redusers/profile'
import { clearSuccesMessage } from 'actions/profile-actions'
import {
  PROFILE_MODAL_FORM_TITLE_LN,
  PROFILE_MODAL_FORM_INPUT_USERMANE_PLACEHOLDER_LN,
  PROFILE_MODAL_FORM_INPUT_NEW_PASSWORD_PLACEHOLDER_LN,
  PROFILE_MODAL_FORM_INPUT_CONFIRM_PASSWORD_PLACEHOLDER_LN,
  PROFILE_MODAL_FORM_INPUT_FIRST_NAME_PLACEHOLDER_LN,
  PROFILE_MODAL_FORM_INPUT_LAST_NAME_PLACEHOLDER_LN,
  PROFILE_MODAL_FORM_INPUT_COMPANY_NAME_PLACEHOLDER_LN,
  PROFILE_MODAL_FORM_INPUT_PHONE_PLACEHOLDER_LN,
  PROFILE_MODAL_FORM_INPUT_PHONE_BOTTOM_HELPER_LN,
  PROFILE_MODAL_SUB_TITLE_2_LN,
  PROFILE_MODAL_FORM_INPUT_ADDRESS_PLACEHOLDER_LN,
  PROFILE_MODAL_FORM_INPUT_CITY_PLACEHOLDER_LN,
  PROFILE_MODAL_FORM_INPUT_STATE_PLACEHOLDER_LN,
  PROFILE_MODAL_FORM_INPUT_POSTAL_CODE_PLACEHOLDER_LN,
  PROFILE_MODAL_FORM_USER_ADMIN_SWITCH_LABEL_LN,
  CLOSE_BUTTON_NAME_LN,
  PROFILE_MODAL_FORM_VALID_MESSAGE_1_LN,
  PROFILE_MODAL_FORM_VALID_MESSAGE_2_LN,
  PROFILE_MODAL_FORM_VALID_MESSAGE_3_LN,
  PROFILE_MODAL_FORM_VALID_MESSAGE_4_LN,
  PROFILE_MODAL_FORM_VALID_MESSAGE_5_LN,
  PROFILE_MODAL_FORM_VALID_MESSAGE_6_LN,
  PROFILE_MODAL_FORM_VALID_MESSAGE_7_LN,
  PROFILE_MODAL_FORM_VALID_MESSAGE_8_LN,
  PROFILE_MODAL_FORM_VALID_MESSAGE_9_LN,
  PROFILE_MODAL_FORM_VALID_MESSAGE_10_LN,
  PROFILE_SUCCSESS_MESSAGE_LN,
  PROFILE_MODAL_FORM_VALID_MESSAGE_0_LN,
} from 'constants/language-key-constants'

const validate = (values) => {
  const i18n = getI18n()
  const errors = {}
  if (!values.userId) {
    errors.userId = i18n.t(PROFILE_MODAL_FORM_VALID_MESSAGE_1_LN)
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.userId)) {
    errors.userId = i18n.t(PROFILE_MODAL_FORM_VALID_MESSAGE_2_LN)
  }

  if (!values.firstName) {
    errors.firstName = i18n.t(PROFILE_MODAL_FORM_VALID_MESSAGE_0_LN)
  }

  if (!values.lastName) {
    errors.lastName = i18n.t(PROFILE_MODAL_FORM_VALID_MESSAGE_0_LN)
  }

  if (!values.companyName) {
    errors.companyName = i18n.t(PROFILE_MODAL_FORM_VALID_MESSAGE_0_LN)
  }

  if (!values.phone) {
    errors.phone = i18n.t(PROFILE_MODAL_FORM_INPUT_PHONE_BOTTOM_HELPER_LN)
  } else if (values.phone.length < 9) {
    errors.phone = i18n.t(PROFILE_MODAL_FORM_VALID_MESSAGE_3_LN)
  } else if (values.phone.length > 20) {
    errors.phone = i18n.t(PROFILE_MODAL_FORM_VALID_MESSAGE_4_LN)
  } else if (
    !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/i.test(values.phone)
  ) {
    errors.phone = i18n.t(PROFILE_MODAL_FORM_VALID_MESSAGE_5_LN)
  }

  if (!values.address) {
    errors.address = i18n.t(PROFILE_MODAL_FORM_VALID_MESSAGE_0_LN)
  }
  if (!values.city) {
    errors.city = i18n.t(PROFILE_MODAL_FORM_VALID_MESSAGE_0_LN)
  } else if (!/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/i.test(values.city)) {
    errors.city = i18n.t(PROFILE_MODAL_FORM_VALID_MESSAGE_10_LN)
  }
  if (!values.stateRegion) {
    errors.stateRegion = i18n.t(PROFILE_MODAL_FORM_VALID_MESSAGE_0_LN)
  }
  if (!values.zipCode) {
    errors.zipCode = i18n.t(PROFILE_MODAL_FORM_VALID_MESSAGE_0_LN)
  }

  if (!values.zipCode) {
    errors.zipCode = i18n.t(PROFILE_MODAL_FORM_VALID_MESSAGE_0_LN)
  } else if (!/^(\d{5}(?:-\d{4})?)$/i.test(values.zipCode)) {
    errors.zipCode = i18n.t(PROFILE_MODAL_FORM_VALID_MESSAGE_6_LN)
  }

  if (values.password && values.password !== values.confirmPassword) {
    errors.confirmPassword = i18n.t(PROFILE_MODAL_FORM_VALID_MESSAGE_7_LN)
  }

  return errors
}

const validatePasswordForProfile = (values) => {
  const i18n = getI18n()
  if (values && values.length < 8) {
    return i18n.t(PROFILE_MODAL_FORM_VALID_MESSAGE_8_LN)
  } else if (values && values.length > 16) {
    return i18n.t(PROFILE_MODAL_FORM_VALID_MESSAGE_9_LN)
  }
}

const validatePasswordForCreateUser = (values) => {
  const i18n = getI18n()
  if (!values) {
    return i18n.t(PROFILE_MODAL_FORM_VALID_MESSAGE_0_LN)
  }
  if (values && values.length < 8) {
    return i18n.t(PROFILE_MODAL_FORM_VALID_MESSAGE_8_LN)
  } else if (values && values.length > 16) {
    return i18n.t(PROFILE_MODAL_FORM_VALID_MESSAGE_9_LN)
  }
}

let Form = (props) => {
  const {
    handleSubmit,
    handleClickClose,
    change,
    currentValues,
    isDisabledUpdateButton,
    isProfileFetching,
    modalProfileState,
    buttonSaveChangeName,
    errorMessage,
    switchValue,
    handleChangeSwitch,
    initialValues,
  } = props

  const dispatch = useDispatch()

  const successMessage = useShallowSelector(getSuccessMessageSelector)

  const { t } = useTranslation()

  const { stateRegion = '' } = currentValues

  const [selectData, setSelectData] = useState('')

  const handleSelectState = useCallback(
    (e) => {
      const stateName = e.target.dataset.stateName
      const stateLabel = e.target.dataset.stateLabel
      change('stateRegion', `${stateName} (${stateLabel})`)
      setSelectData(`${stateName} (${stateLabel})`)
    },
    [change]
  )

  const handleFocusedSelectState = useCallback(
    (focused) => {
      if (!focused) {
        change('stateRegion', '')
      }
      if (focused && !stateRegion) {
        change('stateRegion', initialValues.stateRegion)
      }

      if (focused && selectData) {
        change('stateRegion', selectData)
      }
    },
    [change, initialValues.stateRegion, selectData, stateRegion]
  )

  const _validPassword =
    modalProfileState === ADD_NEW_USER_MODAL_STATE
      ? validatePasswordForCreateUser
      : validatePasswordForProfile

  const _searchDataState = USState.filter(
    (state) =>
      state.name
        ?.toLocaleLowerCase()
        ?.indexOf(stateRegion?.toLocaleLowerCase()) !== -1 ||
      state.label
        ?.toLocaleLowerCase()
        ?.indexOf(stateRegion?.toLocaleLowerCase()) !== -1
  )

  useEffect(() => {
    let timer = null
    if (successMessage) {
      timer = setTimeout(() => {
        dispatch(clearSuccesMessage())
      }, 5000)
    }

    return () => {
      clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successMessage])

  return (
    <form className="user_profile_form" onSubmit={handleSubmit}>
      <div className="user_profile_form__section_profile user_profile_form__sections order_1">
        <Title
          title={t(PROFILE_MODAL_FORM_TITLE_LN)}
          extraClassName={'user_profile_form__title'}
          component="h3"
        />
        <Input
          placeholder={t(PROFILE_MODAL_FORM_INPUT_USERMANE_PLACEHOLDER_LN)}
          classNameContainer={'user_profile_form__inputs_container user_id'}
          classNameInput={
            'user_profile_form__inputs user_profile_form__user_id_input'
          }
          type="text"
          name="userId"
          validateAfterSubmit={false}
          serverError={errorMessage}
          bottomHelpersText={
            modalProfileState === ADD_NEW_USER_MODAL_STATE
              ? t(PROFILE_MODAL_FORM_VALID_MESSAGE_1_LN)
              : ''
          }
        />
        <div className="user_profile_form__inputs_row">
          <Input
            placeholder={t(
              PROFILE_MODAL_FORM_INPUT_NEW_PASSWORD_PLACEHOLDER_LN
            )}
            classNameContainer={
              'user_profile_form__inputs_container margin_left'
            }
            classNameInput={
              'user_profile_form__inputs user_profile_form__user_id_input'
            }
            type="password"
            name="password"
            isPassword
            autoComplete="new-password"
            validateAfterSubmit={false}
            validate={_validPassword}
          />
          <Input
            placeholder={t(
              PROFILE_MODAL_FORM_INPUT_CONFIRM_PASSWORD_PLACEHOLDER_LN
            )}
            classNameContainer={'user_profile_form__inputs_container'}
            classNameInput={
              'user_profile_form__inputs user_profile_form__user_id_input'
            }
            type="password"
            name="confirmPassword"
            isPassword
            autoComplete="new-password"
            validateAfterSubmit={false}
          />
        </div>
        <div className="user_profile_form__inputs_row">
          <Input
            placeholder={t(PROFILE_MODAL_FORM_INPUT_FIRST_NAME_PLACEHOLDER_LN)}
            classNameContainer={
              'user_profile_form__inputs_container margin_left'
            }
            classNameInput={
              'user_profile_form__inputs user_profile_form__user_id_input'
            }
            type="text"
            name="firstName"
            validateAfterSubmit={false}
          />
          <Input
            placeholder={t(PROFILE_MODAL_FORM_INPUT_LAST_NAME_PLACEHOLDER_LN)}
            classNameContainer={'user_profile_form__inputs_container'}
            classNameInput={
              'user_profile_form__inputs user_profile_form__user_id_input'
            }
            type="text"
            name="lastName"
            validateAfterSubmit={false}
          />
        </div>
        <Input
          placeholder={t(PROFILE_MODAL_FORM_INPUT_COMPANY_NAME_PLACEHOLDER_LN)}
          classNameContainer={'user_profile_form__inputs_container user_id'}
          classNameInput={
            'user_profile_form__inputs user_profile_form__user_id_input'
          }
          type="text"
          name="companyName"
        />
        <Input
          placeholder={t(PROFILE_MODAL_FORM_INPUT_PHONE_PLACEHOLDER_LN)}
          classNameContainer={'user_profile_form__inputs_container user_id'}
          classNameInput={
            'user_profile_form__inputs user_profile_form__user_id_input'
          }
          type="text"
          name="phone"
          bottomHelpersText={t(PROFILE_MODAL_FORM_INPUT_PHONE_BOTTOM_HELPER_LN)}
          validateAfterSubmit={false}
          mask={'(999)999-9999'}
          autoComplete="off false"
        />
      </div>
      <div className="user_profile_form__section_profile user_profile_form__sections order_2">
        <Title
          title={t(PROFILE_MODAL_SUB_TITLE_2_LN)}
          extraClassName={'user_profile_form__title'}
          component="h3"
        />
        <Input
          placeholder={t(PROFILE_MODAL_FORM_INPUT_ADDRESS_PLACEHOLDER_LN)}
          classNameContainer={'user_profile_form__inputs_container user_id'}
          classNameInput={
            'user_profile_form__inputs user_profile_form__user_id_input'
          }
          type="text"
          name="address"
        />
        <div className="user_profile_form__inputs_row">
          <Input
            placeholder={t(PROFILE_MODAL_FORM_INPUT_CITY_PLACEHOLDER_LN)}
            classNameContainer={
              'user_profile_form__inputs_container margin_left'
            }
            classNameInput={
              'user_profile_form__inputs user_profile_form__user_id_input'
            }
            type="text"
            name="city"
          />
          <Select
            placeholder={t(PROFILE_MODAL_FORM_INPUT_STATE_PLACEHOLDER_LN)}
            classNameSelectContainer={'user_profile_form__inputs_container'}
            //classNameInputContainer={'user_profile_form__inputs_container'}
            classNameInput={
              'user_profile_form__inputs user_profile_form__user_id_input'
            }
            type="text"
            name="stateRegion"
            dataOptions={_searchDataState}
            datakey={'name'}
            validateAfterSubmit={true}
            handleSelect={handleSelectState}
            handleClickInput={handleFocusedSelectState}
          />
        </div>
        <Input
          placeholder={t(PROFILE_MODAL_FORM_INPUT_POSTAL_CODE_PLACEHOLDER_LN)}
          classNameContainer={'user_profile_form__inputs_container user_id'}
          classNameInput={
            'user_profile_form__inputs user_profile_form__user_id_input'
          }
          type="text"
          name="zipCode"
          autoComplete="postal-code"
        />
      </div>
      <div className="user_profile_form__section_buttons user_profile_form__sections order_4">
        {modalProfileState === ADD_NEW_USER_MODAL_STATE && (
          <div className={'make_admin_switch'}>
            <FormControlLabel
              control={
                <Switch
                  checked={switchValue}
                  onChange={handleChangeSwitch}
                  name="checkedB"
                  color="primary"
                />
              }
              label={t(PROFILE_MODAL_FORM_USER_ADMIN_SWITCH_LABEL_LN)}
            />
          </div>
        )}
        <div className="user_profile_form__inputs_row buttons">
          {successMessage && (
            <InfoBox
              textContent={t(PROFILE_SUCCSESS_MESSAGE_LN)}
              styleType="success"
              extraClassNameContainer="profile_info_box_container show-animated"
            />
          )}
          <span className="user_profile_form__inputs_row_buttons_container">
            <Button
              name={buttonSaveChangeName}
              classNameContainer="margin_left"
              classNameButton="user_profile_form_buttons"
              type="submit"
              styletype="green"
              disabled={isDisabledUpdateButton || isProfileFetching}
              isSpinner={isProfileFetching}
            />
            <Button
              name={t(CLOSE_BUTTON_NAME_LN)}
              classNameButton="user_profile_form_buttons"
              type="button"
              styletype="outline_blue"
              onClick={handleClickClose}
            />
          </span>
        </div>
      </div>
    </form>
  )
}

Form.propTypes = {}

Form = reduxForm({
  form: 'userProfile',
  validate,
})(memo(Form))

export default Form
