import moment from 'moment'
import uniqBy from 'lodash/uniqBy'
import sortBy from 'lodash/sortBy'
import {
  SET_FROM_DATE_TO_NEW_BATCH,
  SET_TO_DATE_TO_NEW_BATCH,
  CLERA_DATE_TO_NEW_BATCH,
  SET_UPDATED_INVOICES_AND_PRODUCTS_FOR_BATCH,
  SET_IS_FETCHING_BATCH,
  SET_ERROR_MESSAGE_IN_CREATE_BATCHES,
  CLEAR_ERROR_MESSAGE_IN_ERROR_BATCHES,
  SET_BATCHES,
  SET_OFFSET_COUNT_FOR_GETTING_BATCH,
  UPDATE_BATCHES,
  SET_IS_PAGGINATION,
  CLEAR_BATCHES,
  SET_BATCH_DETAIL,
  CLEAR_BATCH_DETAIL,
  CLEAR_ALL_DATA_BATCHES,
  SET_IS_PROCESSED_BATCH_ID,
  SET_TAX_EXEMPTIONS_LIST,
  START_FETCHING_CREATE_BATCH,
  STOP_FETCHING_CREATE_BATCH,
  SET_BATCH_INVOICES,
  START_FETCHING_GET_BATCH_INVOICES_IN_BATCH_DETAIL,
  STOP_FETCHING_GET_BATCH_INVOICES_IN_BATCH_DETAIL,
  UPDATE_INVOICES_IN_BATCH_DETAIL,
  START_FETCHING_GET_BATCH_INVOICES_IN_BATCH,
  STOP_FETCHING_GET_BATCH_INVOICES_IN_BATCH,
  SET_SEARCH_VALUE_BATCHES,
  CLEAR_SEARCH_VALUE_BACHES,
  SET_SELECTED_TRANSACTIONS_TYPE,
  SET_TAX_TYPE,
} from 'constants/actions-constants'

import {
  N_TAX_EXEMPT_CONST,
  defaultCustomerTypeValue,
  customer_data_constants,
} from 'constants/tax-exempt-constants'

import { transactionsTypes } from 'constants/transactions-type-constants'

// fromDate(pin):"2020-11-06T00:00:00+02:00"
// toDate(pin):"2020-11-06T23:59:59+02:00"

// fromDate(pin):"2020-11-06T00:00:00+02:00"
// toDate(pin):"2020-11-06T23:59:59+02:00"

const initialState = {
  isFetchingBatch: false,
  isFetchintCreateBatch: false,
  isFetchingInvoicesInBatchDetail: false,
  isFetchingInvoicesInBatch: false,
  errorMessage: '',
  isValidZipCodes: true,
  isProcessedBatchId: null,
  searchValue: '',
  createBatch: {
    fromDate: moment().startOf('day').format(),
    toDate: moment().endOf('day').format(),
    transactionsType: transactionsTypes.INVOICES,
  },
  batchesData: {
    batches: [],
    offsetCount: 0,
    isPaggination: true,
  },
  batchInvoices: [],
  updatedInvoices: {
    transactions: [],
    products: [],
  },
  tax_exemptions_list: [],
  processedInvoicesData: [],
  batchDetails: {
    _id: '',
    realm_id: '',
    transactions_ids: [],
    status: '',
    created_datetime: '',
    updated_datetime: '',
    invoices: [],
    offsetCount: 0,
    isPaggination: true,
  },
  taxTypes: [],
}

export const batches = (state = initialState, actions) => {
  switch (actions.type) {
    case SET_TAX_TYPE:
      return { ...state, taxTypes: actions.payload }
    case CLEAR_SEARCH_VALUE_BACHES:
      return { ...state, searchValue: initialState.searchValue }
    case SET_SEARCH_VALUE_BATCHES:
      return { ...state, searchValue: actions.payload }
    case START_FETCHING_GET_BATCH_INVOICES_IN_BATCH_DETAIL:
      return { ...state, isFetchingInvoicesInBatchDetail: true }
    case STOP_FETCHING_GET_BATCH_INVOICES_IN_BATCH_DETAIL:
      return { ...state, isFetchingInvoicesInBatchDetail: false }
    case START_FETCHING_GET_BATCH_INVOICES_IN_BATCH:
      return { ...state, isFetchingInvoicesInBatch: true }
    case STOP_FETCHING_GET_BATCH_INVOICES_IN_BATCH:
      return { ...state, isFetchingInvoicesInBatch: false }
    case START_FETCHING_CREATE_BATCH:
      return { ...state, isFetchintCreateBatch: true }
    case STOP_FETCHING_CREATE_BATCH:
      return { ...state, isFetchintCreateBatch: false }
    case SET_TAX_EXEMPTIONS_LIST:
      return { ...state, tax_exemptions_list: actions.payload }
    case SET_IS_PROCESSED_BATCH_ID:
      return { ...state, isProcessedBatchId: actions.payload }
    case CLEAR_ALL_DATA_BATCHES:
      return { ...initialState }
    case SET_BATCH_DETAIL:
      return {
        ...state,
        batchDetails: actions.payload,
      }
    case UPDATE_INVOICES_IN_BATCH_DETAIL:
      return {
        ...state,
        batchDetails: {
          ...state.batchDetails,
          invoices: [
            ...state.batchDetails.invoices,
            ...actions.payload.invoices,
          ],
          offsetCount: actions.payload.newOffsetCount,
          isPaggination: actions.payload.isPaggination,
        },
      }
    case CLEAR_BATCH_DETAIL:
      return {
        ...state,
        batchDetails: initialState.batchDetails,
      }
    case SET_BATCHES:
      return {
        ...state,
        batchesData: {
          offsetCount: 0,
          isPaggination: true,
          batches: actions.payload,
        },
      }
    case CLEAR_BATCHES: {
      return {
        ...state,
        batchesData: initialState.batches,
      }
    }
    case UPDATE_BATCHES:
      return {
        ...state,
        batchesData: {
          offsetCount: state.batchesData.offsetCount,
          isPaggination: state.batchesData.isPaggination,
          batches: [...state.batchesData.batches, ...actions.payload],
        },
      }
    case SET_OFFSET_COUNT_FOR_GETTING_BATCH:
      return {
        ...state,
        batchesData: {
          ...state.batchesData,
          offsetCount: actions.payload,
        },
      }
    case SET_IS_PAGGINATION:
      return {
        ...state,
        batchesData: {
          ...state.batchesData,
          isPaggination: actions.payload,
        },
      }
    case SET_FROM_DATE_TO_NEW_BATCH:
      return {
        ...state,
        createBatch: {
          ...state.createBatch,
          fromDate: moment(actions.payload)
            .set({ hours: 0, minutes: 0, seconds: 0 })
            .format(),
        },
      }
    case SET_TO_DATE_TO_NEW_BATCH:
      return {
        ...state,
        createBatch: {
          ...state.createBatch,
          toDate: moment(actions.payload)
            .set({ hours: 23, minutes: 59, seconds: 59 })
            .format(),
        },
      }
    case SET_SELECTED_TRANSACTIONS_TYPE:
      return {
        ...state,
        createBatch: {
          ...state.createBatch,
          transactionsType: actions.payload,
        },
      }
    case CLERA_DATE_TO_NEW_BATCH:
      return {
        ...state,
        createBatch: {
          ...state.createBatch,
          fromDate: initialState.createBatch.fromDate,
          toDate: initialState.createBatch.toDate,
          transactionsType: initialState.createBatch.transactionsType,
        },
        updatedInvoices: { ...initialState.updatedInvoices },
      }
    case SET_UPDATED_INVOICES_AND_PRODUCTS_FOR_BATCH:
      return {
        ...state,
        updatedInvoices: actions.payload,
      }
    case SET_IS_FETCHING_BATCH:
      return {
        ...state,
        isFetchingBatch: actions.payload,
      }

    case SET_ERROR_MESSAGE_IN_CREATE_BATCHES:
      return {
        ...state,
        errorMessage: actions.payload,
      }
    case CLEAR_ERROR_MESSAGE_IN_ERROR_BATCHES:
      return {
        ...state,
        errorMessage: initialState.errorMessage,
      }
    case SET_BATCH_INVOICES:
      return {
        ...state,
        batchInvoices: uniqBy(
          [...actions.payload, ...state.batchInvoices],
          '_id'
        ),
      }
    default:
      return state
  }
}

export const getIsFetchingInvoicesInBatchDetailSelector = (state) =>
  state.batches.isFetchingInvoicesInBatchDetail

export const getIsFetchingInvoicesInBatchSelector = (state) =>
  state.batches.isFetchingInvoicesInBatch

export const getTaxExemptionListSelector = (state) =>
  state.batches.tax_exemptions_list

export const getIsProcessedBatchIdSelector = (state) =>
  state.batches.isProcessedBatchId

export const isValidZipCodesSelector = (state) => state.batches.isValidZipCodes

export const getOffsetCountForGettingBatchesSelector = (state) =>
  state.batches.batchesData.offsetCount

export const getIsPagginatioForGettingBatchesSelector = (state) =>
  state.batches.batchesData.isPaggination

export const getBatchInvoicesSelector = (state) => {
  return state.batches.batchInvoices?.map((item) => {
    const {
      quickbooks_data = {},
      _id,
      csi_data = {},
      is_send_to_qb,
      zip_code,
      exemption_data,
      is_deleted,
      customer_type,
    } = item || {}
    const {
      DocNumber,
      CustomerRef = {},
      BillAddr = {},
      ShipAddr = {},
      TotalAmt,
      EmailStatus,
      TxnDate,
    } = quickbooks_data || {}

    const { tax_summary = {}, is_errors_exists: isErrorExists } = csi_data || {}

    let total_tax_amount = tax_summary?.total_tax_amount ?? 0

    const numInvoice = DocNumber

    const customer = CustomerRef?.name || ''

    const issueDate = TxnDate

    const zipCode =
      zip_code ||
      (BillAddr && BillAddr?.PostalCode) ||
      (ShipAddr && ShipAddr?.PostalCode) ||
      (ShipAddr && ShipAddr?.Line4 && ShipAddr?.Line4?.slice(-5)) ||
      (BillAddr && BillAddr?.Line4 && BillAddr?.Line4?.slice(-5)) ||
      ''
    const revenue = TotalAmt

    const taxTotal = total_tax_amount

    const taxesExist = is_send_to_qb ? 'Y' : 'N'

    const emailed = EmailStatus === 'EmailSent'

    const taxEx = exemption_data?.exempt_code || N_TAX_EXEMPT_CONST

    return {
      _id,
      numInvoice,
      customer,
      issueDate,
      zipCode,
      revenue,
      taxTotal,
      taxesExist,
      emailed,
      isErrorExists,
      taxEx,
      is_deleted,
      customer_type,
    }
  })
}

export const getBatchesSelector = (state) => {
  return state.batches.batchesData.batches
}

export const getSearchValueForBatchSelector = (state) =>
  state.batches.searchValue

export const getFromDateToCreatingBatchSelector = (state) =>
  state.batches.createBatch.fromDate

export const getToDateToCreatingBatchSelector = (state) =>
  state.batches.createBatch.toDate

export const getTransactionsTypeCreatingBatchSelector = (state) =>
  state.batches.createBatch.transactionsType

export const getIsFetchingBatchSelector = (state) =>
  state.batches.isFetchingBatch

export const getBatchInvoicesUnmappenProduct = (state) => {
  const _unmappedProducts = state.batches.updatedInvoices?.products?.filter(
    (item) => {
      return !item?.csi_mapping
    }
  )
  return sortBy(_unmappedProducts, [
    function (o) {
      return o?.quickbooks_data?.Name
    },
  ])
}

export const getCreatebatchInvoicesSelector = (state) => {
  return state.batches.updatedInvoices?.transactions?.map((item) => {
    const {
      quickbooks_data = {},
      _id: id,
      zip_code,
      is_deleted,
      qb_status,
      revenue: _revenue,
      type,
    } = item || {}
    const {
      DocNumber,
      CustomerRef = {},
      TotalAmt,
      EmailStatus,
      TxnDate,
      Id,
      Line = [],
    } = quickbooks_data || {}

    const withoutQuantity = Line.some(
      (l) => l.SalesItemLineDetail && l.SalesItemLineDetail.Qty === null
    )

    const isUnmapProductService = Line.some((l) => {
      if (
        state.batches.updatedInvoices?.products?.some(
          (p) =>
            p?.quickbooks_data?.Description === l?.Description &&
            p?.quickbooks_data?.Id === l?.SalesItemLineDetail?.ItemRef?.value
        )
      ) {
        return true
      }
      return false
    })

    const numInvoice = DocNumber
    const customer = CustomerRef?.name || ''
    const customerCode = CustomerRef?.value || ''
    const issueDate = TxnDate
    const zipCode = zip_code || ''

    const revenue = _revenue || TotalAmt

    const taxesExist = qb_status === 'TAXES UPDATED'

    const emailed = EmailStatus === 'EmailSent'
    const _id = id || Id

    return {
      _id,
      numInvoice,
      customer,
      customerCode,
      issueDate,
      zipCode,
      revenue,
      taxesExist,
      emailed,
      is_deleted,
      type: type || '',
      withoutQuantity,
      isUnmapProductService,
    }
  })
}

export const getProcessedInvoicesDataSelector = (state) => {
  return state.batches.batchDetails?.invoices?.map((item) => {
    const {
      quickbooks_data = {},
      _id,
      csi_data = {},
      exemption_data,
      is_deleted,
      csi_status,
      qb_status,
      revenue,
      customer_type,
      type,
    } = item || {}
    const {
      DocNumber,
      CustomerRef = {},
      EmailStatus,
      TxnDate,
    } = quickbooks_data || {}

    const tax_summary = csi_data?.tax_summary || {}
    const isErrorExists = csi_status === 'ERROR'
    const { total_tax_amount = 0 } = tax_summary || {}

    const numInvoice = DocNumber
    const customer = CustomerRef.name || ''
    const issueDate = TxnDate

    const taxTotal = total_tax_amount || 0

    const taxesExist = qb_status === 'TAXES UPDATED'

    const emailed = EmailStatus === 'EmailSent'
    const taxEx = exemption_data?.exempt_code || N_TAX_EXEMPT_CONST
    return {
      _id,
      numInvoice,
      customer,
      issueDate,
      revenue:
        type === transactionsTypes.CREDIT_MEMO ? -revenue || 0 : revenue || 0,
      taxTotal:
        type === transactionsTypes.CREDIT_MEMO ? -taxTotal || 0 : taxTotal || 0,
      taxesExist,
      emailed,
      taxEx,
      isErrorExists,
      is_qb_update_needed:
        csi_status !== 'CALCULATED' || qb_status === 'OUTDATED',
      is_deleted,
      customer_type:
        customer_data_constants.customer_type[customer_type] ??
        defaultCustomerTypeValue,
      type,
    }
  })
}

export const getErrorMessageForNewBatchSelector = (state) =>
  state.batches.errorMessage

export const getBatchDetailIdSelector = (state) =>
  state.batches.batchDetails._id

export const getOffsetCountForInvoicesBatchDetailSelector = (state) =>
  state.batches.batchDetails.offsetCount

export const getIsFetchingCreateBatch = (state) =>
  state.batches.isFetchintCreateBatch

export const getBatchInvoicesAllSizeSelector = (state) =>
  state.batches.batchDetails.transactions_ids.length

export const getIsPagginationInvoicesInBatchDetailSelector = (state) =>
  state.batches.batchDetails.isPaggination

export const getTaxTypesSelector = (state) => state.batches.taxTypes
