import React, { useState, memo, useCallback } from 'react'
import RadioGroup from '@material-ui/core/RadioGroup'

import CustomRadioButton from 'components/ui/custom-radio-button'

const radioGroupCsiProducts = ({
  data = [],
  csiProductSelectedValue = '',
  input,
  ...rest
}) => {
  return (
    <RadioGroup {...input} {...rest}>
      {data.map(({ services, _id, code, description }) => {
        const [productCode, serviceCode] = csiProductSelectedValue.split(';')
        const selectedServiceCode = productCode === code ? serviceCode : null

        return (
          <ServicesList
            services={services}
            key={_id}
            productCode={code}
            productDescription={description}
            selectedServiceCode={selectedServiceCode}
          />
        )
      })}
    </RadioGroup>
  )
}

const ServicesList = memo(
  ({ services = [], productCode, productDescription, selectedServiceCode }) => {
    const [isOpen, setIsOpen] = useState(false)

    const handleClick = useCallback(
      (e) => {
        e.preventDefault()
        setIsOpen(!isOpen)
      },
      [isOpen]
    )

    const classNameButton = isOpen ? 'up' : ''

    return (
      <div className="csi_products_services_list">
        <button
          type="button"
          onClick={handleClick}
          className={`csi_products_services_list_button ${classNameButton}`}
        >
          {`${productCode} - ${productDescription}`}
        </button>
        {isOpen && (
          <div className="csi_products_list_wrap show-animated">
            {services.map((service) => {
              return (
                <CustomRadioButton
                  value={`${productCode};${service.code}`}
                  label={
                    <div className="csi_product_label_container">
                      <span className="code">
                        {`${productCode} - ${service.code} -`}&nbsp;
                      </span>
                      <span className="description">{service.description}</span>
                    </div>
                  }
                  key={service.code}
                  className="csi_product_service"
                  selected={
                    Number(selectedServiceCode) === Number(service.code)
                  }
                />
              )
            })}
          </div>
        )}
      </div>
    )
  }
)

export default memo(radioGroupCsiProducts)
