import React, { useEffect, memo, useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import InfoBox from 'components/ui/infobox-component'
import Title from 'components/ui/title-component'
import Button from 'components/ui/button-component'
import SearchInput from 'components/ui/search-input'
import { Table, TableRow, TableCell } from 'components/ui/table'
import {
  getMappedProductsSelector,
  getIsfetchingUnmapProducts,
  getIsShowUnMappedProductAlert,
} from 'redusers/products'
import {
  unmapProducts,
  showUnmappedProductAlert,
} from 'actions/products-actions'
import { showConfirmationModal } from 'actions/modals-state-actions'
import { getDataSelectedStateSelector } from 'redusers/checkbox-state'
import { useSelectedIds } from 'custom-hooks/useSelectedIds'
import {
  MAPPED_PRODUCT_TITLE_LN,
  MAPPED_PRODUCT_INFO_MESSAGE_LN,
  MAPPED_PRODUCT_TABLE_COUNT_TITLE_LN,
  MAPPED_PRODUCT_BUTTON_NAME_1_LN,
  DEFAULT_EXIT_BUTTON_LN,
  MAPPED_PRODUCT_TABLE_CELL_TITLE_2_LN,
  MAPPED_PRODUCT_TABLE_CELL_TITLE_3_LN,
  MAPEED_PRODUCT_TITLE_TOOLTIP_1_LN,
  MAPPED_PRODUCT_CONFIRMATION_TITLE_LN,
} from 'constants/language-key-constants'

const MappedProducts = (props) => {
  const {
    classNameContainer,
    mappedProducts = [],
    unmapProducts,
    handleClickClose,
    isFetchingUnmapProducts,
    showUnmappedProductAlert,
    isShowUnmappedProductAlert,
    showConfirmationModal,
    selectedMappedProducts = [],
  } = props

  const { t } = useTranslation()

  const [selectedIds] = useSelectedIds(selectedMappedProducts)
  const [searchValue, setSearchValue] = useState('')

  const handleClickUnmapped = useCallback(() => {
    showConfirmationModal({
      cb: unmapProducts,
      arg: selectedIds,
      title: t(MAPPED_PRODUCT_CONFIRMATION_TITLE_LN),
    })
  }, [selectedIds, showConfirmationModal, t, unmapProducts])

  useEffect(() => {
    let _t = null
    if (isShowUnmappedProductAlert) {
      _t = setTimeout(() => {
        showUnmappedProductAlert(false)
      }, 5000)
    }
    return () => {
      clearTimeout(_t)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowUnmappedProductAlert])

  const searchFilteringData = mappedProducts.filter((item) =>
    String(item?.productsName)
      .toLowerCase()
      .includes(String(searchValue).toLowerCase())
  )

  return (
    <div className={`mapped_products_container ${classNameContainer}`}>
      <div className="mapped_products_title_container">
        <Title
          title={t(MAPPED_PRODUCT_TITLE_LN)}
          extraClassName={'settings_sub_title'}
          clasNameContainer={'mapped_products_title'}
          component={'h3'}
          withTooltip
          textTooltip={t(MAPEED_PRODUCT_TITLE_TOOLTIP_1_LN)}
          placementTooltip="top"
        >
          {isShowUnmappedProductAlert && (
            <InfoBox
              textContent={t(MAPPED_PRODUCT_INFO_MESSAGE_LN)}
              extraClassNameContainer="piu_infobox_container show-animated"
              styleType={'success'}
            />
          )}
        </Title>
        <SearchInput
          placeholder={'Search by product name'}
          classNameContainer={'mapped_products_search_container'}
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
        />
      </div>
      {!!mappedProducts.length && (
        <>
          <Table
            className="mapped_products_table_container"
            headerComponent={MappedProductsTableHeader}
            bodyTableComponent={MappedProductsTableBody}
            autoHeightMin={45}
            autoHeightMax={135}
            data={searchFilteringData}
            checkboxKey={'select_cell'}
            countTitleText={t(MAPPED_PRODUCT_TABLE_COUNT_TITLE_LN)}
            defaultChecked={false}
            isUpdateTable={true}
          />
        </>
      )}
      <div className="unmap_product_button_group">
        <Button
          name={t(MAPPED_PRODUCT_BUTTON_NAME_1_LN)}
          styletype="green"
          classNameButton="unmap_product_button"
          classNameContainer="button_wrapp first"
          onClick={handleClickUnmapped}
          disabled={
            !selectedIds.length ||
            isFetchingUnmapProducts ||
            !mappedProducts.length
          }
          isSpinner={isFetchingUnmapProducts}
        />
        <Button
          name={t(DEFAULT_EXIT_BUTTON_LN)}
          styletype="outline_blue"
          classNameButton="unmap_product_button"
          classNameContainer="button_wrapp"
          onClick={handleClickClose}
        />
      </div>
    </div>
  )
}

const MappedProductsTableHeader = memo((headerProps) => {
  const { handlerOnChangeAllCheckBoxs, toggleSelectAllCheckBox, notEmpty } =
    headerProps
  const { t } = useTranslation()
  return (
    <>
      <TableCell
        //text={t(MAPPED_PRODUCT_TABLE_CELL_TITLE_1_LN)}
        className="select_cell"
        isAllCheckBox
        handlerOnChangeAllCheckBoxs={handlerOnChangeAllCheckBoxs}
        checkBoxValue={toggleSelectAllCheckBox}
        notEmpty={notEmpty}
      />
      <TableCell
        text={t(MAPPED_PRODUCT_TABLE_CELL_TITLE_2_LN)}
        className="products_cell"
      />
      <TableCell
        text={t(MAPPED_PRODUCT_TABLE_CELL_TITLE_3_LN)}
        className="tax_code_cell"
      />
    </>
  )
})

const MappedProductsTableBody = memo((bodyProps) => {
  const { data, handleCheckBox } = bodyProps
  return data.map((product) => {
    const { _id, productsName, taxCode, selected } = product

    return (
      <TableRow key={_id} className="mapped_product_table_row">
        <TableCell
          className="select_cell"
          isCheckBox
          idCheckedCell={_id}
          handleCheckBox={handleCheckBox}
          checkBoxValue={selected}
        />
        <TableCell className="products_cell" text={productsName} />
        <TableCell className="tax_code_cell" text={taxCode} />
      </TableRow>
    )
  })
})

const mapStateToProps = (state) => ({
  mappedProducts: getMappedProductsSelector(state),
  isFetchingUnmapProducts: getIsfetchingUnmapProducts(state),
  isShowUnmappedProductAlert: getIsShowUnMappedProductAlert(state),
  selectedMappedProducts: getDataSelectedStateSelector(state),
})

const actions = {
  unmapProducts,
  showUnmappedProductAlert,
  showConfirmationModal,
}

export default connect(mapStateToProps, actions)(memo(MappedProducts))
