import { all } from 'redux-saga/effects'

import { watchProfileWorkers } from './profile-sagas'
import { watchQBWorker } from './quickbooks-sagas'
import { watchBatchesWorker } from './batches-sagas'
import { watchInvoiceDetailWorkers } from './invoice-detail-saga'
import { watchModalStateWorkers } from './modal-state-sagas'
import { watchProcessedInvoiceDetailWorkers } from './processed-invoice-detail-sagas'
import { watchshowAlert } from './alert-saga'
import { watchProductsWorker } from './products-sagas'
import { watchConfirmationSagas } from './confirmation'
import { watchFireBaseNotivicationSagas } from './firebase-notification-handle'
import { notificationWatcherSagas } from './notification-sagas'
import { usersSagaWatcher } from './users'
import { companiesSagaWatcher } from './companies-sagas'
import { reportsSagaWatcher } from './reports-sagas'
import { logsSagaWatcher } from './logs-sagas'
import { watchInvoicesSagaWatcher } from './invoicesSaga'

export function* rootSagas() {
  yield all([
    watchProfileWorkers(),
    watchQBWorker(),
    watchBatchesWorker(),
    watchInvoiceDetailWorkers(),
    watchModalStateWorkers(),
    watchProcessedInvoiceDetailWorkers(),
    watchshowAlert(),
    watchProductsWorker(),
    watchConfirmationSagas(),
    watchFireBaseNotivicationSagas(),
    notificationWatcherSagas(),
    usersSagaWatcher(),
    companiesSagaWatcher(),
    reportsSagaWatcher(),
    logsSagaWatcher(),
    watchInvoicesSagaWatcher(),
  ])
}
