import React, { useState, useRef, useEffect, memo, useCallback } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import GetAppIcon from '@material-ui/icons/GetApp'
import { saveAs } from 'file-saver'

import Button from 'components/ui/button-component'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const RawTaxModal = ({
  content = [],
  buttonName = '',
  modalTitle = '',
  nameOfSavingFile = '',
}) => {
  const [open, setOpen] = useState(false)

  const handleClickOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleDownLoad = useCallback(() => {
    saveAs(
      new Blob([JSON.stringify(content, null, 2)]),
      `${nameOfSavingFile}.json`
    )
  }, [content, nameOfSavingFile])

  const descriptionElementRef = useRef(null)

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  return (
    <>
      <Button
        name={buttonName}
        styletype="link"
        classNameButton={'tax_summary_raw_button'}
        classNameContainer={''}
        onClick={handleClickOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        //scroll={'paper'}
        // maxWidth={'md'}
        fullWidth={true}
        aria-labelledby="max-width-dialog-title"
        aria-describedby="scroll-dialog-description"
        className="raw_tax_modal"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          className="tax_summary_raw_title"
        >
          {modalTitle}
          <button
            onClick={handleDownLoad}
            className="tax_summary_raw_download_button"
            title="download"
          >
            <GetAppIcon />
          </button>
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            component={'span'}
          >
            <code>
              {content.map((item, index) => {
                return (
                  <pre className="raw_tax_summary_text" key={index}>
                    {JSON.stringify(item, null, 2)}
                  </pre>
                )
              })}
            </code>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  )
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

export default memo(RawTaxModal)
