import {
  SHOW_CONFIRMATION_MODAL,
  HIDE_CONFIRMATION_MODAL,
} from 'constants/actions-constants'

const initialState = {
  title: '',
  yesButtonName: '',
  noButtonName: '',
  middleMessage: null,
  isCloseIconButton: false,
  yesButtonIsRight: false,
}

export const confirmationModal = (state = initialState, actions) => {
  switch (actions.type) {
    case SHOW_CONFIRMATION_MODAL:
      return {
        ...state,
        title: actions.payload.title || initialState.title,
        yesButtonName:
          actions.payload.yesButtonName || initialState.yesButtonName,
        noButtonName: actions.payload.noButtonName || initialState.noButtonName,
        middleMessage:
          actions.payload.middleMessage || initialState.middleMessage,
        isCloseIconButton:
          actions.payload.isCloseIconButton || initialState.isCloseIconButton,
        yesButtonIsRight:
          actions.payload.yesButtonIsRight || initialState.yesButtonIsRight,
      }
    case HIDE_CONFIRMATION_MODAL:
      return { ...initialState }
    default:
      return state
  }
}

export const getYesButtonIsRightSelector = (state) =>
  state.confirmationModal.yesButtonIsRight

export const getIsCloseIconButtonSelector = (state) =>
  state.confirmationModal.isCloseIconButton

export const getMiddleMessageConfirmationModalSelector = (state) => {
  return state.confirmationModal.middleMessage
}

export const getConfirmationModalTitleSelector = (state) =>
  state.confirmationModal.title

export const getConfirmationButtonsName = (state) => [
  state.confirmationModal.yesButtonName,
  state.confirmationModal.noButtonName,
]
