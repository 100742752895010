import React, { memo } from 'react'
import CompaniesList from 'components/companies-component'

const CompaniesPage = () => {
  return (
    <div className="batches_page_container container">
      <CompaniesList />
    </div>
  )
}

export default memo(CompaniesPage)
