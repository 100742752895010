import {
  SET_FROM_DATE_TO_NEW_BATCH,
  SET_TO_DATE_TO_NEW_BATCH,
  CLERA_DATE_TO_NEW_BATCH,
  RETRIEVE_UPDATED_INVOICES_AND_PRODUCTS_FOR_BATCH,
  SET_UPDATED_INVOICES_AND_PRODUCTS_FOR_BATCH,
  SET_IS_FETCHING_BATCH,
  CREATE_BATCH,
  SET_ERROR_MESSAGE_IN_CREATE_BATCHES,
  CLEAR_ERROR_MESSAGE_IN_ERROR_BATCHES,
  UPDATE_PRODUCTS_DATA_AFTER_MAPPED,
  GET_BATCHES,
  SET_BATCHES,
  SET_OFFSET_COUNT_FOR_GETTING_BATCH,
  UPDATE_BATCHES,
  SET_IS_PAGGINATION,
  CLEAR_BATCHES,
  SEND_BATCH,
  GET_BATCH_DETAIL_BY_ID,
  SET_BATCH_DETAIL,
  CLEAR_BATCH_DETAIL,
  EXPORT_TO_QB,
  CLEAR_ALL_DATA_BATCHES,
  SET_IS_PROCESSED_BATCH_ID,
  GET_TAX_EXEMPTIONS_LIST,
  SET_TAX_EXEMPTIONS_LIST,
  UPDATE_CUSTOMER_TAX_EXEMPTION,
  START_FETCHING_CREATE_BATCH,
  STOP_FETCHING_CREATE_BATCH,
  SET_BATCH_INVOICES,
  UPDATE_INVOICES_IN_BATCH_DETAIL,
  GET_BATCH_INVOICES_IN_BATCH_DETAIL,
  START_FETCHING_GET_BATCH_INVOICES_IN_BATCH_DETAIL,
  STOP_FETCHING_GET_BATCH_INVOICES_IN_BATCH_DETAIL,
  START_FETCHING_GET_BATCH_INVOICES_IN_BATCH,
  STOP_FETCHING_GET_BATCH_INVOICES_IN_BATCH,
  UPDATE_DELETED_INVOICE_IN_BATCHES,
  SET_SEARCH_VALUE_BATCHES,
  CLEAR_SEARCH_VALUE_BACHES,
  SET_SELECTED_TRANSACTIONS_TYPE,
  GET_TAX_TYPE,
  SET_TAX_TYPE
} from 'constants/actions-constants'

export const getTaxType = () => ({
  type: GET_TAX_TYPE
})

export const setTaxType = (payload) => ({
  type: SET_TAX_TYPE,
  payload
})

export const setSerchValueBatches = (payload) => ({
  type: SET_SEARCH_VALUE_BATCHES,
  payload,
})

export const clearSearchValue = () => ({
  type: CLEAR_SEARCH_VALUE_BACHES,
})

export const setIsProcessedBatchId = (payload) => ({
  type: SET_IS_PROCESSED_BATCH_ID,
  payload,
})

export const sendBatch = (payload) => ({
  type: SEND_BATCH,
  payload,
})

export const clearBatches = () => ({
  type: CLEAR_BATCHES,
})

export const clearAllDataBatches = () => ({
  type: CLEAR_ALL_DATA_BATCHES,
})

export const getBatches = (payload) => ({
  type: GET_BATCHES,
  payload,
})

export const setOffsetCountForGettingBatch = (payload) => ({
  type: SET_OFFSET_COUNT_FOR_GETTING_BATCH,
  payload,
})

export const setIsPaggination = (payload) => ({
  type: SET_IS_PAGGINATION,
  payload,
})

export const setBatches = (payload) => ({
  type: SET_BATCHES,
  payload,
})

export const updateBatches = (payload) => ({
  type: UPDATE_BATCHES,
  payload,
})

export const setFromDateToNewBatch = (payload) => ({
  type: SET_FROM_DATE_TO_NEW_BATCH,
  payload,
})

export const setToDateToNewBatch = (payload) => ({
  type: SET_TO_DATE_TO_NEW_BATCH,
  payload,
})

export const clearDateToNewBatch = () => ({
  type: CLERA_DATE_TO_NEW_BATCH,
})

export const getRetriveUpdatedInvoicesAndProductsForBatch = (payload) => ({
  type: RETRIEVE_UPDATED_INVOICES_AND_PRODUCTS_FOR_BATCH,
  payload,
})

export const setUpdatedInvoicesAndProductsForBatch = (payload) => ({
  type: SET_UPDATED_INVOICES_AND_PRODUCTS_FOR_BATCH,
  payload,
})

export const setIsFetchingBatch = (payload) => ({
  type: SET_IS_FETCHING_BATCH,
  payload,
})

export const createBatch = (payload) => ({
  type: CREATE_BATCH,
  payload,
})

export const setErrorMessageForNewBatches = (payload) => ({
  type: SET_ERROR_MESSAGE_IN_CREATE_BATCHES,
  payload,
})

export const clearErrorMessageForNewBatch = () => ({
  type: CLEAR_ERROR_MESSAGE_IN_ERROR_BATCHES,
})

export const updateProductsDataAfterMapped = (payload) => ({
  type: UPDATE_PRODUCTS_DATA_AFTER_MAPPED,
  payload,
})

export const getBatchDetailById = (payload) => ({
  type: GET_BATCH_DETAIL_BY_ID,
  payload,
})

export const setBatchDetail = (payload) => ({
  type: SET_BATCH_DETAIL,
  payload,
})

export const clearBatchDetail = () => ({
  type: CLEAR_BATCH_DETAIL,
})

export const exportToQb = (payload) => ({
  type: EXPORT_TO_QB,
  payload,
})

export const getTaxExemptionList = () => ({
  type: GET_TAX_EXEMPTIONS_LIST,
})

export const setTaxExemptionList = (payload) => ({
  type: SET_TAX_EXEMPTIONS_LIST,
  payload,
})

export const updateCustomersTaxExemption = (payload) => ({
  type: UPDATE_CUSTOMER_TAX_EXEMPTION,
  payload,
})

export const startFetchingCreateBatch = () => ({
  type: START_FETCHING_CREATE_BATCH,
})

export const stopFetchingCreateBatch = () => ({
  type: STOP_FETCHING_CREATE_BATCH,
})

// export const getBatchInvoices = (payload) => ({
//   type: GET_BATCH_INVOICES,
//   payload,
// })

export const setBatchInvoices = (payload) => {
  return {
    type: SET_BATCH_INVOICES,
    payload,
  }
}

// export const updateBatchInvoices = (payload) => ({
//   type: UPDATE_BATCH_INVOICES,
//   payload,
// })

export const getBatchInvoicesInBatchDetail = (payload) => ({
  type: GET_BATCH_INVOICES_IN_BATCH_DETAIL,
  payload,
})

export const startFetchingGetInvoicesInBatchDetail = () => ({
  type: START_FETCHING_GET_BATCH_INVOICES_IN_BATCH_DETAIL,
})

export const stopFetchingGetInvoicesInBatchDetail = () => ({
  type: STOP_FETCHING_GET_BATCH_INVOICES_IN_BATCH_DETAIL,
})

export const updateInvoicesInBatchDetail = (payload) => ({
  type: UPDATE_INVOICES_IN_BATCH_DETAIL,
  payload,
})

export const startFetchingGetInvoicesInBatch = () => ({
  type: START_FETCHING_GET_BATCH_INVOICES_IN_BATCH,
})

export const stopFetchingGetInvoicesInBatch = () => ({
  type: STOP_FETCHING_GET_BATCH_INVOICES_IN_BATCH,
})

export const updateDeletedInvoiceInBatches = (payload) => ({
  type: UPDATE_DELETED_INVOICE_IN_BATCHES,
  payload,
})

export const setSelectedTransactionsType = (payload) => ({
  type: SET_SELECTED_TRANSACTIONS_TYPE,
  payload,
})
