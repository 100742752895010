import {
  GET_NOTIFICATION_FROM_BACK_END,
  SET_NOTIFICATIONS_FROM_BACK_END,
  MARK_AS_READ,
} from 'constants/actions-constants'

export const getNotificationsFromBackEnd = () => ({
  type: GET_NOTIFICATION_FROM_BACK_END,
})

export const setNotificationsFromBackEnd = (payload) => ({
  type: SET_NOTIFICATIONS_FROM_BACK_END,
  payload,
})

export const markAsRead = () => ({
  type: MARK_AS_READ,
})
