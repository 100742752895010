import {
  SET_FIREBASE_TOKEN,
  SET_FIREBASE_ERROR,
  SET_FIREBASE_PERMISSION_STATUS,
} from 'constants/actions-constants'

import { FIREBASE_ASK_STATUS_CONSTANT } from 'constants/firebase-constant'

const initialState = {
  permissionStatus: FIREBASE_ASK_STATUS_CONSTANT, ////allow, block, ask
  firebaseToken: '',
  error: '',
}

export const NOTIFICATION_PERMISSION_STATUS_BLOCK = 'block'

export const firebaseNotification = (state = initialState, actions) => {
  switch (actions.type) {
    case SET_FIREBASE_TOKEN:
      return { ...state, firebaseToken: actions.payload }
    case SET_FIREBASE_ERROR:
      return { ...state, error: actions.payload }
    case SET_FIREBASE_PERMISSION_STATUS:
      return { ...state, permissionStatus: actions.payload }
    default:
      return state
  }
}

export const getFirebaseTokenSelector = (state) =>
  state.firebaseNotification.firebaseToken

export const getFirebaseNotificationStatusSelector = (state) =>
  state.firebaseNotification.permissionStatus
