/* eslint-disable no-undef */
import React, { useState, useEffect, memo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { useShallowSelector } from 'custom-hooks/useShallowSelector'
import Button from 'components/ui/button-component'
import Modal from 'components/ui/modal-component'
import Title from 'components/ui/title-component'
import ColorTextAria from 'components/ui/color-text-aria'
import CircularProgressWithLabel from 'components/ui/circular-progress-with-label'
import { getIsProcessedBatchIdSelector } from 'redusers/batches'
import {
  setIsProcessedBatchId,
  getBatchDetailById,
} from 'actions/batches-actions'
import {
  PROCESSING_INVOICESS_MODAL_TITLE_LN,
  PROCESSING_INVOICESS_MODAL_MESSAGE_1_LN,
  PROCESSING_INVOICESS_MODAL_MESSAGE_2_LN,
  PROCESSING_INVOICESS_MODAL_MESSAGE_3_LN,
  PROCESSING_INVOICESS_MODAL_BUTTON_LN,
} from 'constants/language-key-constants'
import {
  getFirebaseNotificationStatusSelector,
  NOTIFICATION_PERMISSION_STATUS_BLOCK,
} from 'redusers/firebase-notification'

const ProcessingInvoicesModal = ({ handleCloseProcessingInvoicesModal }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isProcessedBatchId = useShallowSelector(getIsProcessedBatchIdSelector)
  const firebaseNotificationStatus = useShallowSelector(
    getFirebaseNotificationStatusSelector
  )

  const [isDisabled, setIsDisabled] = useState(true)
  const [progress, setProgress] = useState(0)

  let _t = null
  const timeWaiting = 1000 * 25

  const delay = new Promise((resolve) => {
    _t = setTimeout(() => {
      resolve()
    }, timeWaiting)
  })

  useEffect(() => {
    if (isProcessedBatchId) {
      setIsDisabled(false)
    }
    if (!isProcessedBatchId) {
      delay.then(() => {
        setIsDisabled(false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProcessedBatchId])

  const handleCloseModal = () => {
    handleCloseProcessingInvoicesModal()
  }

  const handleClickOk = useCallback(() => {
    if (isProcessedBatchId) {
      dispatch(getBatchDetailById(isProcessedBatchId))
    } else {
      handleCloseProcessingInvoicesModal()
    }
  }, [dispatch, handleCloseProcessingInvoicesModal, isProcessedBatchId])

  useEffect(() => {
    let timer = setTimeout(function tick() {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + 3.125
      )
      timer = setTimeout(tick, 1000)
    }, 1000)

    return () => {
      clearTimeout(_t)
      clearTimeout(timer)
      dispatch(setIsProcessedBatchId(null))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal
      classNameContainer={'processing_invoices_modal_container'}
      classNameWrap={'processing_invoices_modal_wrap'}
      isCloseButton={isDisabled}
      handleClickClose={handleCloseModal}
    >
      <span className="processing_invoices_modal_title_section">
        {isDisabled &&
          firebaseNotificationStatus !==
            NOTIFICATION_PERMISSION_STATUS_BLOCK && (
            <span className="progress_container">
              <CircularProgressWithLabel value={progress} />
            </span>
          )}
        <Title
          title={t(PROCESSING_INVOICESS_MODAL_TITLE_LN)}
          extraClassName="processing_invoices_modal_title"
        />
      </span>
      <span className="processing_invoices_modal_text_area_section">
        {!isDisabled &&
          progress >= 74 &&
          firebaseNotificationStatus !== NOTIFICATION_PERMISSION_STATUS_BLOCK &&
          !isProcessedBatchId && (
            <ColorTextAria
              styleType="green"
              className="info_box_processing_invoices show-animated"
            >
              <span>{t(PROCESSING_INVOICESS_MODAL_MESSAGE_1_LN)}</span>
            </ColorTextAria>
          )}
        <ColorTextAria styleType="green" className="">
          <span className="first_paragraph">
            {t(PROCESSING_INVOICESS_MODAL_MESSAGE_2_LN)}
          </span>
          <span>{t(PROCESSING_INVOICESS_MODAL_MESSAGE_3_LN)}</span>
        </ColorTextAria>
      </span>
      <span className={'processing_invoices_modal_button_section'}>
        <Button
          styletype="green"
          classNameButton="processing_invoices_modal_button"
          name={t(PROCESSING_INVOICESS_MODAL_BUTTON_LN)}
          type="button"
          onClick={handleClickOk}
          disabled={
            isDisabled &&
            firebaseNotificationStatus !== NOTIFICATION_PERMISSION_STATUS_BLOCK
          }
        />
      </span>
    </Modal>
  )
}

export default memo(ProcessingInvoicesModal)
