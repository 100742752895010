import {
  GET_INVOICES,
  SET_INVOICES,
  SET_SEARCH_VALUE_FOR_INVOICES,
  SET_ORDER_BY_FOR_INVOICES_SORT,
  SET_QB_CONNECT_STATUS_FOR_INVOICES_FILTERING,
  SET_CSI_STATUS_FOR_INVOICES_FILTERING,
  SET_FROM_DATE_FOR_INVOICES_FILTERING,
  SET_TO_DATE_FOR_INVOICES_FILTERING,
  SET_OFFSET_GETTING_INVOICES,
  CLEAR_ALL_INVOICES_STATE,
  CLEAR_INVOICES,
  CLEAR_SORT_PARAM,
  CLEAR_FILTER_PARAM,
  CLEAR_SEARCH_VALUE,
  START_FETCHING_INVOICES,
  STOP_FETCHING_INVOICES,
  SET_INVOICES_FROM_PAGGINATION,
  UPDATE_INVOICES,
} from 'constants/actions-constants'

export const getInvoices = (payload) => ({
  type: GET_INVOICES,
  payload,
})

export const setInvoices = (payload) => ({
  type: SET_INVOICES,
  payload,
})

export const setInvoicesFromPaginnation = (payload) => ({
  type: SET_INVOICES_FROM_PAGGINATION,
  payload,
})

export const setSearchValueInvoices = (payload) => ({
  type: SET_SEARCH_VALUE_FOR_INVOICES,
  payload,
})

export const setOrderBySortInvoices = (payload) => ({
  type: SET_ORDER_BY_FOR_INVOICES_SORT,
  payload,
})

export const setQbStatusInvoicesFilter = (payload) => ({
  type: SET_QB_CONNECT_STATUS_FOR_INVOICES_FILTERING,
  payload,
})

export const setCsiStatusInvoicesFilter = (payload) => ({
  type: SET_CSI_STATUS_FOR_INVOICES_FILTERING,
  payload,
})

export const setFromDateInvoicesFilter = (payload) => ({
  type: SET_FROM_DATE_FOR_INVOICES_FILTERING,
  payload,
})

export const setToDateInvoicesFilter = (payload) => ({
  type: SET_TO_DATE_FOR_INVOICES_FILTERING,
  payload,
})

export const setOffsetInvoices = (payload) => ({
  type: SET_OFFSET_GETTING_INVOICES,
  payload,
})

export const clearAllInvoicesState = () => ({
  type: CLEAR_ALL_INVOICES_STATE,
})

export const clearInvoices = () => ({
  type: CLEAR_INVOICES,
})

export const clearSortParams = () => ({
  type: CLEAR_SORT_PARAM,
})

export const clearFilterParams = () => ({
  type: CLEAR_FILTER_PARAM,
})

export const clearSearchInvoicesValue = () => ({
  type: CLEAR_SEARCH_VALUE,
})

export const startFetchingInvoices = () => ({
  type: START_FETCHING_INVOICES,
})

export const stopFetchingInvoices = () => ({
  type: STOP_FETCHING_INVOICES,
})

export const updateInvoices = (payload) => ({
  type: UPDATE_INVOICES,
  payload,
})
