/* eslint-disable react/prop-types */
import React, { useEffect, memo, Suspense } from 'react'
import { useDispatch } from 'react-redux'
import isEqual from 'lodash/isEqual'

import { useShallowSelector } from 'custom-hooks/useShallowSelector'
import { useSortedData } from 'custom-hooks/useSort'
import { usePrevious } from 'custom-hooks/usePrevious'

import CustomScrollBar from 'components/ui/custom-scroll-bar'
import TabRow from './TableRow'
import Spinner from 'components/ui/spinner'
import {
  getDataSelectedStateSelector,
  getIsSelectedAllCheckboxSelector,
  getNotEmptyAllCheckboxSelector,
} from 'redusers/checkbox-state'
import { setSelectedState } from 'actions/checkbox-actions'

function Table(props) {
  const {
    className = '',
    headerComponent: Header,
    bodyTableComponent: Body,
    data = [],
    defaultsortedkey,
    secondDefaulSortedKey,
    checkboxKey = '',
    classNameHeaderRow = '',
    countTitleText = '',
    selectedCountTitleText = '',
    defaultChecked = true,
    exemptDefaultCheckedKey = '',
    autoHeightMin = 25,
    autoHeightMax = '100%',
    isScroll = true,
    isUpdateTable = false,
    bodyProps = {},
    headerProps = {},
    isSpinner = false,
    onScrollFrame,
  } = props
  const dispatch = useDispatch()
  const dataSelectedState = useShallowSelector(getDataSelectedStateSelector)
  const isSelectedAllCheckbox = useShallowSelector(
    getIsSelectedAllCheckboxSelector
  )
  const notEmptyAllCheckbox = useShallowSelector(getNotEmptyAllCheckboxSelector)
  const prevData = usePrevious(data)

  const [sortedData, sortedKey, handleSortClick] = useSortedData(
    defaultsortedkey,
    checkboxKey ? dataSelectedState : data,
    secondDefaulSortedKey
  )

  const handlerOnChangeAllCheckBoxs = () => {
    const _data = dataSelectedState.map((item) => {
      return { ...item, selected: !isSelectedAllCheckbox }
    })
    dispatch(setSelectedState(_data))
  }

  const handleOnChangeCheckbox = (id, checkedState) => {
    const _newSelected = dataSelectedState.map((item) => {
      if (id === item._id) return { ...item, selected: checkedState }
      return item
    })
    dispatch(setSelectedState(_newSelected))
  }

  useEffect(() => {
    if (!isUpdateTable) {
      if (
        (checkboxKey && !dataSelectedState.length && data.length) ||
        (prevData &&
          data &&
          prevData.length &&
          data.length &&
          checkboxKey &&
          !isEqual(data, prevData))
      ) {
        const _data = data.map((item) => {
          return {
            ...item,
            selected: exemptDefaultCheckedKey
              ? !item[exemptDefaultCheckedKey]
              : defaultChecked,
          }
        })
        dispatch(setSelectedState(_data))
      } else if (
        checkboxKey &&
        dataSelectedState.length &&
        !isEqual(data, prevData)
      ) {
        const _data = data.map((item) => {
          return {
            ...item,
            selected: dataSelectedState.find((s) => s._id === item._id)
              ?.selected,
            zipCode:
              dataSelectedState.find((s) => s._id === item._id)?.zipCode ||
              item.zipCode,
          }
        })
        dispatch(setSelectedState(_data))
      }
    } else if (checkboxKey && !isEqual(data, prevData)) {
      const _data = data.map((item) => {
        return {
          ...item,
          selected: exemptDefaultCheckedKey
            ? !item[exemptDefaultCheckedKey]
            : defaultChecked,
        }
      })
      dispatch(setSelectedState(_data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const selectedCount = dataSelectedState.filter((i) => i.selected).length

  return (
    <span className={`table_container ${className}`}>
      <TabRow isHeader={true} className={classNameHeaderRow}>
        <Header
          sortedKey={sortedKey}
          handleSortClick={handleSortClick}
          defaultsortedkey={defaultsortedkey}
          handlerOnChangeAllCheckBoxs={handlerOnChangeAllCheckBoxs}
          toggleSelectAllCheckBox={isSelectedAllCheckbox}
          notEmpty={notEmptyAllCheckbox}
          {...headerProps}
        />
      </TabRow>
      {isScroll ? (
        <Suspense
          fallback={
            <div style={{ paddingTop: '15px' }}>
              <Spinner styleSpinner={'green'} />
            </div>
          }
        >
          <CustomScrollBar
            autoHeight
            autoHeightMin={autoHeightMin}
            autoHeightMax={autoHeightMax}
            onScrollFrame={onScrollFrame}
          >
            <Body
              handleCheckBox={handleOnChangeCheckbox}
              data={sortedData}
              {...bodyProps}
            />
          </CustomScrollBar>
        </Suspense>
      ) : (
        <Body
          handleCheckBox={handleOnChangeCheckbox}
          data={sortedData}
          {...bodyProps}
        />
      )}
      {(!!selectedCountTitleText || !!countTitleText) && (
        <div className="table_footer">
          {!!selectedCountTitleText && (
            <span className="counter">{`${selectedCountTitleText}: ${selectedCount} `}</span>
          )}
          {!!countTitleText && (
            <span className="counter size-count">{`${countTitleText}: ${sortedData.length} `}</span>
          )}
        </div>
      )}
      {isSpinner && (
        <Spinner styleSpinner={'green'} className="table_footer_spinner" />
      )}
    </span>
  )
}

Table.propTypes = {}

export default memo(Table)
