import React, { useEffect, useState, memo } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import IconButton from '@material-ui/core/IconButton'
import { FileCopyOutlined, DoneAllOutlined } from '@material-ui/icons'

const CopyToClipboardButton = (props) => {
  const { copyContent = '', titleText = '', classNameTitle = '' } = props
  const [isCopy, setisCopy] = useState(false)

  const handleCopy = (copyText, res) => {
    setisCopy(!!res)
  }

  const handleClickCopy = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  let IconComponent = isCopy ? DoneAllOutlined : FileCopyOutlined

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setisCopy(false)
    }, 3000)
    return () => {
      clearTimeout(timeOut)
    }
  }, [isCopy])

  return (
    <>
      <span className={`${classNameTitle}`}>{titleText}</span>
      <CopyToClipboard text={copyContent} onCopy={handleCopy} disabled={isCopy}>
        <IconButton className="copy_button" onClick={handleClickCopy}>
          <IconComponent />
        </IconButton>
      </CopyToClipboard>
    </>
  )
}

export default memo(CopyToClipboardButton)
