import { useState, useEffect } from 'react'

import { sortTableByKey } from 'utils'
import { A_Z_DIRECTION_SORT } from 'constants/direction-sort-constant'

export const useSortedData = (
  defaultSortKey,
  data = [],
  secondDefaulSortedKey = []
) => {
  const [sortedParams, setSortedParams] = useState({
    sortedKey: defaultSortKey,
    direction: A_Z_DIRECTION_SORT,
  })

  const { sortedKey, direction } = sortedParams

  const _secondSortKey =
    sortedKey === defaultSortKey ? secondDefaulSortedKey : []

  const [sortedData, setsortedData] = useState(
    sortTableByKey(data, sortedKey, direction, _secondSortKey)
  )

  const handleSortClick = (key, direction) => {
    setSortedParams({
      sortedKey: key,
      direction: direction,
    })
  }

  useEffect(() => {
    setsortedData(sortTableByKey(data, sortedKey, direction, _secondSortKey))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedKey, direction, data])

  return [sortedData, sortedKey, handleSortClick]
}
