import React, { memo, useEffect, useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { formValueSelector, clearFields } from 'redux-form'
import MenuItem from '@material-ui/core/MenuItem'
import isEqual from 'lodash/isEqual'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import Title from 'components/ui/title-component'
import InfoBox from 'components/ui/infobox-component'
import PiuForm from './PiuForm'

import {
  getPuiTypeSelector,
  getPuiValueSelector,
  getIsPiuFetchingSelector,
  getIsShowinfoBoxSelector,
  getProviderTypeSelector,
} from 'redusers/profile'
import { updatePiu, showPiuInfoBox } from 'actions/profile-actions'
import { showConfirmationModal } from 'actions/modals-state-actions'
import { NO_TYPE_CSI_TYPE_CONST } from 'constants/csi-type-constant'
import { provider_types_data } from 'constants/provider-type-constants'
import {
  SETTINGS_MODAL_PIU_TITLE_LN,
  SETTINGS_MODAL_PIU_MESSAGE_1_LN,
  SETTING_RATE_CONFIRMATION_MESSAGE_1_LN,
  SETTINGS_MODAL_PIU_MESSAGE_2_LN,
  RATE_TEXT_TOOLTIP_LN,
} from 'constants/language-key-constants'

const formSelector = formValueSelector('piuForm')

const PiuSection = (props) => {
  const {
    piuValues,
    csiType,
    csiValue,
    updatePiu,
    clearFields,
    isPiuFetching,
    isShowPiuInfobox,
    showPiuInfoBox,
    showConfirmationModal,
    provider_type,
  } = props

  const { t } = useTranslation()

  const [selectedProviderType, setSelectedProviderType] = useState(
    provider_type
  )

  const [isUpdateProviderType, setIsUpdateProviderType] = useState(false)
  const [isUpdateRate, setIsUpdateRate] = useState(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const numFormat = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
  })

  const handleSubmit = useCallback(
    (reduxFormData) => {
      let _csi_value =
        reduxFormData.piuRadioButton === NO_TYPE_CSI_TYPE_CONST
          ? null
          : Number(reduxFormData.piuValue)
      const _csiObj = {
        csi_type: reduxFormData.piuRadioButton,
        provider_type: selectedProviderType,
      }
      if (_csi_value) {
        _csiObj.csi_value = _csi_value
      }
      const data = {
        profile: {
          csi: _csiObj,
        },
      }

      updatePiu(data)

      if (reduxFormData.piuRadioButton === NO_TYPE_CSI_TYPE_CONST) {
        clearFields('piuForm', false, false, 'piuValue')
      }
    },
    [clearFields, updatePiu, selectedProviderType]
  )

  const _handleSubmit = useCallback(
    (reduxFormData) => {
      let _title = t(SETTING_RATE_CONFIRMATION_MESSAGE_1_LN)
      if (
        !(
          (piuValues.piuRadioButton === NO_TYPE_CSI_TYPE_CONST &&
            csiType === NO_TYPE_CSI_TYPE_CONST) ||
          isEqual(
            {
              piuRadioButton: csiType,
              piuValue: numFormat.format(csiValue),
            },
            piuValues
          )
        ) &&
        provider_type === selectedProviderType
      ) {
        _title = t(SETTING_RATE_CONFIRMATION_MESSAGE_1_LN)
        setIsUpdateRate(true)
      }
      if (
        ((piuValues.piuRadioButton === NO_TYPE_CSI_TYPE_CONST &&
          csiType === NO_TYPE_CSI_TYPE_CONST) ||
          isEqual(
            {
              piuRadioButton: csiType,
              piuValue: numFormat.format(csiValue),
            },
            piuValues
          )) &&
        provider_type !== selectedProviderType
      ) {
        _title = 'Update Provider Type?'
        setIsUpdateProviderType(true)
      } else if (
        !(
          (piuValues.piuRadioButton === NO_TYPE_CSI_TYPE_CONST &&
            csiType === NO_TYPE_CSI_TYPE_CONST) ||
          isEqual(
            {
              piuRadioButton: csiType,
              piuValue: numFormat.format(csiValue),
            },
            piuValues
          )
        ) &&
        provider_type !== selectedProviderType
      ) {
        _title = 'Update Rate and Provider Type?'
        setIsUpdateProviderType(true)
        setIsUpdateRate(true)
      }
      showConfirmationModal({
        cb: handleSubmit,
        arg: reduxFormData,
        title: _title,
      })
    },
    [
      t,
      piuValues,
      csiType,
      numFormat,
      csiValue,
      provider_type,
      selectedProviderType,
      showConfirmationModal,
      handleSubmit,
    ]
  )

  const handleChangeProviderType = useCallback((event) => {
    setSelectedProviderType(event.target.value)
  }, [])

  useEffect(() => {
    let _t = null
    if (isShowPiuInfobox) {
      _t = setTimeout(() => {
        showPiuInfoBox(false)
        setIsUpdateProviderType(false)
        setIsUpdateRate(false)
      }, 5000)
    }
    return () => {
      clearTimeout(_t)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowPiuInfobox])

  const isDisabledUpdateButton =
    provider_type === selectedProviderType &&
    ((piuValues.piuRadioButton === NO_TYPE_CSI_TYPE_CONST &&
      csiType === NO_TYPE_CSI_TYPE_CONST) ||
      isEqual(
        {
          piuRadioButton: csiType,
          piuValue: numFormat.format(csiValue),
        },
        piuValues
      ))

  return (
    <div className="piu_section">
      <div className="piu_forms_container">
        <div className="piu_rate_section">
          <span className="sections_title_container">
            <Title
              title={t(SETTINGS_MODAL_PIU_TITLE_LN)}
              extraClassName={'settings_sub_title'}
              component={'h3'}
              clasNameContainer={'sections_title'}
              withTooltip
              textTooltip={t(RATE_TEXT_TOOLTIP_LN)}
              placementTooltip="right"
            >
              {isShowPiuInfobox && isUpdateRate && (
                <InfoBox
                  textContent={
                    csiType === NO_TYPE_CSI_TYPE_CONST
                      ? t(SETTINGS_MODAL_PIU_MESSAGE_2_LN)
                      : t(SETTINGS_MODAL_PIU_MESSAGE_1_LN)
                  }
                  extraClassNameContainer="piu_infobox_container show-animated"
                  styleType="success"
                />
              )}
            </Title>
          </span>
          <PiuForm
            initialValues={{
              piuRadioButton: csiType,
              piuValue: numFormat.format(csiValue),
            }}
            piuValues={piuValues}
            onSubmit={_handleSubmit}
            csiValue={csiValue}
            isPiuFetching={isPiuFetching}
            isDisabledUpdateButton={isDisabledUpdateButton}
          />
        </div>
        <div className="piu_provider_type_sections">
          <span className="sections_title_container">
            <Title
              title={'Provider Type'}
              extraClassName={'settings_sub_title'}
              component={'h3'}
              clasNameContainer={'sections_title'}
              withTooltip
              textTooltip={
                'This value will be sent to Tax Rating system with invoice data. Contact Compliance Solutions if you’re unsure about provider type for your company.'
              }
              placementTooltip="right"
            >
              {isShowPiuInfobox && isUpdateProviderType && (
                <InfoBox
                  textContent={`Provider type set to ${
                    Object.entries(provider_types_data).find(
                      (item) => item[1] === selectedProviderType
                    )[0]
                  }`}
                  extraClassNameContainer="piu_infobox_container show-animated provider_type"
                  styleType="success"
                />
              )}
            </Title>
          </span>
          <FormControl>
            <Select
              value={selectedProviderType}
              onChange={handleChangeProviderType}
              displayEmpty
              className={'piu_input'}
              inputProps={{
                'aria-label': 'Without label',
              }}
            >
              {Object.entries(provider_types_data).map((item) => (
                <MenuItem key={item[0]} value={item[1]}>
                  {item[0]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  piuValues: formSelector(state, 'piuRadioButton', 'piuValue'),
  csiType: getPuiTypeSelector(state),
  csiValue: getPuiValueSelector(state),
  isPiuFetching: getIsPiuFetchingSelector(state),
  isShowPiuInfobox: getIsShowinfoBoxSelector(state),
  provider_type: getProviderTypeSelector(state),
})

const actions = {
  updatePiu,
  clearFields,
  showPiuInfoBox,
  showConfirmationModal,
}

export default connect(mapStateToProps, actions)(memo(PiuSection))
