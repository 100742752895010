import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

import CustomScrollBar from 'components/ui/custom-scroll-bar'
import { getNotificationsListSelector } from 'redusers/notifications'
import { markAsRead } from 'actions/notification-actions'
import { getBatchDetailById } from 'actions/batches-actions'
import { useShallowSelector } from 'custom-hooks/useShallowSelector'

TimeAgo.addLocale(en)
const timeAgo = new TimeAgo('en-US')

const NotificationsList = ({ isNewNotification }) => {
  const dispatch = useDispatch()
  const notificationList = useShallowSelector(getNotificationsListSelector)

  useEffect(() => {
    return () => {
      if (isNewNotification) {
        dispatch(markAsRead())
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="notification_list_container">
      <CustomScrollBar autoHeight autoHeightMin={45} autoHeightMax={225}>
        {notificationList.map((notification) => {
          const { _id, title, is_read, data, date_created } = notification
          return (
            <NotificationItem
              key={_id}
              title={title}
              is_read={is_read}
              batchId={data?.object_id}
              date={date_created}
            />
          )
        })}
      </CustomScrollBar>
    </div>
  )
}

const NotificationItem = ({ title, is_read, batchId, date }) => {
  const dispatch = useDispatch()

  const readClassName = is_read ? 'read' : ''

  const handleClickNotification = () => {
    if (batchId) {
      dispatch(getBatchDetailById(batchId))
    }
  }

  return (
    <div className="notification_item_container">
      <button
        className={`notification_item_title ${readClassName}`}
        onClick={handleClickNotification}
      >
        {title}
      </button>
      <span className="notification_item_time">
        {timeAgo.format(new Date(`${date}Z`))}
      </span>
    </div>
  )
}

export default NotificationsList
