import React, { memo } from 'react'

import { useShallowSelector } from 'custom-hooks/useShallowSelector'
import ReportNavMenu from 'components/report-nav-menu'
import ReportSearchExportSection from 'components/report-search-export-section'
import ReportRoutes from 'routes/reports-routes'
import ReportFilterModal from 'components/report-filter-modal'
import { getIsShowReportFilterModalSelector } from 'redusers/modalsState'

const ReportsPage = () => {
  const isShowFilterModal = useShallowSelector(getIsShowReportFilterModalSelector)
  return (
    <div className="report_page_container container">
      <ReportNavMenu />
      <ReportSearchExportSection />
      <ReportRoutes />
      {isShowFilterModal && <ReportFilterModal />}
    </div>
  )
}

export default memo(ReportsPage)
