import React from 'react'

import CheckBoxStyled from 'components/ui/checkbox-styled'

const ProductsForm = (props) => {
  const { handleSubmit, data, onChange, values = [] } = props

  return (
    <form className="products_form" onSubmit={handleSubmit}>
      {data.map((item) => {
        return (
          <div className="products_radio_group" key={item._id}>
            <span className="product_radio_button_line">
              <CheckBoxStyled
                id={item._id}
                label={`${item.quickbooks_data.Name}`}
                checked={values.some((s) => s === item._id)}
                onChange={onChange}
                className="product_form_checkbox"
              />
            </span>
          </div>
        )
      })}
    </form>
  )
}

export default ProductsForm
