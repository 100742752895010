import { put, take, call, fork, race } from 'redux-saga/effects'

import { hideConfirmationModal } from 'actions/modals-state-actions'
import {
  SHOW_CONFIRMATION_MODAL,
  CALL_YES_FUNCTION_CONFIRMATION,
  HIDE_CONFIRMATION_MODAL,
} from 'constants/actions-constants'

export function* callYesFuctionOfConfirmationSagas({ cb, arg }) {
  yield put(hideConfirmationModal())
  yield call(cb, arg)
}

export function* watchConfirmationSagas() {
  while (true) {
    const { payload } = yield take(SHOW_CONFIRMATION_MODAL)
    const [yesActions] = yield race([
      take(CALL_YES_FUNCTION_CONFIRMATION),
      take(HIDE_CONFIRMATION_MODAL),
    ])
    if (yesActions) {
      yield fork(callYesFuctionOfConfirmationSagas, payload)
    }
  }
}
