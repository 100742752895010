import {
  SET_SELECTED_STATE,
  CLEAR_SELECTED_STATE,
  SET_NEW_VALUE_IN_SELECTED_STATE,
} from 'constants/actions-constants'

const initialState = {
  dataSelectedState: [],
  onlySelectedId: [],
  isSelectedAllCheckbox: true,
  notEmptyAllCheckbox: false,
}

export const checkBoxState = (state = initialState, actions) => {
  switch (actions.type) {
    case SET_SELECTED_STATE:
      return {
        ...state,
        dataSelectedState: actions.payload,
        isSelectedAllCheckbox: actions.payload.every((item) => item.selected),
        notEmptyAllCheckbox:
          actions.payload.some((item) => item.selected) &&
          !actions.payload.every((item) => item.selected),
      }
    case CLEAR_SELECTED_STATE:
      return { ...initialState }
    case SET_NEW_VALUE_IN_SELECTED_STATE:
      return {
        ...state,
        dataSelectedState: state.dataSelectedState.map((item) => {
          const _item = { ...item }

          if (item._id === actions.payload.id) {
            _item[actions.payload.valueKey] = actions.payload.value
          }

          return _item
        }),
      }
    default:
      return state
  }
}

export const getDataSelectedStateSelector = (state) =>
  state.checkBoxState.dataSelectedState

export const getOnlySelectedIdSelector = (state) =>
  state.checkBoxState.onlySelectedId

export const getIsSelectedAllCheckboxSelector = (state) =>
  state.checkBoxState.isSelectedAllCheckbox

export const getNotEmptyAllCheckboxSelector = (state) =>
  state.checkBoxState.notEmptyAllCheckbox
