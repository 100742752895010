import {
  SET_IS_LOADING,
  SET_IS_AUTS,
  SET_PROFILE_INFO,
  SET_PROFILE_ERROR_MESSAGE,
  CLEAR_PROFILE_ERROR_MESSAGE,
  SET_PROFILE_FETCHING,
  CLEAR_USER_PROFILE_DATA,
  SET_IS_PIU_FETCHING,
  SHOW_INFOBOX_ABOUT_PIU,
  SET_SUCCESS_MESSAGE,
  CLEAR_SUCCESS_MESSAGE,
} from 'constants/actions-constants'
import { ADMIN_ROLE, CLIENT_ROLE } from 'constants/roles-constant'
import { NO_TYPE_CSI_TYPE_CONST } from 'constants/csi-type-constant'
import { provider_types_data } from 'constants/provider-type-constants'

const initialState = {
  isAuts: false,
  isLoading: true,
  isProfileFetching: false,
  isPiuFetching: false,
  showPiuInfobox: false,
  errorMessage: '',
  successMessage: '',
  _id: '',
  email: '',
  is_active: false,
  roles: [],
  profile: {
    first_name: '',
    last_name: '',
    company_name: '',
    phone: '',
    address: {
      address_line: '',
      city: '',
      state: '',
      zip_code: '',
    },
    quickbooks: null,
    quickbooks_enabled: false,
    csi: {
      access: '',
      csi_type: '',
      csi_value: null,
      provider_type: provider_types_data.VOIP,
    },
    csi_enabled: false,
  },
  company: {
    realm_id: '',
    company_name: '',
  },
}

export const profile = (state = initialState, action) => {
  switch (action.type) {
    case SET_SUCCESS_MESSAGE:
      return { ...state, successMessage: action.payload }

    case CLEAR_SUCCESS_MESSAGE:
      return { ...state, successMessage: '' }

    case SET_IS_LOADING:
      return { ...state, isLoading: action.payload }

    case SET_IS_AUTS:
      return { ...state, isAuts: action.payload }

    case SET_PROFILE_INFO:
      return { ...state, ...action.payload }

    case SET_PROFILE_ERROR_MESSAGE:
      return { ...state, errorMessage: action.payload }

    case CLEAR_PROFILE_ERROR_MESSAGE:
      return { ...state, errorMessage: initialState.errorMessage }

    case SET_PROFILE_FETCHING:
      return { ...state, isProfileFetching: action.payload }

    case CLEAR_USER_PROFILE_DATA:
      return { ...initialState, isLoading: false }

    case SET_IS_PIU_FETCHING:
      return { ...state, isPiuFetching: action.payload }

    case SHOW_INFOBOX_ABOUT_PIU:
      return { ...state, showPiuInfobox: action.payload }

    default:
      return state
  }
}
/** SELECTORS FUNCTIONS */
export const getProfileConnectedCompanyInfoSelector = (state) => {
  const company = state.profile.company
  if (company) {
    return state.profile.company
  }
  return initialState.company
}

export const getIsAutsSelector = (state) => state.profile.isAuts

export const getIsLoadingSelector = (state) => state.profile.isLoading

export const getIsPiuFetchingSelector = (state) => state.profile.isPiuFetching

export const getIsShowinfoBoxSelector = (state) => state.profile.showPiuInfobox

export const getProfileErrorMessageSelector = (state) =>
  state.profile.errorMessage

export const getProfileFetchingStatusSelector = (state) =>
  state.profile.isProfileFetching

export const getProfileQuickbooksEnabledSelector = (state) =>
  state.profile.profile.quickbooks_enabled

export const getProfileCsiEnabledSelector = (state) =>
  state.profile.profile.csi_enabled

export const getRoleUserSelector = (state) => {
  const roles = state.profile.roles
  let isAdmin = false
  if (roles.length) {
    isAdmin = roles.some((role) => role === ADMIN_ROLE)
  }
  if (isAdmin) return ADMIN_ROLE
  return CLIENT_ROLE
}

export const getRolesLoadingSelector = (state) => !!state.profile.roles.length

export const getCsiTokenKeySelector = (state) => {
  const { csi } = state.profile.profile
  if (csi && csi.access) return csi.access
  return ''
}

export const getProfileEmailSelector = (state) => state.profile.email

export const getProfileFirstNameSelector = (state) =>
  state.profile.profile.first_name

export const getProfileLastNameSelector = (state) =>
  state.profile.profile.last_name

export const getProfileCompanyNameSelector = (state) =>
  state.profile.profile.company_name

export const getProfilePhoneSelector = (state) => state.profile.profile.phone

export const getProfileAddressSelector = (state) => {
  return state.profile.profile.address || {}
}

export const getProfileAdressSelector = (state) => {
  return getProfileAddressSelector(state).address_line || ''
}

export const getProfileCitySelector = (state) =>
  getProfileAddressSelector(state).city || ''

export const getProfileStateRegionSelector = (state) =>
  getProfileAddressSelector(state).state || ''

export const getProfileZipCodeSelector = (state) =>
  getProfileAddressSelector(state).zip_code || ''

export const getInitialsNamesSelector = (state) => {
  const firsnNameInitial = state.profile.profile.first_name[0] || 'N'
  const lastNameInitial = state.profile.profile.last_name[0] || 'N'
  return `${firsnNameInitial}${lastNameInitial}`
}

export const getPuiTypeSelector = (state) => {
  const { csi } = state.profile.profile
  if (csi && csi.csi_type) return csi.csi_type
  return NO_TYPE_CSI_TYPE_CONST
}

export const getPuiValueSelector = (state) => {
  const { csi } = state.profile.profile
  if (csi && csi.csi_value) return csi.csi_value
  return ''
}

export const getProfileRealmIdSelector = (state) =>
  state.profile.company?.realm_id

export const getSuccessMessageSelector = (state) => state.profile.successMessage

export const getProviderTypeSelector = (state) =>
  state?.profile?.profile?.csi?.provider_type || provider_types_data.VOIP
