import React, { memo } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Dialog from '@material-ui/core/Dialog'

import Title from 'components/ui/title-component'
import Button from 'components/ui/button-component'
import ColorTextAria from 'components/ui/color-text-aria'
import { hideConfirmationModal } from 'actions/modals-state-actions'
import { callYesFuctionOfConfirmationModal } from 'actions/confirmation'
import {
  getConfirmationModalTitleSelector,
  getConfirmationButtonsName,
  getMiddleMessageConfirmationModalSelector,
  getYesButtonIsRightSelector,
} from 'redusers/confirmation-modal'
import {
  DEFAULT_CONFIRAMATION_MODAL_TITLE_LN,
  DEFAULT_CONFIRAMATION_MODAL_BUTTON_YES_LN,
  DEFAULT_CONFIRAMATION_MODAL_BUTTON_NO_LN,
} from 'constants/language-key-constants'
import { useShallowSelector } from 'custom-hooks/useShallowSelector'

const ConfirmModal = ({
  hideConfirmationModal,
  callYesFuctionOfConfirmationModal,
}) => {
  const { t } = useTranslation()
  const title = useShallowSelector(getConfirmationModalTitleSelector)
  const [yesButtonName, noButtonName] = useShallowSelector(
    getConfirmationButtonsName,
  )
  const middleMessage = useShallowSelector(getMiddleMessageConfirmationModalSelector)
  const yesButtonIsRight = useShallowSelector(getYesButtonIsRightSelector)

  const classNameisRight = yesButtonIsRight ? 'right' : ''

  ///confirm_modal_wrap

  return (
    <Dialog
      open={true}
      onClose={hideConfirmationModal}
      className="confirm_modal_container"
    >
      <Title
        title={title || t(DEFAULT_CONFIRAMATION_MODAL_TITLE_LN)}
        clasNameContainer="confirm_modal_title_container"
        extraClassName="confirm_modal_title"
      />
      {middleMessage && (
        <span className="confirmation_modal_middle_message_container">
          <ColorTextAria
            styleType="blue"
            className="confirmation_modal_middle_message"
          >
            <span>{middleMessage}</span>
          </ColorTextAria>
        </span>
      )}
      <div className={`buttons-section ${classNameisRight}`}>
        <Button
          name={yesButtonName || t(DEFAULT_CONFIRAMATION_MODAL_BUTTON_YES_LN)}
          styletype={yesButtonIsRight ? 'outline_blue' : 'green'}
          classNameContainer="yes_button"
          classNameButton=""
          onClick={callYesFuctionOfConfirmationModal}
        />
        <Button
          name={noButtonName || t(DEFAULT_CONFIRAMATION_MODAL_BUTTON_NO_LN)}
          styletype={!yesButtonIsRight ? 'outline_blue' : 'green'}
          classNameContainer="no_button"
          classNameButton=""
          onClick={hideConfirmationModal}
        />
      </div>
    </Dialog>
  )
}

const actions = {
  hideConfirmationModal,
  callYesFuctionOfConfirmationModal,
}

export default connect(null, actions)(memo(ConfirmModal))
