import {
  SET_IS_PRODUCTS_FETCHING,
  GET_ALL_PRODUCTS,
  SET_PRODUCTS,
  CLEAR_PRODUCTS,
  SET_IS_FETCHING_CSI_PRODUCTS,
  GET_CSI_PRODUCTS,
  SET_CSI_PRODUCTS,
  CLEAR_CSI_PRODUCTS,
  MAP_PRODUCT,
  PULL_PRODUCTS_SERVICES_FROM_QUICKBOOKS,
  UNMAP_PRODUCTS,
  SET_IS_FETCHING_UNMAP_PRODUCTS,
  SHOW_MAPPED_PRODUCT_ALERT,
  SHOW_UNMAPPED_PRODUCTS_ALERT,
} from 'constants/actions-constants'

export const setIsFetchingProducts = (payload) => ({
  type: SET_IS_PRODUCTS_FETCHING,
  payload,
})

export const getAllProducts = () => ({
  type: GET_ALL_PRODUCTS,
})

export const setProducts = (payload) => ({
  type: SET_PRODUCTS,
  payload,
})

export const clearProducts = () => ({
  type: CLEAR_PRODUCTS,
})

export const setIsFetchingCsiProducts = (payload) => ({
  type: SET_IS_FETCHING_CSI_PRODUCTS,
  payload,
})

export const getCsiProducts = () => ({
  type: GET_CSI_PRODUCTS,
})

export const setCsiProducts = (payload) => ({
  type: SET_CSI_PRODUCTS,
  payload,
})

export const clearCsiProducts = () => ({
  type: CLEAR_CSI_PRODUCTS,
})

export const mapPropduct = (payload) => ({
  type: MAP_PRODUCT,
  payload,
})

export const pullProductsServicesFromQuickBooks = () => ({
  type: PULL_PRODUCTS_SERVICES_FROM_QUICKBOOKS,
})

export const unmapProducts = (payload) => ({
  type: UNMAP_PRODUCTS,
  payload,
})

export const setIsFetchingUnmapProducts = (payload) => ({
  type: SET_IS_FETCHING_UNMAP_PRODUCTS,
  payload,
})

export const showMappedProductAlert = (payload) => ({
  type: SHOW_MAPPED_PRODUCT_ALERT,
  payload,
})

export const showUnmappedProductAlert = (payload) => ({
  type: SHOW_UNMAPPED_PRODUCTS_ALERT,
  payload,
})
