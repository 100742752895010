import React, { useState, useEffect, memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'

import {
  Z_A_DIRECTION_SORT,
  A_Z_DIRECTION_SORT,
} from 'constants/direction-sort-constant'
import CheckBoxStyled from 'components/ui/checkbox-styled'

function TableCell(props) {
  const {
    text = '',
    isDeleted,
    className = '',
    isSort = false,
    isSortBackEnd = false,
    handleSortClick,
    dataSortKey,
    defaultsortedkey,
    chosenSortedKey,
    isButton = false,
    buttonKey,
    isCheckBox = false,
    checkBoxValue = true,
    idCheckedCell,
    handleCheckBox,
    isCurrency = false,
    handleClickCellButton,
    isDisabledCheckBox,
    isAllCheckBox,
    handlerOnChangeAllCheckBoxs,
    notEmpty,
    editable,
    handleOnSubmitEditableCell,
    validPattern = '',
    renderComponent: RenderComponent = null,
    propsRenderComponent = {},
    tooltipText = '',
  } = props

  const [sortedDirection, setSortedDirection] = useState(
    defaultsortedkey ? A_Z_DIRECTION_SORT : Z_A_DIRECTION_SORT
  )

  const handleClick = useCallback(
    (event) => {
      const key = event.currentTarget.dataset.sortKey
      setSortedDirection(
        sortedDirection === A_Z_DIRECTION_SORT
          ? Z_A_DIRECTION_SORT
          : A_Z_DIRECTION_SORT
      )
      handleSortClick(
        key,
        sortedDirection === A_Z_DIRECTION_SORT
          ? Z_A_DIRECTION_SORT
          : A_Z_DIRECTION_SORT
      )
    },
    [handleSortClick, sortedDirection]
  )

  const handleClickButtonCell = useCallback(
    (event) => {
      const key = event.currentTarget.dataset.buttonKey
      handleClickCellButton(key)
    },
    [handleClickCellButton]
  )

  const _handleCheckBox = useCallback(
    (event) => {
      const checkedId = event.target.dataset.id
      const checkedState = event.target.checked
      handleCheckBox(checkedId, checkedState)
    },
    [handleCheckBox]
  )

  const handleCheckBoxAll = useCallback(() => {
    handlerOnChangeAllCheckBoxs()
  }, [handlerOnChangeAllCheckBoxs])

  const handleOnSubmitEditable = useCallback(
    (e) => {
      e.preventDefault()
      const id = e.currentTarget.dataset.id
      const value = e.currentTarget.input.value
      if (id) {
        handleOnSubmitEditableCell({ id, value })
      }
    },
    [handleOnSubmitEditableCell]
  )

  const usdFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    minimumFractionDigits: 2,
    currency: 'USD',
  })

  const _text = isCurrency ? usdFormat.format(text) : text

  let classNameForEditableCell = ''

  if (editable && validPattern && (!validPattern.test(text) || !text)) {
    classNameForEditableCell = 'invalid'
  }

  useEffect(() => {
    if (chosenSortedKey !== dataSortKey) {
      setSortedDirection(Z_A_DIRECTION_SORT)
    }
  }, [chosenSortedKey, dataSortKey])

  if (isSortBackEnd) {
    return (
      <Tooltip title={tooltipText || ''}>
        <button
          className={`table_cell sort_button ${className} ${
            sortedDirection === Z_A_DIRECTION_SORT &&
            chosenSortedKey === dataSortKey
              ? 'up'
              : chosenSortedKey !== dataSortKey
              ? ''
              : 'down'
          }`}
          //title={text}
          onClick={handleClick}
          data-sort-key={dataSortKey}
        >
          <span className="triangle">{_text}</span>
        </button>
      </Tooltip>
    )
  }
  if (isSort) {
    return (
      <Tooltip title={tooltipText || ''}>
        <button
          className={`table_cell sort_button ${className} ${
            sortedDirection === Z_A_DIRECTION_SORT &&
            chosenSortedKey === dataSortKey
              ? 'up'
              : chosenSortedKey !== dataSortKey
              ? ''
              : 'down'
          }`}
          //title={text}
          onClick={handleClick}
          data-sort-key={dataSortKey}
        >
          <span className="triangle">{_text}</span>
        </button>
      </Tooltip>
    )
  }
  if (isButton) {
    return (
      <Tooltip title={tooltipText || ''}>
        <span className={`table_cell ${className}`}>
          <button
            disabled={isDeleted}
            type="button"
            className="button_in_table_cell"
            //title={text}
            onClick={handleClickButtonCell}
            data-button-key={buttonKey}
          >
            {_text}
          </button>
        </span>
      </Tooltip>
    )
  }
  if (isCheckBox) {
    return (
      <Tooltip title={tooltipText || ''}>
        <span className={`table_cell ${className}`}>
          <CheckBoxStyled
            checked={checkBoxValue}
            id={idCheckedCell}
            onChange={_handleCheckBox}
            isDisabledCheckBox={isDisabledCheckBox}
          />
        </span>
      </Tooltip>
    )
  }
  if (isAllCheckBox) {
    return (
      <Tooltip title={tooltipText || ''}>
        <span className={`table_cell ${className}`}>
          <CheckBoxStyled
            checked={checkBoxValue}
            onChange={handleCheckBoxAll}
            isHeaderStyle
            notEmpty={notEmpty}
          />
          <span>{`${_text}`}</span>
        </span>
      </Tooltip>
    )
  }
  if (editable) {
    return (
      <span className={`table_cell ${className} editable`}>
        <form
          onSubmit={handleOnSubmitEditable}
          data-id={buttonKey}
          onBlur={handleOnSubmitEditable}
        >
          <input
            type="text"
            defaultValue={_text}
            className={`input ${classNameForEditableCell}`}
            name="input"
          />
        </form>
      </span>
    )
  }
  if (RenderComponent) {
    return (
      <Tooltip title={tooltipText || ''}>
        <span className={`table_cell ${className}`}>
          <RenderComponent {...propsRenderComponent} />
        </span>
      </Tooltip>
    )
  }
  return (
    <Tooltip title={tooltipText || ''}>
      <span className={`table_cell ${className}`}>{_text}</span>
    </Tooltip>
  )
}

TableCell.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  isSort: PropTypes.bool,
  children: PropTypes.node,
  handleSortClick: PropTypes.func,
  dataSortKey: PropTypes.string,
  defaultsortedkey: PropTypes.string,
  chosenSortedKey: PropTypes.string,
}

export default memo(TableCell)
