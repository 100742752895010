import React, { useEffect, memo } from 'react'
import { useTranslation } from 'react-i18next'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import Alert from 'react-bootstrap/Alert'

import { hideAlert } from 'actions/alert'
import {
  getAlertDelaySelector,
  getAlertIsShownAlertSelector,
  getAlertMessageSelector,
  getAlertTitleSelector,
  getAlertTypeSelector,
} from 'redusers/alert'
import { DEFAULT_ALERT_MESSAGE_LN } from 'constants/language-key-constants'

function AlertStyled({
  type,
  message,
  title,
  delay,
  hideAlert,
  isShownAlert,
  dismissible = true,
}) {
  const { t } = useTranslation()

  const interval = setInterval(() => {
    if (isShownAlert) {
      document.getElementById('alert').classList.add('end_animation')
    }
    clearInterval(interval)
  }, delay - 400)

  useEffect(() => {
    return () => {
      clearInterval(interval)
    }
  }, [interval])

  const AlertComponent = () => (
    <Alert
      variant={type}
      dismissible={dismissible}
      className="alert_container"
      id="alert"
      onClose={hideAlert}
    >
      <Alert.Heading>{title}</Alert.Heading>
      <p className="alert_message">{message || t(DEFAULT_ALERT_MESSAGE_LN)}</p>
    </Alert>
  )

  return ReactDOM.createPortal(
    <AlertComponent />,
    document.getElementById('alert')
  )
}

const mapStateToProps = (state) => ({
  type: getAlertTypeSelector(state),
  title: getAlertTitleSelector(state),
  message: getAlertMessageSelector(state),
  delay: getAlertDelaySelector(state),
  isShownAlert: getAlertIsShownAlertSelector(state),
})

const actions = { hideAlert }

export default connect(mapStateToProps, actions)(memo(AlertStyled))
