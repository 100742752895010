import React, { memo } from 'react'
import PropTypes from 'prop-types'

import Logo from 'components/ui/Logo'
import NavMenu from './NavMenu'
import ProfileMenu from './ProfileMenu'
import Notifications from './Notifications'
import { getRoleUserSelector } from 'redusers/profile'
import { CLIENT_ROLE } from 'constants/roles-constant'
import { useShallowSelector } from 'custom-hooks/useShallowSelector'

const Header = () => {
  const roleUser = useShallowSelector(getRoleUserSelector)

  return (
    <div className="header_container">
      <Logo
        classNameContainer="header_section header_logo_section"
        classNameImage="header_logo"
        isLink={true}
        typeLogo={'long'}
      />
      <NavMenu roleUser={roleUser} />
      <span className="header_notification_profile_section">
        {roleUser === CLIENT_ROLE && <Notifications />}
        <ProfileMenu roleUser={roleUser} />
      </span>
    </div>
  )
}

Header.propTypes = {
  roleUser: PropTypes.string,
}

export default memo(Header)
