import {
  SET_SELECTED_STATE,
  SET_NEW_VALUE_IN_SELECTED_STATE,
  CLEAR_SELECTED_STATE,
} from 'constants/actions-constants'

export const setSelectedState = (payload) => ({
  type: SET_SELECTED_STATE,
  payload,
})

export const setNewValueInSelectedState = (payload) => ({
  type: SET_NEW_VALUE_IN_SELECTED_STATE,
  payload,
})

export const clearSelectedState = () => ({
  type: CLEAR_SELECTED_STATE,
})
