import {
  SHOW_QB_CONNECT_MODAL,
  HIDE_QB_CONNECT_MODAL,
  SHOW_CSI_CONNECT_MODAL,
  HIDE_CSI_CONNECT_MODAL,
  SHOW_CSI_FORM_MODAL,
  HIDE_CSI_FORM_MODAL,
  SHOW_CREATE_BATCH_MODAL,
  HIDE_CREATE_BATCH_MODAL,
  SHOW_CREATE_BATCH_AVILABLE_INVOICES_MODAL,
  SHOW_PROCESSED_BATCH_MODAL,
  HIDE_PROCESSED_BATCH_MODAL,
  SHOW_EXPORT_CONFIRMATION_MODAL,
  HIDE_EXPORT_CONFIRMATION_MODAL,
  SHOW_INVOICE_DETAIL_MODAL,
  HIDE_INVOICE_DETAIL_MODAL,
  SHOW_INVOICE_TAX_SUMMARY_MODAL,
  HIDE_INVOICE_TAX_SUMMARY_MODAL,
  SHOW_PROFILE_MENU_MODAL,
  HIDE_PROFILE_MENU_MODAL,
  SHOW_SETTINGS_MENU_MODAL,
  HIDE_SETTINGS_MENU_MODAL,
  SHOW_CONFIRMATION_MODAL,
  HIDE_CONFIRMATION_MODAL,
  CLEAR_PREV_SHOWN_MODAL_NAME,
  SET_PREV_SHOWN_MODAL_NAME,
  SHOW_PROCESSING_INVOICES_MODAL,
  HIDE_PROCESSING_INVOICES_MODAL,
  CLEAR_CURRENT_SHOWN_MODAL_NAME,
  SET_PROFILE_MODAL_STATE,
  SHOW_REPORT_FILTER_MODAL,
  HIDE_REPORT_FILTER_MODAL,
  SHOW_LOGS_MODAL,
  HIDE_LOGS_MODAL,
  SHOW_SUCCES_CONNECTED_CSI_MODAL,
  HIDE_SUCCES_CONNECTED_CSI_MODAL,
  SHOW_INVOICES_FILTER_MODAL,
  HIDE_INVOICES_FILTER_MODAL,
} from 'constants/actions-constants'

import {
  QB_CONNECT_MODAL_NAME,
  CSI_CONNECT_MODAL_NAME,
  CSI_CONNECT_FORM_MODAL_NAME,
  NEW_BATCH_MODAL_NAME,
  PROCESSED_BATCH_MODAL_NAME,
  EXPORT_CONFIRMATION_MODAL_NAME,
  INVOICE_DETAIL_MODAL_NAMES,
  TAX_SUMMARY_MODAL_NAMES,
  PROFILE_MENU_MODAL_NAMES,
  SETTINGS_MENU_MODAL_NAMES,
  PROCESSING_INVOICES_MODAL_NAME,
  REPORT_FILTER_MODAL_NAME,
  LOGS_MODAL_NAME,
  SUCCES_CONNECTED_CSI_MODAL_NAME,
  INVOICES_FILTER_MODAL_NAME,
} from 'constants/modal-names-constants'
import { USER_PROFILE_MODAL_STATE } from 'constants/profile-modal-state-constants'

const initialState = {
  isShowQbConnectModal: false,
  isShownCsiConnectModal: false,
  isShowCsiFormModal: false,
  iShowCreateNewBatchModal: false,
  isShownAvailableInvoices: false,
  isShownProcessedBatchModal: false,
  isShowExportConfirmationModal: false,
  isShowProfileMenuModal: false,
  isOpenInvoiceDetailModal: false,
  isOpenInvoiceTaxSummaryModal: false,
  isOpenSettingModal: false,
  isShownConfirmationModal: false,
  isShownProcessingInvoicesModal: false,
  isShowReportFilterModal: false,
  isShowLogsModal: false,
  isShowSuccesConnectedCsiModal: false,
  isShowInvoicesModal: false,
  currentShownModalName: '',
  prevShownModalName: '',
  modalProfileState: USER_PROFILE_MODAL_STATE,
}

export const modalState = (state = initialState, actions) => {
  switch (actions.type) {
    case SET_PROFILE_MODAL_STATE: {
      return { ...state, modalProfileState: actions.payload }
    }

    case CLEAR_PREV_SHOWN_MODAL_NAME: {
      return { ...state, prevShownModalName: initialState.prevShownModalName }
    }

    case CLEAR_CURRENT_SHOWN_MODAL_NAME: {
      return {
        ...state,
        currentShownModalName: initialState.currentShownModalName,
      }
    }

    case SET_PREV_SHOWN_MODAL_NAME: {
      return { ...state, prevShownModalName: state.currentShownModalName }
    }

    case SHOW_PROCESSING_INVOICES_MODAL:
      return {
        ...state,
        isShownProcessingInvoicesModal: true,
        currentShownModalName: PROCESSING_INVOICES_MODAL_NAME,
      }

    case HIDE_PROCESSING_INVOICES_MODAL:
      return {
        ...state,
        isShownProcessingInvoicesModal: false,
        //prevShownModalName: state.currentShownModalName,
        currentShownModalName: initialState.currentShownModalName,
      }

    case SHOW_PROFILE_MENU_MODAL:
      return {
        ...state,
        isShowProfileMenuModal: true,
        currentShownModalName: PROFILE_MENU_MODAL_NAMES,
      }

    case HIDE_PROFILE_MENU_MODAL:
      return {
        ...state,
        isShowProfileMenuModal: false,
        //prevShownModalName: state.currentShownModalName,
        currentShownModalName: initialState.currentShownModalName,
        modalProfileState: initialState.modalProfileState,
      }
    case SHOW_QB_CONNECT_MODAL:
      return {
        ...state,
        isShowQbConnectModal: true,
        currentShownModalName: QB_CONNECT_MODAL_NAME,
      }

    case HIDE_QB_CONNECT_MODAL:
      return {
        ...state,
        isShowQbConnectModal: false,
        //prevShownModalName: state.currentShownModalName,
        currentShownModalName: initialState.currentShownModalName,
      }
    case SHOW_CSI_CONNECT_MODAL:
      return {
        ...state,
        isShownCsiConnectModal: true,
        currentShownModalName: CSI_CONNECT_MODAL_NAME,
      }

    case HIDE_CSI_CONNECT_MODAL:
      return {
        ...state,
        isShownCsiConnectModal: false,
        //prevShownModalName: state.currentShownModalName,
        currentShownModalName: initialState.currentShownModalName,
      }

    case SHOW_CSI_FORM_MODAL:
      return {
        ...state,
        isShowCsiFormModal: true,
        currentShownModalName: CSI_CONNECT_FORM_MODAL_NAME,
      }

    case HIDE_CSI_FORM_MODAL:
      return {
        ...state,
        isShowCsiFormModal: false,
        //prevShownModalName: state.currentShownModalName,
        currentShownModalName: initialState.currentShownModalName,
      }

    case SHOW_CREATE_BATCH_MODAL:
      return {
        ...state,
        iShowCreateNewBatchModal: true,
        currentShownModalName: NEW_BATCH_MODAL_NAME,
      }

    case HIDE_CREATE_BATCH_MODAL:
      return {
        ...state,
        iShowCreateNewBatchModal: false,
        isShownAvailableInvoices: actions.payload,
        //prevShownModalName: state.currentShownModalName,
        currentShownModalName: initialState.currentShownModalName,
      }

    case SHOW_CREATE_BATCH_AVILABLE_INVOICES_MODAL:
      return {
        ...state,
        isShownAvailableInvoices: true,
      }

    case SHOW_PROCESSED_BATCH_MODAL:
      return {
        ...state,
        isShownProcessedBatchModal: true,
        currentShownModalName: PROCESSED_BATCH_MODAL_NAME,
      }

    case HIDE_PROCESSED_BATCH_MODAL:
      return {
        ...state,
        isShownProcessedBatchModal: false,
        //prevShownModalName: state.currentShownModalName,
        currentShownModalName: initialState.currentShownModalName,
      }

    case SHOW_EXPORT_CONFIRMATION_MODAL:
      return {
        ...state,
        isShowExportConfirmationModal: true,
        currentShownModalName: EXPORT_CONFIRMATION_MODAL_NAME,
      }

    case HIDE_EXPORT_CONFIRMATION_MODAL:
      return {
        ...state,
        isShowExportConfirmationModal: false,
        //prevShownModalName: state.currentShownModalName,
        currentShownModalName: initialState.currentShownModalName,
      }

    case SHOW_INVOICE_DETAIL_MODAL:
      return {
        ...state,
        isOpenInvoiceDetailModal: true,
        currentShownModalName: INVOICE_DETAIL_MODAL_NAMES,
      }

    case HIDE_INVOICE_DETAIL_MODAL:
      return {
        ...state,
        isOpenInvoiceDetailModal: false,
        //prevShownModalName: state.currentShownModalName,
        currentShownModalName: initialState.currentShownModalName,
      }

    case SHOW_INVOICE_TAX_SUMMARY_MODAL:
      return {
        ...state,
        isOpenInvoiceTaxSummaryModal: true,
        currentShownModalName: TAX_SUMMARY_MODAL_NAMES,
      }

    case HIDE_INVOICE_TAX_SUMMARY_MODAL:
      return {
        ...state,
        isOpenInvoiceTaxSummaryModal: false,
        //prevShownModalName: state.currentShownModalName,
        currentShownModalName: initialState.currentShownModalName,
      }

    case SHOW_SETTINGS_MENU_MODAL:
      return {
        ...state,
        isOpenSettingModal: true,
        currentShownModalName: SETTINGS_MENU_MODAL_NAMES,
      }

    case HIDE_SETTINGS_MENU_MODAL:
      return {
        ...state,
        isOpenSettingModal: false,
        //prevShownModalName: state.currentShownModalName,
        currentShownModalName: initialState.currentShownModalName,
      }

    case SHOW_CONFIRMATION_MODAL:
      return {
        ...state,
        isShownConfirmationModal: true,
        //prevShownModalName: state.currentShownModalName,
        //currentShownModalName: CONFIRMATION_MODAL_NAMES,
      }

    case HIDE_CONFIRMATION_MODAL:
      return {
        ...state,
        isShownConfirmationModal: false,
        //currentShownModalName: state.prevShownModalName,
        //prevShownModalName: initialState.currentShownModalName,
      }

    case SHOW_REPORT_FILTER_MODAL:
      return {
        ...state,
        isShowReportFilterModal: true,
        currentShownModalName: REPORT_FILTER_MODAL_NAME,
      }

    case HIDE_REPORT_FILTER_MODAL:
      return {
        ...state,
        isShowReportFilterModal: false,
        //prevShownModalName: state.currentShownModalName,
        currentShownModalName: initialState.currentShownModalName,
      }

    case SHOW_LOGS_MODAL:
      return {
        ...state,
        isShowLogsModal: true,
        currentShownModalName: LOGS_MODAL_NAME,
      }

    case HIDE_LOGS_MODAL:
      return {
        ...state,
        isShowLogsModal: false,
        currentShownModalName: initialState.currentShownModalName,
      }

    case SHOW_SUCCES_CONNECTED_CSI_MODAL:
      return {
        ...state,
        isShowSuccesConnectedCsiModal: true,
        currentShownModalName: SUCCES_CONNECTED_CSI_MODAL_NAME,
      }

    case HIDE_SUCCES_CONNECTED_CSI_MODAL:
      return {
        ...state,
        isShowSuccesConnectedCsiModal: false,
        currentShownModalName: initialState.currentShownModalName,
      }

    case SHOW_INVOICES_FILTER_MODAL:
      return {
        ...state,
        isShowInvoicesModal: true,
        currentShownModalName: INVOICES_FILTER_MODAL_NAME,
      }

    case HIDE_INVOICES_FILTER_MODAL:
      return {
        ...state,
        isShowInvoicesModal: false,
        currentShownModalName: initialState.currentShownModalName,
      }

    default:
      return state
  }
}

export const getIsShowInvoicesFilterModalSelector = (state) =>
  state.modalState.isShowInvoicesModal

export const getIsShowSuccesConnectedCsiModalSelector = (state) =>
  state.modalState.isShowSuccesConnectedCsiModal

export const getIsShowLogsModalSelector = (state) =>
  state.modalState.isShowLogsModal

export const getIsShowReportFilterModalSelector = (state) =>
  state.modalState.isShowReportFilterModal

export const getIsShownConfirmationModal = (state) =>
  state.modalState.isShownConfirmationModal

export const getCurrentShownModalNameSelector = (state) =>
  state.modalState.currentShownModalName

export const getPrevShownModalNameSelector = (state) =>
  state.modalState.prevShownModalName

export const getIsShowQbConnectModalSelector = (state) =>
  state.modalState.isShowQbConnectModal

export const getisShowCsiConnectModalSelector = (state) =>
  state.modalState.isShownCsiConnectModal

export const getisShowCsiFormModalSelector = (state) =>
  state.modalState.isShowCsiFormModal

export const getIsOpenCreatrNewBatchModalSelector = (state) =>
  state.modalState.iShowCreateNewBatchModal

export const getIsShowAvailableInvoicesSelector = (state) =>
  state.modalState.isShownAvailableInvoices

export const getIsOpenProcessedBatchModalSelector = (state) =>
  state.modalState.isShownProcessedBatchModal

export const getIsOpenExportConfirmationModalSelector = (state) =>
  state.modalState.isShowExportConfirmationModal

export const getIsOpenInvoiceDetailModalSelector = (state) =>
  state.modalState.isOpenInvoiceDetailModal

export const getIsOpenInvoiceTaxSummaryModalSelector = (state) =>
  state.modalState.isOpenInvoiceTaxSummaryModal

export const getIsShowProfileMenuModalSelector = (state) =>
  state.modalState.isShowProfileMenuModal

export const getIsSettingsModalSelector = (state) =>
  state.modalState.isOpenSettingModal

export const getIsShownProcessingInvoicesModal = (state) =>
  state.modalState.isShownProcessingInvoicesModal

export const getModalProfileUserStateSelector = (state) =>
  state.modalState.modalProfileState
