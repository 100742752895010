import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { useShallowSelector } from 'custom-hooks/useShallowSelector'
import Title from 'components/ui/title-component'
import { Table, TableCell, TableRow } from 'components/ui/table'
import CopyToClipboardButton from 'components/ui/copy-to-clipboar-button'
import {
  getUsersListSelector,
  getSearchValueUsersSelector,
} from 'redusers/users'
import UsersMenu from './UsersMenu'
import { ADMIN_ROLE } from 'constants/roles-constant'
import {
  USER_NAME_SORT_CONST,
  USER_COMANY_NAME_SORT_CONST,
  USER_COMPANY_ID_SORT_CONST,
  USER_ROLE_SORT_CONST,
  USER_EMAIL_SORT_CONST,
} from 'constants/users-table-sort-constants'
import {
  USERS_PAGE_TITLE_LN,
  USERS_PAGE_TABLE_CELL_1_LN,
  USERS_PAGE_TABLE_CELL_2_LN,
  USERS_PAGE_TABLE_CELL_3_LN,
  USERS_PAGE_TABLE_CELL_4_LN,
  USERS_PAGE_TABLE_CELL_5_LN,
  USERS_PAGE_TABLE_CELL_6_LN,
  USER_ADMIN_QB_COMPANY_TEXT_LN,
} from 'constants/language-key-constants'

const Users = (props) => {
  const { handleClickEditUser } = props
  const { t } = useTranslation()

  const usersList = useShallowSelector(getUsersListSelector)
  const searchValue = useShallowSelector(getSearchValueUsersSelector)

  const _searchData = usersList.filter(
    (user) =>
      user.email.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
      user.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
      user.companyName.toLowerCase().indexOf(searchValue.toLowerCase()) !==
        -1 ||
      user.companyId.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
  )

  return (
    <div className="users_table_container">
      <Title
        title={t(USERS_PAGE_TITLE_LN)}
        extraClassName="users_table_title"
        clasNameContainer="users_table_title_container"
      />
      <Table
        className="users_table"
        classNameHeaderRow="users_table_header"
        headerComponent={HeaderTable}
        bodyTableComponent={BodyTable}
        autoHeightMin={25}
        autoHeightMax={'100%'}
        data={_searchData}
        isScroll={false}
        defaultsortedkey={USER_ROLE_SORT_CONST}
        secondDefaulSortedKey={[USER_NAME_SORT_CONST]}
        bodyProps={{
          handleClickEditUser: handleClickEditUser,
        }}
      />
    </div>
  )
}

const HeaderTable = memo((props) => {
  const { handleSortClick, sortedKey, defaultsortedkey } = props
  const { t } = useTranslation()
  return (
    <>
      <TableCell
        text={t(USERS_PAGE_TABLE_CELL_1_LN)}
        className="name"
        isSort
        dataSortKey={USER_NAME_SORT_CONST}
        handleSortClick={handleSortClick}
        chosenSortedKey={sortedKey}
      />
      <TableCell
        text={t(USERS_PAGE_TABLE_CELL_2_LN)}
        className="company_name"
        isSort
        dataSortKey={USER_COMANY_NAME_SORT_CONST}
        handleSortClick={handleSortClick}
        chosenSortedKey={sortedKey}
      />
      <TableCell
        text={t(USERS_PAGE_TABLE_CELL_3_LN)}
        className="company_id"
        isSort
        dataSortKey={USER_COMPANY_ID_SORT_CONST}
        handleSortClick={handleSortClick}
        chosenSortedKey={sortedKey}
      />
      <TableCell
        text={'CSI Auth Key'}
        className="csi_access_token"
        isSort
        dataSortKey={'csiAccessToken'}
        handleSortClick={handleSortClick}
        chosenSortedKey={sortedKey}
      />
      <TableCell
        text={t(USERS_PAGE_TABLE_CELL_4_LN)}
        className="role"
        isSort
        dataSortKey={USER_ROLE_SORT_CONST}
        handleSortClick={handleSortClick}
        chosenSortedKey={sortedKey}
        defaultsortedkey={defaultsortedkey}
      />
      <TableCell
        text={'Last Login'}
        className="last_login"
        isSort
        dataSortKey={'last_login'}
        handleSortClick={handleSortClick}
        chosenSortedKey={sortedKey}
      />
      <TableCell
        text={t(USERS_PAGE_TABLE_CELL_5_LN)}
        className="user_email"
        isSort
        dataSortKey={USER_EMAIL_SORT_CONST}
        handleSortClick={handleSortClick}
        chosenSortedKey={sortedKey}
      />
      <TableCell text={t(USERS_PAGE_TABLE_CELL_6_LN)} className="actions" />
    </>
  )
})

const BodyTable = memo((props) => {
  const { data, handleClickEditUser } = props
  return data.map((user) => {
    const {
      _id,
      email,
      roleUser,
      name,
      companyName,
      companyId,
      quickbooks_enabled,
      csiAccessToken,
      csi_enabled,
      last_login,
    } = user

    const lastLogin = last_login
      ? moment(`${last_login}Z`).format('MM/DD/YY')
      : ''

    return (
      <TableRow key={_id} className="users_body_row">
        <TableCell
          //text={name}
          className={'name'}
          renderComponent={RenderNameUsers}
          propsRenderComponent={{
            name: name,
          }}
          tooltipText={name || ''}
        />
        <TableCell
          text={companyName}
          className="company_name"
          tooltipText={companyName || ''}
        />
        <TableCell
          //text={''}
          className="company_id"
          renderComponent={RenderCopyText}
          propsRenderComponent={{
            content: companyId,
            roleUser,
          }}
          tooltipText={companyId || ''}
        />
        <TableCell
          text={''}
          className="csi_access_token"
          renderComponent={RenderCopyText}
          propsRenderComponent={{
            content: csiAccessToken,
            roleUser,
          }}
          tooltipText={csiAccessToken || ''}
        />
        <TableCell text={roleUser} className={'role'} />
        <TableCell
          text={lastLogin}
          className={'last_login'}
          tooltipText={lastLogin}
        />
        <TableCell
          text={email}
          className={'user_email'}
          tooltipText={email || ''}
        />
        <TableCell
          text=""
          className="actions"
          renderComponent={UsersMenu}
          propsRenderComponent={{
            usersId: _id,
            handleClickEditUser: handleClickEditUser,
            userName: name,
            quickbooks_enabled: quickbooks_enabled,
            role: roleUser,
            csi_enabled: csi_enabled,
          }}
        />
      </TableRow>
    )
  })
})

const RenderNameUsers = memo(({ name }) => {
  return (
    <span className="name_users">
      <span className="name_text">{name}</span>
    </span>
  )
})

const RenderCopyText = memo(({ content, roleUser }) => {
  const { t } = useTranslation()
  return (
    <span className="copy_text_container">
      {roleUser === ADMIN_ROLE ? (
        t(USER_ADMIN_QB_COMPANY_TEXT_LN)
      ) : content ? (
        <CopyToClipboardButton
          copyContent={content}
          titleText={content?.substring(content.length - 5)}
        />
      ) : (
        ''
      )}
    </span>
  )
})

export default memo(Users)
