import React, { memo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined'
import AutorenewIcon from '@material-ui/icons/Autorenew'
import IconButton from '@material-ui/core/IconButton'

import { useShallowSelector } from 'custom-hooks/useShallowSelector'
import Button from 'components/ui/button-component'
import Modal from 'components/ui/modal-component'
import Title from 'components/ui/title-component'
import InfoBox from 'components/ui/infobox-component'
import FielsWithTitle from 'components/ui/field-with-title'
import { Table, TableRow, TableCell } from 'components/ui/table'
import { getInvoiceDetailSelector } from 'redusers/invoicesDetail'
import { getRoleUserSelector } from 'redusers/profile'
import { getIsFetchingCreateBatch } from 'redusers/batches'
import { closeInvoiceDetailModal } from 'actions/modals-state-actions'
import {
  invoiceDelete,
  getCsvReportInvoiceDetail,
} from 'actions/invoice-detail-actions'
import { showConfirmationModal } from 'actions/modals-state-actions'
import {
  PRODUCT_SERVICE_SORTED_CONS,
  DESCRIPTION_SORTED_CONS,
  QUANTITY_SORTED_CONS,
  RATE_SORTED_CONS,
  AMOUNT_SORTED_CONS,
} from 'constants/invoices-detail-sort-constant'
import { CLIENT_ROLE, ADMIN_ROLE } from 'constants/roles-constant'
import {
  INVOICE_DETAIL_TITLE_PART_1_LN,
  INVOICE_DETAIL_TITLE_PART_2_LN,
  INVOICE_DETAIL_CUSTOMER_LN,
  INVOICE_DETAIL_EMAIL_LN,
  INVOICE_DETAIL_INVOICE_DATE_LN,
  INVOICE_DETAIL_ADRESS_LN,
  INVOICE_DETALT_TABLE_COUNT_TITLE_LN,
  INVOICE_DETALT_EXPORT_CSV_BUTTON_LN,
  CLOSE_BUTTON_NAME_LN,
  INVOICE_DETAIL_TOTAL_LN,
  INVOICE_DETALT_TABLE_CELL_1_TITLE_LN,
  INVOICE_DETALT_TABLE_CELL_2_TITLE_LN,
  INVOICE_DETALT_TABLE_CELL_3_TITLE_LN,
  INVOICE_DETALT_TABLE_CELL_4_TITLE_LN,
  INVOICE_DETALT_TABLE_CELL_5_TITLE_LN,
  CONFIRMATION_TITLE_FOR_DELETE_INVOICE_LN,
  DELETE_INVOICE_MESSAGES_POP_UP_LN,
  DELETED_INVOICES_MESSAGE_LN,
  DISABLED_EXPORT_SCV_TOOLTIP_LN,
  INVOICE_DETAIL_TITLE_PART_3_LN,
  INVOICE_DETAIL_TITLE_PART_4_LN,
} from 'constants/language-key-constants'
import { NEW_BATCH_MODAL_NAME } from 'constants/modal-names-constants'
import { getPrevShownModalNameSelector } from 'redusers/modalsState'
import { createBatch } from 'actions/batches-actions'
import { transactionsTypes } from 'constants/transactions-type-constants'

function InvoiceDetailModal() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const roleUser = useShallowSelector(getRoleUserSelector)
  const prevShowModalName = useShallowSelector(getPrevShownModalNameSelector)
  const isClient = roleUser === CLIENT_ROLE
  const invoiceDetail = useShallowSelector(getInvoiceDetailSelector)
  const isFetchingReprocessingBatch = useShallowSelector(
    getIsFetchingCreateBatch
  )
  const {
    numInvoice,
    customer,
    address,
    email,
    invoice_date,
    total,
    linesData,
    id,
    is_deleted,
    type,
    qbTransactionId,
    zipCode,
  } = invoiceDetail

  const handleCloseModal = useCallback(() => {
    dispatch(closeInvoiceDetailModal())
  }, [dispatch])

  const handleDeleteInvoice = useCallback(() => {
    const _invoiceDelete = dispatch.bind(this, invoiceDelete(id))

    const _title = () => {
      switch (type) {
        case transactionsTypes.CREDIT_MEMO:
          return `Delete credit memo #${numInvoice}?`
        case transactionsTypes.INVOICES:
          return `${t(
            CONFIRMATION_TITLE_FOR_DELETE_INVOICE_LN
          )} #${numInvoice}?`
        case transactionsTypes.SALES_RECEIPT:
          return `Delete sales receipt #${numInvoice}?`
        default:
          return `Delete #${numInvoice}?`
      }
    }

    dispatch(
      showConfirmationModal({
        cb: _invoiceDelete,

        title: _title(),
        middleMessage: `${t(DELETE_INVOICE_MESSAGES_POP_UP_LN)}`,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id])

  const reprocessInvoice = () => {
    const invoiceDataForReprocessing = [
      {
        qb_transaction_id: qbTransactionId,
        zip_code: zipCode,
      },
    ]

    dispatch(createBatch(invoiceDataForReprocessing))
  }

  const handleClickReprocess = () => {
    const _title = () => {
      switch (type) {
        case transactionsTypes.CREDIT_MEMO:
          return `Continue with Credit memo #${numInvoice} Reprocess?`
        case transactionsTypes.INVOICES:
          return `Continue with Invoice #${numInvoice} Reprocess?`
        case transactionsTypes.SALES_RECEIPT:
          return `Continue with Sales receipt #${numInvoice} Reprocess?`
        default:
          return `Continue with Invoice #${numInvoice} Reprocess?`
      }
    }

    const _subtitle = () => {
      switch (type) {
        case transactionsTypes.CREDIT_MEMO:
          return "The current Credit memo will be reprocessed with previous selections under today's date and added to a new batch."
        case transactionsTypes.INVOICES:
          return "The current Invoice will be reprocessed with previous selections under today's date and added to a new batch."
        case transactionsTypes.SALES_RECEIPT:
          return "The current Sales receipt will be reprocessed with previous selections under today's date and added to a new batch."
        default:
          return "The current Invoice will be reprocessed with previous selections under today's date and added to a new batch."
      }
    }

    dispatch(
      showConfirmationModal({
        cb: reprocessInvoice,
        title: _title(),
        middleMessage: _subtitle(),
      })
    )
  }

  const handleExportCoCsvInvoice = useCallback(() => {
    dispatch(getCsvReportInvoiceDetail())
  }, [dispatch])

  const invoiceDate = invoice_date
    ? moment(invoice_date).format('M/D/yyyy')
    : ''

  const isAvailableChangeInvocie = prevShowModalName !== NEW_BATCH_MODAL_NAME

  const title = () => {
    switch (type) {
      case transactionsTypes.CREDIT_MEMO:
        return `${t(INVOICE_DETAIL_TITLE_PART_3_LN)} #${numInvoice} ${t(
          INVOICE_DETAIL_TITLE_PART_2_LN
        )}`

      case transactionsTypes.INVOICES:
        return `${t(INVOICE_DETAIL_TITLE_PART_1_LN)} #${numInvoice} ${t(
          INVOICE_DETAIL_TITLE_PART_2_LN
        )}`

      case transactionsTypes.SALES_RECEIPT:
        return `${t(INVOICE_DETAIL_TITLE_PART_4_LN)} #${numInvoice} ${t(
          INVOICE_DETAIL_TITLE_PART_2_LN
        )}`
      default:
        return ''
    }
  }

  const fetchingClassName = isFetchingReprocessingBatch ? 'rotate' : ''

  return (
    <Modal
      classNameWrap={'invoice_detail_modal'}
      classNameContainer={''}
      isCloseButton={true}
      handleClickClose={handleCloseModal}
    >
      <Title
        title={title()}
        extraClassName="invoice_detail_modal_title modal_title"
      ></Title>
      {is_deleted && (
        <span className="invoice_detail_info_box_container">
          <InfoBox
            textContent={t(DELETED_INVOICES_MESSAGE_LN)}
            styleType={'warning'}
          />
        </span>
      )}
      <span className="text_fields_section">
        <div className="text_fields_column">
          <FielsWithTitle
            title={t(INVOICE_DETAIL_CUSTOMER_LN)}
            text={customer}
            classNameContainer={'text_fields_item'}
          />
          <FielsWithTitle
            title={t(INVOICE_DETAIL_EMAIL_LN)}
            text={email}
            classNameContainer={'text_fields_item'}
            isLink
          />
        </div>
        <div className="text_fields_column">
          <FielsWithTitle
            title={t(INVOICE_DETAIL_ADRESS_LN)}
            text={address?.trim()}
            classNameContainer={'text_fields_item address'}
          />
        </div>
        <div className="text_fields_column">
          <FielsWithTitle
            title={t(INVOICE_DETAIL_INVOICE_DATE_LN)}
            text={invoiceDate}
            classNameContainer={'text_fields_item last'}
          />
        </div>
      </span>
      <Table
        className="invoice_details_table"
        classNameHeaderRow="invoice_details_table_header"
        headerComponent={Header}
        bodyTableComponent={BodyTable}
        autoHeightMin={25}
        autoHeightMax={125}
        data={linesData}
        countTitleText={t(INVOICE_DETALT_TABLE_COUNT_TITLE_LN)}
      />
      <span className="invoice_details_modal_button_section">
        {isAvailableChangeInvocie && roleUser === CLIENT_ROLE && (
          <Button
            name={t(INVOICE_DETALT_EXPORT_CSV_BUTTON_LN)}
            styletype="green"
            classNameButton={'invoice_details_modal_buttons'}
            classNameContainer={
              'invoice_details_modal_buttons_wrap left_button'
            }
            disabled={roleUser === ADMIN_ROLE || isFetchingReprocessingBatch}
            onClick={handleExportCoCsvInvoice}
            tooltipTitle={
              roleUser === ADMIN_ROLE
                ? `${t(DISABLED_EXPORT_SCV_TOOLTIP_LN)}`
                : ''
            }
            //isSpinner={isFetchingBatch}
          />
        )}
        <Button
          name={t(CLOSE_BUTTON_NAME_LN)}
          styletype="outline_blue"
          classNameButton={'invoice_details_modal_buttons'}
          classNameContainer={'invoice_details_modal_buttons_wrap'}
          onClick={handleCloseModal}
        />
        {!is_deleted && isClient && isAvailableChangeInvocie && (
          <>
            <IconButton
              className="invoice_detail_delete_button_container"
              onClick={handleDeleteInvoice}
              disabled={isFetchingReprocessingBatch}
            >
              <DeleteForeverOutlinedIcon className="button_icon" />
            </IconButton>
            <IconButton
              className="invoice_detail_delete_button_container"
              onClick={handleClickReprocess}
              disabled={isFetchingReprocessingBatch}
            >
              <AutorenewIcon
                className={`button_icon reprocess_icon ${fetchingClassName}`}
              />
            </IconButton>
          </>
        )}
        <FielsWithTitle
          title={t(INVOICE_DETAIL_TOTAL_LN)}
          text={total}
          classNameContainer={'total'}
          isCurrency
        />
      </span>
    </Modal>
  )
}

const Header = memo(({ handleSortClick, sortedKey, defaultsortedkey }) => {
  const { t } = useTranslation()
  return (
    <>
      <TableCell
        text={t(INVOICE_DETALT_TABLE_CELL_1_TITLE_LN)}
        className="product"
        isSort
        dataSortKey={PRODUCT_SERVICE_SORTED_CONS}
        defaultsortedkey={defaultsortedkey}
        handleSortClick={handleSortClick}
        chosenSortedKey={sortedKey}
      />
      <TableCell
        text={t(INVOICE_DETALT_TABLE_CELL_2_TITLE_LN)}
        className="description"
        isSort
        dataSortKey={DESCRIPTION_SORTED_CONS}
        handleSortClick={handleSortClick}
        chosenSortedKey={sortedKey}
      />
      <TableCell
        text={t(INVOICE_DETALT_TABLE_CELL_3_TITLE_LN)}
        className="quantity"
        isSort
        dataSortKey={QUANTITY_SORTED_CONS}
        handleSortClick={handleSortClick}
        chosenSortedKey={sortedKey}
      />
      <TableCell
        text={t(INVOICE_DETALT_TABLE_CELL_4_TITLE_LN)}
        className="rate"
        isSort
        dataSortKey={RATE_SORTED_CONS}
        handleSortClick={handleSortClick}
        chosenSortedKey={sortedKey}
      />
      <TableCell
        text={t(INVOICE_DETALT_TABLE_CELL_5_TITLE_LN)}
        className="amount"
        isSort
        dataSortKey={AMOUNT_SORTED_CONS}
        handleSortClick={handleSortClick}
        chosenSortedKey={sortedKey}
      />
    </>
  )
})

const BodyTable = memo((props) => {
  const { data } = props
  return data.map((line) => {
    const { product, description, quantity, rate, amount, id } = line
    return (
      <TableRow key={id} className="invoice_detail_table_row">
        <TableCell text={product} className="product" tooltipText={product} />
        <TableCell
          text={description}
          className="description"
          tooltipText={description}
        />
        <TableCell text={quantity} className="quantity" />
        <TableCell text={rate} className="rate" isCurrency />
        <TableCell text={amount} className="amount" isCurrency />
      </TableRow>
    )
  })
})

export default memo(InvoiceDetailModal)
