import { A_Z_DIRECTION_SORT } from 'constants/direction-sort-constant'

import orderBy from 'lodash/orderBy'

export const endAnimation = (ref, className, callback) => {
  let _timeOut = null

  ref.current.classList.add(className)
  _timeOut = setTimeout(() => {
    callback()
    clearTimeout(_timeOut)
  }, 400)
}

export const sortTableByKey = (
  array,
  key,
  direction,
  secondDefaulSortedKey = []
) => {
  if (!array || !Array.isArray(array)) {
    return []
  }
  if (!key) {
    return array || []
  }

  // const orderBy = (a, b) => {
  //   switch (direction) {
  //     case A_Z_DIRECTION_SORT:
  //       if (typeof a[key] === 'string') {
  //         const _v1 = a[key]?.toLocaleLowerCase()
  //         const _v2 = b[key]?.toLocaleLowerCase()
  //         if (_v1 > _v2) return 1
  //         if (_v1 === _v2) return 0
  //         if (_v1 < _v2) return -1
  //       }
  //       return a[key] - b[key]

  //     case Z_A_DIRECTION_SORT:
  //       if (typeof a[key] === 'string') {
  //         const _v1 = a[key]?.toLocaleLowerCase()
  //         const _v2 = b[key]?.toLocaleLowerCase()
  //         if (_v1 < _v2) return 1
  //         if (_v1 === _v2) return 0
  //         if (_v1 > _v2) return -1
  //       }
  //       return b[key] - a[key]

  //     default:
  //       return
  //   }
  // }
  const _directionForLodash = direction === A_Z_DIRECTION_SORT ? 'asc' : 'desc'

  const keys = [key, ...secondDefaulSortedKey]
  const directions = [_directionForLodash]

  if (secondDefaulSortedKey.length) {
    secondDefaulSortedKey.forEach(() => {
      directions.push('asc')
    })
  }

  return orderBy(
    array,
    keys.map((c) => {
      return (row) => {
        let value = row[c]

        if (!value) return ''
        if (typeof value === 'string') return value.toString().toLowerCase()

        return value
      }
    }),
    directions
  )
}

export const generateId = () => {
  return '_' + Math.random().toString(36).substr(2, 9)
}
