import React from 'react'

const ShowPassword = () => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
      <g
        id="Design"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Sign-in" transform="translate(-658.000000, -450.000000)">
          <g id="Group-3" transform="translate(282.000000, 222.000000)">
            <g id="Group-2">
              <g id="Group">
                <g
                  id="text-field-+-counter"
                  transform="translate(28.000000, 192.000000)"
                >
                  <g id="text-field">
                    <g
                      id="Show_Password_icon"
                      transform="translate(348.000000, 36.000000)"
                    >
                      <rect
                        id="Rectangle"
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                      ></rect>
                      <path
                        d="M23.8344795,11.6597427 C23.7216842,11.5259883 21.0219883,8.37515789 17.2982456,6.44594152 C17.0567018,6.31920468 16.7523275,6.41501754 16.6255439,6.66053801 C16.498807,6.90507602 16.5946199,7.20645614 16.8401404,7.33323977 C19.708538,8.81833918 22.0000468,11.1358129 22.7835322,11.9821754 C21.5938713,13.2706433 16.912,17.9704795 11.976655,17.9704795 C10.3019415,17.9704795 8.61422222,17.566269 6.95845614,16.7678129 C6.71293567,16.6470643 6.41253801,16.7518596 6.29277193,17.0003743 C6.17202339,17.2479064 6.27681871,17.5462924 6.52533333,17.6660585 C8.31681871,18.5313684 10.1512515,18.9685146 11.9767018,18.9685146 C18.1257076,18.9685146 23.6050058,12.5769825 23.8355556,12.3045146 C23.9931696,12.1188304 23.9921871,11.8463626 23.8344795,11.6597427 Z"
                        id="Path"
                        fill="#757575"
                        fillRule="nonzero"
                      ></path>
                      <path
                        d="M15.2512749,5.57061988 C14.1155088,5.18835088 13.0136608,4.99574269 11.976655,4.99574269 C5.82764912,4.99574269 0.348350877,11.3872749 0.11780117,11.6597427 C-0.0279298246,11.8313918 -0.0399064327,12.0809357 0.089871345,12.2675556 C0.158736842,12.3663626 1.80851462,14.7087719 4.66792982,16.6080936 C4.75274854,16.6649825 4.84757895,16.6919298 4.94339181,16.6919298 C5.10409357,16.6919298 5.26278363,16.6140819 5.35859649,16.4673684 C5.51129825,16.2388304 5.44842105,15.9284211 5.2188538,15.7767018 C3.11897076,14.3804444 1.68177778,12.6947368 1.14381287,12.009076 C2.2925848,10.7614971 7.00538012,5.99382456 11.976655,5.99382456 C12.9058246,5.99382456 13.9009123,6.16949708 14.9328655,6.51578947 C15.1943392,6.60959064 15.4778012,6.46488889 15.5646316,6.20238596 C15.6524912,5.94091228 15.5127485,5.65847953 15.2512749,5.57061988 Z"
                        id="Path"
                        fill="#757575"
                        fillRule="nonzero"
                      ></path>
                      <path
                        d="M16.6255439,6.66053801 C16.7523275,6.41501754 17.0567018,6.31920468 17.2982456,6.44594152 C21.0219883,8.37515789 23.7216842,11.5259883 23.8344795,11.6597427 C23.9921871,11.8463626 23.9931696,12.1188304 23.8355556,12.3045146 C23.6050058,12.5769825 18.1257076,18.9685146 11.9767018,18.9685146 C10.1512515,18.9685146 8.31681871,18.5313684 6.52533333,17.6660585 C6.27681871,17.5462924 6.17202339,17.2479064 6.29277193,17.0003743 C6.41253801,16.7518596 6.71293567,16.6470643 6.95845614,16.7678129 C8.61422222,17.566269 10.3019415,17.9704795 11.976655,17.9704795 C16.912,17.9704795 21.5938713,13.2706433 22.7835322,11.9821754 C22.0000468,11.1358129 19.708538,8.81833918 16.8401404,7.33323977 C16.5946199,7.20645614 16.498807,6.90507602 16.6255439,6.66053801 Z M11.976655,4.99574269 C13.0136608,4.99574269 14.1155088,5.18835088 15.2512749,5.57061988 C15.5127485,5.65847953 15.6524912,5.94091228 15.5646316,6.20238596 C15.4778012,6.46488889 15.1943392,6.60959064 14.9328655,6.51578947 C13.9009123,6.16949708 12.9058246,5.99382456 11.976655,5.99382456 C7.00538012,5.99382456 2.2925848,10.7614971 1.14381287,12.009076 C1.68177778,12.6947368 3.11897076,14.3804444 5.2188538,15.7767018 C5.44842105,15.9284211 5.51129825,16.2388304 5.35859649,16.4673684 C5.26278363,16.6140819 5.10409357,16.6919298 4.94339181,16.6919298 C4.84757895,16.6919298 4.75274854,16.6649825 4.66792982,16.6080936 C1.80851462,14.7087719 0.158736842,12.3663626 0.089871345,12.2675556 C-0.0399064327,12.0809357 -0.0279298246,11.8313918 0.11780117,11.6597427 C0.348350877,11.3872749 5.82764912,4.99574269 11.976655,4.99574269 Z"
                        id="Combined-Shape"
                        fill="#757575"
                        fillRule="nonzero"
                      ></path>
                      <path
                        d="M12,8 C14.209139,8 16,9.790861 16,12 C16,14.209139 14.209139,16 12,16 C9.790861,16 8,14.209139 8,12 C8,9.790861 9.790861,8 12,8 Z M12,9 C10.3431458,9 9,10.3431458 9,12 C9,13.6568542 10.3431458,15 12,15 C13.6568542,15 15,13.6568542 15,12 C15,10.3431458 13.6568542,9 12,9 Z"
                        id="Combined-Shape"
                        fill="#757575"
                        opacity="0.900000036"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ShowPassword
