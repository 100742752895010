import React, { memo } from 'react'

const InvoiceItem = React.lazy(() => import('./InvoiceItem'))

const InvoiceTableBody = (props) => {
  const { data, handleClickInvoiceDetail, handlerClickTaxSummary } = props
  return data.map((invoice) => {
    return (
      <InvoiceItem
        key={invoice._id}
        {...invoice}
        handleClickInvoiceDetail={handleClickInvoiceDetail}
        handlerClickTaxSummary={handlerClickTaxSummary}
      />
    )
  })
}

export default memo(InvoiceTableBody)
