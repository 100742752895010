import axios from 'axios'
import store from 'store'

import { DANGER_ALERT } from 'constants/alert-constant'
import { showAler } from 'actions/alert'
import { refreshAction } from 'actions/profile-actions'
import { BASE_URL } from 'constants/url-constants'
import {
  USER_TOKEN_LS_KEY,
  USER_TOKEN_SS_KEY,
} from 'constants/local-storage-key-constants'

export const fetchApi = async ({
  url,
  body = null,
  method = 'get',
  customHeaders,
  authenticated = true,
  ...rest
}) => {
  try {
    let headers = {
      'Content-Type': 'application/json',
    }

    const URL = `${BASE_URL}/${url}`

    const data = body

    if (authenticated) {
      const userTokenSS = sessionStorage.getItem(USER_TOKEN_SS_KEY)
      const userToken = localStorage.getItem(USER_TOKEN_LS_KEY)
      if (userTokenSS) {
        headers['Authorization'] = `Bearer ${userTokenSS}`
      } else if (userToken) {
        headers['Authorization'] = `Bearer ${userToken}`
      }
    }

    if (customHeaders) {
      headers = { ...headers, ...customHeaders }
    }
    const options = {
      url: URL,
      method,
      headers,
      data,
      timeout: 1000 * 60 * 3,
      ...rest,
    }
    const response = await axios(options)

    return response
  } catch (error) {
    const { response } = error

    if (response) {
      if (response.status === 401) {
        localStorage.removeItem(USER_TOKEN_LS_KEY)
        store.dispatch(refreshAction())
      }

      if (response.status === 500) {
        store.dispatch(
          showAler({
            type: DANGER_ALERT,
            message: 'You have issues with server. Please contact support',
            delay: 15000,
          })
        )
      }
    }

    throw error
  }
}
