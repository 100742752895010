import React, { memo } from 'react'
import { Field, reduxForm } from 'redux-form'

import radioGroupCsiProducts from './radioGroupCsiProducts'

const CsiProductsForm = (props) => {
  const { data, handleSubmit, csiProductSelectedValue } = props
  return (
    <form className="csi_products_form" onSubmit={handleSubmit}>
      <Field
        name="csiProductsRadioButton"
        component={radioGroupCsiProducts}
        className="csi_products_radio_group"
        data={data}
        csiProductSelectedValue={csiProductSelectedValue}
      />
    </form>
  )
}

export default reduxForm({ form: 'csiProductsForm' })(memo(CsiProductsForm))
