import React, { memo, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded'

import { useQuery } from 'custom-hooks/useQuery'
import Title from 'components/ui/title-component'
import Button from 'components/ui/button-component'
import SearchInput from 'components/ui/search-input'
import InvoicesComponent from 'components/invoices-component'
import {
  PLACEHOLDER_BY_ADMIN_BATCHES_SEARCH_INPUT_LN,
  BACK_TO_COMAPNIES_BUTTON_NAME_LN,
  COMPANY_NAME_TITLE_LN,
} from 'constants/language-key-constants'
import { setChosenRealmIdCompany } from 'actions/companies'
import {
  setSearchValueInvoices,
  clearSearchInvoicesValue,
} from 'actions/invoices'

const CompanyBatchesPage = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const query = useQuery()
  const realm_id = query.get('realm_id')
  const comapnyName = history?.location?.state?.companyName || 'Company name'

  const handleClickGoBack = useCallback(() => {
    history.goBack()
  }, [history])

  const handleChangeSearchValueInput = (e) => {
    const value = e.target.value
    // if (value.length > 3) {
    //   dispatch(setSearchValueInvoices(value))
    // }
    // if (!value) {
    //   dispatch(setSearchValueInvoices(''))
    // }
    dispatch(setSearchValueInvoices(value))
  }

  const handleClickClear = () => {
    dispatch(setSearchValueInvoices(''))
  }

  useEffect(() => {
    dispatch(setChosenRealmIdCompany(realm_id))
  }, [dispatch, realm_id])

  useEffect(() => {
    return () => {
      dispatch(clearSearchInvoicesValue(''))
      dispatch(setChosenRealmIdCompany(null))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="container company_batches_container">
      <div className="batches_search_section">
        <div className="search_container">
          <SearchInput
            placeholder={t(PLACEHOLDER_BY_ADMIN_BATCHES_SEARCH_INPUT_LN)}
            classNameContainer="batches_page_search_input_container"
            onChange={handleChangeSearchValueInput}
            handleClickClear={handleClickClear}
            isClearButton={true}
          />
        </div>
      </div>
      <div className="company_batches_header">
        <Button
          name={
            <span className="company_batches_button_name">
              <ArrowBackIosRoundedIcon />
              <span>{t(BACK_TO_COMAPNIES_BUTTON_NAME_LN)}</span>
            </span>
          }
          styletype={'link'}
          classNameContainer="company_batches_button_back"
          classNameButton="company_batches_button"
          onClick={handleClickGoBack}
        />
        <Title
          title={`${comapnyName} ${t(COMPANY_NAME_TITLE_LN)}`}
          clasNameContainer="company_batches_title"
        />
      </div>
      <div className="batches_section">
        {/* <Batches realmId={realm_id} /> */}
        <InvoicesComponent realmId={realm_id} />
      </div>
    </div>
  )
}

export default memo(CompanyBatchesPage)
