import React, { memo } from 'react'
import PropTypes from 'prop-types'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { useTranslation } from 'react-i18next'

import Modal from 'components/ui/modal-component'
import Title from 'components/ui/title-component'
import Button from 'components/ui/button-component'
import { QB_AUTH_CONNECT_SUCCSES } from 'constants/qb-connect-constant'
import {
  SIGN_IN_QB_MODAL_SUCCESS_MESSAGE_LN,
  SIGN_IN_QB_MODAL_DENIE_MESSAGE_LN,
  SIGN_IN_QB_MODAL_BUTTON_LN,
} from 'constants/language-key-constants'

const SignInQbConnectedModal = (props) => {
  const { handleClickOK, qbAuthConnectStatus } = props
  const { t } = useTranslation()

  const _className =
    qbAuthConnectStatus === QB_AUTH_CONNECT_SUCCSES ? 'Success' : 'Denied'

  const _text =
    qbAuthConnectStatus === QB_AUTH_CONNECT_SUCCSES
      ? t(SIGN_IN_QB_MODAL_SUCCESS_MESSAGE_LN)
      : t(SIGN_IN_QB_MODAL_DENIE_MESSAGE_LN)

  return (
    <Modal classNameWrap="signin_connect_modal_container">
      <div className={`signin_connect_modal_title_wrap ${_className}`}>
        {qbAuthConnectStatus === QB_AUTH_CONNECT_SUCCSES ? (
          <CheckCircleOutlineIcon />
        ) : (
          <HighlightOffIcon />
        )}
        <Title title={_text} extraClassName="signin_connect_modal_title" />
      </div>

      <Button
        name={t(SIGN_IN_QB_MODAL_BUTTON_LN)}
        type="button"
        styletype="green"
        classNameButton="signin_connect_modal_button"
        onClick={handleClickOK}
      />
    </Modal>
  )
}

SignInQbConnectedModal.propTypes = {
  handleClickOK: PropTypes.func.isRequired,
  qbAuthConnectStatus: PropTypes.string.isRequired,
}

export default memo(SignInQbConnectedModal)
