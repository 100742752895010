import {
  put,
  takeEvery,
  select,
  call,
  throttle,
  debounce,
} from 'redux-saga/effects'
import api from 'api'

import { UPDATE_PAGINATION_CONSTANT } from 'constants/pagination-constants'
import {
  GET_INVOICES,
  SET_ORDER_BY_FOR_INVOICES_SORT,
  SET_SEARCH_VALUE_FOR_INVOICES,
} from 'constants/actions-constants'
import { ADMIN_ROLE } from 'constants/roles-constant'
import { INVOICES_FILTER_MODAL_NAME } from 'constants/modal-names-constants'
import {
  getOrderByInvoicesSelector,
  getOffsetInvoicesSelector,
  getSearchValueInvoicesSelector,
} from 'redusers/invoices'
import { getCurrentShownModalNameSelector } from 'redusers/modalsState'
import { getRoleUserSelector } from 'redusers/profile'
import { getChosenRealMIdSelector } from 'redusers/companies'
import {
  setInvoices,
  startFetchingInvoices,
  stopFetchingInvoices,
  setInvoicesFromPaginnation,
} from 'actions/invoices'
import { hideInvoicesFilterModal } from 'actions/modals-state-actions'
import { destructureResInvoicesForBatches } from 'service/destructureResponce'

function* getInvocesSagaWorker({ payload }) {
  try {
    const LIMIT = 50
    yield put(startFetchingInvoices())
    const roleUser = yield select(getRoleUserSelector)
    const orderBy = yield select(getOrderByInvoicesSelector)
    const searchValue = yield select(getSearchValueInvoicesSelector)
    const realmIdInState = yield select(getChosenRealMIdSelector)

    const _realmId = payload?.realm_id || realmIdInState

    let URL = `transactions/?limit=${encodeURIComponent(LIMIT)}`

    if (searchValue) {
      URL = URL + `&search=${encodeURIComponent(searchValue)}`
    }

    if (orderBy) {
      URL = URL + `&orderBy=${encodeURIComponent(orderBy)}`
    }

    if (roleUser === ADMIN_ROLE) {
      URL = URL + `&realmId=${encodeURIComponent(_realmId)}`
    }

    if (payload?.isPaggination === UPDATE_PAGINATION_CONSTANT) {
      const offset = yield select(getOffsetInvoicesSelector)

      URL = URL + `&offset=${encodeURIComponent(offset + LIMIT)}`

      const res = yield call([api, 'getInvoicesApi'], URL)

      const { data } = res
      if (data?.length) {
        const _data = destructureResInvoicesForBatches(data)
        yield put(
          setInvoicesFromPaginnation({
            invoices: _data,
            offset: offset + LIMIT,
          })
        )
      }
    } else {
      const res = yield call([api, 'getInvoicesApi'], URL)
      const { data } = res

      const _data = destructureResInvoicesForBatches(data)
      yield put(setInvoices(_data))
      const currentShowModalName = yield select(
        getCurrentShownModalNameSelector
      )
      if (currentShowModalName === INVOICES_FILTER_MODAL_NAME) {
        yield put(hideInvoicesFilterModal())
      }
    }
  } catch (error) {
    //to do
  } finally {
    yield put(stopFetchingInvoices())
  }
}

export function* watchInvoicesSagaWatcher() {
  yield throttle(500, GET_INVOICES, getInvocesSagaWorker)
  yield debounce(500, SET_SEARCH_VALUE_FOR_INVOICES, getInvocesSagaWorker)
  yield takeEvery(SET_ORDER_BY_FOR_INVOICES_SORT, getInvocesSagaWorker)
}
