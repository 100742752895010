import React, { memo, useState, useCallback, useEffect } from 'react'
import Tooltip from '@material-ui/core/Tooltip'

import {
  Z_A_DIRECTION_SORT,
  A_Z_DIRECTION_SORT,
} from 'constants/direction-sort-constant'

const Cell = (props) => {
  const {
    dataSortKey = '',
    text = '',
    defaultsortedkey = '',
    chosenSortedKey = '',
    handleSortClick,
    title = '',
    ...rest
  } = props

  const [sortedDirection, setSortedDirection] = useState(
    defaultsortedkey ? A_Z_DIRECTION_SORT : Z_A_DIRECTION_SORT
  )

  const handleClick = useCallback(
    (event) => {
      const key = event.currentTarget.dataset.sortKey
      setSortedDirection(
        sortedDirection === A_Z_DIRECTION_SORT
          ? Z_A_DIRECTION_SORT
          : A_Z_DIRECTION_SORT
      )
      handleSortClick(
        key,
        sortedDirection === A_Z_DIRECTION_SORT
          ? Z_A_DIRECTION_SORT
          : A_Z_DIRECTION_SORT
      )
    },
    [handleSortClick, sortedDirection]
  )

  useEffect(() => {
    if (chosenSortedKey !== dataSortKey) {
      setSortedDirection(Z_A_DIRECTION_SORT)
    }
  }, [chosenSortedKey, dataSortKey])

  const sortClassName =
    sortedDirection === Z_A_DIRECTION_SORT && chosenSortedKey === dataSortKey
      ? 'up'
      : chosenSortedKey !== dataSortKey
      ? ''
      : 'down'

  return (
    <th className="report_cell" {...rest}>
      <button
        className={`sort_button ${sortClassName}`}
        data-sort-key={dataSortKey}
        onClick={handleClick}
      >
        <Tooltip title={title || ''} placement="top">
          <span className="triangle">{text}</span>
        </Tooltip>
      </button>
    </th>
  )
}

export default memo(Cell)
