import React, { useLayoutEffect, memo } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'

import { useShallowSelector } from 'custom-hooks/useShallowSelector'
import { getPrevShownModalNameSelector } from 'redusers/modalsState'
import CloseButton from 'components/ui/close-button'

const Modal = (props) => {
  const {
    children,
    classNameContainer = '',
    classNameWrap = '',
    isCloseButton = false,
    handleClickClose,
  } = props

  const modalRoot = document.getElementById('modal')
  const prevShownModalName = useShallowSelector(getPrevShownModalNameSelector)
  const currentScrollPosition = document.documentElement.scrollTop

  useLayoutEffect(() => {
    modalRoot.classList.add('active')

    return () => {
      if (prevShownModalName) return
      modalRoot.scrollTo(0, currentScrollPosition)
      modalRoot.classList.remove('active')
      window.scrollTo(0, currentScrollPosition)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return createPortal(
    <div className={`modal_container ${classNameContainer}`}>
      <div className={`modal_wrap  ${classNameWrap}`}>
        <>
          {children}
          {isCloseButton && <CloseButton handleClickClose={handleClickClose} />}
        </>
      </div>
    </div>,
    modalRoot
  )
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  classNameWrap: PropTypes.string,
  classNameContainer: PropTypes.string,
  isCloseButton: PropTypes.bool,
  handleClickClose: PropTypes.func,
}

export default memo(Modal)
