import { put, call, takeEvery, select, fork } from 'redux-saga/effects'
import { getI18n } from 'react-i18next'
import api from 'api'
import {
  CHECK_AUTH_STATUS,
  SIGN_IN,
  GET_PROFILE_INFO,
  REFRESH,
  LOGOUT,
  HIDE_QB_CONNECT_MODAL,
  UPDATE_PROFILE_DATA,
  UPDATE_PIU,
  LOGOUT_AFTER_IDLE,
} from 'constants/actions-constants'
import {
  USER_TOKEN_LS_KEY,
  REFRESH_USER_TOKEN_LS_KEY,
  WAS_SHOWN_QB_MODAL_LS_KEY,
} from 'constants/local-storage-key-constants'
import { ADMIN_ROLE, CLIENT_ROLE } from 'constants/roles-constant'
import {
  setIsLoadingAction,
  setIsAutsAction,
  getProfileInfo,
  setProfileInfo,
  setProfileErrorMessage,
  setProfileFetching,
  setIsPiuFetching,
  showPiuInfoBox,
  setSuccessMessage,
} from 'actions/profile-actions'

import { showQbConnectModal } from 'actions/modals-state-actions'
import { startUsersFetching } from 'actions/users'
import { getFirebaseTokenSelector } from 'redusers/firebase-notification'
import { getRoleUserSelector } from 'redusers/profile'
import { PROFILE_SUCCSESS_MESSAGE_LN } from 'constants/language-key-constants'

function* authCheckStateSaga() {
  try {
    const userToken = yield call([localStorage, 'getItem'], USER_TOKEN_LS_KEY)
    if (userToken) {
      yield put(getProfileInfo())
      yield put(setIsAutsAction(true))
    }
  } catch (error) {
    //to do
    //console.error('error authCheckStateSaga')
  } finally {
    yield put(setIsLoadingAction(false))
  }
}

function* getProfileInfoSaga() {
  try {
    const URL = 'users/whoami/'
    yield put(setProfileFetching(true))
    const res = yield call([api, 'getProfileInfo'], URL)
    const { data } = res

    if (data) {
      yield put(setProfileInfo(data))

      const roleUser = yield select(getRoleUserSelector)

      if (roleUser === CLIENT_ROLE) {
        yield call(checkQBShownModal)
      }
      if (roleUser === ADMIN_ROLE) {
        yield call([sessionStorage, 'clear'])
        yield call([localStorage, 'removeItem'], WAS_SHOWN_QB_MODAL_LS_KEY)
      }
    }
  } catch (error) {
    //to do
  } finally {
    yield put(setProfileFetching(false))
  }
}

function* setQbShownModal() {
  yield call([localStorage, 'setItem'], WAS_SHOWN_QB_MODAL_LS_KEY, true)
}

function* checkQBShownModal() {
  try {
    const {
      profile: { quickbooks_enabled },
    } = yield select((state) => state.profile)

    if (!quickbooks_enabled) {
      const wasShownQbmModal = yield call(
        [localStorage, 'getItem'],
        WAS_SHOWN_QB_MODAL_LS_KEY
      )

      const _wasShownQbmModal = wasShownQbmModal === 'true'

      if (!_wasShownQbmModal) {
        yield put(showQbConnectModal())
      }
    } else {
      yield call(setQbShownModal)
      //yield call(checkSciShownModal)
    }
  } catch (error) {
    //to do
  }
}

function* loginSaga({ payload }) {
  try {
    yield put(setProfileFetching(true))
    const URL = 'login/'
    const res = yield call([api, 'login'], URL, payload)
    const { data } = res

    if (data && data.access && data.refresh) {
      yield call([localStorage, 'setItem'], USER_TOKEN_LS_KEY, data.access)
      yield call(
        [localStorage, 'setItem'],
        REFRESH_USER_TOKEN_LS_KEY,
        data.refresh
      )
      yield put(getProfileInfo())
      yield put(setIsAutsAction(true))
    }
  } catch (error) {
    if (
      error?.response?.status === 422 &&
      error?.response?.data?.detail?.length
    ) {
      yield put(setProfileErrorMessage(error.response.data.detail[0].msg))
    }
    if (error?.response?.status === 400 && error?.response?.data?.detail) {
      yield put(setProfileErrorMessage(error?.response?.data?.detail))
    }
  } finally {
    yield put(setProfileFetching(false))
  }
}

function* refreshSaga() {
  try {
    yield put(setProfileFetching(true))
    const URL = 'refresh/'
    const refreshUserToken = yield call(
      [localStorage, 'getItem'],
      REFRESH_USER_TOKEN_LS_KEY
    )
    const refreshUserTokenSS = yield call(
      [sessionStorage, 'getItem'],
      REFRESH_USER_TOKEN_LS_KEY
    )
    const _refreshToken = refreshUserTokenSS || refreshUserToken
    const res = yield call([api, 'refresh'], URL, { refresh: _refreshToken })

    const { data } = res

    if (data && data.access && data.refresh) {
      if (refreshUserTokenSS) {
        yield call([sessionStorage, 'setItem'], USER_TOKEN_LS_KEY, data.access)
        yield call(
          [sessionStorage, 'setItem'],
          REFRESH_USER_TOKEN_LS_KEY,
          data.refresh
        )
      } else {
        yield call([localStorage, 'setItem'], USER_TOKEN_LS_KEY, data.access)
        yield call(
          [localStorage, 'setItem'],
          REFRESH_USER_TOKEN_LS_KEY,
          data.refresh
        )
      }

      yield put(getProfileInfo())
      yield put(setIsAutsAction(true))
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      yield call(logOutSaga)
    }
  } finally {
    yield put(setProfileFetching(false))
  }
}

function* logOutSaga() {
  try {
    const ss_token = yield call([sessionStorage, 'getItem'], USER_TOKEN_LS_KEY)
    if (ss_token) {
      yield put(startUsersFetching())
      yield call([sessionStorage, 'clear'])
      yield call([localStorage, 'removeItem'], WAS_SHOWN_QB_MODAL_LS_KEY)
      document.location.replace(`${document.location.origin}/users`)
      return
    }
    const URL = 'logout/'
    const firebaseToken = yield select(getFirebaseTokenSelector)
    yield fork([api, 'logoutApi'], URL, { registration_id: firebaseToken })
    yield call([localStorage, 'clear'])
    document.location.replace(`${document.location.origin}`)
    // yield put(clearUserProfileData())
    // yield put(clearAllDataBatches())
    // yield put(clearAllInvoicesState())
    // yield put(clearReportsDateRange())
    // yield put(setIsAutsAction(false))
  } catch (error) {
    //to do
    // yield put(clearAllModalState())
    // yield put(clearUserProfileData())
    // yield put(clearAllDataBatches())
    // yield put(setIsAutsAction(false))
    yield call([localStorage, 'clear'])
    document.location.replace(`${document.location.origin}`)
  }
}

function* logoutAfterIdleSagaWorker() {
  try {
    const ss_token = yield call([sessionStorage, 'getItem'], USER_TOKEN_LS_KEY)
    if (ss_token) {
      yield put(startUsersFetching())
      yield call([sessionStorage, 'clear'])
      yield call([localStorage, 'removeItem'], WAS_SHOWN_QB_MODAL_LS_KEY)
    }
    const URL = 'logout/'
    const firebaseToken = yield select(getFirebaseTokenSelector)
    yield fork([api, 'logoutApi'], URL, { registration_id: firebaseToken })
    yield call([localStorage, 'clear'])
    document.location.replace(`${document.location.origin}`)
  } catch (error) {
    // to do
    yield call([localStorage, 'clear'])
    document.location.replace(`${document.location.origin}`)
  }
}

function* updateProfileData({ payload }) {
  const i18n = getI18n()
  try {
    yield put(setProfileFetching(true))
    const { _id } = yield select((state) => state.profile)
    const URL = `users/${_id}/`
    const res = yield call([api, 'updateProfileDataApi'], URL, payload)
    const { data } = res

    yield put(setProfileInfo(data))
    yield put(setSuccessMessage(i18n.t(PROFILE_SUCCSESS_MESSAGE_LN)))
  } catch (error) {
    if (
      error?.response?.status === 422 &&
      error?.response?.data?.detail?.length
    ) {
      yield put(setProfileErrorMessage(error.response.data.detail[0].msg))
    }

    if (error?.response?.status === 400 && error?.response?.data?.detail) {
      yield put(setProfileErrorMessage(error?.response?.data.detail))
    }
  } finally {
    yield put(setProfileFetching(false))
  }
}

function* updatePiuData({ payload }) {
  try {
    yield put(setIsPiuFetching(true))
    const { _id } = yield select((state) => state.profile)
    const URL = `users/${_id}/`
    const res = yield call([api, 'updateProfileDataApi'], URL, payload)
    const { data } = res

    yield put(showPiuInfoBox(true))
    yield put(setProfileInfo(data))
  } catch (error) {
    if (
      error?.response?.status === 422 &&
      error?.response?.data?.detail?.length
    ) {
      yield put(setProfileErrorMessage(error.response.data.detail[0].msg))
    }

    if (error?.response?.status === 400 && error?.response?.data?.detail) {
      yield put(setProfileErrorMessage(error?.response?.data?.detail))
    }
  } finally {
    yield put(setIsPiuFetching(false))
  }
}

export function* watchProfileWorkers() {
  yield takeEvery(CHECK_AUTH_STATUS, authCheckStateSaga)
  yield takeEvery(SIGN_IN, loginSaga)
  yield takeEvery(GET_PROFILE_INFO, getProfileInfoSaga)
  yield takeEvery(REFRESH, refreshSaga)
  yield takeEvery(LOGOUT, logOutSaga)
  yield takeEvery(HIDE_QB_CONNECT_MODAL, setQbShownModal)
  yield takeEvery(UPDATE_PROFILE_DATA, updateProfileData)
  yield takeEvery(UPDATE_PIU, updatePiuData)
  yield takeEvery(LOGOUT_AFTER_IDLE, logoutAfterIdleSagaWorker)
}
