import React, { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { BATCH_COMPANY_TITLE_LN } from 'constants/language-key-constants'
const Company = ({ companyName, realmId }) => {
  const { t } = useTranslation()
  let history = useHistory()

  const handleClickOpenCompanyBatch = useCallback(() => {
    history.push(`/companies/transactions/?realm_id=${realmId}`, {
      companyName: companyName,
    })
  }, [companyName, history, realmId])

  return (
    <div className="company_container">
      <div className="comapny_name_section">
        <button
          className={'comapny_name_button'}
          onClick={handleClickOpenCompanyBatch}
        >
          <span>{companyName}</span>
        </button>
      </div>
      <div className="comapny_id_section">
        <span>{`${t(BATCH_COMPANY_TITLE_LN)}: ${realmId}`}</span>
      </div>
    </div>
  )
}

export default memo(Company)
