import {
  HIDE_ALERT,
  START_SHOW_ALERT,
  SET_MESSAGE,
} from 'constants/actions-constants'
import { WARNING_ALERT } from 'constants/alert-constant'

const initial_state = {
  type: WARNING_ALERT,
  message: '',
  title: '',
  delay: 3000,
  isShownAlert: false,
}

export const alert = (state = initial_state, action) => {
  switch (action.type) {
    case SET_MESSAGE:
      return { ...state, ...action.payload }
    case START_SHOW_ALERT:
      return { ...state, isShownAlert: true }
    case HIDE_ALERT:
      return { ...initial_state }

    default:
      return state
  }
}

export const getAlertData = (state) => state.alert

export const getAlertTypeSelector = (state) => getAlertData(state).type

export const getAlertTitleSelector = (state) => getAlertData(state).title

export const getAlertMessageSelector = (state) => getAlertData(state).message

export const getAlertDelaySelector = (state) => getAlertData(state).delay

export const getAlertIsShownAlertSelector = (state) =>
  getAlertData(state).isShownAlert
