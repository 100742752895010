import { put, takeEvery, select, call } from 'redux-saga/effects'
import api from 'api'
import { saveAs } from 'file-saver'
import {
  GET_INVOICE_DETAIL_FROM_AVAILABLE_INVOICES,
  GET_INVOICE_DETAIL_FROM_BATCH,
  DELETE_INVOICE,
  GET_CSV_REPORT_INVOICE_DETAIL,
  GET_CSV_REPORT_INVOICE_TAX_SUMMARY,
  GET_INVOICE_DETAIL_FROM_BACKEND,
  GET_INVOICE_CSI_OPERATIONS,
} from 'constants/actions-constants'
import { ADMIN_ROLE } from 'constants/roles-constant'
import {
  setInvoiceDetail,
  setInvoiseCsiOperations,
} from 'actions/invoice-detail-actions'
import { getBatchDetailById } from 'actions/batches-actions'
import {
  showInvoiceDetailModal,
  showInvoiceTaxSummaryModal,
} from 'actions/modals-state-actions'
import { getPrevShownModalNameSelector } from 'redusers/modalsState'
import { getBatchDetailIdSelector } from 'redusers/batches'
import {
  getInvoiceDetailIdSelector,
  getInvoiceDetailNumberSelector,
  getRealmIdFromInvoiceDetailSelector,
} from 'redusers/invoicesDetail'
import { PROCESSED_BATCH_MODAL_NAME } from 'constants/modal-names-constants'
import { getRoleUserSelector } from 'redusers/profile'
import { updateInvoices } from 'actions/invoices'
import {
  getQbPortalComparisonReports,
  getTaxSummaryReports,
} from 'actions/reports-actions'

function* getInvoiceDetailFromAvailableInvoicesWorker({ payload: id }) {
  try {
    const invoices = yield select(
      (state) => state.batches.updatedInvoices.transactions
    )
    const invoice = invoices.find(
      (invoice) => invoice.quickbooks_data.Id === id
    )
    yield put(setInvoiceDetail(invoice || {}))
  } catch (error) {
    //to do
  } finally {
    //to do
  }
}

function* getInvoiceDetailFromInvoicesWorker({ payload: id }) {
  try {
    const invoices = yield select((state) => state.invoicesState.invoicesData)

    const invoice = invoices.find((invoice) => invoice._id === id)

    yield put(setInvoiceDetail(invoice || {}))
  } catch (error) {
    //to do
  } finally {
    //to do
  }
}

function* deleteInvoiceSagaWorker({ payload }) {
  try {
    const URL = `transactions/${payload}/`
    const res = yield call([api, 'deleteInvoiceApi'], URL)
    const { data } = res

    yield put(setInvoiceDetail(data))
    const prevShowModalName = yield select(getPrevShownModalNameSelector)
    const invoices = yield select((state) => state.invoicesState.invoicesData)
    const _updatedInvoices = [...invoices]
    const batchDetailID = yield select(getBatchDetailIdSelector)
    if (prevShowModalName === PROCESSED_BATCH_MODAL_NAME) {
      yield put(getBatchDetailById(batchDetailID))
    }

    for (let x = 0; x < _updatedInvoices.length; x++) {
      if (_updatedInvoices[x]._id === data._id) {
        _updatedInvoices[x].is_deleted = true

        yield put(updateInvoices(_updatedInvoices))
      }
    }
    if (window.location.pathname.includes('reports/qb_to_portal_comparison')) {
      yield put(getQbPortalComparisonReports())
    }

    if (window.location.pathname.includes('reports/monthly_tax_summary')) {
      yield put(getTaxSummaryReports())
    }
  } catch (error) {
    //to do
  }
}

function* getCsvReportInvoiceDetailSagaWorker() {
  try {
    const invoiseID = yield select(getInvoiceDetailIdSelector)
    const invoiceNumber = yield select(getInvoiceDetailNumberSelector)
    const URL = `transactions/${invoiseID}/csv-export/`
    const res = yield call([api, 'getCsvApi'], URL)
    const { data, headers } = res

    saveAs(
      new Blob([data], { type: headers['content-type'] }),
      `invoice_${invoiceNumber}_detail.csv`
    )
  } catch (error) {
    //to do
  }
}

function* getCsvReportInvoiceTaxSummarySagaWorker() {
  try {
    const invoiseID = yield select(getInvoiceDetailIdSelector)
    const invoiceNumber = yield select(getInvoiceDetailNumberSelector)
    const URL = `transactions/${invoiseID}/tax-summary/csv-export/`
    const res = yield call([api, 'getCsvApi'], URL)
    const { data, headers } = res

    saveAs(
      new Blob([data], { type: headers['content-type'] }),
      `invoice_${invoiceNumber}_tax_summary.csv`
    )
  } catch (error) {
    //to do
  }
}

function* getInvoiceDetailByIdSagaWorker({ payload: { id, modalName } }) {
  try {
    const URL = `transactions/${id}/`
    const res = yield call([api, 'getInvoiceById'], URL)
    const { data } = res

    yield put(setInvoiceDetail(data || {}))
    if (modalName === 'invoice_detail') {
      yield put(showInvoiceDetailModal())
    }
    if (modalName === 'tax_summary') {
      yield put(showInvoiceTaxSummaryModal())
    }
  } catch (error) {
    // to do
  }
}

function* getInvoiceCsiOperationsSagaWorkers() {
  try {
    const roleUser = yield select(getRoleUserSelector)
    const realmId = yield select(getRealmIdFromInvoiceDetailSelector)
    const invoiceId = yield select(getInvoiceDetailIdSelector)
    const URL =
      roleUser === ADMIN_ROLE
        ? `transactions/${invoiceId}/csi-operations/?realmId=${realmId}`
        : `transactions/${invoiceId}/csi-operations/`
    const res = yield call([api, 'getInvoiceById'], URL)
    const { data } = res

    yield put(setInvoiseCsiOperations(data))
  } catch (error) {
    // to do
  }
}

export function* watchInvoiceDetailWorkers() {
  yield takeEvery(
    GET_INVOICE_DETAIL_FROM_AVAILABLE_INVOICES,
    getInvoiceDetailFromAvailableInvoicesWorker
  )
  yield takeEvery(
    GET_INVOICE_DETAIL_FROM_BATCH,
    getInvoiceDetailFromInvoicesWorker
  )
  yield takeEvery(DELETE_INVOICE, deleteInvoiceSagaWorker)
  yield takeEvery(
    GET_CSV_REPORT_INVOICE_DETAIL,
    getCsvReportInvoiceDetailSagaWorker
  )
  yield takeEvery(
    GET_CSV_REPORT_INVOICE_TAX_SUMMARY,
    getCsvReportInvoiceTaxSummarySagaWorker
  )
  yield takeEvery(
    GET_INVOICE_DETAIL_FROM_BACKEND,
    getInvoiceDetailByIdSagaWorker
  )
  yield takeEvery(
    GET_INVOICE_CSI_OPERATIONS,
    getInvoiceCsiOperationsSagaWorkers
  )
}
