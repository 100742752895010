import {
  START_USERS_FETCHING,
  STOP_USERS_FETCHING,
  GET_USERS,
  SET_USERS,
  CREATE_USER,
  EDIT_USER,
  DELETE_USER,
  SET_SELECTED_USER_ID,
  CLEAR_SELECTED_USER_ID,
  TOGGLE_ACTIVATE_STATUS_USER,
  SET_IS_SIGN_IN_AS_USER,
  SIGN_IN_AS_USER,
  SET_SEARCH_VALUE_USERS,
  CLEAR_SEARCH_VALUE_USERS,
  DISCONNECT_CSI_ACCOUNT,
  CONNECT_CSI_ACCOUNT,
} from 'constants/actions-constants'

export const setSearchValueUsers = (payload) => ({
  type: SET_SEARCH_VALUE_USERS,
  payload,
})

export const clearSearchValueUsers = () => ({
  type: CLEAR_SEARCH_VALUE_USERS,
})

export const startUsersFetching = () => ({
  type: START_USERS_FETCHING,
})

export const stopUsersFetching = () => ({
  type: STOP_USERS_FETCHING,
})

export const getUsersList = () => ({
  type: GET_USERS,
})

export const setUsersList = (payload) => ({
  type: SET_USERS,
  payload,
})

export const createUser = (payload) => ({
  type: CREATE_USER,
  payload,
})

export const editUser = (payload) => ({
  type: EDIT_USER,
  payload,
})
export const deleteUser = (payload) => ({
  type: DELETE_USER,
  payload,
})

export const setSelectedUserId = (payload) => ({
  type: SET_SELECTED_USER_ID,
  payload,
})

export const clearSelectedUserId = () => ({
  type: CLEAR_SELECTED_USER_ID,
})

export const toggleActivateStatusUser = () => ({
  type: TOGGLE_ACTIVATE_STATUS_USER,
})

export const setIsSingInAsUser = (payload) => ({
  type: SET_IS_SIGN_IN_AS_USER,
  payload,
})

export const signInAsUser = () => ({
  type: SIGN_IN_AS_USER,
})

export const disconnectCsiAcc = () => ({
  type: DISCONNECT_CSI_ACCOUNT,
})

export const connectCsiAccount = (payload) => ({
  type: CONNECT_CSI_ACCOUNT,
  payload,
})
