import React, { memo, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { useShallowSelector } from 'custom-hooks/useShallowSelector'
import {
  TAX_AMOUNT_SUM_SORT_CONST,
  TAX_DESCRIPTION_SORT_CONST,
  TAX_AUTH_SORT_CONST,
} from 'constants/tax-summary-sort-constant'
import { ADMIN_ROLE, CLIENT_ROLE } from 'constants/roles-constant'
import Button from 'components/ui/button-component'
import Modal from 'components/ui/modal-component'
import Title from 'components/ui/title-component'
import FielsWithTitle from 'components/ui/field-with-title'
import { Table, TableRow, TableCell } from 'components/ui/table'
import InfoBox from 'components/ui/infobox-component'
import RawTaxModal from 'components/raw-tax-modal'
import { getRoleUserSelector } from 'redusers/profile'
import {
  getInvoiceTaxSummarySelector,
  getInvoiceCsiTaxSummaryRawDetailSelector,
  getInvoiceDetailNumberSelector,
} from 'redusers/invoicesDetail'
import { closeInvoiceTaxSummaryModal } from 'actions/modals-state-actions'
import {
  getCsvReportInvoiceTaxSummary,
  getInvoiceCsiOperations,
} from 'actions/invoice-detail-actions'
import {
  TAX_SUMMARY_MODAL_TITLE_LN,
  TAX_SUMMARY_MODAL_TITLE_TOOLTIP_LN,
  TAX_SUMMARY_MODAL_INFO_MESSAGE_1_LN,
  TAX_SUMMARY_MODAL_INFO_MESSAGE_2_LN,
  TAX_SUMMARY_MODAL_TOTAL_TITLE_LN,
  TAX_SUMMARY_MODAL_BILLABLE_TOTAL_TITLE_LN,
  TAX_SUMMARY_MODAL_BUTTON_EXPORT_CSV_LN,
  CLOSE_BUTTON_NAME_LN,
  TAX_SUMMARY_MODAL_TABLE_CELL_1_LN,
  TAX_SUMMARY_MODAL_TABLE_CELL_2_LN,
  TAX_SUMMARY_MODAL_TABLE_CELL_3_LN,
  TAX_SUMMARY_MODAL_TABLE_CELL_4_LN,
  DEFAULT_CONFIRAMATION_MODAL_BUTTON_YES_LN,
  DEFAULT_CONFIRAMATION_MODAL_BUTTON_NO_LN,
  RAW_DETAIL_TAX_SUMMARY_MODAL_BUTTON_LN,
  RAW_DETAIL_TAX_SUMMARY_MODAL_BUTTON_1_LN,
  DISABLED_EXPORT_SCV_TOOLTIP_LN,
  RAW_DETAIL_TAX_SUMMARY_MODAL_TITLE_1_LN,
  RAW_DETAIL_TAX_SUMMARY_MODAL_TITLE_2_LN,
  RAW_DETAIL_TAX_SUMMARY_MODAL_TITLE_3_LN,
} from 'constants/language-key-constants'

function TaxSummaryModal() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const taxSummarysData = useShallowSelector(getInvoiceTaxSummarySelector)
  const taxRawDetail = useShallowSelector(
    getInvoiceCsiTaxSummaryRawDetailSelector
  )
  const {
    total_tax_amount,
    dataLines = [],
    is_errors_exists,
    billable_total_tax_amount,
  } = taxSummarysData
  const roleUser = useShallowSelector(getRoleUserSelector)
  const docNumber = useShallowSelector(getInvoiceDetailNumberSelector)

  const handleCloseModal = useCallback(() => {
    dispatch(closeInvoiceTaxSummaryModal())
  }, [dispatch])

  const handlerExportCsv = useCallback(() => {
    dispatch(getCsvReportInvoiceTaxSummary())
  }, [dispatch])

  useEffect(() => {
    dispatch(getInvoiceCsiOperations())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const nonTaxable = !is_errors_exists && !total_tax_amount && !dataLines.length

  return (
    <Modal
      classNameWrap={'invoice_detail_modal tax_summary_modal'}
      classNameContainer={''}
      isCloseButton={true}
      handleClickClose={handleCloseModal}
    >
      <span className={'tax_summary_modal_title_section'}>
        <Title
          title={t(TAX_SUMMARY_MODAL_TITLE_LN)}
          extraClassName="tax_summary_modal_title"
          withTooltip={true}
          textTooltip={t(TAX_SUMMARY_MODAL_TITLE_TOOLTIP_LN)}
          placementTooltip={'top'}
        />
        <span className="tax_summary_raw_button_container">
          <RawTaxModal
            content={taxRawDetail[0]?.csi_request}
            buttonName={t(RAW_DETAIL_TAX_SUMMARY_MODAL_BUTTON_1_LN)}
            modalTitle={`${t(
              RAW_DETAIL_TAX_SUMMARY_MODAL_TITLE_1_LN
            )} ${docNumber} ${t(RAW_DETAIL_TAX_SUMMARY_MODAL_TITLE_3_LN)}`}
            nameOfSavingFile={`invoice_${docNumber}_input_raw_detail`}
          />
          <RawTaxModal
            content={taxRawDetail[0]?.csi_response}
            buttonName={t(RAW_DETAIL_TAX_SUMMARY_MODAL_BUTTON_LN)}
            modalTitle={`${t(
              RAW_DETAIL_TAX_SUMMARY_MODAL_TITLE_1_LN
            )} ${docNumber} ${t(RAW_DETAIL_TAX_SUMMARY_MODAL_TITLE_2_LN)}`}
            nameOfSavingFile={`invoice_${docNumber}_tax_raw_detail`}
          />
        </span>
      </span>
      {is_errors_exists && (
        <span className="tax_summary_info_box">
          <InfoBox
            textContent={t(TAX_SUMMARY_MODAL_INFO_MESSAGE_1_LN)}
            styleType="warning"
          />
        </span>
      )}
      {!is_errors_exists && (nonTaxable || !dataLines?.length) && (
        <span className="tax_summary_info_box">
          <InfoBox
            textContent={t(TAX_SUMMARY_MODAL_INFO_MESSAGE_2_LN)}
            styleType="success"
          />
        </span>
      )}
      {!is_errors_exists && !nonTaxable && !!dataLines?.length && (
        <span className="invoice_details_tax_summary_table_container">
          <span className="invoice_details_tax_summary_table_section">
            <Table
              className="invoice_details_tax_summary_table"
              classNameHeaderRow="invoice_details_tax_summary_table_header"
              headerComponent={Header}
              bodyTableComponent={BodyTable}
              autoHeightMin={25}
              autoHeightMax={150}
              data={dataLines}
            />
          </span>
        </span>
      )}
      <span className="tax_summary_buttons_container">
        <span className="tax_summary_buttons_item buttons">
          {roleUser === CLIENT_ROLE && (
            <Button
              name={t(TAX_SUMMARY_MODAL_BUTTON_EXPORT_CSV_LN)}
              styletype="green"
              classNameButton={'invoice_details_modal_buttons'}
              classNameContainer={
                'invoice_details_modal_buttons_wrap left_button'
              }
              disabled={
                is_errors_exists || nonTaxable || roleUser === ADMIN_ROLE
              }
              tooltipTitle={
                roleUser === ADMIN_ROLE
                  ? `${t(DISABLED_EXPORT_SCV_TOOLTIP_LN)}`
                  : ''
              }
              onClick={handlerExportCsv}
              //isSpinner={isFetchingBatch}
            />
          )}
          <Button
            name={t(CLOSE_BUTTON_NAME_LN)}
            styletype="outline_blue"
            classNameButton={'invoice_details_modal_buttons'}
            classNameContainer={'invoice_details_modal_buttons_wrap'}
            onClick={handleCloseModal}
          />
        </span>
        {!(nonTaxable || is_errors_exists) && (
          <span className="tax_summary_buttons_item">
            <span className="tax_summary_modal_total_section">
              <FielsWithTitle
                title={t(TAX_SUMMARY_MODAL_TOTAL_TITLE_LN)}
                text={total_tax_amount}
                classNameContainer={'total smaller'}
                isCurrency
              />
            </span>
            <span className="tax_summary_modal_total_section">
              <FielsWithTitle
                title={t(TAX_SUMMARY_MODAL_BILLABLE_TOTAL_TITLE_LN)}
                text={billable_total_tax_amount}
                classNameContainer={'total'}
                isCurrency
              />
            </span>
          </span>
        )}
      </span>
    </Modal>
  )
}

const Header = memo(({ handleSortClick, sortedKey, defaultsortedkey }) => {
  const { t } = useTranslation()
  return (
    <>
      <TableCell
        text={t(TAX_SUMMARY_MODAL_TABLE_CELL_1_LN)}
        className="tax_description"
        isSort
        dataSortKey={TAX_DESCRIPTION_SORT_CONST}
        defaultsortedkey={defaultsortedkey}
        handleSortClick={handleSortClick}
        chosenSortedKey={sortedKey}
      />
      <TableCell
        text={t(TAX_SUMMARY_MODAL_TABLE_CELL_2_LN)}
        className="tax_authority"
        isSort
        dataSortKey={TAX_AUTH_SORT_CONST}
        handleSortClick={handleSortClick}
        chosenSortedKey={sortedKey}
      />
      <TableCell
        text={t(TAX_SUMMARY_MODAL_TABLE_CELL_3_LN)}
        className="tax_amount"
        isSort
        dataSortKey={TAX_AMOUNT_SUM_SORT_CONST}
        handleSortClick={handleSortClick}
        chosenSortedKey={sortedKey}
      />
      <TableCell
        text={t(TAX_SUMMARY_MODAL_TABLE_CELL_4_LN)}
        className="billable"
        //isSort
        //dataSortKey={TAX_AMOUNT_SUM_SORT_CONST}
        handleSortClick={handleSortClick}
        chosenSortedKey={sortedKey}
      />
    </>
  )
})

const BodyTable = memo((props) => {
  const { t } = useTranslation()
  const { data } = props
  return data.map((line) => {
    const {
      id,
      tax_description,
      tax_auth,
      tax_amount_sum,
      is_billable,
      description,
    } = line
    const billableText = is_billable
      ? t(DEFAULT_CONFIRAMATION_MODAL_BUTTON_YES_LN)
      : t(DEFAULT_CONFIRAMATION_MODAL_BUTTON_NO_LN)

    const _billableText = description === 'no tax' ? '-' : billableText

    return (
      <TableRow key={id} className="invoice_detail_table_row">
        <TableCell
          text={tax_description}
          className="tax_description"
          tooltipText={tax_description}
        />
        <TableCell
          text={tax_auth}
          className="tax_authority"
          tooltipText={tax_auth}
        />
        <TableCell
          text={description === 'no tax' ? '-' : tax_amount_sum}
          className="tax_amount  right"
          isCurrency={description !== 'no tax'}
        />
        <TableCell text={_billableText} className="billable" />
      </TableRow>
    )
  })
})

export default memo(TaxSummaryModal)
