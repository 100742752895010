export const A_TAX_EXEMPT_CONST = 'A' // 'A' // Exempt from federal taxes
export const B_TAX_EXEMPT_CONST = 'B' // 'B' // Exempt from state taxes
export const C_TAX_EXEMPT_CONST = 'C' // 'C' // Exempt from country taxes
export const D_TAX_EXEMPT_CONST = 'D' // 'D' // Exempt from local taxes
export const E_TAX_EXEMPT_CONST = 'E' // 'E' // Exempt from federal and state taxes
export const F_TAX_EXEMPT_CONST = 'F' // 'F' // Exempt from federal and country taxes
export const G_TAX_EXEMPT_CONST = 'G' // 'G' // Exempt from federal and local taxes
export const H_TAX_EXEMPT_CONST = 'H' // 'H' // Exempt from state and country taxes
export const I_TAX_EXEMPT_CONST = 'I' // 'I' // Exempt from state and local taxes  # noqa
export const J_TAX_EXEMPT_CONST = 'J' // 'J' // Exempt from country and local taxes
export const K_TAX_EXEMPT_CONST = 'K' // 'K' // Exempt from federal, state and country taxes
export const L_TAX_EXEMPT_CONST = 'L' // 'L' // Exempt from federal, state and local taxes
export const M_TAX_EXEMPT_CONST = 'M' // 'M' // Exempt from federal, country and local taxes
export const N_TAX_EXEMPT_CONST = 'N' // 'N' // NOT EXEMPT = This is the default if blank
export const O_TAX_EXEMPT_CONST = 'O' // 'O' // Exempt from state, country and local taxes  # noqa
export const S_TAX_EXEMPT_CONST = 'S' // 'S' // Exempt from sales tax
export const X_TAX_EXEMPT_CONST = 'X' // 'X' // Exempt from all tax types EXCEPT exempt_list
export const Y_TAX_EXEMPT_CONST = 'Y' // 'Y' // Exempt from all taxes (fully exempt)
export const Z_TAX_EXEMPT_CONST = 'Z' // 'Z' // Exempt from all tax types in exempt_list

export const TAX_ECEMPIONS_CODES = [
  A_TAX_EXEMPT_CONST,
  B_TAX_EXEMPT_CONST,
  C_TAX_EXEMPT_CONST,
  D_TAX_EXEMPT_CONST,
  E_TAX_EXEMPT_CONST,
  F_TAX_EXEMPT_CONST,
  G_TAX_EXEMPT_CONST,
  H_TAX_EXEMPT_CONST,
  I_TAX_EXEMPT_CONST,
  J_TAX_EXEMPT_CONST,
  K_TAX_EXEMPT_CONST,
  L_TAX_EXEMPT_CONST,
  M_TAX_EXEMPT_CONST,
  N_TAX_EXEMPT_CONST,
  O_TAX_EXEMPT_CONST,
  S_TAX_EXEMPT_CONST,
  X_TAX_EXEMPT_CONST,
  Y_TAX_EXEMPT_CONST,
  Z_TAX_EXEMPT_CONST,
]

export const defaultCustomerTypeValue = 1
export const defaultExemptioCodeValue = N_TAX_EXEMPT_CONST

export const customer_data_constants = {
  customer_type: {
    0: 'RES',
    1: 'BUS',
  },
  customer_type_default: {
    1: 'BUS',
  },
  exemption_code: {
    [A_TAX_EXEMPT_CONST]: {
      value: A_TAX_EXEMPT_CONST,
      listName: 'A = Exempt from Federal taxes',
    },
    [B_TAX_EXEMPT_CONST]: {
      value: B_TAX_EXEMPT_CONST,
      listName: 'B = Exempt from state taxes',
    },
    [C_TAX_EXEMPT_CONST]: {
      value: C_TAX_EXEMPT_CONST,
      listName: 'C = Exempt from county taxes',
    },
    [D_TAX_EXEMPT_CONST]: {
      value: D_TAX_EXEMPT_CONST,
      listName: 'D = Exempt from local taxes',
    },
    [E_TAX_EXEMPT_CONST]: {
      value: E_TAX_EXEMPT_CONST,
      listName: 'E = Exempt from federal and state taxes',
    },
    [F_TAX_EXEMPT_CONST]: {
      value: F_TAX_EXEMPT_CONST,
      listName: 'F = Exempt from federal and county taxes',
    },
    [G_TAX_EXEMPT_CONST]: {
      value: G_TAX_EXEMPT_CONST,
      listName: 'G = Exempt from federal and local taxes',
    },
    [H_TAX_EXEMPT_CONST]: {
      value: H_TAX_EXEMPT_CONST,
      listName: 'H = Exempt from state and county taxes',
    },
    [I_TAX_EXEMPT_CONST]: {
      value: I_TAX_EXEMPT_CONST,
      listName: 'I = Exempt from state and local taxes',
    },
    [J_TAX_EXEMPT_CONST]: {
      value: J_TAX_EXEMPT_CONST,
      listName: 'J = Exempt from county and local taxes',
    },
    [K_TAX_EXEMPT_CONST]: {
      value: K_TAX_EXEMPT_CONST,
      listName: 'K = Exempt from federal, state and county taxes',
    },
    [L_TAX_EXEMPT_CONST]: {
      value: L_TAX_EXEMPT_CONST,
      listName: 'L = Exempt from federal, state and local taxes',
    },
    [M_TAX_EXEMPT_CONST]: {
      value: M_TAX_EXEMPT_CONST,
      listName: 'M = Exempt from federal, county and local taxes',
    },
    [N_TAX_EXEMPT_CONST]: {
      value: N_TAX_EXEMPT_CONST,
      listName: 'N = NOT EXEMPT (default)',
    },
    [O_TAX_EXEMPT_CONST]: {
      value: O_TAX_EXEMPT_CONST,
      listName: 'O = Exempt from state, county and local taxes',
    },
    [S_TAX_EXEMPT_CONST]: {
      value: S_TAX_EXEMPT_CONST,
      listName: 'S = Exempt from Sales Tax',
    },
    [X_TAX_EXEMPT_CONST]: {
      value: X_TAX_EXEMPT_CONST,
      listName: 'X = Exempt from all tax types EXCEPT exempt_list',
    },
    [Y_TAX_EXEMPT_CONST]: {
      value: Y_TAX_EXEMPT_CONST,
      listName: 'Y = Exempt from all taxes (fully exempt)',
    },
    [Z_TAX_EXEMPT_CONST]: {
      value: Z_TAX_EXEMPT_CONST,
      listName: 'Z = Exempt from all tax types in exempt_list',
    },
  },
  exemption_code_default: {
    [N_TAX_EXEMPT_CONST]: {
      value: N_TAX_EXEMPT_CONST,
      listName: 'N = NOT EXEMPT (default)',
    },
  },
}
