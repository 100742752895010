import React, { memo } from 'react'
import PropTypes from 'prop-types'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'

const CalendarStyled = (props) => {
  const { placeholder, className, ...rest } = props

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        label={placeholder}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        className={`styled_calendar ${className}`}
        autoOk={true}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  )
}

CalendarStyled.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
}

export default memo(CalendarStyled)
