import { put, call, takeEvery, select } from 'redux-saga/effects'

import api from 'api'

import {
  GET_NOTIFICATION_FROM_BACK_END,
  MARK_AS_READ,
} from 'constants/actions-constants'
import { setNotificationsFromBackEnd } from 'actions/notification-actions'
import {
  getNotificationsLimitSelector,
  getUnreadIdNotificationSelector,
} from 'redusers/notifications'

function* getNotificationWorkerSagas() {
  try {
    const limit = yield select(getNotificationsLimitSelector)
    const URL = `notifications/?limit=${limit}`
    const res = yield call([api, 'getNotificationsApi'], URL)
    const { data } = res

    yield put(setNotificationsFromBackEnd(data))
  } catch (error) {
    //to do
  } finally {
    //to do
  }
}

function* markAsReadrSagaWorker() {
  try {
    const unreadNotificationsId = yield select(getUnreadIdNotificationSelector)
    const URL = 'notifications/mark-as-read/'
    yield call([api, 'markAsReadApi'], URL, unreadNotificationsId)

    yield call(getNotificationWorkerSagas)
  } catch (error) {
    // to do
  } finally {
    // to do
  }
}

export function* notificationWatcherSagas() {
  yield takeEvery(GET_NOTIFICATION_FROM_BACK_END, getNotificationWorkerSagas)
  yield takeEvery(MARK_AS_READ, markAsReadrSagaWorker)
}
