import React, { memo } from 'react'

const CheckBoxStyled = (props) => {
  const {
    id,
    className = '',
    isDisabledCheckBox = false,
    isHeaderStyle = false,
    notEmpty = false,
    label = '',
    ...rest
  } = props

  const disabledClassName = isDisabledCheckBox ? 'disabled' : ''
  const headerClassNames = isHeaderStyle ? 'header' : ''
  const notEmptyClassName = notEmpty ? 'not_empty' : ''
  return (
    <label
      className={`checkbox_container ${className} ${disabledClassName} ${headerClassNames} ${notEmptyClassName}`}
      data-id={id}
    >
      <input
        type="checkbox"
        className="checkbox"
        data-id={id}
        disabled={isDisabledCheckBox}
        {...rest}
      />
      <span>{label}</span>

      <span className="styles_checkbox"></span>
    </label>
  )
}

export default memo(CheckBoxStyled)
