import React, { memo } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import PrivateRoute from 'components/private-route'
import Dashboard from 'components/dashboard'
import SignIn from 'components/sign-in'

function AuthRoute() {
  return (
    <Router>
      <Switch>
        <Route path="/auth" component={SignIn} exact />
        <PrivateRoute path="/" component={Dashboard} />
      </Switch>
    </Router>
  )
}

AuthRoute.propTypes = {}

export default memo(AuthRoute)
