import React from 'react'

export default function FilterIconButton() {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Design"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Home" transform="translate(-742.000000, -120.000000)">
          <g id="Group-11" transform="translate(742.000000, 120.000000)">
            <g id="Primary-button-Copy">
              <g id="Group-7">
                <g>
                  <g id="Filter_icon">
                    <path
                      d="M18.9568528,3.9999988 L4.6924175,3.9999988 C4.44083123,3.99952411 4.210184,4.14006981 4.09517766,4.36389596 C3.97858502,4.59073604 3.99904822,4.86389596 4.14800126,5.07106599 L9.37388958,12.4327411 C9.37563452,12.4352792 9.37753807,12.4376586 9.37928301,12.4401967 C9.56916244,12.6965419 9.6717957,13.0069797 9.67243021,13.3261421 L9.67243021,19.3280457 C9.6713198,19.5058693 9.74111675,19.6765546 9.86643403,19.802665 C9.99175127,19.9287754 10.1621193,19.9995241 10.3397843,19.9995241 C10.430203,19.9995241 10.5195114,19.9814404 10.6029505,19.9468591 L13.5394987,18.8270939 C13.802665,18.7468274 13.977316,18.4985723 13.977316,18.1995558 L13.977316,13.3261421 C13.9777919,13.0071383 14.0805838,12.6965419 14.2703046,12.4401967 C14.2720495,12.4376586 14.2739531,12.4352792 14.2756979,12.4327411 L19.5014277,5.07074875 C19.6503807,4.8637373 19.6708439,4.59073604 19.5542513,4.36389596 C19.4392449,4.14006981 19.2085977,3.99952411 18.9568528,3.9999988 Z M13.3899861,11.9604311 C13.1348617,12.2925746 12.9969004,12.6941531 12.9960684,13.106772 L12.9960684,17.1105851 L10.6533605,17.967481 L10.6533605,13.106772 C10.6525286,12.6941531 10.5145673,12.2925746 10.2593042,11.9604311 L5.82471448,5.96748097 L17.8247145,5.96748097 L13.3899861,11.9604311 Z"
                      id="Shape"
                      fill="#00447E"
                      fillRule="nonzero"
                    ></path>
                    <rect
                      id="Rectangle"
                      x="0"
                      y="0"
                      width="24"
                      height="24"
                    ></rect>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
