import { useState, useEffect, useCallback } from 'react'

export const useToggleMenu = (defaultState) => {
  const [isOpenMenu, setIsOpenMenu] = useState(defaultState)

  const handleOverClick = useCallback((event) => {
    event.preventDefault()
    event.stopPropagation()
    setIsOpenMenu(false)
    document.removeEventListener('click', handleOverClick)
  }, [])

  useEffect(() => {
    if (isOpenMenu) {
      document.addEventListener('click', handleOverClick)
    }
  }, [handleOverClick, isOpenMenu])

  useEffect(() => {
    return () => {
      document.removeEventListener('click', handleOverClick)
    }
  }, [handleOverClick])

  return [isOpenMenu, setIsOpenMenu]
}
