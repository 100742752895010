import React, { memo, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import Tooltip from '@material-ui/core/Tooltip'

import { useShallowSelector } from 'custom-hooks/useShallowSelector'
import CustomScrollBar from 'components/ui/custom-scroll-bar'
import {
  getQbPortalComparisonReports,
  clearQbPortalComparisonReports,
  clearSearchValuesReports,
} from 'actions/reports-actions'
import Title from 'components/ui/title-component'
import InfoBox from 'components/ui/infobox-component'
import Spinner from 'components/ui/spinner'
import CopyToClipboardButton from 'components/ui/copy-to-clipboar-button'
import { useSortedData } from 'custom-hooks/useSort'
import Cell from './Cell'
import {
  getQbPortalComparisonReportProcessedDatetimeFromSelector,
  getQbPortalComparisonReportProcessedDatetimeToSelector,
  getQbPortalComparisonReportsSelector,
  getIsFetchinReportsSelector,
  getSumTotalsTaxAmountSelector,
} from 'redusers/reports'
import { getInvoiceDetailFromBackEnd } from 'actions/invoice-detail-actions'
import { UPDATE_PAGINATION_CONSTANT } from 'constants/pagination-constants'
import {
  QB_TO_PORTAL_REPORT_TITLE_LN,
  QB_TO_PORTAL_REPORT_TABLE_CELL_1_LN,
  QB_TO_PORTAL_REPORT_TABLE_CELL_2_LN,
  QB_TO_PORTAL_REPORT_TABLE_CELL_4_LN,
  QB_TO_PORTAL_REPORT_TABLE_CELL_5_LN,
  QB_TO_PORTAL_REPORT_TABLE_CELL_6_LN,
  QB_TO_PORTAL_REPORT_TABLE_CELL_7_LN,
  QB_TO_PORTAL_REPORT_TABLE_CELL_8_LN,
  QB_TO_PORTAL_REPORT_TABLE_CELL_9_LN,
  QB_TO_PORTAL_REPORT_TABLE_CELL_10_LN,
  QB_TO_PORTAL_REPORT_TABLE_CELL_11_LN,
  QB_TO_PORTAL_REPORT_TABLE_INFO_BOX_LN,
  PROCESSED_BATCH_SUMMARY_TABLE_CELL_1_TOOLTIP_LN,
} from 'constants/language-key-constants'

let _prevScrollHeight = 0

const QbPortalCompariconReport = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const reports = useShallowSelector(getQbPortalComparisonReportsSelector)
  const isFetching = useShallowSelector(getIsFetchinReportsSelector)
  const fromDate = useShallowSelector(
    getQbPortalComparisonReportProcessedDatetimeFromSelector
  )
  const toDate = useShallowSelector(
    getQbPortalComparisonReportProcessedDatetimeToSelector
  )

  const sumTotalTaxAmount = useShallowSelector(getSumTotalsTaxAmountSelector)

  const [sortedData, sortedKey, handleSortClick] = useSortedData(
    'processed_date',
    reports
  )

  const handleUpdate = (value) => {
    if (
      value?.clientHeight >= value?.scrollHeight - value?.scrollTop &&
      _prevScrollHeight !== value?.scrollTop
    ) {
      dispatch(getQbPortalComparisonReports(UPDATE_PAGINATION_CONSTANT))
    }
    _prevScrollHeight = value?.scrollTop
  }

  const handleClickInvoiceDetail = useCallback(
    (e) => {
      const invoiceId = e.currentTarget.dataset.invoiceId
      const modalType = e.currentTarget.dataset.invoiceModal
      dispatch(
        getInvoiceDetailFromBackEnd({ id: invoiceId, modalName: modalType })
      )
    },
    [dispatch]
  )

  useEffect(() => {
    dispatch(getQbPortalComparisonReports())
    return () => {
      _prevScrollHeight = 0
      dispatch(clearQbPortalComparisonReports())
      dispatch(clearSearchValuesReports())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fromDateText = moment(fromDate).format('M/D/YYYY h:mm A')

  const toDateText = moment(toDate).format('M/D/YYYY h:mm A')

  const usdFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    minimumFractionDigits: 2,
    currency: 'USD',
  })

  return (
    <div className="tax_summary_report_container container qb_portal_comparison_report">
      <div className="title_section">
        <Title
          title={t(QB_TO_PORTAL_REPORT_TITLE_LN)}
          extraClassName="tax_summary_report_title"
          component="h1"
        />
        <span className="tax_summary_report_date_range_label">{`${fromDateText} - ${toDateText}`}</span>
      </div>

      <div className="table_section">
        {reports?.length ? (
          <>
            <CustomScrollBar
              autoHeight
              autoHeightMin={45}
              autoHeightMax={'55vh'}
              className="tax_summary_report_table_scroll_container"
              onScrollFrame={handleUpdate}
              renderTrackHorizontal={() => <span></span>}
              renderThumbHorizontal={() => <span></span>}
            >
              <table
                className="tax_summary_report_table"
                cols="11"
                width="100%"
              >
                <thead className="tax_summary_report_table_head">
                  <tr className="tax_summary_report_table_row header">
                    <Cell
                      dataSortKey={'last_batch_id'}
                      text={t(QB_TO_PORTAL_REPORT_TABLE_CELL_1_LN)}
                      chosenSortedKey={sortedKey}
                      handleSortClick={handleSortClick}
                    />
                    <Cell
                      defaultsortedkey={'processed_date'}
                      dataSortKey={'processed_date'}
                      text={t(QB_TO_PORTAL_REPORT_TABLE_CELL_2_LN)}
                      chosenSortedKey={sortedKey}
                      handleSortClick={handleSortClick}
                    />
                    <Cell
                      dataSortKey={'customer_name'}
                      text={t(QB_TO_PORTAL_REPORT_TABLE_CELL_5_LN)}
                      chosenSortedKey={sortedKey}
                      handleSortClick={handleSortClick}
                      className={'customer_name'}
                    />
                    <Cell
                      dataSortKey={'doc_number'}
                      text={t(QB_TO_PORTAL_REPORT_TABLE_CELL_6_LN)}
                      chosenSortedKey={sortedKey}
                      handleSortClick={handleSortClick}
                      className={'doc_number'}
                    />
                    <Cell
                      dataSortKey={'type'}
                      text={'Type'}
                      chosenSortedKey={sortedKey}
                      handleSortClick={handleSortClick}
                      className={'type'}
                    />
                    <Cell
                      dataSortKey={'transaction_date'}
                      text={t(QB_TO_PORTAL_REPORT_TABLE_CELL_7_LN)}
                      chosenSortedKey={sortedKey}
                      handleSortClick={handleSortClick}
                    />
                    <Cell
                      dataSortKey={'exempt_code'}
                      text={t(QB_TO_PORTAL_REPORT_TABLE_CELL_4_LN)}
                      chosenSortedKey={sortedKey}
                      handleSortClick={handleSortClick}
                      className={'exempt_code'}
                    />
                    <Cell
                      dataSortKey={'revenue'}
                      text={t(QB_TO_PORTAL_REPORT_TABLE_CELL_8_LN)}
                      chosenSortedKey={sortedKey}
                      handleSortClick={handleSortClick}
                      className="revenue"
                    />
                    <Cell
                      dataSortKey={'billable_total_tax_amount'}
                      text={t(QB_TO_PORTAL_REPORT_TABLE_CELL_9_LN)}
                      chosenSortedKey={sortedKey}
                      handleSortClick={handleSortClick}
                    />
                    <Cell
                      dataSortKey={'billable_total_tax_amount_qb'}
                      text={t(QB_TO_PORTAL_REPORT_TABLE_CELL_10_LN)}
                      chosenSortedKey={sortedKey}
                      handleSortClick={handleSortClick}
                    />
                    <Cell
                      dataSortKey={'tax_difference'}
                      text={t(QB_TO_PORTAL_REPORT_TABLE_CELL_11_LN)}
                      chosenSortedKey={sortedKey}
                      handleSortClick={handleSortClick}
                    />
                  </tr>
                </thead>
                <tbody className="tax_summary_report_table_body">
                  {sortedData.map((report, index) => {
                    const {
                      _id,
                      processed_date,
                      exempt_code,
                      customer_name,
                      doc_number,
                      transaction_date,
                      revenue,
                      billable_total_tax_amount_qb,
                      billable_total_tax_amount,
                      tax_difference,
                      last_batch_id,
                      type,
                      csi_status,
                      qb_status,
                    } = report

                    const is_qb_update_needed =
                      csi_status !== 'CALCULATED' || qb_status === 'OUTDATED'

                    const isqbupdateneededClassName = is_qb_update_needed
                      ? 'is_qb_update_needed'
                      : ''

                    const tooltipText = is_qb_update_needed
                      ? t(PROCESSED_BATCH_SUMMARY_TABLE_CELL_1_TOOLTIP_LN)
                      : ''

                    return (
                      <Tooltip key={index} title={tooltipText || ''}>
                        <tr
                          key={index}
                          className={`tax_summary_report_table_row ${isqbupdateneededClassName}`}
                        >
                          <td className="report_cell">
                            <Tooltip title={last_batch_id || ''}>
                              <span className="batch_id_cell_container">
                                <CopyToClipboardButton
                                  copyContent={last_batch_id}
                                  titleText={last_batch_id?.substring(
                                    last_batch_id.length - 5
                                  )}
                                />
                              </span>
                            </Tooltip>
                          </td>
                          <td className="report_cell">{processed_date}</td>
                          <td className="report_cell customer_name">
                            <Tooltip title={customer_name || ''}>
                              <span className="customer_name_text">
                                {customer_name}
                              </span>
                            </Tooltip>
                          </td>
                          <td className="report_cell doc_number">
                            <button
                              className="button_cell "
                              data-invoice-id={_id}
                              data-invoice-modal={'invoice_detail'}
                              onClick={handleClickInvoiceDetail}
                            >
                              {doc_number}
                            </button>
                          </td>
                          <td className="report_cell">{type?.toUpperCase()}</td>
                          <td className="report_cell">{transaction_date}</td>
                          <td className="report_cell align_center exempt_code">
                            {exempt_code}
                          </td>
                          <td className="report_cell currency revenue qb_cell_currency">
                            <span className="currency_content ">
                              {usdFormat.format(revenue)}
                            </span>
                          </td>
                          <td className="report_cell currency qb_cell_currency">
                            <button
                              className="button_cell currency_content"
                              data-invoice-id={_id}
                              data-invoice-modal={'tax_summary'}
                              onClick={handleClickInvoiceDetail}
                            >
                              {usdFormat.format(billable_total_tax_amount)}
                            </button>
                          </td>
                          <td className="report_cell currency qb_cell_currency">
                            <span className="currency_content">
                              {usdFormat.format(billable_total_tax_amount_qb)}
                            </span>
                          </td>
                          <td className="report_cell currency qb_cell_currency">
                            <span className="currency_content">
                              {usdFormat.format(tax_difference)}
                            </span>
                          </td>
                        </tr>
                      </Tooltip>
                    )
                  })}
                </tbody>
              </table>
            </CustomScrollBar>
          </>
        ) : (
          !isFetching && (
            <InfoBox textContent={t(QB_TO_PORTAL_REPORT_TABLE_INFO_BOX_LN)} />
          )
        )}
      </div>
      {!!reports?.length && (
        <div className="sum_total_tax_container qb_report">
          <span className="sum_total_tax_title">
            Tax Total (CSI) = {usdFormat.format(sumTotalTaxAmount)}
          </span>
        </div>
      )}
      {isFetching && (
        <Spinner styleSpinner="green" className="report_spinner" />
      )}
    </div>
  )
}

export default memo(QbPortalCompariconReport)
