import {
  SET_IS_LOADING,
  SET_IS_AUTS,
  CHECK_AUTH_STATUS,
  SIGN_IN,
  GET_PROFILE_INFO,
  SET_PROFILE_INFO,
  REFRESH,
  LOGOUT,
  SET_PROFILE_ERROR_MESSAGE,
  CLEAR_PROFILE_ERROR_MESSAGE,
  SET_PROFILE_FETCHING,
  CLEAR_USER_PROFILE_DATA,
  UPDATE_PROFILE_DATA,
  SET_IS_PIU_FETCHING,
  UPDATE_PIU,
  SHOW_INFOBOX_ABOUT_PIU,
  SET_SUCCESS_MESSAGE,
  CLEAR_SUCCESS_MESSAGE,
  LOGOUT_AFTER_IDLE,
} from 'constants/actions-constants'

export const logoutAfterIdle = () => ({
  type: LOGOUT_AFTER_IDLE,
})

export const setIsLoadingAction = (payload) => ({
  type: SET_IS_LOADING,
  payload,
})

export const setIsAutsAction = (payload) => ({
  type: SET_IS_AUTS,
  payload,
})

export const authCheckState = () => ({
  type: CHECK_AUTH_STATUS,
})

export const signInAction = (payload) => ({
  type: SIGN_IN,
  payload,
})

export const getProfileInfo = () => ({
  type: GET_PROFILE_INFO,
})

export const setProfileInfo = (payload) => ({
  type: SET_PROFILE_INFO,
  payload,
})

export const refreshAction = () => ({
  type: REFRESH,
})

export const logOut = () => ({
  type: LOGOUT,
})

export const setProfileErrorMessage = (payload) => ({
  type: SET_PROFILE_ERROR_MESSAGE,
  payload,
})

export const clearProfileErrorMessage = () => ({
  type: CLEAR_PROFILE_ERROR_MESSAGE,
})

export const setProfileFetching = (payload) => ({
  type: SET_PROFILE_FETCHING,
  payload,
})

export const clearUserProfileData = () => ({
  type: CLEAR_USER_PROFILE_DATA,
})

export const updateProfileData = (payload) => ({
  type: UPDATE_PROFILE_DATA,
  payload,
})

export const setIsPiuFetching = (payload) => ({
  type: SET_IS_PIU_FETCHING,
  payload,
})

export const updatePiu = (payload) => ({
  type: UPDATE_PIU,
  payload,
})

export const showPiuInfoBox = (payload) => ({
  type: SHOW_INFOBOX_ABOUT_PIU,
  payload,
})

export const setSuccessMessage = (payload) => ({
  type: SET_SUCCESS_MESSAGE,
  payload,
})

export const clearSuccesMessage = () => ({
  type: CLEAR_SUCCESS_MESSAGE,
})
