import {
  put,
  call,
  takeEvery,
  select,
  debounce,
  throttle,
} from 'redux-saga/effects'
import { saveAs } from 'file-saver'
import api from 'api'
import {
  GET_TAX_SUMMARY_REPORTS,
  EXPORT_CSV_TAX_SUMMARY_REPORTS,
  GET_QB_PORTAL_COMPARISON_REPORTS,
  EXPORT_CSV_QB_PORTAL_COMPARISON_REPORTS,
  SET_SEARCH_VALUE_QB_PORTAL_COMPARISON_REPORTS,
  SET_SEARCH_VALUE_TAX_SUMMARY_REPORTS,
  GET_SUM_TOTAL_FOR_REPORTS,
} from 'constants/actions-constants'
import { UPDATE_PAGINATION_CONSTANT } from 'constants/pagination-constants'
import {
  setTaxSummaryReport,
  startFetchingReports,
  stopFetchingReports,
  updateTaxSummaryReport,
  setQbPortalComparisonReports,
  updateQbPortalComparisonReports,
  startFetchingExportCsvReport,
  stopFetchingExportCsvReport,
  setIsShowInfoBoxIfEmptyDataTable,
  setSumTotalForReports,
  getSumTotalForReports,
  setErrorMessageMtsReport,
  clearErrorMessageMtsReport,
} from 'actions/reports-actions'
import {
  getIssueDatetimeFromSelector,
  getIssueDatetimeToSelector,
  getOffsetTaxSummaryReportSelector,
  getQbPortalComparisonReportProcessedDatetimeFromSelector,
  getQbPortalComparisonReportProcessedDatetimeToSelector,
  getQbPortalComparisonReportOffsetSelector,
  getSearchValueQbPortalCompanyReportsSelector,
  getSearchValueTaxSummaryReportsSelector,
  getHasZeroTaxSelector,
} from 'redusers/reports'
import { getCurrentShownModalNameSelector } from 'redusers/modalsState'
import { hideReportFilterModal } from 'actions/modals-state-actions'
import { REPORT_FILTER_MODAL_NAME } from 'constants/modal-names-constants'

function* getTaxSummaryReportsSagaWorker({ payload: isUpdate }) {
  const modalState = yield select(getCurrentShownModalNameSelector)
  try {
    const LIMIT = 20
    yield put(startFetchingReports())
    const dateFrom = yield select(getIssueDatetimeFromSelector)
    const dateTo = yield select(getIssueDatetimeToSelector)
    const searchValue = yield select(getSearchValueTaxSummaryReportsSelector)
    if (isUpdate === UPDATE_PAGINATION_CONSTANT) {
      const offset = yield select(getOffsetTaxSummaryReportSelector)

      let URL = `reports/monthly-tax-summary-report/?limit=${LIMIT}&issueDatetimeFrom=${encodeURIComponent(
        dateFrom
      )}&issueDatetimeTo=${encodeURIComponent(dateTo)}&offset=${offset + LIMIT}`

      if (searchValue) {
        URL = URL + `&search=${searchValue}`
      }

      const res = yield call([api, 'getReports'], URL)

      const { data } = res
      if (data?.length) {
        yield put(
          updateTaxSummaryReport({ reports: data, offset: offset + LIMIT })
        )
      }
    } else {
      let URL = `reports/monthly-tax-summary-report/?limit=${LIMIT}&issueDatetimeFrom=${encodeURIComponent(
        dateFrom
      )}&issueDatetimeTo=${encodeURIComponent(dateTo)}`

      if (searchValue) {
        URL = URL + `&search=${searchValue}`
      }

      const res = yield call([api, 'getReports'], URL)

      const { data } = res

      if (data) {
        yield put(setTaxSummaryReport(data))

        if (modalState === REPORT_FILTER_MODAL_NAME) {
          yield put(hideReportFilterModal())
        }

        if (data.length) {
          yield put(setIsShowInfoBoxIfEmptyDataTable(false))
          yield put(getSumTotalForReports({ dateFrom, dateTo, searchValue }))
        } else {
          yield put(setIsShowInfoBoxIfEmptyDataTable(true))
        }
        yield put(clearErrorMessageMtsReport())
      }
    }
  } catch (error) {
    if (error?.response?.status === 400 && error?.response?.detail?.message) {
      if (modalState === REPORT_FILTER_MODAL_NAME) {
        yield put(hideReportFilterModal())
      }
      yield put(
        setErrorMessageMtsReport(error?.response?.detail?.message || '')
      )
    }
  } finally {
    yield put(stopFetchingReports())
  }
}

function* exportCsvTaxSummaryReportSagaWorker() {
  try {
    yield put(startFetchingExportCsvReport())
    const dateFrom = yield select(getIssueDatetimeFromSelector)
    const dateTo = yield select(getIssueDatetimeToSelector)
    const URL = `reports/monthly-tax-summary-report/csv-export/?issueDatetimeFrom=${encodeURIComponent(
      dateFrom
    )}&issueDatetimeTo=${encodeURIComponent(dateTo)}`
    const res = yield call([api, 'getCsvApi'], URL)
    const { data, headers } = res

    saveAs(
      new Blob([data], { type: headers['content-type'] }),
      `Monthly_tax_summary_from_${dateFrom}_to_${dateTo}.csv`
    )
  } catch (error) {
    // to do
  } finally {
    yield put(stopFetchingExportCsvReport())
  }
}

function* getQbPortalComparisonReportsSagaWorker({ payload: isUpdate }) {
  try {
    const LIMIT = 50
    yield put(startFetchingReports())
    const dateFrom = yield select(
      getQbPortalComparisonReportProcessedDatetimeFromSelector
    )
    const dateTo = yield select(
      getQbPortalComparisonReportProcessedDatetimeToSelector
    )
    const searchValue = yield select(
      getSearchValueQbPortalCompanyReportsSelector
    )
    const hasZeroTax = yield select(getHasZeroTaxSelector)

    if (isUpdate === UPDATE_PAGINATION_CONSTANT) {
      const offset = yield select(getQbPortalComparisonReportOffsetSelector)
      const URL = searchValue
        ? `reports/comparison-report/?limit=${LIMIT}&showZeroTaxes=${hasZeroTax}&issueDatetimeFrom=${encodeURIComponent(
            dateFrom
          )}&issueDatetimeTo=${encodeURIComponent(dateTo)}&offset=${
            offset + LIMIT
          }&search=${searchValue}`
        : `reports/comparison-report/?limit=${LIMIT}&showZeroTaxes=${hasZeroTax}&issueDatetimeFrom=${encodeURIComponent(
            dateFrom
          )}&issueDatetimeTo=${encodeURIComponent(dateTo)}&offset=${
            offset + LIMIT
          }`

      const res = yield call([api, 'getReports'], URL)

      const { data } = res
      if (data?.length) {
        yield put(
          updateQbPortalComparisonReports({
            reports: data,
            offset: offset + LIMIT,
          })
        )
      }
    } else {
      const URL = searchValue
        ? `reports/comparison-report/?limit=${LIMIT}&showZeroTaxes=${hasZeroTax}&issueDatetimeFrom=${encodeURIComponent(
            dateFrom
          )}&issueDatetimeTo=${encodeURIComponent(
            dateTo
          )}&search=${searchValue}`
        : `reports/comparison-report/?limit=${LIMIT}&showZeroTaxes=${hasZeroTax}&issueDatetimeFrom=${encodeURIComponent(
            dateFrom
          )}&issueDatetimeTo=${encodeURIComponent(dateTo)}`

      const res = yield call([api, 'getReports'], URL)

      const { data } = res
      if (data) {
        yield put(setQbPortalComparisonReports(data))
        const modalState = yield select(getCurrentShownModalNameSelector)
        if (modalState === REPORT_FILTER_MODAL_NAME) {
          yield put(hideReportFilterModal())
        }
        if (data.length) {
          yield put(setIsShowInfoBoxIfEmptyDataTable(false))
          yield put(getSumTotalForReports({ dateFrom, dateTo, searchValue }))
        } else {
          yield put(setIsShowInfoBoxIfEmptyDataTable(true))
        }
      }
    }
  } catch (error) {
    //to do
  } finally {
    yield put(stopFetchingReports())
  }
}

function* exportCsvQbPortalComparisonReportsSagaWorker() {
  try {
    yield put(startFetchingExportCsvReport())
    const dateFrom = yield select(
      getQbPortalComparisonReportProcessedDatetimeFromSelector
    )
    const dateTo = yield select(
      getQbPortalComparisonReportProcessedDatetimeToSelector
    )
    const hasZeroTax = yield select(getHasZeroTaxSelector)

    const URL = `reports/comparison-report/csv-export/?issueDatetimeFrom=${encodeURIComponent(
      dateFrom
    )}&issueDatetimeTo=${encodeURIComponent(
      dateTo
    )}&showZeroTaxes=${hasZeroTax}`
    const res = yield call([api, 'getCsvApi'], URL)
    const { data, headers } = res

    saveAs(
      new Blob([data], { type: headers['content-type'] }),
      `Qb_portal_comparison_report_from_${dateFrom}_to_${dateTo}.csv`
    )
  } catch (error) {
    // to do
  } finally {
    yield put(stopFetchingExportCsvReport())
  }
}

function* getSumTotalReportsSagaWorker({ payload }) {
  try {
    //yield put(startFetchingExportCsvReport())
    const { dateFrom, dateTo, searchValue } = payload

    let URL = `reports/get-sum-total-tax-amount/?issueDatetimeFrom=${encodeURIComponent(
      dateFrom
    )}&issueDatetimeTo=${encodeURIComponent(dateTo)}`

    if (searchValue) {
      URL = URL + `&search=${encodeURIComponent(searchValue)}`
    }

    const res = yield call([api, 'getSumTotalReports'], URL)
    const { data } = res

    yield put(setSumTotalForReports(data))
  } catch (error) {
    // to do
  } finally {
    //yield put(stopFetchingExportCsvReport())
  }
}

export function* reportsSagaWatcher() {
  yield throttle(500, GET_TAX_SUMMARY_REPORTS, getTaxSummaryReportsSagaWorker)
  yield debounce(
    1000,
    SET_SEARCH_VALUE_TAX_SUMMARY_REPORTS,
    getTaxSummaryReportsSagaWorker
  )
  yield takeEvery(
    EXPORT_CSV_TAX_SUMMARY_REPORTS,
    exportCsvTaxSummaryReportSagaWorker
  )
  yield debounce(
    500,
    SET_SEARCH_VALUE_QB_PORTAL_COMPARISON_REPORTS,
    getQbPortalComparisonReportsSagaWorker
  )
  yield throttle(
    500,
    GET_QB_PORTAL_COMPARISON_REPORTS,
    getQbPortalComparisonReportsSagaWorker
  )
  yield takeEvery(
    EXPORT_CSV_QB_PORTAL_COMPARISON_REPORTS,
    exportCsvQbPortalComparisonReportsSagaWorker
  )
  yield takeEvery(GET_SUM_TOTAL_FOR_REPORTS, getSumTotalReportsSagaWorker)
}
