import React from 'react'
import { reduxForm } from 'redux-form'
import { useTranslation, getI18n } from 'react-i18next'

import Input from 'components/ui/input-component'
import Button from 'components/ui/button-component'

import {
  SIGN_IN_LN,
  EMAIL_LN,
  PASSWORD_LN,
  SIGN_IN_VALID_MESSAGE_1_LN,
  SIGN_IN_VALID_MESSAGE_2_LN,
  SIGN_IN_VALID_MESSAGE_3_LN,
} from 'constants/language-key-constants'

const validate = (values) => {
  const errors = {}
  const i18n = getI18n()
  if (!values.login && values.password) {
    errors.login = i18n.t(SIGN_IN_VALID_MESSAGE_1_LN)
  }

  if (!values.password && values.login) {
    errors.password = i18n.t(SIGN_IN_VALID_MESSAGE_2_LN)
  }

  if (!values.password && !values.login) {
    errors.login = i18n.t(SIGN_IN_VALID_MESSAGE_3_LN)
  }
  return errors
}

const Form = (props) => {
  const { isProfileFetching, handleSubmit } = props
  const { t } = useTranslation()

  return (
    <form className="login_form__inputs_container" onSubmit={handleSubmit}>
      <Input
        classNameContainer="login_form__input_email_container"
        classNameInput="login_form__input email"
        placeholder={t(EMAIL_LN)}
        name="login"
      />
      <Input
        type="password"
        name="password"
        title={t(PASSWORD_LN)}
        isPassword={true}
        classNameContainer="login_form__input_email_container password"
        classNameInput="login_form__input"
        placeholder={t(PASSWORD_LN)}
      />

      <Button
        name={t(SIGN_IN_LN).toLocaleUpperCase()}
        styletype="green"
        classNameButton="login_form_sign_button"
        disabled={isProfileFetching}
        type="submit"
        isSpinner={isProfileFetching}
      />
    </form>
  )
}

Form.propTypes = {}

export default reduxForm({
  form: 'login',
  validate,
})(Form)
