import React, { forwardRef, memo } from 'react'
import PropTypes from 'prop-types'

const InfoBox = forwardRef((props, ref) => {
  const {
    textContent = '',
    extraClassNameContainer = '',
    extraClassNameText = '',
    styleType = '',
  } = props
  return (
    <div
      ref={ref}
      className={`info_box_container ${extraClassNameContainer} ${styleType}`}
    >
      <span className={`info_box_text ${extraClassNameText}`}>
        {textContent}
      </span>
    </div>
  )
})

InfoBox.propTypes = {
  textContent: PropTypes.string,
  extraClassNameContainer: PropTypes.string,
  extraClassNameText: PropTypes.string,
}

export default memo(InfoBox)
