import React, { memo } from 'react'

const ColorTextAria = (props) => {
  const { styleType, className, children } = props
  return (
    <div className={`text_area_container ${styleType} ${className}`}>
      {children}
    </div>
  )
}

export default memo(ColorTextAria)
