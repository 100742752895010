export const NUM_INVOICE_SORTED_CONS = 'numInvoice'
export const CUSTOMER_INVOICES_SORTED_CONS = 'customer'
export const ISSUE_DATE_INVOICE_SORTED_CONS = 'issueDate'
export const ZIP_CODE_INVOICE_SORTED_CONS = 'zipCode'
export const BILL_TO_INVOICE_SORTED_CONS = 'billTo'
export const REVENUE_INVOICE_SORTED_CONS = 'revenue'
export const TAX_TOTAL_INVOICE_SORTED_CONS = 'taxTotal'
export const EMAILED_INVOICES_SORTED_CONS = 'emailed'
export const TACES_EXIST_INVOICES_SORTED_CONS = 'taxesExist'
export const TAX_EXEMPT_SORTED_CONST = 'taxEx'
export const IS_ERROR_TAX_EXIST = 'isErrorExists'
