import React, { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import userProfileIcon from 'assets/images/user_profile_icon.png'
import settingsProfileIcon from 'assets/images/settings_icon.png'
import LogoutProfileIcon from 'assets/images/logout_icon.png'

import { CLIENT_ROLE } from 'constants/roles-constant'
import { useToggleMenu } from 'custom-hooks/useToggleMenu'
import { getInitialsNamesSelector } from 'redusers/profile'
import {
  showProfileModal,
  showSettingModal,
} from 'actions/modals-state-actions'
import { logOut } from 'actions/profile-actions'
import {
  HEADER_PROFILE_MENU_ITEM_1_LN,
  HEADER_PROFILE_MENU_ITEM_2_LN,
  HEADER_PROFILE_MENU_ITEM_3_LN,
} from 'constants/language-key-constants'
import { useShallowSelector } from 'custom-hooks/useShallowSelector'

const ProfileMenu = (props) => {
  const { roleUser } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const inialsName = useShallowSelector(getInitialsNamesSelector)
  const [isOpenMenu, setIsOpenMenu] = useToggleMenu(false)

  const handleClickToggleMenu = useCallback(
    (event) => {
      event.preventDefault()
      setIsOpenMenu(!isOpenMenu)
    },
    [isOpenMenu, setIsOpenMenu]
  )

  const handleClickLogout = useCallback(
    (event) => {
      event.preventDefault()
      event.nativeEvent.stopImmediatePropagation()
      dispatch(logOut())
    },
    [dispatch]
  )

  const handlerClickUserProfile = useCallback(
    (event) => {
      event.preventDefault()
      dispatch(showProfileModal())
    },
    [dispatch]
  )

  const handleClickSettings = useCallback(
    (event) => {
      event.preventDefault()
      dispatch(showSettingModal())
    },
    [dispatch]
  )

  const activClassName = isOpenMenu ? 'active' : ''

  return (
    <div className={'header_section header_profile_section'}>
      <button
        type="button"
        className={`button_menu ${activClassName}`}
        onClick={handleClickToggleMenu}
      >
        <span className="button_menu_name">{inialsName}</span>
      </button>
      <div className={`menu_container ${activClassName}`}>
        <ul className="menu_wrap">
          <li className="menu_item" onClick={handlerClickUserProfile}>
            <img
              src={userProfileIcon}
              alt="Profile icon"
              className="menu_icon"
            />
            <span>{t(HEADER_PROFILE_MENU_ITEM_1_LN)}</span>
          </li>
          {roleUser === CLIENT_ROLE && (
            <li className="menu_item" onClick={handleClickSettings}>
              <img
                src={settingsProfileIcon}
                alt="Profile icon"
                className="menu_icon"
              />
              <span>{t(HEADER_PROFILE_MENU_ITEM_2_LN)}</span>
            </li>
          )}
          <li className="menu_item logout" onClick={handleClickLogout}>
            <img
              src={LogoutProfileIcon}
              alt="Profile icon"
              className="menu_icon"
            />
            <span>{t(HEADER_PROFILE_MENU_ITEM_3_LN)}</span>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default memo(ProfileMenu)
