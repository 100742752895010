import React from 'react'

const HidePassword = () => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
      <g
        id="Design"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Sign-in" transform="translate(-688.000000, -450.000000)">
          <g id="Group-3" transform="translate(282.000000, 222.000000)">
            <g id="Group-2">
              <g id="Group">
                <g
                  id="text-field-+-counter"
                  transform="translate(28.000000, 192.000000)"
                >
                  <g id="text-field">
                    <g
                      id="Hide_Password_icon"
                      transform="translate(378.000000, 36.000000)"
                    >
                      <rect id="Rectangle" x="0" y="0" width="24" height="24" />
                      <g
                        transform="translate(0.000000, 3.000000)"
                        fill="#757575"
                        fillRule="nonzero"
                        id="Path"
                      >
                        <path d="M14.7991579,6.15962573 C14.604538,5.96500585 14.2881404,5.96500585 14.0935205,6.15962573 C13.8989006,6.35424561 13.8989006,6.67162573 14.0935205,6.86526316 C14.6584327,7.43017544 14.970807,8.1817076 14.970807,8.98212865 C14.970807,10.6328889 13.6274152,11.9762807 11.976655,11.9762807 C11.1762339,11.9762807 10.4247018,11.6648889 9.85978947,11.0989942 C9.66516959,10.9043743 9.34877193,10.9043743 9.15415205,11.0989942 C8.95953216,11.2926316 8.95953216,11.6100117 9.15415205,11.8046316 C9.90666667,12.5591579 10.9097076,12.9743626 11.976655,12.9743626 C14.1783392,12.9743626 15.9688421,11.1838596 15.9688421,8.98217544 C15.9688421,7.91518129 15.5536842,6.91214035 14.7991579,6.15962573 Z" />
                        <path d="M12.6722807,5.05876023 C12.4457076,5.0188538 12.2141754,4.98989474 11.976655,4.98989474 C9.77497076,4.98989474 7.98446784,6.78039766 7.98446784,8.98208187 C7.98446784,9.21960234 8.0134269,9.45118129 8.05431579,9.6777076 C8.09721637,9.91925146 8.30783626,10.0899181 8.54437427,10.0899181 C8.57333333,10.0899181 8.60224561,10.0879064 8.63218713,10.0819181 C8.90264327,10.0340117 9.08430409,9.77450292 9.03639766,9.50404678 C9.00547368,9.33436257 8.98250292,9.16173099 8.98250292,8.98208187 C8.98250292,7.33132164 10.3258947,5.98792982 11.976655,5.98792982 C12.1563041,5.98792982 12.3289825,6.01090058 12.4986199,6.04084211 C12.764117,6.09473684 13.0285848,5.90708772 13.0764912,5.63663158 C13.1243977,5.36617544 12.9427836,5.10666667 12.6722807,5.05876023 Z" />
                        <path d="M23.8344795,8.65974269 C23.7216842,8.5259883 21.0219883,5.37515789 17.2982456,3.44594152 C17.0567018,3.31920468 16.7523275,3.41501754 16.6255439,3.66053801 C16.498807,3.90507602 16.5946199,4.20645614 16.8401404,4.33323977 C19.708538,5.81833918 22.0000468,8.13581287 22.7835322,8.98217544 C21.5938713,10.2706433 16.912,14.9704795 11.976655,14.9704795 C10.3019415,14.9704795 8.61422222,14.566269 6.95845614,13.7678129 C6.71293567,13.6470643 6.41253801,13.7518596 6.29277193,14.0003743 C6.17202339,14.2479064 6.27681871,14.5462924 6.52533333,14.6660585 C8.31681871,15.5313684 10.1512515,15.9685146 11.9767018,15.9685146 C18.1257076,15.9685146 23.6050058,9.57698246 23.8355556,9.30451462 C23.9931696,9.11883041 23.9921871,8.84636257 23.8344795,8.65974269 Z" />
                        <path d="M15.2512749,2.57061988 C14.1155088,2.18835088 13.0136608,1.99574269 11.976655,1.99574269 C5.82764912,1.99574269 0.348350877,8.38727485 0.11780117,8.65974269 C-0.0279298246,8.83139181 -0.0399064327,9.08093567 0.089871345,9.26755556 C0.158736842,9.36636257 1.80851462,11.7087719 4.66792982,13.6080936 C4.75274854,13.6649825 4.84757895,13.6919298 4.94339181,13.6919298 C5.10409357,13.6919298 5.26278363,13.6140819 5.35859649,13.4673684 C5.51129825,13.2388304 5.44842105,12.9284211 5.2188538,12.7767018 C3.11897076,11.3804444 1.68177778,9.69473684 1.14381287,9.00907602 C2.2925848,7.76149708 7.00538012,2.99382456 11.976655,2.99382456 C12.9058246,2.99382456 13.9009123,3.16949708 14.9328655,3.51578947 C15.1943392,3.60959064 15.4778012,3.46488889 15.5646316,3.20238596 C15.6524912,2.94091228 15.5127485,2.65847953 15.2512749,2.57061988 Z" />
                        <path d="M20.8123977,0.146339181 C20.6177778,-0.0482807018 20.3013801,-0.0482807018 20.1067602,0.146339181 L3.13992982,17.1132164 C2.94530994,17.3078363 2.94530994,17.6242339 3.13992982,17.8188538 C3.23775439,17.9156491 3.36547368,17.9645848 3.49323977,17.9645848 C3.62100585,17.9645848 3.74872515,17.9156959 3.84556725,17.8188538 L20.8123977,0.851976608 C21.0070175,0.657356725 21.0070175,0.340959064 20.8123977,0.146339181 Z" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default HidePassword
