import React, { useEffect, memo } from 'react'
import { Field, reduxForm } from 'redux-form'
import { useTranslation, getI18n } from 'react-i18next'
import RadioGroup from '@material-ui/core/RadioGroup'
import InputAdornment from '@material-ui/core/InputAdornment'

import CustomRadioButton from 'components/ui/custom-radio-button'
import Input from 'components/ui/input-component'
import Button from 'components/ui/button-component'
import {
  NO_TYPE_CSI_TYPE_CONST,
  PIU_CSI_TYPE_CONST,
} from 'constants/csi-type-constant'
import {
  SETTINGS_MODAL_PIU_FORM_FIELD_1_LN,
  SETTINGS_MODAL_PIU_FORM_FIELD_2_LN,
  SETTINGS_MODAL_PIU_FORM_FIELD_SAFE_HARBOR_HELPER_1_LN,
  SETTINGS_MODAL_PIU_FORM_FIELD_SAFE_HARBOR_HELPER_2_LN,
  SETTINGS_MODAL_PIU_FORM_BUTTON_LN,
  SETTINGS_MODAL_PIU_FORM_VALID_MESSAGE_1_LN,
  SETTINGS_MODAL_PIU_FORM_VALID_MESSAGE_2_LN,
  SETTINGS_MODAL_PIU_FORM_VALID_MESSAGE_3_LN,
  SETTINGS_MODAL_PIU_FORM_VALID_MESSAGE_4_LN,
} from 'constants/language-key-constants'
import {
  VOIP_SAFE_HARBOR_VALUE,
  WIRELESS_SAFE_HARBOR_VALUE,
} from 'constants/safe-harbor-constant'

const validate = (values) => {
  const errors = {}
  const i18n = getI18n()
  const regExpPiuValue = /^\d{1,3}(\.\d{1,2})?$/

  if (!values.piuValue) {
    errors.piuValue = i18n.t(SETTINGS_MODAL_PIU_FORM_VALID_MESSAGE_1_LN)
  } else if (!regExpPiuValue.test(values.piuValue)) {
    errors.piuValue = i18n.t(SETTINGS_MODAL_PIU_FORM_VALID_MESSAGE_2_LN)
  } else if (Number(values.piuValue) > 200) {
    errors.piuValue = i18n.t(SETTINGS_MODAL_PIU_FORM_VALID_MESSAGE_3_LN)
  } else if (Number(values.piuValue) < 1) {
    errors.piuValue = i18n.t(SETTINGS_MODAL_PIU_FORM_VALID_MESSAGE_4_LN)
  }

  return errors
}

const radioGroupPiu = ({ input, t, ...rest }) => {
  return (
    <RadioGroup {...input} {...rest}>
      <CustomRadioButton
        value={PIU_CSI_TYPE_CONST}
        label={t(SETTINGS_MODAL_PIU_FORM_FIELD_1_LN)}
        className="piu_radio_button"
      />
      <CustomRadioButton
        value={NO_TYPE_CSI_TYPE_CONST}
        label={t(SETTINGS_MODAL_PIU_FORM_FIELD_2_LN)}
      />
    </RadioGroup>
  )
}

const PiuForm = (props) => {
  const {
    handleSubmit,
    piuValues,
    change,
    csiValue,
    isPiuFetching,
    isDisabledUpdateButton,
  } = props
  const { t } = useTranslation()
  const numFormat = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
  })

  const handleBlurInputPiu = (e) => {
    e.preventDefault()
    if (!isNaN(Number(piuValues.piuValue))) {
      change('piuValue', numFormat.format(piuValues.piuValue))
    }
  }

  useEffect(() => {
    if (
      piuValues.piuRadioButton === NO_TYPE_CSI_TYPE_CONST &&
      !csiValue &&
      piuValues.piuValue
    ) {
      change('piuValue', '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [piuValues])

  return (
    <form className="piu_form" onSubmit={handleSubmit}>
      <span className="radio_grout_wrap">
        <Field
          name="piuRadioButton"
          component={radioGroupPiu}
          className="piu_radio_group"
          t={t}
        />
        {piuValues.piuRadioButton === PIU_CSI_TYPE_CONST && (
          <Input
            classNameContainer={'piu_input_container show-animated'}
            classNameInput={'piu_input'}
            type="text"
            name="piuValue"
            showValidMessage={true}
            onBlur={handleBlurInputPiu}
            InputProps={{
              endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
          />
        )}
        {piuValues.piuRadioButton === NO_TYPE_CSI_TYPE_CONST && (
          <div className="safe_harbor_info show-animated">
            <span className="safe_harbor_info_item">
              <Input
                classNameContainer={'safe_harbor_info_input show-animated'}
                //classNameInput={'piu_input'}
                type="text"
                name="piuValue2"
                disabled={true}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                  value: VOIP_SAFE_HARBOR_VALUE,
                }}
              />
              <span className="safe_harbor_info_label">
                {t(SETTINGS_MODAL_PIU_FORM_FIELD_SAFE_HARBOR_HELPER_1_LN)}
              </span>
            </span>
            <span className="safe_harbor_info_item">
              <Input
                classNameContainer={'safe_harbor_info_input show-animated'}
                //classNameInput={'piu_input'}
                type="text"
                name="piuValue4"
                disabled={true}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                  value: WIRELESS_SAFE_HARBOR_VALUE,
                }}
              />
              <span className="safe_harbor_info_label">
                {t(SETTINGS_MODAL_PIU_FORM_FIELD_SAFE_HARBOR_HELPER_2_LN)}
              </span>
            </span>
          </div>
        )}
      </span>
      <Button
        name={t(SETTINGS_MODAL_PIU_FORM_BUTTON_LN)}
        classNameContainer="update_piu_button_container"
        type="submit"
        styletype="green"
        disabled={isPiuFetching || isDisabledUpdateButton}
        isSpinner={isPiuFetching}
      />
    </form>
  )
}

export default reduxForm({
  form: 'piuForm',
  validate,
})(memo(PiuForm))
