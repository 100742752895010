import {
  START_GETTING_FIREBASE_NOTIFICATIONS,
  SET_FIREBASE_TOKEN,
  SET_FIREBASE_ERROR,
  SET_FIREBASE_PERMISSION_STATUS,
  HANDLE_FIREBASE_NOTIFICATION,
  GET_FIREBASE_NOTIFICATION,
} from 'constants/actions-constants'

export const startGettingFirebaseNotifications = () => ({
  type: START_GETTING_FIREBASE_NOTIFICATIONS,
})

export const setFirebaseToken = (payload) => ({
  type: SET_FIREBASE_TOKEN,
  payload,
})

export const setFirebaseError = (payload) => ({
  type: SET_FIREBASE_ERROR,
  payload,
})

export const setFirebasePermissionStatus = (payload) => ({
  type: SET_FIREBASE_PERMISSION_STATUS,
  payload,
})

export const getFirebaseNotification = () => ({
  type: GET_FIREBASE_NOTIFICATION,
})

export const handleFirebaseNotification = (payload) => {
  return { type: HANDLE_FIREBASE_NOTIFICATION, payload }
}
