import { put, take, call, takeEvery, select } from 'redux-saga/effects'
import { eventChannel, END } from 'redux-saga'

import api from 'api'
import {
  START_GETTING_FIREBASE_NOTIFICATIONS,
  HANDLE_FIREBASE_NOTIFICATION,
  GET_FIREBASE_NOTIFICATION,
} from 'constants/actions-constants'
import {
  requestFirebaseNotificationPermission,
  messaging,
} from 'service/firebaseInit'
import {
  setFirebaseToken,
  setFirebasePermissionStatus,
  setFirebaseError,
  handleFirebaseNotification,
  getFirebaseNotification,
} from 'actions/firebase-notification-actions'
import { getNotificationsFromBackEnd } from 'actions/notification-actions'
import {
  getBatchDetailById,
  setIsProcessedBatchId,
} from 'actions/batches-actions'
import { getInvoices } from 'actions/invoices'
import {
  FIREBASE_ALLOW_STATUS_CONSTANT,
  FIREBASE_BLOCK_STATUS_CONSTANT,
} from 'constants/firebase-constant'
import {
  BATCH_PROCESSED_NOTIFICATION_TYPE,
  BATCH_PROCESSING_STARTED_NOTIFICATION_TYPE,
} from 'constants/notifications-type-constants'
import {
  PROCESSING_INVOICES_MODAL_NAME,
  PROCESSED_BATCH_MODAL_NAME,
} from 'constants/modal-names-constants'
import { getCurrentShownModalNameSelector } from 'redusers/modalsState'
import { getBatchDetailIdSelector } from 'redusers/batches'

function* getFirebaseTokenSaga() {
  try {
    const token = yield call(requestFirebaseNotificationPermission)
    if (token) {
      yield put(setFirebaseToken(token))
      yield put(setFirebasePermissionStatus(FIREBASE_ALLOW_STATUS_CONSTANT))
      yield put(getFirebaseNotification())
      const URL = `devices/?registration_id=${token}`
      yield call([api, 'registerDevicesApi'], URL)
    }
  } catch (error) {
    const { code } = error
    yield put(setFirebasePermissionStatus(FIREBASE_BLOCK_STATUS_CONSTANT))
    if (code) {
      yield put(setFirebaseError(code))
    }
  }
}

const onMessageListener = () => {
  return eventChannel((emitter) => {
    const unsubscribe = messaging.onMessage((notification) => {
      if (notification) {
        emitter(notification)
      } else {
        emitter(END)
      }
    })
    return unsubscribe
  })
}

function* getFirebaseNotificationSaga() {
  const channel = yield call(onMessageListener)
  try {
    while (true) {
      const notification = yield take(channel)
      yield put(handleFirebaseNotification(notification))
    }
  } catch (error) {
    /// to do
    //console.dir(error)
  }
}

function* handleFirebaseNotificationSaga({ payload }) {
  try {
    const currentShowModal = yield select(getCurrentShownModalNameSelector)
    const currentBatchIdOpened = yield select(getBatchDetailIdSelector)
    const { data } = payload
    const notificationType = data?.type
    const notificationObjectId = data?.object_id
    if (
      notificationType === BATCH_PROCESSED_NOTIFICATION_TYPE ||
      notificationType === BATCH_PROCESSING_STARTED_NOTIFICATION_TYPE
    ) {
      yield put(getNotificationsFromBackEnd())
    }
    if (notificationType === BATCH_PROCESSED_NOTIFICATION_TYPE) {
      switch (currentShowModal) {
        case PROCESSING_INVOICES_MODAL_NAME:
          yield put(setIsProcessedBatchId(notificationObjectId))
          break
        case PROCESSED_BATCH_MODAL_NAME:
          if (currentBatchIdOpened === notificationObjectId) {
            yield put(getBatchDetailById(notificationObjectId))
            //ref ?
          }
          break
        default:
          break
      }
      yield put(getInvoices())
    }
  } catch (error) {
    /// to do
    // console.dir(error)
  }
}

export function* watchFireBaseNotivicationSagas() {
  yield takeEvery(START_GETTING_FIREBASE_NOTIFICATIONS, getFirebaseTokenSaga)
  yield takeEvery(GET_FIREBASE_NOTIFICATION, getFirebaseNotificationSaga)
  yield takeEvery(HANDLE_FIREBASE_NOTIFICATION, handleFirebaseNotificationSaga)
}
