import React, { memo, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import Tooltip from '@material-ui/core/Tooltip'

import { useShallowSelector } from 'custom-hooks/useShallowSelector'
import {
  getTaxSummaryReports,
  clearTaxSummaryReport,
  clearSearchValuesReports,
} from 'actions/reports-actions'
import Title from 'components/ui/title-component'
import InfoBox from 'components/ui/infobox-component'
import Spinner from 'components/ui/spinner'
import { useSortedData } from 'custom-hooks/useSort'
import Cell from './Cell'
import {
  getIssueDatetimeFromSelector,
  getIssueDatetimeToSelector,
  getTaxSummaryReportsSelector,
  getIsFetchinReportsSelector,
  getSumTotalsTaxAmountSelector,
  getMTSreportErrorMessageSelector,
} from 'redusers/reports'
import { getInvoiceDetailFromBackEnd } from 'actions/invoice-detail-actions'
import { UPDATE_PAGINATION_CONSTANT } from 'constants/pagination-constants'
import {
  MONTHLY_TAX_SUMMARY_REPORT_TITLE_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_1_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_2_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_3_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_4_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_5_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_6_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_7_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_8_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_9_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_10_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_11_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_12_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_13_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_14_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_15_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_16_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_17_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_18_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_20_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_21_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_INFO_BOX_LN,
} from 'constants/language-key-constants'

let _prevScrollHeight = 0

const MonthlyTaxSummaryReport = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const reports = useShallowSelector(getTaxSummaryReportsSelector)
  const isFetching = useShallowSelector(getIsFetchinReportsSelector)
  const fromDate = useShallowSelector(getIssueDatetimeFromSelector)
  const toDate = useShallowSelector(getIssueDatetimeToSelector)
  const sumTotalTaxAmount = useShallowSelector(getSumTotalsTaxAmountSelector)
  const errorMessage = useShallowSelector(getMTSreportErrorMessageSelector)

  const [sortedData, sortedKey, handleSortClick] = useSortedData(
    'geocode',
    reports
  )

  const handleUpdate = (e) => {
    const value = e.target
    if (
      value?.clientHeight >= value?.scrollHeight - value?.scrollTop &&
      _prevScrollHeight !== value?.scrollTop
    ) {
      dispatch(getTaxSummaryReports(UPDATE_PAGINATION_CONSTANT))
    }
    _prevScrollHeight = value?.scrollTop
  }

  const handleClickInvoiceDetail = useCallback(
    (e) => {
      const invoiceId = e.currentTarget.dataset.invoiceId
      const modalType = e.currentTarget.dataset.invoiceModal
      dispatch(
        getInvoiceDetailFromBackEnd({ id: invoiceId, modalName: modalType })
      )
    },
    [dispatch]
  )

  useEffect(() => {
    dispatch(getTaxSummaryReports())
    return () => {
      _prevScrollHeight = 0
      dispatch(clearTaxSummaryReport())
      dispatch(clearSearchValuesReports())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fromDateText = moment(fromDate).format('M/D/YYYY h:mm A')

  const toDateText = moment(toDate).format('M/D/YYYY h:mm A')

  const usdFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    minimumFractionDigits: 2,
    currency: 'USD',
  })

  return (
    <div className="tax_summary_report_container container">
      <div className="title_section">
        <Title
          title={t(MONTHLY_TAX_SUMMARY_REPORT_TITLE_LN)}
          extraClassName="tax_summary_report_title"
          component="h1"
        />
        <span className="tax_summary_report_date_range_label">{`${fromDateText} - ${toDateText}`}</span>
      </div>
      <div className="table_section">
        {reports?.length ? (
          <div
            className="tax_summary_report_table_scroll_container"
            onScroll={handleUpdate}
          >
            <table className="tax_summary_report_table" cols="20">
              <thead className="tax_summary_report_table_head">
                <tr className="tax_summary_report_table_row header">
                  <Cell
                    dataSortKey={'geocode'}
                    defaultsortedkey={'geocode'}
                    text={t(MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_1_LN)}
                    chosenSortedKey={sortedKey}
                    handleSortClick={handleSortClick}
                  />
                  <Cell
                    dataSortKey={'tax_auth'}
                    text={t(MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_2_LN)}
                    chosenSortedKey={sortedKey}
                    handleSortClick={handleSortClick}
                  />
                  <Cell
                    dataSortKey={'report_to_id'}
                    text={t(MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_3_LN)}
                    chosenSortedKey={sortedKey}
                    handleSortClick={handleSortClick}
                  />
                  <Cell
                    dataSortKey={'report_to'}
                    text={t(MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_4_LN)}
                    chosenSortedKey={sortedKey}
                    handleSortClick={handleSortClick}
                  />
                  <Cell
                    dataSortKey={'tax_auth_type'}
                    text={t(MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_5_LN)}
                    chosenSortedKey={sortedKey}
                    handleSortClick={handleSortClick}
                  />
                  <Cell
                    dataSortKey={'customer_id'}
                    text={t(MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_6_LN)}
                    chosenSortedKey={sortedKey}
                    handleSortClick={handleSortClick}
                  />
                  <Cell
                    dataSortKey={'doc_number'}
                    text={t(MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_7_LN)}
                    chosenSortedKey={sortedKey}
                    handleSortClick={handleSortClick}
                  />
                  <Cell
                    dataSortKey={'type'}
                    text={'Type'}
                    chosenSortedKey={sortedKey}
                    handleSortClick={handleSortClick}
                  />
                  <Cell
                    dataSortKey={'transaction_date'}
                    text={t(MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_8_LN)}
                    chosenSortedKey={sortedKey}
                    handleSortClick={handleSortClick}
                  />
                  <Cell
                    dataSortKey={'tax_type'}
                    text={t(MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_9_LN)}
                    chosenSortedKey={sortedKey}
                    handleSortClick={handleSortClick}
                  />
                  <Cell
                    dataSortKey={'tax_cat'}
                    text={t(MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_10_LN)}
                    chosenSortedKey={sortedKey}
                    handleSortClick={handleSortClick}
                  />
                  <Cell
                    dataSortKey={'product_code'}
                    text={t(MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_11_LN)}
                    chosenSortedKey={sortedKey}
                    handleSortClick={handleSortClick}
                  />
                  <Cell
                    dataSortKey={'service_code'}
                    text={t(MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_12_LN)}
                    chosenSortedKey={sortedKey}
                    handleSortClick={handleSortClick}
                  />
                  <Cell
                    dataSortKey={'pass_flag'}
                    text={t(MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_13_LN)}
                    chosenSortedKey={sortedKey}
                    handleSortClick={handleSortClick}
                  />
                  <Cell
                    dataSortKey={'tax_description'}
                    text={t(MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_14_LN)}
                    chosenSortedKey={sortedKey}
                    handleSortClick={handleSortClick}
                  />
                  <Cell
                    dataSortKey={'tax_rate_or_fee'}
                    text={t(MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_15_LN)}
                    chosenSortedKey={sortedKey}
                    handleSortClick={handleSortClick}
                  />
                  <Cell
                    dataSortKey={'original_charge_amount'}
                    text={t(MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_16_LN)}
                    chosenSortedKey={sortedKey}
                    handleSortClick={handleSortClick}
                  />
                  <Cell
                    dataSortKey={'exempt_amount'}
                    text={t(MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_17_LN)}
                    chosenSortedKey={sortedKey}
                    handleSortClick={handleSortClick}
                  />
                  <Cell
                    dataSortKey={'charge_amount'}
                    text={t(MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_18_LN)}
                    chosenSortedKey={sortedKey}
                    handleSortClick={handleSortClick}
                  />
                  <Cell
                    dataSortKey={'lines'}
                    text={t(MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_21_LN)}
                    chosenSortedKey={sortedKey}
                    handleSortClick={handleSortClick}
                    title={
                      'This column is for reporting line-based taxes and fees and not related to QuickBooks quantity.'
                    }
                  />
                  <Cell
                    dataSortKey={'tax_amount'}
                    text={t(MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_20_LN)}
                    chosenSortedKey={sortedKey}
                    handleSortClick={handleSortClick}
                  />
                </tr>
              </thead>
              <tbody className="tax_summary_report_table_body">
                {sortedData.map((report, index) => {
                  const {
                    geocode,
                    tax_auth,
                    report_to_id,
                    report_to,
                    tax_auth_type,
                    customer_id,
                    // invoice_number,// change to transaction_date
                    doc_number,
                    //invoice_date, // change to transaction_date
                    transaction_date,
                    tax_type,
                    tax_cat,
                    product_code,
                    service_code,
                    pass_flag,
                    tax_description,
                    tax_rate_or_fee,
                    original_charge_amount,
                    exempt_amount,
                    charge_amount,
                    tax_amount,
                    _id: transaction_id,
                    lines,
                    type,
                  } = report

                  return (
                    <tr key={index} className="tax_summary_report_table_row">
                      <td text={geocode} className="report_cell geocode">
                        <span>{geocode}</span>
                      </td>
                      <td
                        //text={tax_auth}
                        className="report_cell authority_name"
                      >
                        <Tooltip title={tax_auth || ''}>
                          <span>{tax_auth}</span>
                        </Tooltip>
                      </td>
                      <td
                        //text={report_to_id}
                        className="report_cell report_to_id"
                      >
                        <span>{report_to_id}</span>
                      </td>
                      <td text={report_to} className="report_cell report_to">
                        <Tooltip title={report_to || ''}>
                          <span>{report_to}</span>
                        </Tooltip>
                      </td>
                      <td
                        text={tax_auth_type}
                        className="report_cell tax_auth_type"
                      >
                        <span>{tax_auth_type}</span>
                      </td>
                      <td
                        text={customer_id}
                        className="report_cell customer_number"
                      >
                        <span>{customer_id}</span>
                      </td>
                      <td
                        text={doc_number}
                        className="report_cell invoice_number"
                      >
                        <button
                          className="button_cell"
                          data-invoice-id={transaction_id}
                          data-invoice-modal={'invoice_detail'}
                          onClick={handleClickInvoiceDetail}
                        >
                          {doc_number}
                        </button>
                      </td>
                      <td
                        text={type?.toUpperCase()}
                        className="report_cell invoice_date"
                      >
                        <span>{type?.toUpperCase()}</span>
                      </td>
                      <td
                        text={transaction_date}
                        className="report_cell invoice_date"
                      >
                        <span>{transaction_date}</span>
                      </td>
                      <td text={tax_type} className="report_cell tax_type">
                        <span>{tax_type}</span>
                      </td>
                      <td text={tax_cat} className="report_cell tax_cat">
                        <span>{tax_cat}</span>
                      </td>
                      <td
                        text={product_code}
                        className="report_cell group_code"
                      >
                        <span>{product_code}</span>
                      </td>
                      <td text={service_code} className="report_cell item_code">
                        <span>{service_code}</span>
                      </td>
                      <td text={pass_flag} className="report_cell pass_flag">
                        <span>{pass_flag}</span>
                      </td>
                      <td
                        text={tax_description}
                        className="report_cell tax_description"
                      >
                        <Tooltip title={tax_description || ''}>
                          <span>{tax_description}</span>
                        </Tooltip>
                      </td>
                      <td
                        text={tax_rate_or_fee}
                        className="report_cell tax_rate_or_fee"
                      >
                        <span>{tax_rate_or_fee}</span>
                      </td>
                      <td
                        text={original_charge_amount}
                        className="report_cell original_charge_amount currency"
                      >
                        <span className="currency_content ">
                          {usdFormat.format(original_charge_amount)}
                        </span>
                      </td>
                      <td
                        text={exempt_amount}
                        className="report_cell exempt_amount currency"
                      >
                        <span className="currency_content ">
                          {usdFormat.format(exempt_amount)}
                        </span>
                      </td>
                      <td
                        text={charge_amount}
                        className="report_cell charge_amount currency"
                      >
                        <span className="currency_content ">
                          {usdFormat.format(charge_amount)}
                        </span>
                      </td>
                      <td text={lines} className="report_cell units ">
                        <span>{lines}</span>
                      </td>
                      <td
                        text={tax_amount}
                        className="report_cell tax_amount currency"
                      >
                        <span className="currency_content ">
                          {usdFormat.format(tax_amount)}
                        </span>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        ) : errorMessage ? (
          <InfoBox textContent={errorMessage} />
        ) : (
          !isFetching && (
            <InfoBox
              textContent={t(MONTHLY_TAX_SUMMARY_REPORT_TABLE_INFO_BOX_LN)}
            />
          )
        )}
      </div>
      {!!reports?.length && (
        <div className="sum_total_tax_container">
          <span className="sum_total_tax_message">
            Please send a monthly CSV export of this report to{` `}
            <a
              href="mailto:revenue@csilongwood.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              revenue@csilongwood.com
            </a>
          </span>
          <span className="sum_total_tax_title">
            Total Tax Amount = {usdFormat.format(sumTotalTaxAmount)}
          </span>
        </div>
      )}
      {isFetching && <Spinner styleSpinner="green" />}
    </div>
  )
}

export default memo(MonthlyTaxSummaryReport)
