import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { registerServiceWorker } from './serviceWorker'
import './styles/styles.scss'
import store from 'store'
import App from 'components/App'

import './i18n/i18n'

ReactDOM.render(
  <BrowserRouter basename="/">
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
)

registerServiceWorker()
