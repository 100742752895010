import React, { memo } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { useShallowSelector } from 'custom-hooks/useShallowSelector'
import { getRoleUserSelector } from 'redusers/profile'
import { ADMIN_ROLE, CLIENT_ROLE } from 'constants/roles-constant'
//import BatchesPage from 'pages/batches-page'
import ReportsPage from 'pages/reports-page'
import LogsPage from 'pages/logs-page'
import UsersPage from 'pages/users-page'
import QuickBooksConnect from 'pages/quick-books-connect'
import CompaniesPage from 'pages/companies-page'
import CompanyBatchesPage from 'pages/company-batches-page'
import InvoicesPage from 'pages/invoices-page'

const MainRoutes = () => {
  const roleUser = useShallowSelector(getRoleUserSelector)
  return (
    <>
      {roleUser === ADMIN_ROLE && (
        <Switch>
          <Route path="/companies" exact>
            <CompaniesPage />
          </Route>
          <Route path="/companies/transactions" exact>
            <CompanyBatchesPage />
          </Route>
          <Route path="/logs" exact>
            <LogsPage />
          </Route>
          <Route path="/users" exact>
            <UsersPage />
          </Route>
          <Redirect from="/" to="/companies" />
        </Switch>
      )}
      {roleUser === CLIENT_ROLE && (
        <Switch>
          <Route path="/transactions" exact>
            <InvoicesPage />
          </Route>
          <Route path="/reports">
            <ReportsPage />
          </Route>
          <Route path="/quick-books-connect" exact>
            <QuickBooksConnect />
          </Route>
          <Redirect from="/" to="/transactions" />
        </Switch>
      )}
    </>
  )
}

export default memo(MainRoutes)
