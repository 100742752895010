import { put, takeEvery, select } from 'redux-saga/effects'

import { GET_INVOICES_DETAIL_FROM_PROCESSED_INVOICES } from 'constants/actions-constants'
import { setInvoiceDetail } from 'actions/invoice-detail-actions'

function* getProcessedInvoiceDetailWorker({ payload: id }) {
  try {
    const invoices = yield select(
      (state) => state.batches.batchDetails.invoices
    )
    const invoice = invoices.find((invoice) => invoice._id === id)
    yield put(setInvoiceDetail(invoice || {}))
  } catch (error) {
    //to do
  } finally {
    //to do
  }
}

export function* watchProcessedInvoiceDetailWorkers() {
  yield takeEvery(
    GET_INVOICES_DETAIL_FROM_PROCESSED_INVOICES,
    getProcessedInvoiceDetailWorker
  )
}
