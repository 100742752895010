export const DEFAULT_CONFIRAMATION_MODAL_TITLE_LN =
  'DEFAULT_CONFIRAMATION_MODAL_TITLE_LN'
export const SIGN_IN_LN = 'SIGN_IN_LN'
export const EMAIL_LN = 'EMAIL_LN'
export const PASSWORD_LN = 'PASSWORD_LN'
export const CANT_ACCESS_YOUR_ACCOUNT_LN = 'CANT_ACCESS_YOUR_ACCOUNT_LN'
export const PLACEHOLDER_BY_USERS_SEARCH_INPUT_LN =
  'PLACEHOLDER_BY_USERS_SEARCH_INPUT_LN'
export const ADD_NEW_USER_BUTTON_LN = 'ADD_NEW_USER_BUTTON_LN'
export const PLACEHOLDER_BY_ADMIN_BATCHES_SEARCH_INPUT_LN =
  'PLACEHOLDER_BY_ADMIN_BATCHES_SEARCH_INPUT_LN'
export const PLACEHOLDER_BY_CLIENT_BATCHES_SEARCH_INPUT_LN =
  'PLACEHOLDER_BY_CLIENT_BATCHES_SEARCH_INPUT_LN'
export const NEW_BATCH_BUTTON_NAME_LN = 'NEW_BATCH_BUTTON_NAME_LN'
export const TOOLTIP_BATCH_TITLE_LN = 'TOOLTIP_BATCH_TITLE_LN'
export const COUNT_BATCH_TEXT_LN = 'COUNT_BATCH_TEXT_LN'
export const BATCH_CELL_NAME_1_LN = 'BATCH_CELL_NAME_1_LN'
export const BATCH_CELL_NAME_2_LN = 'BATCH_CELL_NAME_2_LN'
export const BATCH_CELL_NAME_3_LN = 'BATCH_CELL_NAME_3_LN'
export const BATCH_CELL_NAME_4_LN = 'BATCH_CELL_NAME_4_LN'
export const BATCH_CELL_NAME_5_LN = 'BATCH_CELL_NAME_5_LN'
export const BATCH_CELL_NAME_6_LN = 'BATCH_CELL_NAME_6_LN'
export const BATCH_CELL_NAME_7_LN = 'BATCH_CELL_NAME_7_LN'
export const BATCH_CELL_NAME_8_LN = 'BATCH_CELL_NAME_8_LN'
export const CSI_ERROR_LN = 'CSI_ERROR_LN'
export const BATCHES_TITLE_LN = 'BATCHES_TITLE_LN'
export const QB_DISCONNECT_MESSAGE_ON_BATCHES_PAGE_LN =
  'QB_DISCONNECT_MESSAGE_ON_BATCHES_PAGE_LN'
export const BATCH_TITLE_LN = 'BATCH_TITLE_LN'
export const BATCH_TITLE_PROCESS_DATE_LN = 'BATCH_TITLE_PROCESS_DATE_LN'
export const BATCH_COMPANY_TITLE_LN = 'BATCH_COMPANY_TITLE_LN'
export const SING_IN_QB_CONNECT_MODAL_TITLE_LN =
  'SING_IN_QB_CONNECT_MODAL_TITLE_LN'
export const SING_IN_QB_CONNECT_MODAL_TOP_MESSAGE_LN =
  'SING_IN_QB_CONNECT_MODAL_TOP_MESSAGE_LN'
export const SING_IN_QB_CONNECT_MODAL_BOTTOM_MESSAGE_LN =
  'SING_IN_QB_CONNECT_MODAL_BOTTOM_MESSAGE_LN'
export const SING_IN_QB_CONNECT_MODAL_BUTTON_CONNECT_LN =
  'SING_IN_QB_CONNECT_MODAL_BUTTON_CONNECT_LN'
export const SKIP_BUTTON_NAME_LN = 'SKIP_BUTTON_NAME_LN'
export const SING_IN_CSI_CONNECT_MODAL_TOP_MESSAGE_LN =
  'SING_IN_CSI_CONNECT_MODAL_TOP_MESSAGE_LN'
export const SING_IN_CSI_CONNECT_MODAL_BOTTOM_MESSAGE_LN =
  'SING_IN_CSI_CONNECT_MODAL_BOTTOM_MESSAGE_LN'
export const SING_IN_CSI_CONNECT_MODAL_BUTTON_CONNECT_LN =
  'SING_IN_CSI_CONNECT_MODAL_BUTTON_CONNECT_LN'
export const EXPORT_CONFIRMATION_MODAL_TITLE_LN =
  'EXPORT_CONFIRMATION_MODAL_TITLE_LN'
export const EXPORT_CONFIRMATION_MODAL_MESSAGE_LN =
  'EXPORT_CONFIRMATION_MODAL_MESSAGE_LN'
export const CLOSE_BUTTON_NAME_LN = 'CLOSE_BUTTON_NAME_LN'
export const HEADER_BATCHES_ITEM_MENU_LN = 'HEADER_BATCHES_ITEM_MENU_LN'
export const HEADER_INVOICES_ITEM_MENU_LN = 'HEADER_INVOICES_ITEM_MENU_LN'
export const HEADER_REPORTS_ITEM_MENU_LN = 'HEADER_REPORTS_ITEM_MENU_LN'
export const HEADER_LOGS_ITEM_MENU_LN = 'HEADER_LOGS_ITEM_MENU_LN'
export const HEADER_USERS_ITEM_MENU_LN = 'HEADER_USERS_ITEM_MENU_LN'
export const HEADER_PROFILE_MENU_ITEM_1_LN = 'HEADER_PROFILE_MENU_ITEM_1_LN'
export const HEADER_PROFILE_MENU_ITEM_2_LN = 'HEADER_PROFILE_MENU_ITEM_2_LN'
export const HEADER_PROFILE_MENU_ITEM_3_LN = 'HEADER_PROFILE_MENU_ITEM_3_LN'
export const INVOICE_DETAIL_TITLE_PART_1_LN = 'INVOICE_DETAIL_TITLE_PART_1_LN'
export const INVOICE_DETAIL_TITLE_PART_2_LN = 'INVOICE_DETAIL_TITLE_PART_2_LN'
export const INVOICE_DETAIL_CUSTOMER_LN = 'INVOICE_DETAIL_CUSTOMER_LN'
export const INVOICE_DETAIL_EMAIL_LN = 'INVOICE_DETAIL_EMAIL_LN'
export const INVOICE_DETAIL_INVOICE_DATE_LN = 'INVOICE_DETAIL_INVOICE_DATE_LN'
export const INVOICE_DETAIL_ADRESS_LN = 'INVOICE_DETAIL_ADRESS_LN'
export const INVOICE_DETAIL_CITY_LN = 'INVOICE_DETAIL_CITY_LN'
export const INVOICE_DETAIL_STATE_LN = 'INVOICE_DETAIL_STATE_LN'
export const INVOICE_DETAIL_POSTAL_CODE_LN = 'INVOICE_DETAIL_POSTAL_CODE_LN'
export const DISABLED_EXPORT_SCV_TOOLTIP_LN = 'DISABLED_EXPORT_SCV_TOOLTIP_LN'
export const INVOICE_DETALT_TABLE_COUNT_TITLE_LN =
  'INVOICE_DETALT_TABLE_COUNT_TITLE_LN'
export const INVOICE_DETALT_EXPORT_CSV_BUTTON_LN =
  'INVOICE_DETALT_EXPORT_CSV_BUTTON_LN'
export const INVOICE_DETAIL_TOTAL_LN = 'INVOICE_DETAIL_TOTAL_LN'
export const INVOICE_DETALT_TABLE_CELL_1_TITLE_LN =
  'INVOICE_DETALT_TABLE_CELL_1_TITLE_LN'
export const INVOICE_DETALT_TABLE_CELL_2_TITLE_LN =
  'INVOICE_DETALT_TABLE_CELL_2_TITLE_LN'
export const INVOICE_DETALT_TABLE_CELL_3_TITLE_LN =
  'INVOICE_DETALT_TABLE_CELL_3_TITLE_LN'
export const INVOICE_DETALT_TABLE_CELL_4_TITLE_LN =
  'INVOICE_DETALT_TABLE_CELL_4_TITLE_LN'
export const INVOICE_DETALT_TABLE_CELL_5_TITLE_LN =
  'INVOICE_DETALT_TABLE_CELL_5_TITLE_LN'
export const DEFAULT_CONFIRAMATION_MODAL_BUTTON_YES_LN =
  'DEFAULT_CONFIRAMATION_MODAL_BUTTON_YES_LN'
export const DEFAULT_CONFIRAMATION_MODAL_BUTTON_NO_LN =
  'DEFAULT_CONFIRAMATION_MODAL_BUTTON_NO_LN'
export const MAP_PRODUCT_TITLE_1_LN = 'MAP_PRODUCT_TITLE_1_LN'
export const MAP_PRODUCT_TITLE_2_LN = 'MAP_PRODUCT_TITLE_2_LN'
export const MAP_PRODUCT_TITLE_TOOLTIP_LN = 'MAP_PRODUCT_TITLE_TOOLTIP_LN'
export const MAP_PRODUCT_MESSAGE_1_LN = 'MAP_PRODUCT_MESSAGE_1_LN'
export const MAP_PRODUCT_MESSAGE_2_LN = 'MAP_PRODUCT_MESSAGE_2_LN'
export const MAP_PRODUCT_MESSAGE_3_LN = 'MAP_PRODUCT_MESSAGE_3_LN'
export const MAP_PRODUCT_MESSAGE_4_LN = 'MAP_PRODUCT_MESSAGE_4_LN'
export const MAP_PRODUCT_SECTION_TITLE_1_LN = 'MAP_PRODUCT_SECTION_TITLE_1_LN'
export const MAP_PRODUCT_SECTION_PLACEHOLDER_1_LN =
  'MAP_PRODUCT_SECTION_PLACEHOLDER_1_LN'
export const MAP_PRODUCT_SECTION_TITLE_2_LN = 'MAP_PRODUCT_SECTION_TITLE_2_LN'
export const MAP_PRODUCT_SECTION_PLACEHOLDER_2_LN =
  'MAP_PRODUCT_SECTION_PLACEHOLDER_2_LN'
export const MAP_PRODUCT_BUTTON_NAME_1_LN = 'MAP_PRODUCT_BUTTON_NAME_1_LN'
export const MAP_PRODUCT_BUTTON_NAME_2_LN = 'MAP_PRODUCT_BUTTON_NAME_2_LN'
export const MAPPED_PRODUCT_TITLE_LN = 'MAPPED_PRODUCT_TITLE_LN'
export const MAPPED_PRODUCT_INFO_MESSAGE_LN = 'MAPPED_PRODUCT_INFO_MESSAGE_LN'
export const MAPPED_PRODUCT_TABLE_COUNT_TITLE_LN =
  'MAPPED_PRODUCT_TABLE_COUNT_TITLE_LN'
export const MAPPED_PRODUCT_BUTTON_NAME_1_LN = 'MAPPED_PRODUCT_BUTTON_NAME_1_LN'
export const MAPPED_PRODUCT_TABLE_CELL_TITLE_1_LN =
  'MAPPED_PRODUCT_TABLE_CELL_TITLE_1_LN'
export const MAPPED_PRODUCT_TABLE_CELL_TITLE_2_LN =
  'MAPPED_PRODUCT_TABLE_CELL_TITLE_2_LN'
export const MAPPED_PRODUCT_TABLE_CELL_TITLE_3_LN =
  'MAPPED_PRODUCT_TABLE_CELL_TITLE_3_LN'
export const NEW_BATCH_MODAL_TOOLTIP_LN = 'NEW_BATCH_MODAL_TOOLTIP_LN'
export const NEW_BATCH_MODAL_INFOBOX_MESSAGE_1_LN =
  'NEW_BATCH_MODAL_INFOBOX_MESSAGE_1_LN'
export const NEW_BATCH_MODAL_INFOBOX_MESSAGE_2_LN =
  'NEW_BATCH_MODAL_INFOBOX_MESSAGE_2_LN'
export const NEW_BATCH_MODAL_TITLE_LN = 'NEW_BATCH_MODAL_TITLE_LN'
export const NEW_BATCH_MODAL_INFOBOX_MESSAGE_3_LN =
  'NEW_BATCH_MODAL_INFOBOX_MESSAGE_3_LN'
export const NEW_BATCH_MODAL_DATE_FROM_LN = 'NEW_BATCH_MODAL_DATE_FROM_LN'
export const NEW_BATCH_MODAL_DATE_TO_LN = 'NEW_BATCH_MODAL_DATE_TO_LN'
export const NEW_BATCH_MODAL_DATE_TO_MESSAGE_LN =
  'NEW_BATCH_MODAL_DATE_TO_MESSAGE_LN'
export const NEW_BATCH_MODAL_BUTTON_TOOLTIP_LN =
  'NEW_BATCH_MODAL_BUTTON_TOOLTIP_LN'
export const NEW_BATCH_MODAL_BUTTON_ZIP_CODE_INVALID_TOOLTIP_LN =
  'NEW_BATCH_MODAL_BUTTON_ZIP_CODE_INVALID_TOOLTIP_LN'
export const NEW_BATCH_MODAL_BUTTON_1_LN = 'NEW_BATCH_MODAL_BUTTON_1_LN'
export const NEW_BATCH_MODAL_BUTTON_2_LN = 'NEW_BATCH_MODAL_BUTTON_2_LN'
export const NEW_BATCH_MODAL_BUTTON_3_LN = 'NEW_BATCH_MODAL_BUTTON_3_LN'
export const NEW_BATCH_MODAL_AVAILABLE_INVOICES_TITLE_LN =
  'NEW_BATCH_MODAL_AVAILABLE_INVOICES_TITLE_LN'
export const NEW_BATCH_MODAL_AVAILABLE_INVOICES_TITLE_TOOLTIP_LN =
  'NEW_BATCH_MODAL_AVAILABLE_INVOICES_TITLE_TOOLTIP_LN'
export const NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_COUNT_TITLE_LN =
  'NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_COUNT_TITLE_LN'
export const NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_1_LN =
  'NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_1_LN'
export const NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_2_LN =
  'NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_2_LN'
export const NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_3_LN =
  'NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_3_LN'
export const NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_4_LN =
  'NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_4_LN'
export const NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_5_LN =
  'NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_5_LN'
export const NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_6_LN =
  'NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_6_LN'
export const NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_7_LN =
  'NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_7_LN'
export const NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_8_LN =
  'NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_8_LN'
export const NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_9_LN =
  'NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_9_LN'
export const NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_SELECT_TITLE_LN =
  'NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_SELECT_TITLE_LN'
export const PROCESSED_BATCH_SUMMARY_CONFIRMATION_TITLE_1_LN =
  'PROCESSED_BATCH_SUMMARY_CONFIRMATION_TITLE_1_LN'
export const PROCESSED_BATCH_SUMMARY_CONFIRMATION_BUTTON_1_LN =
  'PROCESSED_BATCH_SUMMARY_CONFIRMATION_BUTTON_1_LN'
export const DEFAULT_CANCEL_BUTTON_LN = 'DEFAULT_CANCEL_BUTTON_LN'
export const PROCESSED_BATCH_SUMMARY_CONFIRMATION_TITLE_2_LN =
  'PROCESSED_BATCH_SUMMARY_CONFIRMATION_TITLE_2_LN'
export const PROCESSED_BATCH_SUMMARY_CONFIRMATION_BUTTON_2_LN =
  'PROCESSED_BATCH_SUMMARY_CONFIRMATION_BUTTON_2_LN'
export const PROCESSED_BATCH_SUMMARY_TITLE_PAST_1_LN =
  'PROCESSED_BATCH_SUMMARY_TITLE_PAST_1_LN'
export const PROCESSED_BATCH_SUMMARY_TITLE_PAST_2_LN =
  'PROCESSED_BATCH_SUMMARY_TITLE_PAST_2_LN'
export const PROCESSED_BATCH_SUMMARY_TITLE_TOOLTIP_LN =
  'PROCESSED_BATCH_SUMMARY_TITLE_TOOLTIP_LN'
export const PROCESSED_BATCH_SUMMARY_TABLE_COUNT_TITLE_LN =
  'PROCESSED_BATCH_SUMMARY_TABLE_COUNT_TITLE_LN'
export const PROCESSED_BATCH_SUMMARY_BUTTON_TITLE_1_LN =
  'PROCESSED_BATCH_SUMMARY_BUTTON_TITLE_1_LN'
export const PROCESSED_BATCH_SUMMARY_TABLE_CELL_1_LN =
  'PROCESSED_BATCH_SUMMARY_TABLE_CELL_1_LN'
export const PROCESSED_BATCH_SUMMARY_TABLE_CELL_2_LN =
  'PROCESSED_BATCH_SUMMARY_TABLE_CELL_2_LN'
export const PROCESSED_BATCH_SUMMARY_TABLE_CELL_3_LN =
  'PROCESSED_BATCH_SUMMARY_TABLE_CELL_3_LN'
export const PROCESSED_BATCH_SUMMARY_TABLE_CELL_4_LN =
  'PROCESSED_BATCH_SUMMARY_TABLE_CELL_4_LN'
export const PROCESSED_BATCH_SUMMARY_TABLE_CELL_5_LN =
  'PROCESSED_BATCH_SUMMARY_TABLE_CELL_5_LN'
export const PROCESSED_BATCH_SUMMARY_TABLE_CELL_6_LN =
  'PROCESSED_BATCH_SUMMARY_TABLE_CELL_6_LN'
export const PROCESSED_BATCH_SUMMARY_TABLE_CELL_7_LN =
  'PROCESSED_BATCH_SUMMARY_TABLE_CELL_7_LN'
export const PROCESSED_BATCH_SUMMARY_TABLE_CELL_8_LN =
  'PROCESSED_BATCH_SUMMARY_TABLE_CELL_8_LN'
export const PROCESSED_BATCH_SUMMARY_TABLE_CELL_9_LN =
  'PROCESSED_BATCH_SUMMARY_TABLE_CELL_9_LN'
export const PROCESSED_BATCH_SUMMARY_TABLE_CELL_1_TOOLTIP_LN =
  'PROCESSED_BATCH_SUMMARY_TABLE_CELL_1_TOOLTIP_LN'
export const PROCESSING_INVOICESS_MODAL_TITLE_LN =
  'PROCESSING_INVOICESS_MODAL_TITLE_LN'
export const PROCESSING_INVOICESS_MODAL_MESSAGE_1_LN =
  'PROCESSING_INVOICESS_MODAL_MESSAGE_1_LN'
export const PROCESSING_INVOICESS_MODAL_MESSAGE_2_LN =
  'PROCESSING_INVOICESS_MODAL_MESSAGE_2_LN'
export const PROCESSING_INVOICESS_MODAL_MESSAGE_3_LN =
  'PROCESSING_INVOICESS_MODAL_MESSAGE_3_LN'
export const PROCESSING_INVOICESS_MODAL_BUTTON_LN =
  'PROCESSING_INVOICESS_MODAL_BUTTON_LN'
export const RAW_DETAIL_TAX_SUMMARY_MODAL_BUTTON_LN =
  'RAW_DETAIL_TAX_SUMMARY_MODAL_BUTTON_LN'
export const RAW_DETAIL_TAX_SUMMARY_MODAL_TITLE_1_LN =
  'RAW_DETAIL_TAX_SUMMARY_MODAL_TITLE_1_LN'
export const SETTINGS_MODAL_TITLE_LN = 'SETTINGS_MODAL_TITLE_LN'
export const SETTINGS_MODAL_TITLE_TOOLTIP_LN = 'SETTINGS_MODAL_TITLE_TOOLTIP_LN'
export const SETTINGS_MODAL_PIU_TITLE_LN = 'SETTINGS_MODAL_PIU_TITLE_LN'
export const SETTINGS_MODAL_PIU_MESSAGE_1_LN = 'SETTINGS_MODAL_PIU_MESSAGE_1_LN'
export const SETTINGS_MODAL_PIU_FORM_FIELD_1_LN =
  'SETTINGS_MODAL_PIU_FORM_FIELD_1_LN'
export const SETTINGS_MODAL_PIU_FORM_FIELD_2_LN =
  'SETTINGS_MODAL_PIU_FORM_FIELD_2_LN'
export const SETTINGS_MODAL_PIU_FORM_FIELD_SAFE_HARBOR_HELPER_1_LN =
  'SETTINGS_MODAL_PIU_FORM_FIELD_SAFE_HARBOR_HELPER_1_LN'
export const SETTINGS_MODAL_PIU_FORM_FIELD_SAFE_HARBOR_HELPER_2_LN =
  'SETTINGS_MODAL_PIU_FORM_FIELD_SAFE_HARBOR_HELPER_2_LN'
export const SETTINGS_MODAL_PIU_FORM_BUTTON_LN =
  'SETTINGS_MODAL_PIU_FORM_BUTTON_LN'
export const SETTINGS_MODAL_PIU_FORM_VALID_MESSAGE_1_LN =
  'SETTINGS_MODAL_PIU_FORM_VALID_MESSAGE_1_LN'
export const SETTINGS_MODAL_PIU_FORM_VALID_MESSAGE_2_LN =
  'SETTINGS_MODAL_PIU_FORM_VALID_MESSAGE_2_LN'
export const SETTINGS_MODAL_PIU_FORM_VALID_MESSAGE_3_LN =
  'SETTINGS_MODAL_PIU_FORM_VALID_MESSAGE_3_LN'
export const SETTINGS_MODAL_PIU_FORM_VALID_MESSAGE_4_LN =
  'SETTINGS_MODAL_PIU_FORM_VALID_MESSAGE_4_LN'
export const SIGN_IN_VALID_MESSAGE_1_LN = 'SIGN_IN_VALID_MESSAGE_1_LN'
export const SIGN_IN_VALID_MESSAGE_2_LN = 'SIGN_IN_VALID_MESSAGE_2_LN'
export const SIGN_IN_VALID_MESSAGE_3_LN = 'SIGN_IN_VALID_MESSAGE_3_LN'
export const SIGN_IN_CSI_MODAL_TITLE_LN = 'SIGN_IN_CSI_MODAL_TITLE_LN'
export const SIGN_IN_CSI_MODAL_INPUT_PLACEHOLDER_LN =
  'SIGN_IN_CSI_MODAL_INPUT_PLACEHOLDER_LN'
export const SIGN_IN_CSI_MODAL_BUTTON_NAME_1_LN =
  'SIGN_IN_CSI_MODAL_BUTTON_NAME_1_LN'
export const SIGN_IN_CSI_MODAL_BUTTON_NAME_2_LN =
  'SIGN_IN_CSI_MODAL_BUTTON_NAME_2_LN'
export const SIGN_IN_CSI_MODAL_BUTTON_NAME_3_LN =
  'SIGN_IN_CSI_MODAL_BUTTON_NAME_3_LN'
export const SIGN_IN_QB_MODAL_SUCCESS_MESSAGE_LN =
  'SIGN_IN_QB_MODAL_SUCCESS_MESSAGE_LN'
export const SIGN_IN_QB_MODAL_DENIE_MESSAGE_LN =
  'SIGN_IN_QB_MODAL_DENIE_MESSAGE_LN'
export const SIGN_IN_QB_MODAL_BUTTON_LN = 'SIGN_IN_QB_MODAL_BUTTON_LN'
export const TAX_SUMMARY_MODAL_TITLE_LN = 'TAX_SUMMARY_MODAL_TITLE_LN'
export const TAX_SUMMARY_MODAL_TITLE_TOOLTIP_LN =
  'TAX_SUMMARY_MODAL_TITLE_TOOLTIP_LN'
export const TAX_SUMMARY_MODAL_INFO_MESSAGE_1_LN =
  'TAX_SUMMARY_MODAL_INFO_MESSAGE_1_LN'
export const TAX_SUMMARY_MODAL_INFO_MESSAGE_2_LN =
  'TAX_SUMMARY_MODAL_INFO_MESSAGE_2_LN'
export const TAX_SUMMARY_MODAL_TOTAL_TITLE_LN =
  'TAX_SUMMARY_MODAL_TOTAL_TITLE_LN'
export const TAX_SUMMARY_MODAL_BILLABLE_TOTAL_TITLE_LN =
  'TAX_SUMMARY_MODAL_BILLABLE_TOTAL_TITLE_LN'
export const TAX_SUMMARY_MODAL_BUTTON_EXPORT_CSV_LN =
  'TAX_SUMMARY_MODAL_BUTTON_EXPORT_CSV_LN'
export const TAX_SUMMARY_MODAL_TABLE_CELL_1_LN =
  'TAX_SUMMARY_MODAL_TABLE_CELL_1_LN'
export const TAX_SUMMARY_MODAL_TABLE_CELL_2_LN =
  'TAX_SUMMARY_MODAL_TABLE_CELL_2_LN'
export const TAX_SUMMARY_MODAL_TABLE_CELL_3_LN =
  'TAX_SUMMARY_MODAL_TABLE_CELL_3_LN'
export const PROFILE_CONFIRMATION_TITLE_1_LN = 'PROFILE_CONFIRMATION_TITLE_1_LN'
export const PROFILE_MODAL_TITLE_1_LN = 'PROFILE_MODAL_TITLE_1_LN'
export const PROFILE_MODAL_TITLE_2_LN = 'PROFILE_MODAL_TITLE_2_LN'
export const PROFILE_MODAL_TITLE_3_LN = 'PROFILE_MODAL_TITLE_3_LN'
export const PROFILE_MODAL_BUTTON_1_LN = 'PROFILE_MODAL_BUTTON_1_LN'
export const PROFILE_MODAL_BUTTON_2_LN = 'PROFILE_MODAL_BUTTON_2_LN'
export const PROFILE_MODAL_SUB_TITLE_1_LN = 'PROFILE_MODAL_SUB_TITLE_1_LN'
export const PROFILE_MODAL_QB_BUTTON_1_LN = 'PROFILE_MODAL_QB_BUTTON_1_LN'
export const PROFILE_MODAL_QB_BUTTON_2_LN = 'PROFILE_MODAL_QB_BUTTON_2_LN'
export const PROFILE_MODAL_COPY_TITLE_1_LN = 'PROFILE_MODAL_COPY_TITLE_1_LN'
export const PROFILE_MODAL_COPY_TITLE_2_LN = 'PROFILE_MODAL_COPY_TITLE_2_LN'
export const PROFILE_MODAL_CSI_BUTTON_1_LN = 'PROFILE_MODAL_CSI_BUTTON_1_LN'
export const PROFILE_MODAL_CSI_BUTTON_2_LN = 'PROFILE_MODAL_CSI_BUTTON_2_LN'
export const PROFILE_MODAL_COPY_TITLE_3_LN = 'PROFILE_MODAL_COPY_TITLE_3_LN'
export const PROFILE_MODAL_FORM_TITLE_LN = 'PROFILE_MODAL_FORM_TITLE_LN'
export const PROFILE_MODAL_FORM_INPUT_USERMANE_PLACEHOLDER_LN =
  'PROFILE_MODAL_FORM_INPUT_USERMANE_PLACEHOLDER_LN'
export const PROFILE_MODAL_FORM_INPUT_NEW_PASSWORD_PLACEHOLDER_LN =
  'PROFILE_MODAL_FORM_INPUT_NEW_PASSWORD_PLACEHOLDER_LN'
export const PROFILE_MODAL_FORM_INPUT_CONFIRM_PASSWORD_PLACEHOLDER_LN =
  'PROFILE_MODAL_FORM_INPUT_CONFIRM_PASSWORD_PLACEHOLDER_LN'
export const PROFILE_MODAL_FORM_INPUT_FIRST_NAME_PLACEHOLDER_LN =
  'PROFILE_MODAL_FORM_INPUT_FIRST_NAME_PLACEHOLDER_LN'
export const PROFILE_MODAL_FORM_INPUT_LAST_NAME_PLACEHOLDER_LN =
  'PROFILE_MODAL_FORM_INPUT_LAST_NAME_PLACEHOLDER_LN'
export const PROFILE_MODAL_FORM_INPUT_COMPANY_NAME_PLACEHOLDER_LN =
  'PROFILE_MODAL_FORM_INPUT_COMPANY_NAME_PLACEHOLDER_LN'
export const PROFILE_MODAL_FORM_INPUT_PHONE_PLACEHOLDER_LN =
  'PROFILE_MODAL_FORM_INPUT_PHONE_PLACEHOLDER_LN'
export const PROFILE_MODAL_FORM_INPUT_PHONE_BOTTOM_HELPER_LN =
  'PROFILE_MODAL_FORM_INPUT_PHONE_BOTTOM_HELPER_LN'
export const PROFILE_MODAL_SUB_TITLE_2_LN = 'PROFILE_MODAL_SUB_TITLE_2_LN'
export const PROFILE_MODAL_FORM_INPUT_ADDRESS_PLACEHOLDER_LN =
  'PROFILE_MODAL_FORM_INPUT_ADDRESS_PLACEHOLDER_LN'
export const PROFILE_MODAL_FORM_INPUT_CITY_PLACEHOLDER_LN =
  'PROFILE_MODAL_FORM_INPUT_CITY_PLACEHOLDER_LN'
export const PROFILE_MODAL_FORM_INPUT_STATE_PLACEHOLDER_LN =
  'PROFILE_MODAL_FORM_INPUT_STATE_PLACEHOLDER_LN'
export const PROFILE_MODAL_FORM_INPUT_POSTAL_CODE_PLACEHOLDER_LN =
  'PROFILE_MODAL_FORM_INPUT_POSTAL_CODE_PLACEHOLDER_LN'
export const PROFILE_MODAL_FORM_USER_ADMIN_SWITCH_LABEL_LN =
  'PROFILE_MODAL_FORM_USER_ADMIN_SWITCH_LABEL_LN'
export const PROFILE_MODAL_FORM_VALID_MESSAGE_1_LN =
  'PROFILE_MODAL_FORM_VALID_MESSAGE_1_LN'
export const PROFILE_MODAL_FORM_VALID_MESSAGE_2_LN =
  'PROFILE_MODAL_FORM_VALID_MESSAGE_2_LN'
export const PROFILE_MODAL_FORM_VALID_MESSAGE_3_LN =
  'PROFILE_MODAL_FORM_VALID_MESSAGE_3_LN'
export const PROFILE_MODAL_FORM_VALID_MESSAGE_4_LN =
  'PROFILE_MODAL_FORM_VALID_MESSAGE_4_LN'
export const PROFILE_MODAL_FORM_VALID_MESSAGE_5_LN =
  'PROFILE_MODAL_FORM_VALID_MESSAGE_5_LN'
export const PROFILE_MODAL_FORM_VALID_MESSAGE_6_LN =
  'PROFILE_MODAL_FORM_VALID_MESSAGE_6_LN'
export const PROFILE_MODAL_FORM_VALID_MESSAGE_7_LN =
  'PROFILE_MODAL_FORM_VALID_MESSAGE_7_LN'
export const PROFILE_MODAL_FORM_VALID_MESSAGE_8_LN =
  'PROFILE_MODAL_FORM_VALID_MESSAGE_8_LN'
export const PROFILE_MODAL_FORM_VALID_MESSAGE_9_LN =
  'PROFILE_MODAL_FORM_VALID_MESSAGE_9_LN'
export const USERS_PAGE_TITLE_LN = 'USERS_PAGE_TITLE_LN'
export const USERS_PAGE_TABLE_CELL_1_LN = 'USERS_PAGE_TABLE_CELL_1_LN'
export const USERS_PAGE_TABLE_CELL_2_LN = 'USERS_PAGE_TABLE_CELL_2_LN'
export const USERS_PAGE_TABLE_CELL_3_LN = 'USERS_PAGE_TABLE_CELL_3_LN'
export const USERS_PAGE_TABLE_CELL_4_LN = 'USERS_PAGE_TABLE_CELL_4_LN'
export const USERS_PAGE_TABLE_CELL_5_LN = 'USERS_PAGE_TABLE_CELL_5_LN'
export const USERS_PAGE_TABLE_CELL_6_LN = 'USERS_PAGE_TABLE_CELL_6_LN'
export const USERS_PAGE_USER_MENU_CONFIRMATION_TITLE_1_LN =
  'USERS_PAGE_USER_MENU_CONFIRMATION_TITLE_1_LN'
export const USERS_PAGE_USER_MENU_CONFIRMATION_BUTTON_1_LN =
  'USERS_PAGE_USER_MENU_CONFIRMATION_BUTTON_1_LN'
export const USERS_PAGE_USER_MENU_CONFIRMATION_MESSAGE_1_LN =
  'USERS_PAGE_USER_MENU_CONFIRMATION_MESSAGE_1_LN'
export const USERS_PAGE_USER_MENU_ITEM_1_LN = 'USERS_PAGE_USER_MENU_ITEM_1_LN'
export const USERS_PAGE_USER_MENU_ITEM_2_LN = 'USERS_PAGE_USER_MENU_ITEM_2_LN'
export const USERS_PAGE_USER_MENU_ITEM_3_LN = 'USERS_PAGE_USER_MENU_ITEM_3_LN'
export const USERS_PAGE_USER_MENU_ITEM_4_LN = 'USERS_PAGE_USER_MENU_ITEM_4_LN'
export const DEFAULT_ALERT_MESSAGE_LN = 'DEFAULT_ALERT_MESSAGE_LN'
export const CREATE_USER_SUCCSESS_MESSAGE_LN = 'CREATE_USER_SUCCSESS_MESSAGE_LN'
export const UPDATE_USER_SUCCSESS_MESSAGE_LN = 'UPDATE_USER_SUCCSESS_MESSAGE_LN'
export const PROFILE_SUCCSESS_MESSAGE_LN = 'PROFILE_SUCCSESS_MESSAGE_LN'
export const NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_1_ALERT_LN =
  'NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_1_ALERT_LN'
export const NOTIFICATION_MENU_TITLE_LN = 'NOTIFICATION_MENU_TITLE_LN'
export const TAX_SUMMARY_MODAL_TABLE_CELL_4_LN =
  'TAX_SUMMARY_MODAL_TABLE_CELL_4_LN'
export const CONFIRMATION_TITLE_FOR_DELETE_INVOICE_LN =
  'CONFIRMATION_TITLE_FOR_DELETE_INVOICE_LN'
export const NEW_BATCH_INFO_BOX_MESSAGE_3_LN = 'NEW_BATCH_INFO_BOX_MESSAGE_3_LN'
export const NEW_BATCH_INFO_BOX_MESSAGE_4_LN = 'NEW_BATCH_INFO_BOX_MESSAGE_4_LN'
export const PROFILE_MODAL_FORM_VALID_MESSAGE_10_LN =
  'PROFILE_MODAL_FORM_VALID_MESSAGE_10_LN'
export const SETTING_RATE_CONFIRMATION_MESSAGE_1_LN =
  'SETTING_RATE_CONFIRMATION_MESSAGE_1_LN'
export const SETTING_RATE_CONFIRMATION_MESSAGE_2_LN =
  'SETTING_RATE_CONFIRMATION_MESSAGE_2_LN'
export const MAPEED_PRODUCT_TITLE_TOOLTIP_1_LN =
  'MAPEED_PRODUCT_TITLE_TOOLTIP_1_LN'
export const DELETED_INVOICES_MESSAGE_LN = 'DELETED_INVOICES_MESSAGE_LN'
export const FILTER_BUTTON_NAME_LN = 'FILTER_BUTTON_NAME_LN'
export const BACK_TO_COMAPNIES_BUTTON_NAME_LN =
  'BACK_TO_COMAPNIES_BUTTON_NAME_LN'
export const REPORT_EXPORT_TO_CSV_BUTTON_LN = 'REPORT_EXPORT_TO_CSV_BUTTON_LN'
export const YOU_DONT_HAVE_BATCHES_INFO_MESSAGE_LN =
  'YOU_DONT_HAVE_BATCHES_INFO_MESSAGE_LN'
export const SEARCH_PLACEHOLDER_FOR_COMPANIES_LN =
  'SEARCH_PLACEHOLDER_FOR_COMPANIES_LN'
export const COMPANIES_NAV_MENU_LN = 'COMPANIES_NAV_MENU_LN'
export const SELECT_DATE_RANGE_LOGS_LN = 'SELECT_DATE_RANGE_LOGS_LN'
export const SELECT_FROM_DATE_LOGS_LN = 'SELECT_FROM_DATE_LOGS_LN'
export const SELECT_TO_DATE_LOGS_LN = 'SELECT_TO_DATE_LOGS_LN'
export const SELECT_LOGS_TYPE_LN = 'SELECT_LOGS_TYPE_LN'
export const EXPORT_TO_CSV_LOGS_LN = 'EXPORT_TO_CSV_LOGS_LN'
export const MONTHLY_TAX_SUMMARY_REPORT_TITLE_LN =
  'MONTHLY_TAX_SUMMARY_REPORT_TITLE_LN'
export const MONTHLY_TAX_SUMMARY_REPORT_SUBTITLE_1_LN =
  'MONTHLY_TAX_SUMMARY_REPORT_SUBTITLE_1_LN'
export const MONTHLY_TAX_SUMMARY_REPORT_SUBTITLE_2_LN =
  'MONTHLY_TAX_SUMMARY_REPORT_SUBTITLE_2_LN'
export const MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_1_LN =
  'MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_1_LN'
export const MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_2_LN =
  'MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_2_LN'
export const MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_3_LN =
  'MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_3_LN'
export const MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_4_LN =
  'MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_4_LN'
export const MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_5_LN =
  'MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_5_LN'
export const MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_6_LN =
  'MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_6_LN'
export const MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_7_LN =
  'MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_7_LN'
export const MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_8_LN =
  'MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_8_LN'
export const MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_9_LN =
  'MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_9_LN'
export const MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_10_LN =
  'MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_10_LN'
export const MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_11_LN =
  'MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_11_LN'
export const MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_12_LN =
  'MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_12_LN'
export const MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_13_LN =
  'MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_13_LN'
export const MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_14_LN =
  'MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_14_LN'
export const MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_15_LN =
  'MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_15_LN'
export const MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_16_LN =
  'MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_16_LN'
export const MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_17_LN =
  'MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_17_LN'
export const MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_18_LN =
  'MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_18_LN'
export const MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_19_LN =
  'MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_19_LN'
export const MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_20_LN =
  'MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_20_LN'
export const MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_21_LN =
  'MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_21_LN'
export const MONTHLY_TAX_SUMMARY_REPORT_TABLE_INFO_BOX_LN =
  'MONTHLY_TAX_SUMMARY_REPORT_TABLE_INFO_BOX_LN'
export const QB_TO_PORTAL_REPORT_TITLE_LN = 'QB_TO_PORTAL_REPORT_TITLE_LN'
export const QB_TO_PORTAL_REPORT_1_LN = 'QB_TO_PORTAL_REPORT_1_LN'
export const QB_TO_PORTAL_REPORT_2_LN = 'QB_TO_PORTAL_REPORT_2_LN'
export const QB_TO_PORTAL_REPORT_TABLE_CELL_1_LN =
  'QB_TO_PORTAL_REPORT_TABLE_CELL_1_LN'
export const QB_TO_PORTAL_REPORT_TABLE_CELL_2_LN =
  'QB_TO_PORTAL_REPORT_TABLE_CELL_2_LN'
export const QB_TO_PORTAL_REPORT_TABLE_CELL_3_LN =
  'QB_TO_PORTAL_REPORT_TABLE_CELL_3_LN'
export const QB_TO_PORTAL_REPORT_TABLE_CELL_4_LN =
  'QB_TO_PORTAL_REPORT_TABLE_CELL_4_LN'
export const QB_TO_PORTAL_REPORT_TABLE_CELL_5_LN =
  'QB_TO_PORTAL_REPORT_TABLE_CELL_5_LN'
export const QB_TO_PORTAL_REPORT_TABLE_CELL_6_LN =
  'QB_TO_PORTAL_REPORT_TABLE_CELL_6_LN'
export const QB_TO_PORTAL_REPORT_TABLE_CELL_7_LN =
  'QB_TO_PORTAL_REPORT_TABLE_CELL_7_LN'
export const QB_TO_PORTAL_REPORT_TABLE_CELL_8_LN =
  'QB_TO_PORTAL_REPORT_TABLE_CELL_8_LN'
export const QB_TO_PORTAL_REPORT_TABLE_CELL_9_LN =
  'QB_TO_PORTAL_REPORT_TABLE_CELL_9_LN'
export const QB_TO_PORTAL_REPORT_TABLE_CELL_10_LN =
  'QB_TO_PORTAL_REPORT_TABLE_CELL_10_LN'
export const QB_TO_PORTAL_REPORT_TABLE_CELL_11_LN =
  'QB_TO_PORTAL_REPORT_TABLE_CELL_11_LN'
export const QB_TO_PORTAL_REPORT_TABLE_INFO_BOX_LN =
  'QB_TO_PORTAL_REPORT_TABLE_INFO_BOX_LN'
export const SELECT_DATE_RANGE_REPORT_LN = 'SELECT_DATE_RANGE_REPORT_LN'
export const SELECT_FROM_DATE_REPORT_LN = 'SELECT_FROM_DATE_REPORT_LN'
export const SELECT_TO_DATE_REPORT_LN = 'SELECT_TO_DATE_REPORT_LN'
export const GET_REPORT_BUTTON_NAME_LN = 'GET_REPORT_BUTTON_NAME_LN'
export const REMORT_NAV_MENU_NAME_1_LN = 'REMORT_NAV_MENU_NAME_1_LN'
export const REMORT_NAV_MENU_NAME_2_LN = 'REMORT_NAV_MENU_NAME_2_LN'
export const REPORT_DATE_RANGE_BUTTON_LN = 'REPORT_DATE_RANGE_BUTTON_LN'
export const RAW_DETAIL_TAX_SUMMARY_MODAL_BUTTON_1_LN =
  'RAW_DETAIL_TAX_SUMMARY_MODAL_BUTTON_1_LN'
export const DELETE_INVOICE_MESSAGES_POP_UP_LN =
  'DELETE_INVOICE_MESSAGES_POP_UP_LN'
export const RATE_TEXT_TOOLTIP_LN = 'RATE_TEXT_TOOLTIP_LN'
export const RAW_DETAIL_TAX_SUMMARY_MODAL_TITLE_2_LN =
  'RAW_DETAIL_TAX_SUMMARY_MODAL_TITLE_2_LN'
export const RAW_DETAIL_TAX_SUMMARY_MODAL_TITLE_3_LN =
  'RAW_DETAIL_TAX_SUMMARY_MODAL_TITLE_3_LN'
export const SIGN_IN_CSI_MODAL_SUCCESS_MESSAGE_LN =
  'SIGN_IN_CSI_MODAL_SUCCESS_MESSAGE_LN'
export const SIGN_IN_CSI_MODAL_DENIE_MESSAGE_LN =
  'SIGN_IN_CSI_MODAL_DENIE_MESSAGE_LN'
export const ADD_NEW_USER_CONFIRM_MODAL_TITLE =
  'ADD_NEW_USER_CONFIRM_MODAL_TITLE'

export const INVOICES_TITLE_LN = 'INVOICES_TITLE_LN'
export const YOU_DONT_HAVE_INVOICES_INFO_MESSAGE_LN =
  'YOU_DONT_HAVE_INVOICES_INFO_MESSAGE_LN'
export const TOOLTIP_BATCH_TITLE_1_LN = 'TOOLTIP_BATCH_TITLE_1_LN'
export const TABLE_COLUMN_HEADER_MOUSE_OVER_INCLUDE_LN =
  'TABLE_COLUMN_HEADER_MOUSE_OVER_INCLUDE_LN'
export const TABLE_COLUMN_HEADER_MOUSE_OVER_EMAILED_LN =
  'TABLE_COLUMN_HEADER_MOUSE_OVER_EMAILED_LN'
export const TABLE_COLUMN_HEADER_MOUSE_OVER_TAXES_EXIST_LN =
  'TABLE_COLUMN_HEADER_MOUSE_OVER_TAXES_EXIST_LN'
export const TABLE_COLUMN_HEADER_MOUSE_OVER_TAX_EXEMPTION_LN =
  'TABLE_COLUMN_HEADER_MOUSE_OVER_TAX_EXEMPTION_LN'
export const TABLE_COLUMN_HEADER_MOUSE_OVER_POSTAL_CODE_LN =
  'TABLE_COLUMN_HEADER_MOUSE_OVER_POSTAL_CODE_LN'
export const SETTINGS_MODAL_PIU_MESSAGE_2_LN = 'SETTINGS_MODAL_PIU_MESSAGE_2_LN'
export const INVOICE_TABLE_HEADER_CELL_1_LN = 'INVOICE_TABLE_HEADER_CELL_1_LN'
export const INVOICE_TABLE_HEADER_CELL_2_LN = 'INVOICE_TABLE_HEADER_CELL_2_LN'
export const INVOICE_TABLE_HEADER_CELL_3_LN = 'INVOICE_TABLE_HEADER_CELL_3_LN'
export const INVOICE_TABLE_HEADER_CELL_4_LN = 'INVOICE_TABLE_HEADER_CELL_4_LN'
export const INVOICE_TABLE_HEADER_CELL_5_LN = 'INVOICE_TABLE_HEADER_CELL_5_LN'
export const INVOICE_TABLE_HEADER_CELL_6_LN = 'INVOICE_TABLE_HEADER_CELL_6_LN'
export const INVOICE_TABLE_HEADER_CELL_7_LN = 'INVOICE_TABLE_HEADER_CELL_7_LN'
export const INVOICE_TABLE_HEADER_CELL_8_LN = 'INVOICE_TABLE_HEADER_CELL_8_LN'
export const INVOICE_TABLE_HEADER_CELL_9_LN = 'INVOICE_TABLE_HEADER_CELL_9_LN'
export const MAP_PRODUCT_CONFIRMATION_TITLE_LN =
  'MAP_PRODUCT_CONFIRMATION_TITLE_LN'
export const MAPPED_PRODUCT_CONFIRMATION_TITLE_LN =
  'MAPPED_PRODUCT_CONFIRMATION_TITLE_LN'
export const NEW_BATCH_CONFIRMATION_TITLE_1_LN =
  'NEW_BATCH_CONFIRMATION_TITLE_1_LN'
export const NEW_BATCH_CONFIRMATION_TITLE_2_LN =
  'NEW_BATCH_CONFIRMATION_TITLE_2_LN'
export const ADD_NEW_USER_CONFIRM_MODAL_1_TITLE =
  'ADD_NEW_USER_CONFIRM_MODAL_1_TITLE'
export const COMPANY_NAME_TITLE_LN = 'COMPANY_NAME_TITLE_LN'
export const USER_MENU_CONFIRMATION_TITLE_WORD_1_LN =
  'USER_MENU_CONFIRMATION_TITLE_WORD_1_LN'
export const USER_MENU_CONFIRMATION_TITLE_WORD_2_LN =
  'USER_MENU_CONFIRMATION_TITLE_WORD_2_LN'
export const USER_ADMIN_QB_COMPANY_TEXT_LN = 'USER_ADMIN_QB_COMPANY_TEXT_LN'
export const PROCESSED_BATCH_SUMMARY_CONFIRMATION_BUTTON_3_LN =
  'PROCESSED_BATCH_SUMMARY_CONFIRMATION_BUTTON_3_LN'
export const NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_2_ALERT_LN =
  'NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_2_ALERT_LN'
export const CSI_DISCONNECT_MESSAGE_ON_BATCHES_PAGE_LN =
  'CSI_DISCONNECT_MESSAGE_ON_BATCHES_PAGE_LN'
export const SIGN_IN_CSI_MODAL_SUCCESS_MESSAGE_2_LN =
  'SIGN_IN_CSI_MODAL_SUCCESS_MESSAGE_2_LN'
export const MAP_PRODUCT_TITLE_TOOLTIP_2_LN = 'MAP_PRODUCT_TITLE_TOOLTIP_2_LN'
export const PROFILE_MODAL_FORM_VALID_MESSAGE_0_LN =
  'PROFILE_MODAL_FORM_VALID_MESSAGE_0_LN'
export const TABLE_COLUMN_HEADER_MOUSE_OVER_INCLUDE_NEW_BATCH_LN =
  'TABLE_COLUMN_HEADER_MOUSE_OVER_INCLUDE_NEW_BATCH_LN'
export const TABLE_COLUMN_HEADER_MOUSE_OVER_CUST_TYPE_LN =
  'TABLE_COLUMN_HEADER_MOUSE_OVER_CUST_TYPE_LN'
export const NEW_BATCH_CONFIRMATION_TITLE_3_LN =
  'NEW_BATCH_CONFIRMATION_TITLE_3_LN'
export const DEFAULT_EXIT_BUTTON_LN = 'DEFAULT_EXIT_BUTTON_LN'
export const CONNECT_CSI_BTN_LN = 'CONNECT_CSI_BTN_LN'
export const DISCONNECT_CSI_BTN_LN = 'DISCONNECT_CSI_BTN_LN'
export const INVOICE_DETAIL_TITLE_PART_3_LN = 'INVOICE_DETAIL_TITLE_PART_3_LN'
export const INVOICE_DETAIL_TITLE_PART_4_LN = 'INVOICE_DETAIL_TITLE_PART_4_LN'
