import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import { qBAuthLogin } from 'actions/qb-actions'
import LoadingScreen from 'components/loading-screen'
import { getQbAuthConnectStatusSelector } from 'redusers/quickbooks-auth'
import SignInQbConnectedModal from 'components/sign-in-qb-connected-modal'
import { QB_AUTH_CONNECT_NO } from 'constants/qb-connect-constant'
import { useQuery } from 'custom-hooks/useQuery'

function QuickBooksConnect(props) {
  const { qBAuthLogin, qbAuthConnectStatus = '' } = props

  const [isShownModal, setIsShownModal] = useState(false)
  const history = useHistory()
  const query = useQuery()

  const handleClickOK = () => {
    setIsShownModal(false)
    history.replace('/invoices')
  }
  //error=access_denied
  useEffect(() => {
    const error = query.get('error')

    if (error) {
      setIsShownModal(true)
      return
    }
    const body = {
      code: query.get('code'),
      realm_id: query.get('realmId'),
      state: query.get('state'),
    }
    qBAuthLogin(body)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (qbAuthConnectStatus !== QB_AUTH_CONNECT_NO) {
      setIsShownModal(true)
    }
  }, [qbAuthConnectStatus])

  return (
    <>
      {isShownModal && (
        <SignInQbConnectedModal
          qbAuthConnectStatus={qbAuthConnectStatus}
          handleClickOK={handleClickOK}
        />
      )}
      <LoadingScreen />
    </>
  )
}

QuickBooksConnect.propTypes = {
  qBAuthLogin: PropTypes.func.isRequired,
  qbAuthConnectStatus: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => ({
  qbAuthConnectStatus: getQbAuthConnectStatusSelector(state),
})

const actions = {
  qBAuthLogin,
}

export default connect(mapStateToProps, actions)(QuickBooksConnect)
