import React, { memo } from 'react'
import Tooltip from '@material-ui/core/Tooltip'

function TableRow(props) {
  const { children, className = '', isHeader = false, tooltipText = '' } = props
  const _extraClassNameForHeadre = isHeader ? 'table_header' : ''
  const _title = tooltipText ? tooltipText : ''
  return (
    <Tooltip title={_title} placement="top">
      <span className={`table_row ${_extraClassNameForHeadre} ${className}`}>
        <>{children}</>
      </span>
    </Tooltip>
  )
}

TableRow.propTypes = {}

export default memo(TableRow)
