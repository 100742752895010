import moment from 'moment'
import {
  START_FETCHING_LOGS,
  STOP_FETCHING_LOGS,
  SET_LOGS_TYPES,
  SET_TO_DATE_LOGS,
  SET_FROM_DATE_LOGS,
  CLEAR_LOGS,
  SET_SELECTED_TYPES,
} from 'constants/actions-constants'

const startOfMonth = moment().startOf('month').format()
const endOfMonth = moment().endOf('month').format()

const initialState = {
  isFetchingLogs: false,
  logsTypes: [],
  toDate: endOfMonth,
  fromDate: startOfMonth,
  selectedLogsTypes: [],
}

export const logs = (state = initialState, actions) => {
  switch (actions.type) {
    case START_FETCHING_LOGS:
      return { ...state, isFetchingLogs: true }
    case STOP_FETCHING_LOGS:
      return { ...state, isFetchingLogs: false }
    case SET_LOGS_TYPES:
      return { ...state, logsTypes: actions.payload }
    case SET_TO_DATE_LOGS:
      return { ...state, toDate: actions.payload }
    case SET_FROM_DATE_LOGS:
      return { ...state, fromDate: actions.payload }
    case CLEAR_LOGS:
      return { ...initialState, logsTypes: state.logsTypes }
    case SET_SELECTED_TYPES:
      return { ...state, selectedLogsTypes: actions.payload }
    default:
      return state
  }
}

export const getIsFetchingLogsSelector = (state) => state.logs.isFetchingLogs

export const getLogsTypesSelector = (state) => state.logs.logsTypes

export const getToDateSelector = (state) => state.logs.toDate

export const getFromDateSelector = (state) => state.logs.fromDate

export const getSelectedLogsTypes = (state) => state.logs.selectedLogsTypes
