import React from 'react'

const BellIconComponent = ({ className }) => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Design"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity="0.5"
        className={'opacity'}
      >
        <g id="Home" transform="translate(-889.000000, -28.000000)">
          <g id="Header-2">
            <g id="bell_icon">
              <g transform="translate(889.000000, 28.000000)">
                <path
                  d="M8,19.838551 C8,19.4146274 8.35839845,19.9665468 8.8,19.9665468 C9.24160155,19.9665468 9.6,19.4146274 9.6,19.838551 C9.6,21.1093845 10.6761719,22.1424757 12,22.1424757 C13.3236328,22.1424757 14.4,21.1093845 14.4,19.838551 C14.4,19.4146274 14.7583985,19.9665468 15.2,19.9665468 C15.6416016,19.9665468 16,19.4146274 16,19.838551 C16,21.9561068 14.2058594,23.6784255 12,23.6784255 C9.7941406,23.6784255 8,21.9561068 8,19.838551 Z"
                  id="Path"
                  className={className}
                  fill="#004482"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M21.9000797,21 L2.1001401,21 C0.941964912,21 0,20.084198 0,18.9584046 C0,18.3609009 0.267626164,17.7952271 0.73454367,17.4066467 C2.55958696,15.9074402 3.59998901,13.7282715 3.59998901,11.4194336 L3.59998901,8.16659545 C3.59998901,3.66342162 7.36806832,0 12.0001099,0 C16.6319317,0 20.400011,3.66342162 20.400011,8.16659545 L20.400011,11.4194336 C20.400011,13.7282715 21.4404131,15.9074402 23.2535912,17.398529 C23.7323739,17.7952271 24,18.3609009 24,18.9584046 C24,20.084198 23.0580351,21 21.9000797,21 Z M12.0001099,1.75 C8.3603505,1.75 5.39998352,4.6281433 5.39998352,8.16659545 L5.39998352,11.4194336 C5.39998352,14.2437439 4.12689173,16.9108276 1.90809964,18.7343139 C1.86613199,18.7693482 1.79999451,18.8417663 1.79999451,18.9584046 C1.79999451,19.1169128 1.93688375,19.25 2.1001401,19.25 L21.9000797,19.25 C22.0631162,19.25 22.2000055,19.1169128 22.2000055,18.9584046 C22.2000055,18.8417663 22.1340877,18.7693482 22.0943174,18.7366638 C19.8731083,16.9108276 18.6000165,14.2437439 18.6000165,11.4194336 L18.6000165,8.16659545 C18.6000165,4.6281433 15.6396496,1.75 12.0001099,1.75 Z"
                  id="Shape"
                  className={className}
                  fill="#004482"
                  fillRule="nonzero"
                ></path>
                <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default BellIconComponent
