import {
  SET_INVOICE_DETAIL,
  CLEAR_INVOICE_DETAIL,
  GET_INVOICE_DETAIL_FROM_AVAILABLE_INVOICES,
  GET_INVOICES_DETAIL_FROM_PROCESSED_INVOICES,
  GET_INVOICE_DETAIL_FROM_BATCH,
  DELETE_INVOICE,
  GET_CSV_REPORT_INVOICE_DETAIL,
  GET_CSV_REPORT_INVOICE_TAX_SUMMARY,
  GET_INVOICE_DETAIL_FROM_BACKEND,
  GET_INVOICE_CSI_OPERATIONS,
  SET_INVOICE_CSI_OPERATIONS,
} from 'constants/actions-constants'

export const setInvoiceDetail = (payload) => ({
  type: SET_INVOICE_DETAIL,
  payload,
})

export const clearInvoiceDetail = () => ({
  type: CLEAR_INVOICE_DETAIL,
})

export const getInvoiceDetailFromAvailableInvoices = (payload) => ({
  type: GET_INVOICE_DETAIL_FROM_AVAILABLE_INVOICES,
  payload,
})

export const getInvoiceDetailFromProcessedInvoices = (payload) => ({
  type: GET_INVOICES_DETAIL_FROM_PROCESSED_INVOICES,
  payload,
})

export const getInvoiceDetailFromBatch = (payload) => ({
  type: GET_INVOICE_DETAIL_FROM_BATCH,
  payload,
})

export const invoiceDelete = (payload) => ({
  type: DELETE_INVOICE,
  payload,
})

export const getCsvReportInvoiceDetail = () => ({
  type: GET_CSV_REPORT_INVOICE_DETAIL,
})

export const getCsvReportInvoiceTaxSummary = () => ({
  type: GET_CSV_REPORT_INVOICE_TAX_SUMMARY,
})

export const getInvoiceDetailFromBackEnd = (payload) => ({
  type: GET_INVOICE_DETAIL_FROM_BACKEND,
  payload,
})

export const getInvoiceCsiOperations = () => ({
  type: GET_INVOICE_CSI_OPERATIONS,
})

export const setInvoiseCsiOperations = (payload) => ({
  type: SET_INVOICE_CSI_OPERATIONS,
  payload,
})
