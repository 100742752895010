import React, { useRef, memo, useState, useCallback, useEffect } from 'react'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'

import SearchIcon from 'components/ui/svg-components/SearchIcon'

const SearchInput = (props) => {
  const {
    classNameContainer = '',
    classNameInput = '',
    placeholder = '',
    onChange,
    handleClickClear,
    isClearButton = false,
    autoClearValue,
    disabled = false,
    value: propsValue,
  } = props
  const inputsRef = useRef(null)
  const formRef = useRef(null)
  const [isFocused, setIsFocused] = useState(false)
  const [value, setIsValue] = useState('')

  const handleFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleBlur = useCallback(() => {
    setIsFocused(false)
  }, [])

  const handleOnChange = (e) => {
    if (onChange) {
      onChange(e)
    }

    setIsValue(e.target.value)
  }

  const handleClear = () => {
    handleClickClear()
    setIsValue('')
  }

  const handleSubmit = (e) => {
    e.preventDefault()
  }

  const classNameFocused = isFocused ? 'focus' : ''

  const classNameIsEmpty = value ? 'not_empty' : ''

  useEffect(() => {
    formRef.current.reset()
    setIsValue('')
  }, [autoClearValue])

  return (
    <div className={`search_input_container ${classNameContainer}`}>
      <form
        className={`search_input_wrap ${classNameFocused} ${classNameIsEmpty}`}
        onSubmit={handleSubmit}
        ref={formRef}
      >
        <SearchIcon
          classNameContainer={`search_input_icon_container ${classNameFocused}`}
          isFocused={isFocused}
        />

        <input
          placeholder={placeholder}
          className={`search_input ${classNameInput}`}
          type="text"
          name="search"
          ref={inputsRef}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleOnChange}
          disabled={disabled}
          value={propsValue}
        />
        {isClearButton && (
          <button className="cancel_button" onClick={handleClear} type="reset">
            <CancelRoundedIcon />
          </button>
        )}
      </form>
    </div>
  )
}

export default memo(SearchInput)
