import React, { memo } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import MonthlyTaxSummaryReport from 'components/monthly-tax-summaty-report'
import QbPortalCompariconReport from 'components/qb-portal-comparison-report'

const ReportRoutes = () => {
  return (
    <Switch>
      <Route path="/reports/qb_to_portal_comparison" exact>
        <QbPortalCompariconReport />
      </Route>
      <Route path="/reports/monthly_tax_summary" exact>
        <MonthlyTaxSummaryReport />
      </Route>
      <Redirect from="/" to="/reports/qb_to_portal_comparison" />
    </Switch>
  )
}

export default memo(ReportRoutes)
