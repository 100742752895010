import React, { memo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { setOrderBySortInvoices } from 'actions/invoices'

import { getOrderByInvoicesSelector } from 'redusers/invoices'

import { useTranslation } from 'react-i18next'
import { TableCell } from 'components/ui/table'
import {
  INVOICE_TABLE_HEADER_CELL_1_LN,
  INVOICE_TABLE_HEADER_CELL_2_LN,
  INVOICE_TABLE_HEADER_CELL_3_LN,
  INVOICE_TABLE_HEADER_CELL_4_LN,
  INVOICE_TABLE_HEADER_CELL_5_LN,
  INVOICE_TABLE_HEADER_CELL_6_LN,
  INVOICE_TABLE_HEADER_CELL_7_LN,
  INVOICE_TABLE_HEADER_CELL_8_LN,
  INVOICE_TABLE_HEADER_CELL_9_LN,
} from 'constants/language-key-constants'
import { useShallowSelector } from 'custom-hooks/useShallowSelector'

const InvoicesTableHeader = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const invoicesOrderBy = useShallowSelector(getOrderByInvoicesSelector)

  const [chosenSortedKey, setChosenSortedKey] = useState(
    'csi_data.processed_datetime'
  )

  const handleClickSort = (sortKey) => {
    let _sortKey = sortKey
    if (invoicesOrderBy === sortKey) {
      _sortKey = `-${sortKey}`
    }
    dispatch(setOrderBySortInvoices(_sortKey))
    setChosenSortedKey(sortKey)
  }

  return (
    <>
      <TableCell
        text={t(INVOICE_TABLE_HEADER_CELL_1_LN)}
        className="process_date"
        isSortBackEnd
        dataSortKey={'csi_data.processed_datetime'}
        handleSortClick={handleClickSort}
        chosenSortedKey={chosenSortedKey}
      />
      <TableCell
        text={t(INVOICE_TABLE_HEADER_CELL_2_LN)}
        className="batch_id"
        isSortBackEnd
        dataSortKey={'last_batch_id'}
        handleSortClick={handleClickSort}
        chosenSortedKey={chosenSortedKey}
      />
      <TableCell
        text={t(INVOICE_TABLE_HEADER_CELL_3_LN)}
        className="invoice_num"
        isSortBackEnd
        dataSortKey={'quickbooks_data.doc_number'}
        handleSortClick={handleClickSort}
        chosenSortedKey={chosenSortedKey}
      />
      <TableCell
        text={'Type'}
        className="invoice_type"
        isSortBackEnd
        dataSortKey={'type'}
        handleSortClick={handleClickSort}
        chosenSortedKey={chosenSortedKey}
      />
      <TableCell
        text={t(INVOICE_TABLE_HEADER_CELL_4_LN)}
        className="customer"
        isSortBackEnd
        dataSortKey={'quickbooks_data.customer_ref.name'}
        handleSortClick={handleClickSort}
        chosenSortedKey={chosenSortedKey}
      />
      <TableCell
        text={'Cust Type'}
        className="cust_type"
        isSortBackEnd
        dataSortKey={'customer_type'}
        handleSortClick={handleClickSort}
        chosenSortedKey={chosenSortedKey}
      />
      <TableCell
        text={t(INVOICE_TABLE_HEADER_CELL_5_LN)}
        className="invoice_date"
        isSortBackEnd
        dataSortKey={'quickbooks_data.txn_date'}
        handleSortClick={handleClickSort}
        chosenSortedKey={chosenSortedKey}
        defaultsortedkey={'quickbooks_data.txn_date'}
      />
      <TableCell
        text={t(INVOICE_TABLE_HEADER_CELL_6_LN)}
        className="postal_code"
        isSortBackEnd
        dataSortKey={'zip_code'}
        handleSortClick={handleClickSort}
        chosenSortedKey={chosenSortedKey}
      />
      <TableCell
        text={t(INVOICE_TABLE_HEADER_CELL_7_LN)}
        className="revenue"
        dataSortKey={'quickbooks_data.total_amt'}
        handleSortClick={handleClickSort}
        chosenSortedKey={chosenSortedKey}
        isSort={false}
      />
      <TableCell
        text={t(INVOICE_TABLE_HEADER_CELL_8_LN)}
        className="exempt_code"
        isSortBackEnd
        dataSortKey={'exemption_data.exempt_code'}
        handleSortClick={handleClickSort}
        chosenSortedKey={chosenSortedKey}
      />
      <TableCell
        text={t(INVOICE_TABLE_HEADER_CELL_9_LN)}
        className="tax_total"
        //isSortBackEnd
        isSort={false}
        dataSortKey={'csi_data.tax_summary.billable_total_tax_amount'}
        handleSortClick={handleClickSort}
        chosenSortedKey={chosenSortedKey}
      />
    </>
  )
}

export default memo(InvoicesTableHeader)
