import {
  SET_COMPANIES,
  START_COMPANIES_FETCHING,
  STOP_COMPANIES_FETCHING,
  CLEAR_COMPANIES_LIST,
  GET_COMPANIES,
  GET_BATCHES_BY_COMPANY,
  SET_SEARCH_BATCH_FROM_COMPANY_VALUE,
  SET_CHOSEN_REALM_ID_COMPANY,
  CLEAR_SEARCH_VALUE_COMPANIES,
} from 'constants/actions-constants'

export const getCompanies = () => ({
  type: GET_COMPANIES,
})

export const setCompaniesList = (payload) => ({
  type: SET_COMPANIES,
  payload,
})

export const clearCompaniesList = () => ({
  type: CLEAR_COMPANIES_LIST,
})

export const startCompaniesFetching = () => ({
  type: START_COMPANIES_FETCHING,
})

export const stopCompaniesFetching = () => ({
  type: STOP_COMPANIES_FETCHING,
})

export const getBatchesByCompany = (payload) => ({
  type: GET_BATCHES_BY_COMPANY,
  payload,
})

export const setSearchValueBatchFromCompany = (payload) => ({
  type: SET_SEARCH_BATCH_FROM_COMPANY_VALUE,
  payload,
})

export const setChosenRealmIdCompany = (payload) => ({
  type: SET_CHOSEN_REALM_ID_COMPANY,
  payload,
})

export const clearSearchValueCompanies = () => ({
  type: CLEAR_SEARCH_VALUE_COMPANIES,
})
