import React from 'react'
import { useTranslation } from 'react-i18next'

import BellIconComponent from 'components/ui/svg-components/BellIconComponent'
import { useToggleMenu } from 'custom-hooks/useToggleMenu'
import NotificationsList from './NotificationsList'
import { getNewNotificationStatusSelector } from 'redusers/notifications'
import { NOTIFICATION_MENU_TITLE_LN } from 'constants/language-key-constants'
import { useShallowSelector } from 'custom-hooks/useShallowSelector'

const Notifications = () => {
  const [isOpen, setIsOpen] = useToggleMenu(false)
  const { t } = useTranslation()
  const isNewNotification = useShallowSelector(getNewNotificationStatusSelector)

  const handleClickToggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const openClassName = isOpen ? 'open' : ''
  return (
    <div className={'header_section header_notification_section'}>
      <button
        type="button"
        className={`header_notification_button_menu ${openClassName}`}
        onClick={handleClickToggleMenu}
      >
        <BellIconComponent className="bell-icon" />
        {isNewNotification && <div className="notification_badge"></div>}
      </button>
      {isOpen && (
        <div className="notification_menu_container">
          <div className="notification_menu_title_container">
            <span className="notification_menu_title">
              {t(NOTIFICATION_MENU_TITLE_LN)}
            </span>
          </div>
          <NotificationsList isNewNotification={isNewNotification} />
        </div>
      )}
    </div>
  )
}

export default Notifications
