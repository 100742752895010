import React, { memo } from 'react'
import { Button as MaterialButton } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'

import Spinner from 'components/ui/spinner'

const Button = (props) => {
  const {
    name = '',
    styletype = '',
    classNameContainer = '',
    classNameButton = '',
    isSpinner,
    tooltipTitle = '',
    ...rest
  } = props

  return (
    <Tooltip title={tooltipTitle}>
      <span className={`button_wrap ${classNameContainer}`}>
        <MaterialButton
          className={`custom_button_container ${classNameButton} ${styletype}`}
          {...rest}
        >
          {name}
        </MaterialButton>

        {isSpinner && <Spinner className="spinner" />}
      </span>
    </Tooltip>
  )
}

export default memo(Button)
