import React, { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { useShallowSelector } from 'custom-hooks/useShallowSelector'
import { getNotificationsFromBackEnd } from 'actions/notification-actions'
import { getRoleUserSelector } from 'redusers/profile'
import { CLIENT_ROLE } from 'constants/roles-constant'

const pageVisibilityHoc = (Component) => {
  return (props) => {
    const dispatch = useDispatch()
    const roleUSer = useShallowSelector(getRoleUserSelector)
    const handleFocusTab = useCallback(() => {
      if (document.visibilityState === 'visible' && roleUSer === CLIENT_ROLE) {
        dispatch(getNotificationsFromBackEnd())
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    useEffect(() => {
      document.addEventListener('visibilitychange', handleFocusTab)
      return () => {
        document.removeEventListener('visibilitychange', handleFocusTab)
      }
    }, [handleFocusTab])

    return <Component {...props} />
  }
}

export default pageVisibilityHoc
