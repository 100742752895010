import { fetchApi } from 'service/fetch-api'

class Api {
  login = (url, body) => {
    return fetchApi({
      url,
      body,
      method: 'post',
      authenticated: false,
    })
  }

  logoutApi = (url, body) => {
    return fetchApi({
      url,
      body,
      method: 'post',
      // authenticated: false,
    })
  }

  refresh = (url, body) => {
    return fetchApi({
      url,
      body,
      method: 'post',
      authenticated: false,
    })
  }

  getProfileInfo = (url) => {
    return fetchApi({
      url,
    })
  }

  getQBAuthUrlApi = (url) => {
    return fetchApi({
      url,
    })
  }

  loginAuthQb = (url, body) => {
    return fetchApi({
      url,
      body,
      method: 'post',
    })
  }

  logoutQb = (url) => {
    return fetchApi({
      url,
      method: 'post',
    })
  }

  getBatchesApi = (url) => {
    return fetchApi({
      url,
    })
  }

  getBatchInvoicesApi = (url) => {
    return fetchApi({
      url,
    })
  }

  retrieveUpdatedInvoicesAndProductsForBatch = (url) => {
    return fetchApi({
      url,
      method: 'put',
    })
  }

  processingInvoices = (url, body) => {
    return fetchApi({
      url,
      method: 'put',
      body,
    })
  }

  createBatch = (url, body) => {
    return fetchApi({
      url,
      method: 'post',
      body,
    })
  }

  sendBatchApi = (url) => {
    return fetchApi({
      url,
      method: 'put',
    })
  }

  updateTaxLinesApi = (url, body) => {
    return fetchApi({
      url,
      method: 'put',
      body,
    })
  }

  connectCsiAccountApi = (url, body) => {
    return fetchApi({
      url,
      method: 'put',
      body,
    })
  }

  disconnectCsiAccountApi = (url) => {
    return fetchApi({
      url,
      method: 'put',
    })
  }

  updateProfileDataApi = (url, body) => {
    return fetchApi({
      url,
      method: 'put',
      body,
    })
  }

  getProducts = (url) => {
    return fetchApi({
      url,
    })
  }

  mapProductApi = (url, body) => {
    return fetchApi({
      url,
      method: 'put',
      body,
    })
  }

  pullProductsFromQbApi = (url) => {
    return fetchApi({
      url,
      method: 'put',
    })
  }

  unmapProductApi = (url, body) => {
    return fetchApi({
      url,
      method: 'put',
      body,
    })
  }

  registerDevicesApi = (url) => {
    return fetchApi({
      url,
      method: 'put',
    })
  }

  getNotificationsApi = (url) => {
    return fetchApi({
      url,
    })
  }

  getBatchDetailByIdApi = (url) => {
    return fetchApi({
      url,
    })
  }

  getUsersListApi = (url) => {
    return fetchApi({
      url,
    })
  }

  createUserApi = (url, body) => {
    return fetchApi({
      url,
      body,
      method: 'post',
    })
  }

  editUserApi = (url, body) => {
    return fetchApi({
      url,
      body,
      method: 'put',
    })
  }

  deleteUserApi = (url) => {
    return fetchApi({
      url,
      method: 'delete',
    })
  }
  loginAsUserApi = (url) => {
    return fetchApi({
      url,
      method: 'post',
    })
  }

  getCompaniesApi = (url) => {
    return fetchApi({
      url,
    })
  }

  markAsReadApi = (url, body) => {
    return fetchApi({
      url,
      method: 'put',
      body,
    })
  }

  getCustomersTaxExemptApi = (url) => {
    return fetchApi({
      url,
    })
  }

  updateCustomersTaxExemptApi = (url, body) => {
    return fetchApi({
      url,
      method: 'put',
      body,
    })
  }

  deleteInvoiceApi = (url) => {
    return fetchApi({
      url,
      method: 'delete',
    })
  }

  getCsvApi = (url) => {
    return fetchApi({
      url,
    })
  }

  getReports = (url) => {
    return fetchApi({
      url,
    })
  }

  getSumTotalReports = (url) => {
    return fetchApi({
      url,
    })
  }

  getInvoiceById = (url) => {
    return fetchApi({
      url,
    })
  }

  getLogsApi = (url) => {
    return fetchApi({
      url,
    })
  }

  getBatchesByCompanyApi = (url) => {
    return fetchApi({
      url,
    })
  }

  getInvoicesApi = (url) => {
    return fetchApi({
      url,
    })
  }

  get = (url) => {
    return fetchApi({
      url,
    })
  }
}

export default new Api()
