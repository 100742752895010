import React, { memo } from 'react'
import { Route, Redirect } from 'react-router-dom'

import { getIsAutsSelector } from 'redusers/profile'
import { useShallowSelector } from 'custom-hooks/useShallowSelector'

const PrivateRoute = (props) => {
  const { component: Component, ...rest } = props
  const isAuth = useShallowSelector(getIsAutsSelector)

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/auth' }} />
        )
      }
    />
  )
}

export default memo(PrivateRoute)
