import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import logo from 'assets/images/logo.png'
import logo1 from 'assets/images/logo1.png'

const Logo = (props) => {
  const {
    classNameContainer = '',
    classNameImage = '',
    isLink = false,
    typeLogo = '',
  } = props
  return (
    <div className={`logo_container ${classNameContainer}`}>
      {isLink ? (
        <Link to="/">
          <img
            src={typeLogo === 'long' ? logo1 : logo}
            alt="Logo"
            className={`logo ${classNameImage}`}
          />
        </Link>
      ) : (
        <img
          src={typeLogo === 'long' ? logo1 : logo}
          alt="Logo"
          className={`logo ${classNameImage}`}
        />
      )}
    </div>
  )
}

Logo.propTypes = {
  classNameContainer: PropTypes.string,
  classNameImage: PropTypes.string,
  isLink: PropTypes.bool,
}

export default memo(Logo)
