import React, { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useShallowSelector } from 'custom-hooks/useShallowSelector'
import Button from 'components/ui/button-component'
import {
  NEW_BATCH_MODAL_BUTTON_1_LN,
  NEW_BATCH_MODAL_BUTTON_2_LN,
} from 'constants/language-key-constants'

import CheckBoxStyled from 'components/ui/checkbox-styled'
import { getProfileCsiEnabledSelector } from 'redusers/profile'

function GenerateInvoicesButtons(props) {
  const {
    isFetchingBatch,
    clearDateToNewBatch,
    handleGenerateInvoices,
    isNoneSelected,
  } = props
  const { t } = useTranslation()
  const [isConfirm, setIsConfirm] = useState(false)

  const isCsiConnected = useShallowSelector(getProfileCsiEnabledSelector)

  let tooltipTitle = !isCsiConnected
    ? 'Unable to generate batch due to CSI Authentication Key failure. Please reach out to support@csilongwood.com'
    : ''

  const isDisabledButton =
    isFetchingBatch || isNoneSelected || !isConfirm || !isCsiConnected

  return (
    <span className="new_batch_modal_buttons_container">
      <Button
        name={t(NEW_BATCH_MODAL_BUTTON_1_LN)}
        styletype="green"
        classNameButton={'new_batch_modal_buttons'}
        classNameContainer={'new_batch_modal_buttons_wrap left_button'}
        disabled={isDisabledButton}
        onClick={isDisabledButton ? () => null : handleGenerateInvoices}
        isSpinner={isFetchingBatch}
        tooltipTitle={tooltipTitle}
      />
      <Button
        name={t(NEW_BATCH_MODAL_BUTTON_2_LN)}
        styletype="outline_blue"
        classNameButton={'new_batch_modal_buttons'}
        classNameContainer={'new_batch_modal_buttons_wrap'}
        onClick={clearDateToNewBatch}
        disabled={isFetchingBatch}
      />
      <CheckBoxStyled
        label="Exemption and Customer Type invoice settings have been reviewed"
        checked={isConfirm}
        onChange={(e) => setIsConfirm(e.target.checked)}
        className={'confirm_create_batch_checkbox'}
      />
    </span>
  )
}

export default memo(GenerateInvoicesButtons)
