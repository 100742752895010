import React, { memo } from 'react'

import Spinner from 'components/ui/spinner'

const LoadingScreen = (props) => {
  const { isCenter = false } = props
  const classNameCenter = isCenter ? 'center' : ''
  return (
    <div className={`loading_screen_container ${classNameCenter}`}>
      <Spinner className="loading_screen_spinner" />
    </div>
  )
}

export default memo(LoadingScreen)
