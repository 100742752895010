import React from 'react'

const SearchIcon = (props) => {
  const { isFocused, classNameContainer } = props
  return (
    <span className={`${classNameContainer}`}>
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Design"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Home" transform="translate(-37.000000, -124.000000)">
            <g id="text-field-2" transform="translate(37.000000, 115.000000)">
              <g id="text-field">
                <g id="Search_icon" transform="translate(0.000000, 9.000000)">
                  <path
                    d="M15.0497577,11.1415186 C15.0497577,13.2962843 13.2962843,15.0497577 11.1415186,15.0497577 C8.98675283,15.0497577 7.23327948,13.2962843 7.23327948,11.1415186 C7.23327948,8.98675283 8.98675283,7.23327948 11.1415186,7.23327948 C13.2962843,7.23327948 15.0497577,8.98675283 15.0497577,11.1415186 L15.0497577,11.1415186 Z M20,18.4210016 L16.2012924,14.622294 C16.907916,13.6016155 17.2830372,12.3802908 17.2830372,11.1415186 C17.2830372,7.74798061 14.5350565,5 11.1415186,5 C7.74798061,5 5,7.74798061 5,11.1415186 C5,14.5350565 7.74798061,17.2830372 11.1415186,17.2830372 C12.3802908,17.2830372 13.6016155,16.907916 14.622294,16.2012924 L18.4210016,19.9912763 L20,18.4210016 Z"
                    id="search_icon"
                    fill={isFocused ? '#00447e' : '#999999'}
                    opacity="0.900000036"
                  ></path>
                  <rect
                    id="Rectangle"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                  ></rect>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  )
}

export default SearchIcon
