import React, { memo, useCallback, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import sortBy from 'lodash/sortBy'
import moment from 'moment'

import { useShallowSelector } from 'custom-hooks/useShallowSelector'
import Modal from 'components/ui/modal-component'
import Title from 'components/ui/title-component'
import CalendarStyled from 'components/ui/calendar-component'
import Button from 'components/ui/button-component'
import CheckBoxStyled from 'components/ui/checkbox-styled'
import { hideLogsModal } from 'actions/modals-state-actions'
import {
  setFromDateLogs,
  setToDateLogs,
  setSelectedTypes,
  exportLogsCsv,
  clearLogs,
} from 'actions/logs-actions'
import {
  getFromDateSelector,
  getIsFetchingLogsSelector,
  getToDateSelector,
  getLogsTypesSelector,
  getSelectedLogsTypes,
} from 'redusers/logs'
import {
  SELECT_DATE_RANGE_LOGS_LN,
  SELECT_FROM_DATE_LOGS_LN,
  SELECT_TO_DATE_LOGS_LN,
  SELECT_LOGS_TYPE_LN,
  EXPORT_TO_CSV_LOGS_LN,
  DEFAULT_CANCEL_BUTTON_LN,
  NEW_BATCH_MODAL_DATE_TO_MESSAGE_LN,
} from 'constants/language-key-constants'

const LogsModal = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const isFetching = useShallowSelector(getIsFetchingLogsSelector)
  const logsTypes = useShallowSelector(getLogsTypesSelector)
  const selectedLogsType = useShallowSelector(getSelectedLogsTypes)
  const [isError, setisError] = useState(false)

  const fromDate = useShallowSelector(getFromDateSelector)
  const toDate = useShallowSelector(getToDateSelector)

  const handleFromDate = useCallback(
    (date) => {
      dispatch(setFromDateLogs(moment(date).format()))
    },
    [dispatch]
  )

  const handleToDate = useCallback(
    (date) => {
      dispatch(setToDateLogs(moment(date).format()))
    },
    [dispatch]
  )

  const handleErrorDate = (e) => {
    if (e && !isError) {
      setisError(true)
    }
    if (!e && isError) {
      setisError(false)
    }
  }

  const handelCloseModal = useCallback(() => {
    dispatch(hideLogsModal())
  }, [dispatch])

  const handleChange = (e) => {
    const checked = e.target.checked
    const name = e.target.name
    if (name) {
      if (checked) {
        const _selected = [...selectedLogsType, name]
        dispatch(setSelectedTypes(_selected))
      } else {
        const _selected = selectedLogsType.filter((item) => item !== name)
        dispatch(setSelectedTypes(_selected))
      }
    }
  }

  const handleClickExportLogsCsv = useCallback(() => {
    dispatch(exportLogsCsv())
  }, [dispatch])

  useEffect(() => {
    return () => {
      dispatch(clearLogs())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const maxDate = new Date()
  const _maxDate = maxDate.setMonth(maxDate.getMonth())

  const sortedLogsTypes = sortBy(logsTypes, ['title'])

  return (
    <Modal
      classNameWrap={'logs_modal_container'}
      classNameContainer={'logs_modal'}
      isCloseButton={true}
      handleClickClose={handelCloseModal}
    >
      <span className="logs_modal_title_container">
        <Title title={t(SELECT_DATE_RANGE_LOGS_LN)} component={'h3'} />
      </span>
      <span className="logs_modal_calendar_container">
        <CalendarStyled
          placeholder={t(SELECT_FROM_DATE_LOGS_LN)}
          className="left_calendar"
          value={fromDate}
          maxDate={_maxDate}
          onChange={handleFromDate}
        />
        <CalendarStyled
          placeholder={t(SELECT_TO_DATE_LOGS_LN)}
          disableFuture={false}
          value={toDate}
          onChange={handleToDate}
          minDate={fromDate}
          // maxDate={_maxDate}
          minDateMessage={t(NEW_BATCH_MODAL_DATE_TO_MESSAGE_LN)}
          onError={handleErrorDate}
        />
      </span>
      <span className="logs_type_form_section">
        <Title
          title={t(SELECT_LOGS_TYPE_LN)}
          extraClassName="logs_type_title"
          component={'h3'}
        />
        <form
          name="log_typer_form"
          onChange={handleChange}
          className="logs_type_form_container"
        >
          {sortedLogsTypes.map((type, i) => {
            return (
              <CheckBoxStyled
                key={i}
                label={type.title}
                name={type.name}
                className="logs_checkbox"
              />
            )
          })}
        </form>
      </span>
      <span className="logs_modal_buttons_container">
        <Button
          name={t(EXPORT_TO_CSV_LOGS_LN)}
          styletype="green"
          classNameContainer={'logs_modal_buttons_wrap left_button'}
          disabled={isFetching || isError}
          onClick={handleClickExportLogsCsv}
          isSpinner={isFetching}
        />
        <Button
          name={t(DEFAULT_CANCEL_BUTTON_LN)}
          styletype="outline_blue"
          classNameContainer={'logs_modal_buttons_wrap'}
          onClick={handelCloseModal}
        />
      </span>
    </Modal>
  )
}

export default memo(LogsModal)
