import React, { memo } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TooltipStyled from 'components/ui/tooltip-component'

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 17,
    height: 17,
    borderWidth: 1,
    borderColor: '#00447E',
    borderStyle: 'solid',
    backgroundColor: '#ffffff',
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:before': {
      display: 'block',
      width: 13,
      height: 13,
      content: '""',
      backgroundColor: '#00447E',
      borderRadius: '50%',
    },
    // 'input:hover ~ &': {
    //   backgroundColor: '#106ba3',
    // },
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontFamily: 'Montserrat, sans-serif',
    fontSize: 12,
  },
})

function CustomRadio(props) {
  const classes = useStyles()

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  )
}

function CustomRadioButton(props) {
  const {
    className = '',
    selected = false,
    isTooltip = false,
    tooltipContent = '',
    ...extra
  } = props
  const classes = useStyles()
  const selectedClassName = selected ? 'selected' : ''
  return (
    <span className="radio_button_label_container">
      <FormControlLabel
        control={<CustomRadio />}
        className={`${classes.label} ${className} ${selectedClassName}`}
        {...extra}
      />
      {isTooltip && <TooltipStyled text={tooltipContent} />}
    </span>
  )
}

CustomRadioButton.propTypes = {}

export default memo(CustomRadioButton)
