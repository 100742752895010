import React, { memo, useCallback } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { useShallowSelector } from 'custom-hooks/useShallowSelector'
import Button from 'components/ui/button-component'
import Modal from 'components/ui/modal-component'
import Title from 'components/ui/title-component'
import { Table, TableCell } from 'components/ui/table'
import { clearBatchDetail, exportToQb } from 'actions/batches-actions'
import { showConfirmationModal } from 'actions/modals-state-actions'
import { clearSelectedState } from 'actions/checkbox-actions'
import {
  NUM_INVOICE_SORTED_CONS,
  CUSTOMER_INVOICES_SORTED_CONS,
  ISSUE_DATE_INVOICE_SORTED_CONS,
  REVENUE_INVOICE_SORTED_CONS,
  TAX_TOTAL_INVOICE_SORTED_CONS,
  EMAILED_INVOICES_SORTED_CONS,
  TACES_EXIST_INVOICES_SORTED_CONS,
} from 'constants/available-invoices-sorted-constant'
import { ADMIN_ROLE } from 'constants/roles-constant'
import {
  getIsFetchingCreateBatch,
  getProcessedInvoicesDataSelector,
  getBatchDetailIdSelector,
  getIsFetchingInvoicesInBatchDetailSelector,
} from 'redusers/batches'
import { getDataSelectedStateSelector } from 'redusers/checkbox-state'
import { getRoleUserSelector } from 'redusers/profile'
import {
  PROCESSED_BATCH_SUMMARY_CONFIRMATION_TITLE_1_LN,
  PROCESSED_BATCH_SUMMARY_CONFIRMATION_BUTTON_1_LN,
  DEFAULT_CANCEL_BUTTON_LN,
  PROCESSED_BATCH_SUMMARY_CONFIRMATION_TITLE_2_LN,
  CLOSE_BUTTON_NAME_LN,
  PROCESSED_BATCH_SUMMARY_CONFIRMATION_BUTTON_2_LN,
  PROCESSED_BATCH_SUMMARY_TITLE_PAST_1_LN,
  PROCESSED_BATCH_SUMMARY_TITLE_TOOLTIP_LN,
  PROCESSED_BATCH_SUMMARY_TABLE_COUNT_TITLE_LN,
  PROCESSED_BATCH_SUMMARY_BUTTON_TITLE_1_LN,
  PROCESSED_BATCH_SUMMARY_TABLE_CELL_1_LN,
  PROCESSED_BATCH_SUMMARY_TABLE_CELL_2_LN,
  PROCESSED_BATCH_SUMMARY_TABLE_CELL_3_LN,
  PROCESSED_BATCH_SUMMARY_TABLE_CELL_4_LN,
  PROCESSED_BATCH_SUMMARY_TABLE_CELL_5_LN,
  PROCESSED_BATCH_SUMMARY_TABLE_CELL_6_LN,
  PROCESSED_BATCH_SUMMARY_TABLE_CELL_7_LN,
  PROCESSED_BATCH_SUMMARY_TABLE_CELL_8_LN,
  PROCESSED_BATCH_SUMMARY_TABLE_CELL_9_LN,
  TOOLTIP_BATCH_TITLE_1_LN,
  TABLE_COLUMN_HEADER_MOUSE_OVER_INCLUDE_LN,
  TABLE_COLUMN_HEADER_MOUSE_OVER_EMAILED_LN,
  TABLE_COLUMN_HEADER_MOUSE_OVER_TAXES_EXIST_LN,
  TABLE_COLUMN_HEADER_MOUSE_OVER_TAX_EXEMPTION_LN,
  PROCESSED_BATCH_SUMMARY_CONFIRMATION_BUTTON_3_LN,
  TABLE_COLUMN_HEADER_MOUSE_OVER_CUST_TYPE_LN,
} from 'constants/language-key-constants'

const InvoiceItem = React.lazy(() => import('./InvoiceItem'))

function ProcessedBatchSummaryModal(props) {
  const {
    handleCloseProcessedModal,
    handleClickInvoiceDetail,
    handlerClickTaxSummary,
    clearBatchDetail,
    exportToQb,
    clearSelectedState,
    showConfirmationModal,
  } = props

  const roleUser = useShallowSelector(getRoleUserSelector)

  const isAdmin = roleUser === ADMIN_ROLE

  const processedInvoicesData = useShallowSelector(
    getProcessedInvoicesDataSelector
  )

  const isFetchingBatch = useShallowSelector(getIsFetchingCreateBatch)
  const isFetchinggetInvoices = useShallowSelector(
    getIsFetchingInvoicesInBatchDetailSelector
  )
  const selectedInvoicesInBatch = useShallowSelector(
    getDataSelectedStateSelector
  )
  const batchId = useShallowSelector(getBatchDetailIdSelector)

  const { t } = useTranslation()

  const handleCreateBatch = useCallback(() => {
    const selectedIds = selectedInvoicesInBatch.reduce((selected, item) => {
      if (
        item.selected &&
        !item.isErrorExists &&
        !item.is_qb_update_needed &&
        !item?.is_deleted
      ) {
        selected.push(item._id)
      }
      return selected
    }, [])

    showConfirmationModal({
      cb: exportToQb,
      arg: selectedIds,
      title: t(PROCESSED_BATCH_SUMMARY_CONFIRMATION_TITLE_1_LN),
      yesButtonName: t(PROCESSED_BATCH_SUMMARY_CONFIRMATION_BUTTON_1_LN),
      noButtonName: t(DEFAULT_CANCEL_BUTTON_LN),
    })
  }, [exportToQb, selectedInvoicesInBatch, showConfirmationModal, t])

  const _handleClose = useCallback(() => {
    clearBatchDetail()
    handleCloseProcessedModal()
    clearSelectedState()
  }, [clearBatchDetail, clearSelectedState, handleCloseProcessedModal])

  const handleClose = useCallback(() => {
    if (isAdmin) {
      _handleClose()
      return
    }
    showConfirmationModal({
      cb: _handleClose,
      title: t(PROCESSED_BATCH_SUMMARY_CONFIRMATION_TITLE_2_LN),
      yesButtonName: t(PROCESSED_BATCH_SUMMARY_CONFIRMATION_BUTTON_2_LN),
      noButtonName: t(PROCESSED_BATCH_SUMMARY_CONFIRMATION_BUTTON_3_LN),
      //yesButtonIsRight: true,
    })
  }, [_handleClose, isAdmin, showConfirmationModal, t])

  const tooltipTitle = (
    <p
      style={{
        fontSize: '12px',
      }}
    >
      {`${t(TOOLTIP_BATCH_TITLE_1_LN)} `}
      <span
        style={{
          fontStyle: 'italic',
          letterSpacing: '1.2px',
        }}
      >{`"${batchId}"`}</span>
    </p>
  )

  return (
    <Modal
      classNameWrap={'new_batch_modal_container processed_batch_modal'}
      classNameContainer={''}
      isCloseButton={!isFetchingBatch}
      handleClickClose={handleClose}
    >
      <span className="new_batch_modal_title_container">
        <Title
          title={`${t(
            PROCESSED_BATCH_SUMMARY_TITLE_PAST_1_LN
          )} ${batchId.substring(batchId.length - 5)}`}
          extraClassName="new_batch_title"
          withTooltip={true}
          textTooltip={t(PROCESSED_BATCH_SUMMARY_TITLE_TOOLTIP_LN)}
          placementTooltip={'top'}
          isCopy={true}
          copyContent={batchId}
          toolTipContent={tooltipTitle}
        />
      </span>
      <div className="new_batch_modal_availeble_invoices_container processed_batch_invoices_container">
        <Table
          className="new_batch_modal_availeble_invoices_table_container processed_batches"
          headerComponent={ProcessedBatchSummaryModalHeader}
          bodyTableComponent={ProcessedBatchSummaryModalBody}
          autoHeightMin={25}
          autoHeightMax={260}
          data={processedInvoicesData}
          defaultsortedkey={NUM_INVOICE_SORTED_CONS}
          checkboxKey={'include'}
          bodyProps={{
            handleClickInvoiceDetail: handleClickInvoiceDetail,
            handlerClickTaxSummary: handlerClickTaxSummary,
            isFetchinggetInvoices: isFetchinggetInvoices,
            isAdmin,
          }}
          countTitleText={t(PROCESSED_BATCH_SUMMARY_TABLE_COUNT_TITLE_LN)}
          isSpinner={isFetchinggetInvoices}
          headerProps={{
            isAdmin,
          }}
          // onLoadMore={isInvoicesPaggination ? handleLoadMoreInvoices : null}
          // disabledLoadMoreButton={isFetchinggetInvoices}
        />
        <span className="processed_batch_modal_buttons_container">
          {!isAdmin && (
            <Button
              name={t(PROCESSED_BATCH_SUMMARY_BUTTON_TITLE_1_LN)}
              styletype="green"
              classNameButton={'processed_batch_modal_buttons'}
              classNameContainer={
                'processed_batch_modal_buttons_wrap left_button'
              }
              disabled={
                isFetchingBatch ||
                selectedInvoicesInBatch.every(
                  (item) =>
                    !item.selected ||
                    item.is_deleted ||
                    item.is_qb_update_needed
                )
              }
              onClick={handleCreateBatch}
              isSpinner={isFetchingBatch}
            />
          )}
          <Button
            name={t(CLOSE_BUTTON_NAME_LN)}
            styletype="outline_blue"
            classNameButton={'new_batch_modal_buttons'}
            classNameContainer={'processed_batch_modal_buttons_wrap'}
            onClick={handleClose}
            disabled={isFetchingBatch}
          />
        </span>
      </div>
    </Modal>
  )
}

const ProcessedBatchSummaryModalHeader = memo((props) => {
  const {
    handleSortClick,
    sortedKey,
    defaultsortedkey,
    handlerOnChangeAllCheckBoxs,
    toggleSelectAllCheckBox,
    notEmpty,
    isAdmin,
  } = props

  const { t } = useTranslation()

  return (
    <>
      {!isAdmin && (
        <TableCell
          text={t(PROCESSED_BATCH_SUMMARY_TABLE_CELL_1_LN)}
          className="include"
          isAllCheckBox
          handlerOnChangeAllCheckBoxs={handlerOnChangeAllCheckBoxs}
          checkBoxValue={toggleSelectAllCheckBox}
          notEmpty={notEmpty}
          tooltipText={t(TABLE_COLUMN_HEADER_MOUSE_OVER_INCLUDE_LN)}
        />
      )}
      <TableCell
        text={t(PROCESSED_BATCH_SUMMARY_TABLE_CELL_2_LN)}
        className="emailed"
        dataSortKey={EMAILED_INVOICES_SORTED_CONS}
        handleSortClick={handleSortClick}
        chosenSortedKey={sortedKey}
        isSort
        tooltipText={t(TABLE_COLUMN_HEADER_MOUSE_OVER_EMAILED_LN)}
      />
      <TableCell
        text={t(PROCESSED_BATCH_SUMMARY_TABLE_CELL_3_LN)}
        className="taxes_exist"
        dataSortKey={TACES_EXIST_INVOICES_SORTED_CONS}
        handleSortClick={handleSortClick}
        chosenSortedKey={sortedKey}
        isSort
        tooltipText={t(TABLE_COLUMN_HEADER_MOUSE_OVER_TAXES_EXIST_LN)}
      />
      <TableCell
        text={t(PROCESSED_BATCH_SUMMARY_TABLE_CELL_4_LN)}
        className="tax_exemption"
        tooltipText={t(TABLE_COLUMN_HEADER_MOUSE_OVER_TAX_EXEMPTION_LN)}
      />
      <TableCell
        text={t(PROCESSED_BATCH_SUMMARY_TABLE_CELL_5_LN)}
        className="invoice"
        isSort
        dataSortKey={NUM_INVOICE_SORTED_CONS}
        defaultsortedkey={defaultsortedkey}
        handleSortClick={handleSortClick}
        chosenSortedKey={sortedKey}
        tooltipText={t(PROCESSED_BATCH_SUMMARY_TABLE_CELL_5_LN)}
      />
      <TableCell text={'Type'} className="type" />
      <TableCell
        text={t(PROCESSED_BATCH_SUMMARY_TABLE_CELL_6_LN)}
        className="customer"
        isSort
        dataSortKey={CUSTOMER_INVOICES_SORTED_CONS}
        handleSortClick={handleSortClick}
        chosenSortedKey={sortedKey}
        tooltipText={t(PROCESSED_BATCH_SUMMARY_TABLE_CELL_6_LN)}
      />
      <TableCell
        text={'Cust Type'}
        className="cust_type"
        //isSort
        //dataSortKey={CUSTOMER_INVOICES_SORTED_CONS}
        //handleSortClick={handleSortClick}
        //chosenSortedKey={sortedKey}
        tooltipText={t(TABLE_COLUMN_HEADER_MOUSE_OVER_CUST_TYPE_LN)}
      />
      <TableCell
        text={t(PROCESSED_BATCH_SUMMARY_TABLE_CELL_7_LN)}
        className="invoice_date"
        isSort
        dataSortKey={ISSUE_DATE_INVOICE_SORTED_CONS}
        handleSortClick={handleSortClick}
        chosenSortedKey={sortedKey}
        tooltipText={t(PROCESSED_BATCH_SUMMARY_TABLE_CELL_7_LN)}
      />
      <TableCell
        text={t(PROCESSED_BATCH_SUMMARY_TABLE_CELL_8_LN)}
        className="revenue"
        isSort
        dataSortKey={REVENUE_INVOICE_SORTED_CONS}
        handleSortClick={handleSortClick}
        chosenSortedKey={sortedKey}
        tooltipText={t(PROCESSED_BATCH_SUMMARY_TABLE_CELL_8_LN)}
      />
      <TableCell
        text={t(PROCESSED_BATCH_SUMMARY_TABLE_CELL_9_LN)}
        className="tax_total"
        isSort
        dataSortKey={TAX_TOTAL_INVOICE_SORTED_CONS}
        handleSortClick={handleSortClick}
        chosenSortedKey={sortedKey}
        tooltipText={t(PROCESSED_BATCH_SUMMARY_TABLE_CELL_9_LN)}
      />
    </>
  )
})

const ProcessedBatchSummaryModalBody = memo((bodyProps) => {
  const {
    data,
    handleCheckBox,
    handleClickInvoiceDetail,
    handlerClickTaxSummary,
    isAdmin,
  } = bodyProps

  return data.map((invoice) => {
    return (
      <InvoiceItem
        key={invoice._id}
        {...invoice}
        handleCheckBox={handleCheckBox}
        handleClickInvoiceDetail={handleClickInvoiceDetail}
        handlerClickTaxSummary={handlerClickTaxSummary}
        isAdmin={isAdmin}
      />
    )
  })
})

const actions = {
  clearBatchDetail,
  exportToQb,
  clearSelectedState,
  showConfirmationModal,
}

export default connect(null, actions)(memo(ProcessedBatchSummaryModal))
