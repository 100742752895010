import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Scrollbars } from 'react-custom-scrollbars'

function CustomScrollBar(props) {
  return (
    <Scrollbars
      //universal={true}
      hideTracksWhenNotNeeded={true}
      autoHide={false}
      className={'custom_styled_scroll'}
      renderView={(props) => <div {...props} className="render-view" />}
      renderTrackVertical={(props) => (
        <div {...props} className="track-vertical" />
      )}
      renderThumbVertical={(props) => (
        <div {...props} className="thumb-vertical" />
      )}
      renderTrackHorizontal={(props) => (
        <div {...props} className="track-horizontal" />
      )}
      renderThumbHorizontal={(props) => (
        <div {...props} className="thumb-horizontal" />
      )}
      {...props}
    >
      {props.children}
    </Scrollbars>
  )
}

CustomScrollBar.propTypes = {
  children: PropTypes.node,
}

export default memo(CustomScrollBar)
