import { put, call, takeEvery, select } from 'redux-saga/effects'
import { getI18n } from 'react-i18next'
import api from 'api'
import {
  GET_USERS,
  CREATE_USER,
  EDIT_USER,
  DELETE_USER,
  TOGGLE_ACTIVATE_STATUS_USER,
  SIGN_IN_AS_USER,
  CONNECT_CSI_ACCOUNT,
  DISCONNECT_CSI_ACCOUNT,
} from 'constants/actions-constants'
import {
  setProfileErrorMessage,
  clearProfileErrorMessage,
  setSuccessMessage,
} from 'actions/profile-actions'
import {
  startUsersFetching,
  setUsersList,
  setIsSingInAsUser,
} from 'actions/users'
import {
  hideProfileModal,
  closeConnectCsiFormModal,
} from 'actions/modals-state-actions'
import {
  getSelectedUserIdSelector,
  getSelectedStatusActivateUserSelector,
} from 'redusers/users'
import {
  USER_TOKEN_SS_KEY,
  REFRESH_USER_TOKEN_SS_KEY,
  SIGN_IN_AS_USER_SS_KEY,
} from 'constants/local-storage-key-constants'
import { UPDATE_USER_SUCCSESS_MESSAGE_LN } from 'constants/language-key-constants'
import { setProfileFetching } from 'actions/profile-actions'

function* getUsersSagaWorker() {
  try {
    const URL = 'users/?limit=1000'
    const res = yield call([api, 'getUsersListApi'], URL)

    const { data } = res

    yield put(setUsersList(data?.documents || []))
  } catch (error) {
    //to do
  }
}
function* deleteUserSagaWorker() {
  try {
    const userId = yield select(getSelectedUserIdSelector)
    const URL = `users/${userId}/`
    const res = yield call([api, 'deleteUserApi'], URL)
    const { status } = res
    if (status === 204 || status === 200) {
      yield call(getUsersSagaWorker)
    }
  } catch (error) {
    //to do
  }
}

function* createUserSagaWorker({ payload }) {
  try {
    yield put(clearProfileErrorMessage())

    const URL = 'users/'
    yield call([api, 'createUserApi'], URL, payload)

    yield call(getUsersSagaWorker)
    yield put(hideProfileModal())
  } catch (error) {
    if (
      error?.response?.status === 422 &&
      error?.response?.data?.detail?.length
    ) {
      yield put(setProfileErrorMessage(error?.response?.data?.detail[0]?.msg))
    }

    if (error?.response?.status === 400 && error?.response?.data?.detail) {
      yield put(setProfileErrorMessage(error?.response?.data?.detail))
    }
  }
}

function* editUserSagaWorker({ payload }) {
  const i18n = getI18n()
  try {
    yield put(clearProfileErrorMessage())
    const userId = yield select(getSelectedUserIdSelector)

    const URL = `users/${userId}/`

    yield call([api, 'editUserApi'], URL, payload)

    yield call(getUsersSagaWorker)
    yield put(setSuccessMessage(i18n.t(UPDATE_USER_SUCCSESS_MESSAGE_LN)))
  } catch (error) {
    if (
      error?.response?.status === 422 &&
      error?.response?.data?.detail?.length
    ) {
      yield put(setProfileErrorMessage(error?.response?.data?.detail[0]?.msg))
    }

    if (error?.response?.status === 400 && error?.response?.data?.detail) {
      yield put(setProfileErrorMessage(error?.response?.data?.detail))
    }
  }
}

function* toggleActivateStatusUserSagaWorker() {
  try {
    const currentStatus = yield select(getSelectedStatusActivateUserSelector)
    const userId = yield select(getSelectedUserIdSelector)

    const URL = `users/${userId}/`

    yield call([api, 'editUserApi'], URL, {
      is_active: !currentStatus,
    })

    yield call(getUsersSagaWorker)
    yield put(hideProfileModal())
  } catch (error) {
    //
  }
}

function* signInAsUserWorker() {
  try {
    yield put(startUsersFetching())
    const userId = yield select(getSelectedUserIdSelector)

    const URL = `login/${userId}/`

    const res = yield call([api, 'loginAsUserApi'], URL)

    const { data } = res

    const { access, refresh } = data
    yield put(setIsSingInAsUser(true))
    yield call([sessionStorage, 'setItem'], USER_TOKEN_SS_KEY, access)
    yield call([sessionStorage, 'setItem'], REFRESH_USER_TOKEN_SS_KEY, refresh)
    yield call([sessionStorage, 'setItem'], SIGN_IN_AS_USER_SS_KEY, true)
    document.location.replace(`${document.location.origin}/`)
  } catch (error) {
    //
  }
}

function* updateUsersList(newData) {
  try {
    const users = yield select((state) => state.users.usersList)
    const newUsersList = users.map((user) => {
      if (user._id === newData._id) {
        user.profile.csi = newData.profile.csi
        user.profile.csi_enabled = newData.profile.csi_enabled
      }
      return user
    })
    yield put(setUsersList(newUsersList))
  } catch (error) {
    // to do
  }
}

function* connectCsiAccount({ payload: csiToken }) {
  try {
    yield put(setProfileFetching(true))
    const userId = yield select(getSelectedUserIdSelector)

    const body = { access: csiToken }
    const URL = `csi/auth/${userId}/login/`
    const res = yield call([api, 'connectCsiAccountApi'], URL, body)
    const { data } = res

    yield put(closeConnectCsiFormModal())
    yield call(updateUsersList, data)
  } catch (error) {
    if (
      error?.response?.status === 422 &&
      error?.response?.data?.detail?.length
    ) {
      yield put(setProfileErrorMessage(error?.response?.data.detail[0].msg))
    }
    if (error?.response?.status === 400 && error?.response?.data?.detail) {
      yield put(setProfileErrorMessage(error?.response?.data?.detail))
    }
  } finally {
    yield put(setProfileFetching(false))
  }
}

function* discconectCsiAccountSagaWorker() {
  try {
    const userId = yield select(getSelectedUserIdSelector)
    const URL = `csi/auth/${userId}/logout/`
    const res = yield call([api, 'disconnectCsiAccountApi'], URL)
    const { data } = res

    yield call(updateUsersList, data)
  } catch (error) {
    //to do
  }
}

export function* usersSagaWatcher() {
  yield takeEvery(GET_USERS, getUsersSagaWorker)
  yield takeEvery(CREATE_USER, createUserSagaWorker)
  yield takeEvery(EDIT_USER, editUserSagaWorker)
  yield takeEvery(DELETE_USER, deleteUserSagaWorker)
  yield takeEvery(
    TOGGLE_ACTIVATE_STATUS_USER,
    toggleActivateStatusUserSagaWorker
  )
  yield takeEvery(SIGN_IN_AS_USER, signInAsUserWorker)
  yield takeEvery(CONNECT_CSI_ACCOUNT, connectCsiAccount)
  yield takeEvery(DISCONNECT_CSI_ACCOUNT, discconectCsiAccountSagaWorker)
}
