import {
  DEFAULT_CONFIRAMATION_MODAL_TITLE_LN,
  DEFAULT_CONFIRAMATION_MODAL_BUTTON_YES_LN,
  DEFAULT_CONFIRAMATION_MODAL_BUTTON_NO_LN,
  DEFAULT_CANCEL_BUTTON_LN,
  SIGN_IN_LN,
  EMAIL_LN,
  PASSWORD_LN,
  CANT_ACCESS_YOUR_ACCOUNT_LN,
  PLACEHOLDER_BY_USERS_SEARCH_INPUT_LN,
  ADD_NEW_USER_BUTTON_LN,
  PLACEHOLDER_BY_ADMIN_BATCHES_SEARCH_INPUT_LN,
  PLACEHOLDER_BY_CLIENT_BATCHES_SEARCH_INPUT_LN,
  NEW_BATCH_BUTTON_NAME_LN,
  TOOLTIP_BATCH_TITLE_LN,
  COUNT_BATCH_TEXT_LN,
  BATCH_CELL_NAME_1_LN,
  BATCH_CELL_NAME_2_LN,
  BATCH_CELL_NAME_3_LN,
  BATCH_CELL_NAME_4_LN,
  BATCH_CELL_NAME_5_LN,
  BATCH_CELL_NAME_6_LN,
  BATCH_CELL_NAME_7_LN,
  BATCH_CELL_NAME_8_LN,
  CSI_ERROR_LN,
  BATCHES_TITLE_LN,
  QB_DISCONNECT_MESSAGE_ON_BATCHES_PAGE_LN,
  BATCH_TITLE_LN,
  BATCH_TITLE_PROCESS_DATE_LN,
  BATCH_COMPANY_TITLE_LN,
  SING_IN_QB_CONNECT_MODAL_TITLE_LN,
  SING_IN_QB_CONNECT_MODAL_TOP_MESSAGE_LN,
  SING_IN_QB_CONNECT_MODAL_BOTTOM_MESSAGE_LN,
  SING_IN_QB_CONNECT_MODAL_BUTTON_CONNECT_LN,
  SKIP_BUTTON_NAME_LN,
  SING_IN_CSI_CONNECT_MODAL_TOP_MESSAGE_LN,
  SING_IN_CSI_CONNECT_MODAL_BOTTOM_MESSAGE_LN,
  SING_IN_CSI_CONNECT_MODAL_BUTTON_CONNECT_LN,
  EXPORT_CONFIRMATION_MODAL_TITLE_LN,
  EXPORT_CONFIRMATION_MODAL_MESSAGE_LN,
  CLOSE_BUTTON_NAME_LN,
  HEADER_BATCHES_ITEM_MENU_LN,
  HEADER_INVOICES_ITEM_MENU_LN,
  HEADER_REPORTS_ITEM_MENU_LN,
  HEADER_LOGS_ITEM_MENU_LN,
  HEADER_USERS_ITEM_MENU_LN,
  HEADER_PROFILE_MENU_ITEM_1_LN,
  HEADER_PROFILE_MENU_ITEM_2_LN,
  HEADER_PROFILE_MENU_ITEM_3_LN,
  INVOICE_DETAIL_TITLE_PART_1_LN,
  INVOICE_DETAIL_TITLE_PART_2_LN,
  INVOICE_DETAIL_CUSTOMER_LN,
  INVOICE_DETAIL_EMAIL_LN,
  INVOICE_DETAIL_INVOICE_DATE_LN,
  INVOICE_DETAIL_ADRESS_LN,
  INVOICE_DETAIL_CITY_LN,
  INVOICE_DETAIL_STATE_LN,
  INVOICE_DETAIL_POSTAL_CODE_LN,
  INVOICE_DETALT_TABLE_COUNT_TITLE_LN,
  INVOICE_DETALT_EXPORT_CSV_BUTTON_LN,
  DISABLED_EXPORT_SCV_TOOLTIP_LN,
  INVOICE_DETAIL_TOTAL_LN,
  INVOICE_DETALT_TABLE_CELL_1_TITLE_LN,
  INVOICE_DETALT_TABLE_CELL_2_TITLE_LN,
  INVOICE_DETALT_TABLE_CELL_3_TITLE_LN,
  INVOICE_DETALT_TABLE_CELL_4_TITLE_LN,
  INVOICE_DETALT_TABLE_CELL_5_TITLE_LN,
  MAP_PRODUCT_TITLE_1_LN,
  MAP_PRODUCT_TITLE_2_LN,
  MAP_PRODUCT_TITLE_TOOLTIP_LN,
  MAP_PRODUCT_MESSAGE_1_LN,
  MAP_PRODUCT_MESSAGE_2_LN,
  MAP_PRODUCT_MESSAGE_3_LN,
  MAP_PRODUCT_MESSAGE_4_LN,
  MAP_PRODUCT_SECTION_TITLE_1_LN,
  MAP_PRODUCT_SECTION_PLACEHOLDER_1_LN,
  MAP_PRODUCT_SECTION_TITLE_2_LN,
  MAP_PRODUCT_SECTION_PLACEHOLDER_2_LN,
  MAP_PRODUCT_BUTTON_NAME_1_LN,
  MAP_PRODUCT_BUTTON_NAME_2_LN,
  MAPPED_PRODUCT_TITLE_LN,
  MAPPED_PRODUCT_INFO_MESSAGE_LN,
  MAPPED_PRODUCT_TABLE_COUNT_TITLE_LN,
  MAPPED_PRODUCT_BUTTON_NAME_1_LN,
  MAPPED_PRODUCT_TABLE_CELL_TITLE_1_LN,
  MAPPED_PRODUCT_TABLE_CELL_TITLE_2_LN,
  MAPPED_PRODUCT_TABLE_CELL_TITLE_3_LN,
  NEW_BATCH_MODAL_TOOLTIP_LN,
  NEW_BATCH_MODAL_INFOBOX_MESSAGE_1_LN,
  NEW_BATCH_MODAL_INFOBOX_MESSAGE_2_LN,
  NEW_BATCH_MODAL_TITLE_LN,
  NEW_BATCH_MODAL_INFOBOX_MESSAGE_3_LN,
  NEW_BATCH_MODAL_DATE_FROM_LN,
  NEW_BATCH_MODAL_DATE_TO_LN,
  NEW_BATCH_MODAL_DATE_TO_MESSAGE_LN,
  NEW_BATCH_MODAL_BUTTON_TOOLTIP_LN,
  NEW_BATCH_MODAL_BUTTON_ZIP_CODE_INVALID_TOOLTIP_LN,
  NEW_BATCH_MODAL_BUTTON_1_LN,
  NEW_BATCH_MODAL_BUTTON_2_LN,
  NEW_BATCH_MODAL_BUTTON_3_LN,
  NEW_BATCH_MODAL_AVAILABLE_INVOICES_TITLE_LN,
  NEW_BATCH_MODAL_AVAILABLE_INVOICES_TITLE_TOOLTIP_LN,
  NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_COUNT_TITLE_LN,
  NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_1_LN,
  NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_2_LN,
  NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_3_LN,
  NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_4_LN,
  NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_5_LN,
  NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_6_LN,
  NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_7_LN,
  NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_8_LN,
  NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_9_LN,
  NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_SELECT_TITLE_LN,
  PROCESSED_BATCH_SUMMARY_CONFIRMATION_TITLE_1_LN,
  PROCESSED_BATCH_SUMMARY_CONFIRMATION_BUTTON_1_LN,
  PROCESSED_BATCH_SUMMARY_CONFIRMATION_TITLE_2_LN,
  PROCESSED_BATCH_SUMMARY_CONFIRMATION_BUTTON_2_LN,
  PROCESSED_BATCH_SUMMARY_TITLE_PAST_1_LN,
  PROCESSED_BATCH_SUMMARY_TITLE_PAST_2_LN,
  PROCESSED_BATCH_SUMMARY_TITLE_TOOLTIP_LN,
  PROCESSED_BATCH_SUMMARY_TABLE_COUNT_TITLE_LN,
  PROCESSED_BATCH_SUMMARY_BUTTON_TITLE_1_LN,
  PROCESSED_BATCH_SUMMARY_TABLE_CELL_1_LN,
  PROCESSED_BATCH_SUMMARY_TABLE_CELL_2_LN,
  PROCESSED_BATCH_SUMMARY_TABLE_CELL_3_LN,
  PROCESSED_BATCH_SUMMARY_TABLE_CELL_4_LN,
  PROCESSED_BATCH_SUMMARY_TABLE_CELL_5_LN,
  PROCESSED_BATCH_SUMMARY_TABLE_CELL_6_LN,
  PROCESSED_BATCH_SUMMARY_TABLE_CELL_7_LN,
  PROCESSED_BATCH_SUMMARY_TABLE_CELL_8_LN,
  PROCESSED_BATCH_SUMMARY_TABLE_CELL_9_LN,
  PROCESSED_BATCH_SUMMARY_TABLE_CELL_1_TOOLTIP_LN,
  PROCESSING_INVOICESS_MODAL_TITLE_LN,
  PROCESSING_INVOICESS_MODAL_MESSAGE_1_LN,
  PROCESSING_INVOICESS_MODAL_MESSAGE_2_LN,
  PROCESSING_INVOICESS_MODAL_MESSAGE_3_LN,
  PROCESSING_INVOICESS_MODAL_BUTTON_LN,
  RAW_DETAIL_TAX_SUMMARY_MODAL_BUTTON_LN,
  RAW_DETAIL_TAX_SUMMARY_MODAL_TITLE_1_LN,
  SETTINGS_MODAL_TITLE_LN,
  SETTINGS_MODAL_TITLE_TOOLTIP_LN,
  SETTINGS_MODAL_PIU_TITLE_LN,
  SETTINGS_MODAL_PIU_MESSAGE_1_LN,
  SETTINGS_MODAL_PIU_FORM_FIELD_1_LN,
  SETTINGS_MODAL_PIU_FORM_FIELD_2_LN,
  SETTINGS_MODAL_PIU_FORM_FIELD_SAFE_HARBOR_HELPER_1_LN,
  SETTINGS_MODAL_PIU_FORM_FIELD_SAFE_HARBOR_HELPER_2_LN,
  SETTINGS_MODAL_PIU_FORM_BUTTON_LN,
  SETTINGS_MODAL_PIU_FORM_VALID_MESSAGE_1_LN,
  SETTINGS_MODAL_PIU_FORM_VALID_MESSAGE_2_LN,
  SETTINGS_MODAL_PIU_FORM_VALID_MESSAGE_3_LN,
  SETTINGS_MODAL_PIU_FORM_VALID_MESSAGE_4_LN,
  SIGN_IN_VALID_MESSAGE_1_LN,
  SIGN_IN_VALID_MESSAGE_2_LN,
  SIGN_IN_VALID_MESSAGE_3_LN,
  SIGN_IN_CSI_MODAL_TITLE_LN,
  SIGN_IN_CSI_MODAL_INPUT_PLACEHOLDER_LN,
  SIGN_IN_CSI_MODAL_BUTTON_NAME_1_LN,
  SIGN_IN_CSI_MODAL_BUTTON_NAME_2_LN,
  SIGN_IN_CSI_MODAL_BUTTON_NAME_3_LN,
  SIGN_IN_QB_MODAL_SUCCESS_MESSAGE_LN,
  SIGN_IN_QB_MODAL_DENIE_MESSAGE_LN,
  SIGN_IN_QB_MODAL_BUTTON_LN,
  TAX_SUMMARY_MODAL_TITLE_LN,
  TAX_SUMMARY_MODAL_TITLE_TOOLTIP_LN,
  TAX_SUMMARY_MODAL_INFO_MESSAGE_1_LN,
  TAX_SUMMARY_MODAL_INFO_MESSAGE_2_LN,
  TAX_SUMMARY_MODAL_TOTAL_TITLE_LN,
  TAX_SUMMARY_MODAL_BILLABLE_TOTAL_TITLE_LN,
  TAX_SUMMARY_MODAL_BUTTON_EXPORT_CSV_LN,
  TAX_SUMMARY_MODAL_TABLE_CELL_1_LN,
  TAX_SUMMARY_MODAL_TABLE_CELL_2_LN,
  TAX_SUMMARY_MODAL_TABLE_CELL_3_LN,
  PROFILE_CONFIRMATION_TITLE_1_LN,
  PROFILE_MODAL_TITLE_1_LN,
  PROFILE_MODAL_TITLE_2_LN,
  PROFILE_MODAL_TITLE_3_LN,
  PROFILE_MODAL_BUTTON_1_LN,
  PROFILE_MODAL_BUTTON_2_LN,
  PROFILE_MODAL_SUB_TITLE_1_LN,
  PROFILE_MODAL_QB_BUTTON_1_LN,
  PROFILE_MODAL_QB_BUTTON_2_LN,
  PROFILE_MODAL_COPY_TITLE_1_LN,
  PROFILE_MODAL_COPY_TITLE_2_LN,
  PROFILE_MODAL_CSI_BUTTON_1_LN,
  PROFILE_MODAL_CSI_BUTTON_2_LN,
  PROFILE_MODAL_COPY_TITLE_3_LN,
  PROFILE_MODAL_FORM_TITLE_LN,
  PROFILE_MODAL_FORM_INPUT_USERMANE_PLACEHOLDER_LN,
  PROFILE_MODAL_FORM_INPUT_NEW_PASSWORD_PLACEHOLDER_LN,
  PROFILE_MODAL_FORM_INPUT_CONFIRM_PASSWORD_PLACEHOLDER_LN,
  PROFILE_MODAL_FORM_INPUT_FIRST_NAME_PLACEHOLDER_LN,
  PROFILE_MODAL_FORM_INPUT_LAST_NAME_PLACEHOLDER_LN,
  PROFILE_MODAL_FORM_INPUT_COMPANY_NAME_PLACEHOLDER_LN,
  PROFILE_MODAL_FORM_INPUT_PHONE_PLACEHOLDER_LN,
  PROFILE_MODAL_FORM_INPUT_PHONE_BOTTOM_HELPER_LN,
  PROFILE_MODAL_SUB_TITLE_2_LN,
  PROFILE_MODAL_FORM_INPUT_ADDRESS_PLACEHOLDER_LN,
  PROFILE_MODAL_FORM_INPUT_CITY_PLACEHOLDER_LN,
  PROFILE_MODAL_FORM_INPUT_STATE_PLACEHOLDER_LN,
  PROFILE_MODAL_FORM_INPUT_POSTAL_CODE_PLACEHOLDER_LN,
  PROFILE_MODAL_FORM_USER_ADMIN_SWITCH_LABEL_LN,
  PROFILE_MODAL_FORM_VALID_MESSAGE_1_LN,
  PROFILE_MODAL_FORM_VALID_MESSAGE_2_LN,
  PROFILE_MODAL_FORM_VALID_MESSAGE_3_LN,
  PROFILE_MODAL_FORM_VALID_MESSAGE_4_LN,
  PROFILE_MODAL_FORM_VALID_MESSAGE_5_LN,
  PROFILE_MODAL_FORM_VALID_MESSAGE_6_LN,
  PROFILE_MODAL_FORM_VALID_MESSAGE_7_LN,
  PROFILE_MODAL_FORM_VALID_MESSAGE_8_LN,
  PROFILE_MODAL_FORM_VALID_MESSAGE_9_LN,
  USERS_PAGE_TITLE_LN,
  USERS_PAGE_TABLE_CELL_1_LN,
  USERS_PAGE_TABLE_CELL_2_LN,
  USERS_PAGE_TABLE_CELL_3_LN,
  USERS_PAGE_TABLE_CELL_4_LN,
  USERS_PAGE_TABLE_CELL_5_LN,
  USERS_PAGE_TABLE_CELL_6_LN,
  USERS_PAGE_USER_MENU_CONFIRMATION_TITLE_1_LN,
  USERS_PAGE_USER_MENU_CONFIRMATION_BUTTON_1_LN,
  USERS_PAGE_USER_MENU_CONFIRMATION_MESSAGE_1_LN,
  USERS_PAGE_USER_MENU_ITEM_1_LN,
  USERS_PAGE_USER_MENU_ITEM_2_LN,
  USERS_PAGE_USER_MENU_ITEM_3_LN,
  USERS_PAGE_USER_MENU_ITEM_4_LN,
  DEFAULT_ALERT_MESSAGE_LN,
  CREATE_USER_SUCCSESS_MESSAGE_LN,
  UPDATE_USER_SUCCSESS_MESSAGE_LN,
  PROFILE_SUCCSESS_MESSAGE_LN,
  NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_1_ALERT_LN,
  NOTIFICATION_MENU_TITLE_LN,
  TAX_SUMMARY_MODAL_TABLE_CELL_4_LN,
  CONFIRMATION_TITLE_FOR_DELETE_INVOICE_LN,
  NEW_BATCH_INFO_BOX_MESSAGE_3_LN,
  NEW_BATCH_INFO_BOX_MESSAGE_4_LN,
  PROFILE_MODAL_FORM_VALID_MESSAGE_10_LN,
  SETTING_RATE_CONFIRMATION_MESSAGE_1_LN,
  SETTING_RATE_CONFIRMATION_MESSAGE_2_LN,
  MAPEED_PRODUCT_TITLE_TOOLTIP_1_LN,
  DELETED_INVOICES_MESSAGE_LN,
  FILTER_BUTTON_NAME_LN,
  BACK_TO_COMAPNIES_BUTTON_NAME_LN,
  REPORT_EXPORT_TO_CSV_BUTTON_LN,
  YOU_DONT_HAVE_BATCHES_INFO_MESSAGE_LN,
  SEARCH_PLACEHOLDER_FOR_COMPANIES_LN,
  COMPANIES_NAV_MENU_LN,
  SELECT_DATE_RANGE_LOGS_LN,
  SELECT_FROM_DATE_LOGS_LN,
  SELECT_TO_DATE_LOGS_LN,
  SELECT_LOGS_TYPE_LN,
  EXPORT_TO_CSV_LOGS_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TITLE_LN,
  MONTHLY_TAX_SUMMARY_REPORT_SUBTITLE_1_LN,
  MONTHLY_TAX_SUMMARY_REPORT_SUBTITLE_2_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_1_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_2_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_3_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_4_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_5_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_6_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_7_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_8_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_9_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_10_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_11_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_12_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_13_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_14_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_15_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_16_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_17_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_18_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_19_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_20_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_INFO_BOX_LN,
  QB_TO_PORTAL_REPORT_TITLE_LN,
  QB_TO_PORTAL_REPORT_1_LN,
  QB_TO_PORTAL_REPORT_2_LN,
  QB_TO_PORTAL_REPORT_TABLE_CELL_1_LN,
  QB_TO_PORTAL_REPORT_TABLE_CELL_2_LN,
  QB_TO_PORTAL_REPORT_TABLE_CELL_3_LN,
  QB_TO_PORTAL_REPORT_TABLE_CELL_4_LN,
  QB_TO_PORTAL_REPORT_TABLE_CELL_5_LN,
  QB_TO_PORTAL_REPORT_TABLE_CELL_6_LN,
  QB_TO_PORTAL_REPORT_TABLE_CELL_7_LN,
  QB_TO_PORTAL_REPORT_TABLE_CELL_8_LN,
  QB_TO_PORTAL_REPORT_TABLE_CELL_9_LN,
  QB_TO_PORTAL_REPORT_TABLE_CELL_10_LN,
  QB_TO_PORTAL_REPORT_TABLE_CELL_11_LN,
  QB_TO_PORTAL_REPORT_TABLE_INFO_BOX_LN,
  SELECT_DATE_RANGE_REPORT_LN,
  SELECT_FROM_DATE_REPORT_LN,
  SELECT_TO_DATE_REPORT_LN,
  GET_REPORT_BUTTON_NAME_LN,
  REMORT_NAV_MENU_NAME_1_LN,
  REMORT_NAV_MENU_NAME_2_LN,
  REPORT_DATE_RANGE_BUTTON_LN,
  RAW_DETAIL_TAX_SUMMARY_MODAL_BUTTON_1_LN,
  DELETE_INVOICE_MESSAGES_POP_UP_LN,
  RATE_TEXT_TOOLTIP_LN,
  RAW_DETAIL_TAX_SUMMARY_MODAL_TITLE_2_LN,
  RAW_DETAIL_TAX_SUMMARY_MODAL_TITLE_3_LN,
  SIGN_IN_CSI_MODAL_SUCCESS_MESSAGE_LN,
  SIGN_IN_CSI_MODAL_DENIE_MESSAGE_LN,
  ADD_NEW_USER_CONFIRM_MODAL_TITLE,
  INVOICES_TITLE_LN,
  YOU_DONT_HAVE_INVOICES_INFO_MESSAGE_LN,
  TOOLTIP_BATCH_TITLE_1_LN,
  TABLE_COLUMN_HEADER_MOUSE_OVER_INCLUDE_LN,
  TABLE_COLUMN_HEADER_MOUSE_OVER_EMAILED_LN,
  TABLE_COLUMN_HEADER_MOUSE_OVER_TAXES_EXIST_LN,
  TABLE_COLUMN_HEADER_MOUSE_OVER_TAX_EXEMPTION_LN,
  TABLE_COLUMN_HEADER_MOUSE_OVER_POSTAL_CODE_LN,
  SETTINGS_MODAL_PIU_MESSAGE_2_LN,
  INVOICE_TABLE_HEADER_CELL_1_LN,
  INVOICE_TABLE_HEADER_CELL_2_LN,
  INVOICE_TABLE_HEADER_CELL_3_LN,
  INVOICE_TABLE_HEADER_CELL_4_LN,
  INVOICE_TABLE_HEADER_CELL_5_LN,
  INVOICE_TABLE_HEADER_CELL_6_LN,
  INVOICE_TABLE_HEADER_CELL_7_LN,
  INVOICE_TABLE_HEADER_CELL_8_LN,
  INVOICE_TABLE_HEADER_CELL_9_LN,
  MAP_PRODUCT_CONFIRMATION_TITLE_LN,
  MAPPED_PRODUCT_CONFIRMATION_TITLE_LN,
  NEW_BATCH_CONFIRMATION_TITLE_1_LN,
  NEW_BATCH_CONFIRMATION_TITLE_2_LN,
  ADD_NEW_USER_CONFIRM_MODAL_1_TITLE,
  COMPANY_NAME_TITLE_LN,
  USER_MENU_CONFIRMATION_TITLE_WORD_1_LN,
  USER_MENU_CONFIRMATION_TITLE_WORD_2_LN,
  USER_ADMIN_QB_COMPANY_TEXT_LN,
  PROCESSED_BATCH_SUMMARY_CONFIRMATION_BUTTON_3_LN,
  NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_2_ALERT_LN,
  CSI_DISCONNECT_MESSAGE_ON_BATCHES_PAGE_LN,
  SIGN_IN_CSI_MODAL_SUCCESS_MESSAGE_2_LN,
  MAP_PRODUCT_TITLE_TOOLTIP_2_LN,
  PROFILE_MODAL_FORM_VALID_MESSAGE_0_LN,
  TABLE_COLUMN_HEADER_MOUSE_OVER_INCLUDE_NEW_BATCH_LN,
  TABLE_COLUMN_HEADER_MOUSE_OVER_CUST_TYPE_LN,
  NEW_BATCH_CONFIRMATION_TITLE_3_LN,
  DEFAULT_EXIT_BUTTON_LN,
  CONNECT_CSI_BTN_LN,
  DISCONNECT_CSI_BTN_LN,
  MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_21_LN,
  INVOICE_DETAIL_TITLE_PART_3_LN,
  INVOICE_DETAIL_TITLE_PART_4_LN,
} from 'constants/language-key-constants'

export const eng_translation = {
  translation: {
    [DEFAULT_CONFIRAMATION_MODAL_TITLE_LN]: 'Are you sure about your action?',
    [DEFAULT_CONFIRAMATION_MODAL_BUTTON_YES_LN]: 'Yes',
    [DEFAULT_CONFIRAMATION_MODAL_BUTTON_NO_LN]: 'No',
    [DEFAULT_EXIT_BUTTON_LN]: 'Exit',
    [DEFAULT_CANCEL_BUTTON_LN]: 'CANCEL',
    [DEFAULT_ALERT_MESSAGE_LN]: 'Something went wrong...',
    [CREATE_USER_SUCCSESS_MESSAGE_LN]: 'The user has been created',
    [UPDATE_USER_SUCCSESS_MESSAGE_LN]: "The user's profile has been updated",
    [PROFILE_SUCCSESS_MESSAGE_LN]: 'User profile has been updated',
    [SIGN_IN_LN]: 'Sign In',
    [EMAIL_LN]: 'Email',
    [PASSWORD_LN]: 'Password',
    [CANT_ACCESS_YOUR_ACCOUNT_LN]: 'Can’t access your account?',
    [PLACEHOLDER_BY_USERS_SEARCH_INPUT_LN]: 'Search by grid content',
    [ADD_NEW_USER_BUTTON_LN]: 'ADD NEW USER',
    [PLACEHOLDER_BY_ADMIN_BATCHES_SEARCH_INPUT_LN]: 'Search by grid content',
    [PLACEHOLDER_BY_CLIENT_BATCHES_SEARCH_INPUT_LN]: 'Search by grid content',
    [NEW_BATCH_BUTTON_NAME_LN]: 'New Batch',
    [TOOLTIP_BATCH_TITLE_1_LN]: 'Click to copy Batch ID:',
    [TOOLTIP_BATCH_TITLE_LN]: 'Click to copy full batch ID',
    [COUNT_BATCH_TEXT_LN]: 'Invoice(s)',
    [BATCH_CELL_NAME_1_LN]: 'Invoice #',
    [BATCH_CELL_NAME_2_LN]: 'Customer',
    [BATCH_CELL_NAME_3_LN]: 'Invoice Date',
    [BATCH_CELL_NAME_4_LN]: 'Postal Code',
    [BATCH_CELL_NAME_5_LN]: 'Revenue',
    [BATCH_CELL_NAME_6_LN]: 'Tax Exempt',
    [BATCH_CELL_NAME_7_LN]: 'Tax Total',
    [BATCH_CELL_NAME_8_LN]: 'In QB',
    [CSI_ERROR_LN]: 'CSI Error',
    [BATCHES_TITLE_LN]: 'Batches',
    [QB_DISCONNECT_MESSAGE_ON_BATCHES_PAGE_LN]:
      'QuickBooks is currently disconnected. Please connect to a valid QuickBooks account',
    [CSI_DISCONNECT_MESSAGE_ON_BATCHES_PAGE_LN]:
      'CSI Tax Rating account is currently disconnected. Please contact customer support at support@csilongwood.com',
    [BATCH_TITLE_LN]: 'Batch ID',
    [BATCH_TITLE_PROCESS_DATE_LN]: 'Process Date',
    [BATCH_COMPANY_TITLE_LN]: 'Company ID',
    [SING_IN_QB_CONNECT_MODAL_TITLE_LN]: 'Portal User',
    [SING_IN_QB_CONNECT_MODAL_TOP_MESSAGE_LN]:
      'Please connect your QuickBooks account',
    [SING_IN_QB_CONNECT_MODAL_BOTTOM_MESSAGE_LN]:
      'Note that certain functionality will be disabled until this connection is successfully established',
    [SING_IN_QB_CONNECT_MODAL_BUTTON_CONNECT_LN]: 'Connect QuickBooks Account',
    [SKIP_BUTTON_NAME_LN]: 'Skip this step',
    [SING_IN_CSI_CONNECT_MODAL_TOP_MESSAGE_LN]:
      'Please connect your CSI Tax Rating account',
    [SING_IN_CSI_CONNECT_MODAL_BOTTOM_MESSAGE_LN]:
      'Note that certain functionality will be disabled until this connection is successfully established',
    [SING_IN_CSI_CONNECT_MODAL_BUTTON_CONNECT_LN]:
      'CONNECT CSI Tax Rating Account',
    [EXPORT_CONFIRMATION_MODAL_TITLE_LN]: 'Export Confirmation',
    [EXPORT_CONFIRMATION_MODAL_MESSAGE_LN]:
      'Selected invoice(s) have been successfully updated in QuickBooks',
    [CLOSE_BUTTON_NAME_LN]: 'Close',
    [HEADER_BATCHES_ITEM_MENU_LN]: 'BATCHES',
    [HEADER_INVOICES_ITEM_MENU_LN]: 'INVOICES',
    [HEADER_REPORTS_ITEM_MENU_LN]: 'REPORTS',
    [HEADER_LOGS_ITEM_MENU_LN]: 'LOGS',
    [HEADER_USERS_ITEM_MENU_LN]: 'USERS',
    [HEADER_PROFILE_MENU_ITEM_1_LN]: 'Profile',
    [HEADER_PROFILE_MENU_ITEM_2_LN]: 'Settings',
    [HEADER_PROFILE_MENU_ITEM_3_LN]: 'Log Out',
    [INVOICE_DETAIL_TITLE_PART_1_LN]: 'Invoice',
    [INVOICE_DETAIL_TITLE_PART_2_LN]: 'Details',
    [INVOICE_DETAIL_TITLE_PART_3_LN]: 'Credit Memo',
    [INVOICE_DETAIL_TITLE_PART_4_LN]: 'Sales Receipt',
    [INVOICE_DETAIL_CUSTOMER_LN]: 'Customer',
    [INVOICE_DETAIL_EMAIL_LN]: 'Email',
    [INVOICE_DETAIL_INVOICE_DATE_LN]: 'Invoice date',
    [INVOICE_DETAIL_ADRESS_LN]: 'Address',
    [INVOICE_DETAIL_CITY_LN]: 'City',
    [INVOICE_DETAIL_STATE_LN]: 'State',
    [INVOICE_DETAIL_POSTAL_CODE_LN]: 'Postal Code',
    [INVOICE_DETALT_TABLE_COUNT_TITLE_LN]: 'Product lines',
    [INVOICE_DETALT_EXPORT_CSV_BUTTON_LN]: 'Export to CSV',
    [DISABLED_EXPORT_SCV_TOOLTIP_LN]:
      "Admin can't export to CSV, log in as a client owner of the invoice",
    [INVOICE_DETAIL_TOTAL_LN]: 'Total',
    [INVOICE_DETALT_TABLE_CELL_1_TITLE_LN]: 'Product/Service',
    [INVOICE_DETALT_TABLE_CELL_2_TITLE_LN]: 'Description',
    [INVOICE_DETALT_TABLE_CELL_3_TITLE_LN]: 'Quantity',
    [INVOICE_DETALT_TABLE_CELL_4_TITLE_LN]: 'Rate',
    [INVOICE_DETALT_TABLE_CELL_5_TITLE_LN]: 'Amount',
    [MAP_PRODUCT_TITLE_1_LN]: 'Unmapped Products',
    [MAP_PRODUCT_TITLE_2_LN]: 'Map Products',
    [MAP_PRODUCT_TITLE_TOOLTIP_LN]:
      'Map Product/Service: select Product/Service, select corresponding Tax Code, click on the Create Mapping button. Update portal with latest available Product/Services from QuickBooks by clicking on the Pull button.',
    [MAP_PRODUCT_TITLE_TOOLTIP_2_LN]:
      'Map Product/Service: select Product/Service, select corresponding Tax Code, click on the Create Mapping button.',
    [MAPEED_PRODUCT_TITLE_TOOLTIP_1_LN]:
      'Unmap Product/Service: select one or more Product(s)/Service(s), click on the Remove Mapping button.',
    [MAP_PRODUCT_MESSAGE_1_LN]:
      'QuickBooks is currently disconnected. Please connect to a valid QuickBooks account',
    [MAP_PRODUCT_MESSAGE_2_LN]: 'Product has been mapped',
    [MAP_PRODUCT_MESSAGE_3_LN]:
      'Unmapped Products/Services identified, please map them prior to proceeding with batch generation',
    [MAP_PRODUCT_MESSAGE_4_LN]: 'No unmapped products/services',
    [MAP_PRODUCT_SECTION_TITLE_1_LN]: 'Product/Service',
    [MAP_PRODUCT_SECTION_PLACEHOLDER_1_LN]: 'Search by Product/Service',
    [MAP_PRODUCT_SECTION_TITLE_2_LN]: 'Tax Code',
    [MAP_PRODUCT_SECTION_PLACEHOLDER_2_LN]: 'Search by Tax Code',
    [MAP_PRODUCT_BUTTON_NAME_1_LN]: 'Create Mapping',
    [MAP_PRODUCT_BUTTON_NAME_2_LN]: 'Pull Products/Services from QuickBooks',
    [MAPPED_PRODUCT_TITLE_LN]: 'Mapped Products',
    [MAPPED_PRODUCT_INFO_MESSAGE_LN]: 'Product(s) has been unmapped',
    [MAPPED_PRODUCT_TABLE_COUNT_TITLE_LN]: 'Mapped Products',
    [MAPPED_PRODUCT_BUTTON_NAME_1_LN]: 'Remove Mapping',
    [MAPPED_PRODUCT_TABLE_CELL_TITLE_1_LN]: 'Select',
    [MAPPED_PRODUCT_TABLE_CELL_TITLE_2_LN]: 'Product/Service',
    [MAPPED_PRODUCT_TABLE_CELL_TITLE_3_LN]: 'Tax Code',
    [NEW_BATCH_MODAL_TOOLTIP_LN]:
      'Populate Batch: select a date range that contains at least one invoice and click on the Get Invoices button. Note that a single batch cannot contain more than 800 invoices.',
    [NEW_BATCH_MODAL_INFOBOX_MESSAGE_1_LN]:
      'Invoice pull request in progress. Please stand by.',
    [NEW_BATCH_MODAL_INFOBOX_MESSAGE_2_LN]:
      'No available invoices in the selected date range. Please select a date range that contains at least one invoice',
    [NEW_BATCH_MODAL_INFOBOX_MESSAGE_3_LN]:
      "Batches with prior or future invoice date will process taxes as of today's date. All tax rating will occur as of today’s date.",
    [NEW_BATCH_MODAL_TITLE_LN]: 'New Batch',
    [NEW_BATCH_MODAL_DATE_FROM_LN]: 'From',
    [NEW_BATCH_MODAL_DATE_TO_LN]: 'To',
    [NEW_BATCH_MODAL_DATE_TO_MESSAGE_LN]:
      '"From" date cannot be later than "To" date',
    [NEW_BATCH_MODAL_BUTTON_TOOLTIP_LN]:
      'Unmapped products/services identified, please map them prior to proceeding with batch generation',
    [NEW_BATCH_MODAL_BUTTON_ZIP_CODE_INVALID_TOOLTIP_LN]:
      'You have invalid postal code in one from selected invoices',
    [NEW_BATCH_MODAL_BUTTON_1_LN]: 'Generate',
    [NEW_BATCH_MODAL_BUTTON_2_LN]: 'Exit',
    [NEW_BATCH_MODAL_BUTTON_3_LN]: 'get invoices',
    [NEW_BATCH_MODAL_AVAILABLE_INVOICES_TITLE_LN]: 'Invoice(s)',
    [NEW_BATCH_MODAL_AVAILABLE_INVOICES_TITLE_TOOLTIP_LN]:
      'All available QuickBooks invoices within the selected date range. Use Include checkbox to designate invoices that should be processed through the Tax Rating System.',
    [NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_COUNT_TITLE_LN]: 'Invoice(s)',
    [NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_1_LN]: 'Include',
    [NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_2_LN]: 'Emailed',
    [NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_3_LN]: 'Taxes Exist',
    [NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_4_LN]: 'Exempt',
    [NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_5_LN]: 'Invoice #',
    [NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_6_LN]: 'Customer',
    [NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_7_LN]: 'Invoice Date',
    [NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_8_LN]: 'Postal Code',
    [NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_9_LN]: 'Revenue',
    [NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_1_ALERT_LN]:
      "You can't select this invoice because it has been deleted. Make sure to delete this invoice from QuickBooks",
    [NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_2_ALERT_LN]:
      "You can't select this invoice, zip code is empty. Please define it in QuickBooks",
    [NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_SELECT_TITLE_LN]:
      'Select Tax Exemption',
    [PROCESSED_BATCH_SUMMARY_CONFIRMATION_TITLE_1_LN]:
      'Proceed with QuickBooks Export?',
    [PROCESSED_BATCH_SUMMARY_CONFIRMATION_BUTTON_1_LN]: 'QuickBooks Export',
    [PROCESSED_BATCH_SUMMARY_CONFIRMATION_TITLE_2_LN]:
      'Exit without sending taxes to QuickBooks?',
    [PROCESSED_BATCH_SUMMARY_CONFIRMATION_BUTTON_2_LN]: 'YES',
    [PROCESSED_BATCH_SUMMARY_CONFIRMATION_BUTTON_3_LN]: 'NO',
    [PROCESSED_BATCH_SUMMARY_TITLE_PAST_1_LN]: 'Processed taxes for Batch ID',
    [PROCESSED_BATCH_SUMMARY_TITLE_PAST_2_LN]: 'Summary',
    [PROCESSED_BATCH_SUMMARY_TITLE_TOOLTIP_LN]:
      'Only items with Include checkmark will be updated in QuickBooks.',
    [PROCESSED_BATCH_SUMMARY_TABLE_COUNT_TITLE_LN]: 'Available Invoices',
    [PROCESSED_BATCH_SUMMARY_BUTTON_TITLE_1_LN]: 'SEND TO QUICKBOOKS',
    [PROCESSED_BATCH_SUMMARY_TABLE_CELL_1_LN]: 'Include',
    [PROCESSED_BATCH_SUMMARY_TABLE_CELL_2_LN]: 'Emailed',
    [PROCESSED_BATCH_SUMMARY_TABLE_CELL_3_LN]: 'Taxes Exist',
    [PROCESSED_BATCH_SUMMARY_TABLE_CELL_4_LN]: 'Exempt',
    [PROCESSED_BATCH_SUMMARY_TABLE_CELL_5_LN]: 'Invoice #',
    [PROCESSED_BATCH_SUMMARY_TABLE_CELL_6_LN]: 'Customer',
    [PROCESSED_BATCH_SUMMARY_TABLE_CELL_7_LN]: 'Invoice Date',
    [PROCESSED_BATCH_SUMMARY_TABLE_CELL_8_LN]: 'Revenue',
    [PROCESSED_BATCH_SUMMARY_TABLE_CELL_9_LN]: 'Billable Taxes',
    [PROCESSED_BATCH_SUMMARY_TABLE_CELL_1_TOOLTIP_LN]:
      'Invoice version is outdated due to changes made in QB. Please reprocess invoice to update Portal version.',
    [PROCESSING_INVOICESS_MODAL_TITLE_LN]: 'Getting Taxes',
    [PROCESSING_INVOICESS_MODAL_MESSAGE_1_LN]:
      'Batch processing is taking more time than expected. You can close this dialog and continue to monitor completion status under the notification area.',
    [PROCESSING_INVOICESS_MODAL_MESSAGE_2_LN]:
      'Batch has been added to the processing queue. You will receive a new notification once the batch has been processed.',
    [PROCESSING_INVOICESS_MODAL_MESSAGE_3_LN]:
      'To complete the batch workflow and export data to QuickBooks you will need to review the Processed Batch Summary dialog. If you choose to Continue, the dialog can be accessed by clicking on the processed batch notification or opening up the Batch ID of a particular invoice on the Invoices screen. You also have an option to remain on this dialog and view the Processed Batch Summary dialog post-processing.',
    [PROCESSING_INVOICESS_MODAL_BUTTON_LN]: 'CONTINUE',
    [RAW_DETAIL_TAX_SUMMARY_MODAL_BUTTON_LN]: 'Tax Raw Detail',
    [RAW_DETAIL_TAX_SUMMARY_MODAL_TITLE_1_LN]: 'Tax Summary Raw Detail',
    [SETTINGS_MODAL_TITLE_LN]: 'Settings',
    [SETTINGS_MODAL_TITLE_TOOLTIP_LN]:
      'Rate selection and product mapping changes can be updated under settings.',
    [SETTINGS_MODAL_PIU_TITLE_LN]: 'Rate',
    [SETTINGS_MODAL_PIU_MESSAGE_1_LN]: 'Rate set to PIU',
    [SETTINGS_MODAL_PIU_MESSAGE_2_LN]: 'Rate set to Safe Harbor defaults',
    [SETTINGS_MODAL_PIU_FORM_FIELD_1_LN]: 'Percentage of Interstate Use (PIU)',
    [SETTINGS_MODAL_PIU_FORM_FIELD_2_LN]: 'Safe Harbor',
    [SETTINGS_MODAL_PIU_FORM_FIELD_SAFE_HARBOR_HELPER_1_LN]: 'VoIP',
    [SETTINGS_MODAL_PIU_FORM_FIELD_SAFE_HARBOR_HELPER_2_LN]: 'Wireless',
    [SETTINGS_MODAL_PIU_FORM_BUTTON_LN]: 'Update',
    [SETTINGS_MODAL_PIU_FORM_VALID_MESSAGE_1_LN]: 'This field cannot be blank',
    [SETTINGS_MODAL_PIU_FORM_VALID_MESSAGE_2_LN]: 'PIU value is invalid',
    [SETTINGS_MODAL_PIU_FORM_VALID_MESSAGE_3_LN]: 'PIU must not be more 200%',
    [SETTINGS_MODAL_PIU_FORM_VALID_MESSAGE_4_LN]: 'PIU must be more 1%',
    [SIGN_IN_VALID_MESSAGE_1_LN]: 'Email field cannot be blank',
    [SIGN_IN_VALID_MESSAGE_2_LN]: 'Password field cannot be blank',
    [SIGN_IN_VALID_MESSAGE_3_LN]: 'Email and password fields cannot be blank',
    [SIGN_IN_CSI_MODAL_TITLE_LN]: 'Connect to CSI Tax Rating Account',
    [SIGN_IN_CSI_MODAL_INPUT_PLACEHOLDER_LN]: 'CSI Access Token',
    [SIGN_IN_CSI_MODAL_BUTTON_NAME_1_LN]: 'Sign In',
    [SIGN_IN_CSI_MODAL_BUTTON_NAME_2_LN]: 'Can’t access your CSI account?',
    [SIGN_IN_CSI_MODAL_BUTTON_NAME_3_LN]: 'Skip this step',
    [SIGN_IN_QB_MODAL_SUCCESS_MESSAGE_LN]:
      'QuickBooks was successfully connected to the Portal',
    [SIGN_IN_QB_MODAL_DENIE_MESSAGE_LN]:
      'Quickbooks account was not connected, you can try again to establish connection from your Portal profile',
    [SIGN_IN_QB_MODAL_BUTTON_LN]: 'Ok',
    [TAX_SUMMARY_MODAL_TITLE_LN]: 'Tax Summary',
    [TAX_SUMMARY_MODAL_TITLE_TOOLTIP_LN]:
      'Tax Summary represents the invoice tax data output post CSI processing',
    [TAX_SUMMARY_MODAL_INFO_MESSAGE_1_LN]:
      'An Error occurred, please contact your administrator',
    [TAX_SUMMARY_MODAL_INFO_MESSAGE_2_LN]:
      'The products in this invoice are non-taxable',
    [TAX_SUMMARY_MODAL_TOTAL_TITLE_LN]: 'Total Government Taxes and Surcharges',
    [TAX_SUMMARY_MODAL_BILLABLE_TOTAL_TITLE_LN]:
      'Total Billable Taxes and Surcharges',
    [TAX_SUMMARY_MODAL_BUTTON_EXPORT_CSV_LN]: 'Export to CSV',
    [TAX_SUMMARY_MODAL_TABLE_CELL_1_LN]: 'Tax Description',
    [TAX_SUMMARY_MODAL_TABLE_CELL_2_LN]: 'Tax Authority',
    [TAX_SUMMARY_MODAL_TABLE_CELL_3_LN]: 'Tax Amount',
    [PROFILE_CONFIRMATION_TITLE_1_LN]: 'Make the user is admin?',
    [PROFILE_MODAL_TITLE_1_LN]: 'Profile',
    [PROFILE_MODAL_TITLE_2_LN]: 'Add New User',
    [PROFILE_MODAL_TITLE_3_LN]: 'Edit User',
    [PROFILE_MODAL_BUTTON_1_LN]: 'Update',
    [PROFILE_MODAL_BUTTON_2_LN]: 'Add User',
    [PROFILE_MODAL_SUB_TITLE_1_LN]: 'Accounts',
    [PROFILE_MODAL_QB_BUTTON_1_LN]: 'Disconnect QuickBooks Account',
    [PROFILE_MODAL_QB_BUTTON_2_LN]: 'Connect QuickBooks Account',
    [PROFILE_MODAL_COPY_TITLE_1_LN]: 'QB Company Name:',
    [PROFILE_MODAL_COPY_TITLE_2_LN]: 'QB Company ID:',
    [PROFILE_MODAL_CSI_BUTTON_1_LN]: 'CSI Tax Rating Account is CONNECTED',
    [PROFILE_MODAL_CSI_BUTTON_2_LN]: 'CONNECT CSI Tax Rating Account',
    [PROFILE_MODAL_COPY_TITLE_3_LN]: 'CSI Authentication Key:',
    [PROFILE_MODAL_FORM_TITLE_LN]: 'User Info',
    [PROFILE_MODAL_FORM_INPUT_USERMANE_PLACEHOLDER_LN]: 'Username',
    [PROFILE_MODAL_FORM_INPUT_NEW_PASSWORD_PLACEHOLDER_LN]: 'New Password',
    [PROFILE_MODAL_FORM_INPUT_CONFIRM_PASSWORD_PLACEHOLDER_LN]:
      'Confirm Password',
    [PROFILE_MODAL_FORM_INPUT_FIRST_NAME_PLACEHOLDER_LN]: 'First Name',
    [PROFILE_MODAL_FORM_INPUT_LAST_NAME_PLACEHOLDER_LN]: 'Last Name',
    [PROFILE_MODAL_FORM_INPUT_COMPANY_NAME_PLACEHOLDER_LN]: 'Company Name',
    [PROFILE_MODAL_FORM_INPUT_PHONE_PLACEHOLDER_LN]: 'Phone',
    [PROFILE_MODAL_FORM_INPUT_PHONE_BOTTOM_HELPER_LN]:
      'Phone format: (800)322-1234',
    [PROFILE_MODAL_SUB_TITLE_2_LN]: 'Address',
    [PROFILE_MODAL_FORM_INPUT_ADDRESS_PLACEHOLDER_LN]: 'Address',
    [PROFILE_MODAL_FORM_INPUT_CITY_PLACEHOLDER_LN]: 'City',
    [PROFILE_MODAL_FORM_INPUT_STATE_PLACEHOLDER_LN]: 'State',
    [PROFILE_MODAL_FORM_INPUT_POSTAL_CODE_PLACEHOLDER_LN]: 'Postal code',
    [PROFILE_MODAL_FORM_USER_ADMIN_SWITCH_LABEL_LN]: 'User is admin',
    [PROFILE_MODAL_FORM_VALID_MESSAGE_1_LN]: 'Email format: abc@example.com',
    [PROFILE_MODAL_FORM_VALID_MESSAGE_2_LN]: 'Invalid email address',
    [PROFILE_MODAL_FORM_VALID_MESSAGE_10_LN]: 'Invalid city name',
    [PROFILE_MODAL_FORM_VALID_MESSAGE_3_LN]:
      'Phone field must have min 9 digits',
    [PROFILE_MODAL_FORM_VALID_MESSAGE_4_LN]:
      'Phone field must have max 20 digits',
    [PROFILE_MODAL_FORM_VALID_MESSAGE_5_LN]: 'Invalid phone format',
    [PROFILE_MODAL_FORM_VALID_MESSAGE_6_LN]: 'Invalid postal code',
    [PROFILE_MODAL_FORM_VALID_MESSAGE_7_LN]: 'Password mismatch',
    [PROFILE_MODAL_FORM_VALID_MESSAGE_8_LN]: 'Password must have min 8 symbols',
    [PROFILE_MODAL_FORM_VALID_MESSAGE_9_LN]:
      "Password must haven't more 16 symbols",
    [PROFILE_MODAL_FORM_VALID_MESSAGE_0_LN]: 'This field is required',
    [USERS_PAGE_TITLE_LN]: 'Users',
    [USERS_PAGE_TABLE_CELL_1_LN]: 'Name',
    [USERS_PAGE_TABLE_CELL_2_LN]: 'Profile Company',
    [USERS_PAGE_TABLE_CELL_3_LN]: 'QB Company ID',
    [USERS_PAGE_TABLE_CELL_4_LN]: 'Role',
    [USERS_PAGE_TABLE_CELL_5_LN]: 'User email',
    [USERS_PAGE_TABLE_CELL_6_LN]: 'Actions',
    [USERS_PAGE_USER_MENU_CONFIRMATION_TITLE_1_LN]: 'Sign in as user',
    [USERS_PAGE_USER_MENU_CONFIRMATION_BUTTON_1_LN]: 'SIGN IN',
    [USERS_PAGE_USER_MENU_CONFIRMATION_MESSAGE_1_LN]:
      "By pressing the 'Sign In' button you will be logged out from this administrator account and signed in under the credentials of",
    [USERS_PAGE_USER_MENU_ITEM_1_LN]: 'Edit User',
    [USERS_PAGE_USER_MENU_ITEM_3_LN]: 'Sign In As User',
    [USERS_PAGE_USER_MENU_ITEM_2_LN]: 'Disconnect QB',
    [USERS_PAGE_USER_MENU_ITEM_4_LN]: 'Delete User',
    [NOTIFICATION_MENU_TITLE_LN]: 'Processed Batches',
    [TAX_SUMMARY_MODAL_TABLE_CELL_4_LN]: 'Billable',
    [CONFIRMATION_TITLE_FOR_DELETE_INVOICE_LN]: 'Delete invoice',
    [NEW_BATCH_INFO_BOX_MESSAGE_3_LN]:
      'You can process 800 or less invoices per single batch',
    [NEW_BATCH_INFO_BOX_MESSAGE_4_LN]:
      'You got eight hundred invoices or more. Maybe you are not getting all invoices on the selected date range, because you can process 800 or less invoices per single batch. You can select less date range for a more accurate result.',
    [SETTING_RATE_CONFIRMATION_MESSAGE_1_LN]: 'Update rate?',
    [SETTING_RATE_CONFIRMATION_MESSAGE_2_LN]:
      'Are you sure that you want to select PIU?',
    [DELETED_INVOICES_MESSAGE_LN]:
      'The Invoice has been deleted. Make sure to delete this invoice from QuickBooks',
    [DELETE_INVOICE_MESSAGES_POP_UP_LN]: 'This action cannot be undone',
    [FILTER_BUTTON_NAME_LN]: 'Filter',
    [BACK_TO_COMAPNIES_BUTTON_NAME_LN]: 'Back to Companies List',
    [REPORT_EXPORT_TO_CSV_BUTTON_LN]: 'EXPORT TO CSV',
    [YOU_DONT_HAVE_BATCHES_INFO_MESSAGE_LN]: "You don't have batches",
    [SEARCH_PLACEHOLDER_FOR_COMPANIES_LN]: 'Search by company name, company ID',
    [COMPANIES_NAV_MENU_LN]: 'COMPANIES',
    [SELECT_DATE_RANGE_LOGS_LN]: 'Select Date Range',
    [SELECT_FROM_DATE_LOGS_LN]: 'From',
    [SELECT_TO_DATE_LOGS_LN]: 'To',
    [SELECT_LOGS_TYPE_LN]: 'Select Log Type(s)',
    [EXPORT_TO_CSV_LOGS_LN]: 'EXPORT TO CSV',
    [MONTHLY_TAX_SUMMARY_REPORT_TITLE_LN]: 'Monthly Tax Summary',
    [MONTHLY_TAX_SUMMARY_REPORT_SUBTITLE_1_LN]: 'From',
    [MONTHLY_TAX_SUMMARY_REPORT_SUBTITLE_2_LN]: 'To',
    [MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_1_LN]: 'Geocode',
    [MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_2_LN]: 'Authority Name',
    [MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_3_LN]: 'Report To ID',
    [MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_4_LN]: 'Report To',
    [MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_5_LN]: 'Tax Auth Type',
    [MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_6_LN]: 'Customer Number',
    [MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_7_LN]: 'Invoice Number',
    [MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_8_LN]: 'Invoice Date',
    [MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_9_LN]: 'Tax Type',
    [MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_10_LN]: 'Tax Cat',
    [MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_11_LN]: 'Product Code',
    [MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_12_LN]: 'Service Code',
    [MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_13_LN]: 'Pass Flag',
    [MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_14_LN]: 'Tax Description',
    [MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_15_LN]: 'Tax Rate or Fee',
    [MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_16_LN]: 'Original Charge Amount',
    [MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_17_LN]: 'Exempt Amount',
    [MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_18_LN]: 'Charge Amount',
    [MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_19_LN]: 'Units',
    [MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_20_LN]: 'Tax Amount',
    [MONTHLY_TAX_SUMMARY_REPORT_TABLE_CELL_21_LN]: 'Lines',
    [MONTHLY_TAX_SUMMARY_REPORT_TABLE_INFO_BOX_LN]:
      'No invoice(s) exist within the selected date range',
    [QB_TO_PORTAL_REPORT_TITLE_LN]: 'QB to Portal Comparison',
    [QB_TO_PORTAL_REPORT_1_LN]: 'From',
    [QB_TO_PORTAL_REPORT_2_LN]: 'To',
    [QB_TO_PORTAL_REPORT_TABLE_CELL_1_LN]: 'Batch ID',
    [QB_TO_PORTAL_REPORT_TABLE_CELL_2_LN]: 'Process Date',
    [QB_TO_PORTAL_REPORT_TABLE_CELL_3_LN]: 'In QB',
    [QB_TO_PORTAL_REPORT_TABLE_CELL_4_LN]: 'Exempt',
    [QB_TO_PORTAL_REPORT_TABLE_CELL_5_LN]: 'Customer',
    [QB_TO_PORTAL_REPORT_TABLE_CELL_6_LN]: 'Invoice #',
    [QB_TO_PORTAL_REPORT_TABLE_CELL_7_LN]: 'Invoice Date',
    [QB_TO_PORTAL_REPORT_TABLE_CELL_8_LN]: 'Revenue',
    [QB_TO_PORTAL_REPORT_TABLE_CELL_9_LN]: 'Tax Total (CSI)',
    [QB_TO_PORTAL_REPORT_TABLE_CELL_10_LN]: 'Tax Total (QB)',
    [QB_TO_PORTAL_REPORT_TABLE_CELL_11_LN]: 'Tax Total (Diff)',
    [QB_TO_PORTAL_REPORT_TABLE_INFO_BOX_LN]:
      'No invoice(s) exist within the selected date range',
    [SELECT_DATE_RANGE_REPORT_LN]: 'Select Date Range',
    [SELECT_FROM_DATE_REPORT_LN]: 'From',
    [SELECT_TO_DATE_REPORT_LN]: 'To',
    [GET_REPORT_BUTTON_NAME_LN]: 'Get report',
    [REMORT_NAV_MENU_NAME_1_LN]: 'QB to Portal Comparison',
    [REMORT_NAV_MENU_NAME_2_LN]: 'Monthly Tax Summary',
    [REPORT_DATE_RANGE_BUTTON_LN]: 'Date Range',
    [RAW_DETAIL_TAX_SUMMARY_MODAL_BUTTON_1_LN]: 'Input Raw Detail',
    [RATE_TEXT_TOOLTIP_LN]:
      'Custom PIU rate overrides the Safe Harbor rate for VoIP and Wireless products. See the User Guide for a complete list of products.',
    [RAW_DETAIL_TAX_SUMMARY_MODAL_TITLE_1_LN]: 'Invoice',
    [RAW_DETAIL_TAX_SUMMARY_MODAL_TITLE_2_LN]: 'Tax Raw Detail',
    [RAW_DETAIL_TAX_SUMMARY_MODAL_TITLE_3_LN]: 'Input Raw Detail',
    [SIGN_IN_CSI_MODAL_SUCCESS_MESSAGE_LN]:
      'CSI Tax Rating account was successfully connected to',
    [SIGN_IN_CSI_MODAL_SUCCESS_MESSAGE_2_LN]: '',
    [SIGN_IN_CSI_MODAL_DENIE_MESSAGE_LN]:
      'CSI account was not connected, you can try again to establish connection from your Portal profile',
    [ADD_NEW_USER_CONFIRM_MODAL_TITLE]:
      'Are you sure you want to add this user?',
    [ADD_NEW_USER_CONFIRM_MODAL_1_TITLE]: 'Save updated profile information?',
    [INVOICES_TITLE_LN]: 'Invoices',
    [YOU_DONT_HAVE_INVOICES_INFO_MESSAGE_LN]: "You don't have invoices",
    [TABLE_COLUMN_HEADER_MOUSE_OVER_INCLUDE_LN]:
      'Select invoice(s) to be updated in QuickBooks.',
    [TABLE_COLUMN_HEADER_MOUSE_OVER_INCLUDE_NEW_BATCH_LN]:
      'Invoices that should be included in the batch and processed through the Tax Rating System.',
    [TABLE_COLUMN_HEADER_MOUSE_OVER_EMAILED_LN]:
      'Invoices that have been previously emailed according to QuickBook data.',
    [TABLE_COLUMN_HEADER_MOUSE_OVER_TAXES_EXIST_LN]:
      'Invoices that have existing taxes according to QuickBooks data.',
    [TABLE_COLUMN_HEADER_MOUSE_OVER_TAX_EXEMPTION_LN]:
      'Tax Exemption selections (one per Customer) will be preserved for all future batches.',
    [TABLE_COLUMN_HEADER_MOUSE_OVER_POSTAL_CODE_LN]:
      'Postal Code according to QuickBooks data is based on priority in the following order: Invoice Shipping, Company Shipping, Invoice Billing.',
    [TABLE_COLUMN_HEADER_MOUSE_OVER_CUST_TYPE_LN]:
      'Customer Type selections (one per Customer) will be preserved for all future batches. Options: RES = Residential, BUS = Commercial.',
    [INVOICE_TABLE_HEADER_CELL_1_LN]: 'Process Date',
    [INVOICE_TABLE_HEADER_CELL_2_LN]: 'Batch ID',
    [INVOICE_TABLE_HEADER_CELL_3_LN]: 'Invoice #',
    [INVOICE_TABLE_HEADER_CELL_4_LN]: 'Customer',
    [INVOICE_TABLE_HEADER_CELL_5_LN]: 'Invoice Date',
    [INVOICE_TABLE_HEADER_CELL_6_LN]: 'Postal Code',
    [INVOICE_TABLE_HEADER_CELL_7_LN]: 'Revenue',
    [INVOICE_TABLE_HEADER_CELL_8_LN]: 'Exempt',
    [INVOICE_TABLE_HEADER_CELL_9_LN]: 'Billable Taxes',
    [MAP_PRODUCT_CONFIRMATION_TITLE_LN]: 'Map product(s)?',
    [MAPPED_PRODUCT_CONFIRMATION_TITLE_LN]: 'Unmap product(s)?',
    [NEW_BATCH_CONFIRMATION_TITLE_1_LN]:
      'Exit batch creation? New batch changes will not be saved.',
    [NEW_BATCH_CONFIRMATION_TITLE_3_LN]: 'Exit batch creation?',
    [NEW_BATCH_CONFIRMATION_TITLE_2_LN]:
      'Proceed with batch creation and processing?',
    [COMPANY_NAME_TITLE_LN]: 'Invoices',
    [USER_MENU_CONFIRMATION_TITLE_WORD_1_LN]: 'Disconnect',
    [USER_MENU_CONFIRMATION_TITLE_WORD_2_LN]: 'from QuickBooks?',
    [USER_ADMIN_QB_COMPANY_TEXT_LN]: 'N/A',
    [CONNECT_CSI_BTN_LN]: 'Connect CSI',
    [DISCONNECT_CSI_BTN_LN]: 'Disconnect CSI',
  },
}
