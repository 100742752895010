export const destructureResInvoicesForBatches = (data) =>
  data.map((item) => {
    const _Line = item?.quickbooks_data?.Line || []
    const _summaries = item?.csi_data?.tax_summary?.summaries || []
    return {
      quickbooks_data: {
        DocNumber: item?.quickbooks_data?.DocNumber,
        Id: item?.quickbooks_data?.Id,
        CustomerRef: {
          name: item?.quickbooks_data?.CustomerRef?.name,
          value: item?.quickbooks_data?.CustomerRef?.value,
        },
        BillEmail: item?.quickbooks_data?.BillEmail,
        BillAddr: {
          PostalCode: item?.quickbooks_data?.BillAddr?.PostalCode,
          Line4: item?.quickbooks_data?.BillAddr?.Line4,
          Line1: item?.quickbooks_data?.BillAddr?.Line1,
          Line3: item?.quickbooks_data?.BillAddr?.Line3,
          City: item?.quickbooks_data?.BillAddr?.City,
          CountrySubDivisionCode:
            item?.quickbooks_data?.BillAddr?.CountrySubDivisionCode,
          Country: item?.quickbooks_data?.BillAddr?.Country,
          Line2: item?.quickbooks_data?.BillAddr?.Line2,
          Line5: item?.quickbooks_data?.BillAddr?.Line5,
        },
        ShipAddr: {
          PostalCode: item?.quickbooks_data?.ShipAddr?.PostalCode,
          Line4: item?.quickbooks_data?.ShipAddr?.Line4,
          Line1: item?.quickbooks_data?.ShipAddr?.Line1,
          Line3: item?.quickbooks_data?.ShipAddr?.Line3,
          City: item?.quickbooks_data?.ShipAddr?.City,
          CountrySubDivisionCode:
            item?.quickbooks_data?.ShipAddr?.CountrySubDivisionCode,
          Country: item?.quickbooks_data?.ShipAddr?.Country,
          Line2: item?.quickbooks_data?.ShipAddr?.Line2,
          Line5: item?.quickbooks_data?.ShipAddr?.Line5,
        },
        TotalAmt: item?.quickbooks_data?.TotalAmt,
        Line: _Line.map((line) => ({
          DetailType: line?.DetailType,
          SalesItemLineDetail: {
            ItemRef: {
              name: line?.SalesItemLineDetail?.ItemRef?.name,
              value: line?.SalesItemLineDetail?.ItemRef?.value,
            },
            Qty: line?.SalesItemLineDetail?.Qty,
            UnitPrice: line?.SalesItemLineDetail?.UnitPrice,
          },
          Description: line?.Description,
          Amount: line?.Amount,
        })),
        EmailStatus: item?.quickbooks_data?.EmailStatus,
        TxnDate: item?.quickbooks_data?.TxnDate,
      },
      _id: item._id,
      csi_data: {
        is_errors_exists: item?.csi_data?.is_errors_exists,
        processed_datetime: item?.csi_data?.processed_datetime,
        tax_summary: {
          total_tax_amount: item?.csi_data?.tax_summary?.total_tax_amount,
          billable_total_tax_amount:
            item?.csi_data?.tax_summary?.billable_total_tax_amount,
          summaries: _summaries.map((sum) => ({
            country: sum?.country,
            tax_type: sum?.tax_type,
            tax_cat: sum?.tax_cat,
            description: sum?.description,
            tax_auth: sum?.tax_auth,
            tax_amount_sum: sum?.tax_amount_sum,
            is_billable: sum?.is_billable,
          })),
        },
        csi_response: item?.csi_data?.csi_response,
      },
      is_send_to_qb: item.is_send_to_qb,
      zip_code: item.zip_code,
      exemption_data: item.exemption_data,
      is_deleted: item.is_deleted,
      is_qb_update_needed: item?.is_qb_update_needed,
      realm_id: item?.realm_id,
      csi_status: item?.csi_status,
      qb_status: item?.qb_status,
      revenue: item?.revenue,
      last_batch_id: item?.last_batch_id,
      customer_type: item?.customer_type,
      type: item?.type,
    }
  })
