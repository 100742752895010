import React, { memo } from 'react'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { useTranslation } from 'react-i18next'

import { useShallowSelector } from 'custom-hooks/useShallowSelector'
import Modal from 'components/ui/modal-component'
import Title from 'components/ui/title-component'
import Button from 'components/ui/button-component'
import {
  getSelectedUserNameSelector,
  getSelectedUserCsiConnectedStatusSelector,
} from 'redusers/users'
import {
  SIGN_IN_CSI_MODAL_SUCCESS_MESSAGE_LN,
  SIGN_IN_CSI_MODAL_DENIE_MESSAGE_LN,
  SIGN_IN_QB_MODAL_BUTTON_LN,
} from 'constants/language-key-constants'

const SuccesConnectedQbCsiModal = (props) => {
  const { handleClickOK } = props
  const { t } = useTranslation()

  const userNameConnectedToCsi = useShallowSelector(getSelectedUserNameSelector)
  const connectedStatus = useShallowSelector(
    getSelectedUserCsiConnectedStatusSelector
  )

  const _className = connectedStatus ? 'Success' : 'Denied'

  const template = (
    <span>
      <span>{t(SIGN_IN_CSI_MODAL_SUCCESS_MESSAGE_LN)}</span>
      <br />
      <span>{userNameConnectedToCsi}</span>
    </span>
  )

  const _text = connectedStatus
    ? template
    : t(SIGN_IN_CSI_MODAL_DENIE_MESSAGE_LN)

  return (
    <Modal classNameWrap="signin_connect_modal_container">
      <span>
        <span></span>
        <br />
        <span></span>
      </span>
      <div className={`signin_connect_modal_title_wrap ${_className}`}>
        {connectedStatus ? <CheckCircleOutlineIcon /> : <HighlightOffIcon />}
        <Title
          title={_text}
          extraClassName="signin_connect_modal_title"
          component={'span'}
        />
      </div>

      <Button
        name={t(SIGN_IN_QB_MODAL_BUTTON_LN)}
        type="button"
        styletype="green"
        classNameButton="signin_connect_modal_button"
        onClick={handleClickOK}
      />
    </Modal>
  )
}

export default memo(SuccesConnectedQbCsiModal)
