import {
  SET_COMPANIES,
  START_COMPANIES_FETCHING,
  STOP_COMPANIES_FETCHING,
  CLEAR_COMPANIES_LIST,
  SET_SEARCH_BATCH_FROM_COMPANY_VALUE,
  SET_CHOSEN_REALM_ID_COMPANY,
  CLEAR_SEARCH_VALUE_COMPANIES,
} from 'constants/actions-constants'

const initialState = {
  companiesList: [],
  isFetchingCompanies: false,
  searchValue: '',
  chosenRealmId: null,
}

export const companies = (state = initialState, actions) => {
  switch (actions.type) {
    case SET_COMPANIES:
      return { ...state, companiesList: actions.payload }
    case START_COMPANIES_FETCHING:
      return { ...state, isFetchingCompanies: true }
    case STOP_COMPANIES_FETCHING:
      return { ...state, isFetchingCompanies: false }
    case CLEAR_COMPANIES_LIST:
      return { ...state, companiesList: initialState.companiesList }
    case SET_SEARCH_BATCH_FROM_COMPANY_VALUE:
      return { ...state, searchValue: actions.payload }
    case CLEAR_SEARCH_VALUE_COMPANIES:
      return { ...state, searchValue: initialState.searchValue }
    case SET_CHOSEN_REALM_ID_COMPANY:
      return { ...state, chosenRealmId: actions.payload }
    default:
      return state
  }
}

export const getChosenRealMIdSelector = (state) => state.companies.chosenRealmId

export const getSearchValueForBatchFromCompanySelector = (state) =>
  state.companies.searchValue

export const getCompaniesListSelector = (state) =>
  state.companies?.companiesList?.map((company) => ({
    _id: company?._id,
    companyName: company?.quickbooks_data?.CompanyName,
    realmId: company?.realm_id,
  }))
