import {
  SET_ISSUE_DATE_TIME_FROM,
  SET_ISSUE_DATE_TIME_TO,
  CLEAR_REPORTS_DATE_RANGE_TIME,
  SET_OFFSET_TAX_SUMMARY_REPORT,
  SET_TAX_SUMMARY_REPORTS,
  CLEAR_TAX_SUMMARY_REPORTS,
  STAR_FETCHING_REPORTS,
  STOP_FETCHING_REPORTS,
  GET_TAX_SUMMARY_REPORTS,
  UPDATE_TAX_SUMMARY_REPORTS,
  EXPORT_CSV_TAX_SUMMARY_REPORTS,
  SET_QB_PORTAL_COMPARISON_REPORTS,
  UPDATE_QB_PORTAL_COMPARISON_REPORTS,
  CLEAR_QB_PORTAL_COMPARISON_REPORTS,
  SET_PROCESSED_DATE_TIME_FROM,
  SET_PROCESSED_DATE_TIME_TO,
  GET_QB_PORTAL_COMPARISON_REPORTS,
  EXPORT_CSV_QB_PORTAL_COMPARISON_REPORTS,
  SET_IS_SHOW_INFOBOX_IF_EMPTY_DATA_TABLE,
  START_FETCHING_EXPORT_CSV,
  STOP_FETCHING_EXPORT_CSV,
  SET_SEARCH_VALUE_QB_PORTAL_COMPARISON_REPORTS,
  SET_SEARCH_VALUE_TAX_SUMMARY_REPORTS,
  CLEAR_SERCH_VALUES_REPORTS,
  GET_SUM_TOTAL_FOR_REPORTS,
  SET_SUM_TOTAL_FOR_REPORTS,
  SET_ERROR_MESSAGE_FOR_MTS_REPORT,
  CLEAR_ERROR_MESSAGE_FOR_MTS_REPORT,
  SET_HAS_ZERO_TAX_QB_REPORT,
} from 'constants/actions-constants'

export const setHasZeroTaxQbReport = (payload) => ({
  type: SET_HAS_ZERO_TAX_QB_REPORT,
  payload,
})

export const setErrorMessageMtsReport = (payload) => ({
  type: SET_ERROR_MESSAGE_FOR_MTS_REPORT,
  payload,
})

export const clearErrorMessageMtsReport = () => ({
  type: CLEAR_ERROR_MESSAGE_FOR_MTS_REPORT,
})

export const getSumTotalForReports = (payload) => ({
  type: GET_SUM_TOTAL_FOR_REPORTS,
  payload,
})

export const setSumTotalForReports = (payload) => ({
  type: SET_SUM_TOTAL_FOR_REPORTS,
  payload,
})

export const clearSearchValuesReports = () => ({
  type: CLEAR_SERCH_VALUES_REPORTS,
})

export const setSearchValueQbPoratlComparisonReports = (payload) => ({
  type: SET_SEARCH_VALUE_QB_PORTAL_COMPARISON_REPORTS,
  payload,
})

export const setSearchValueTaxSummaryReports = (payload) => ({
  type: SET_SEARCH_VALUE_TAX_SUMMARY_REPORTS,
  payload,
})

export const setIssueDateTimeFrom = (payload) => ({
  type: SET_ISSUE_DATE_TIME_FROM,
  payload,
})

export const setIssueDateTimeTo = (payload) => ({
  type: SET_ISSUE_DATE_TIME_TO,
  payload,
})

export const clearReportsDateRange = () => ({
  type: CLEAR_REPORTS_DATE_RANGE_TIME,
})

export const setOffsetTaxSummaryReport = (payload) => ({
  type: SET_OFFSET_TAX_SUMMARY_REPORT,
  payload,
})

export const getTaxSummaryReports = (payload) => ({
  type: GET_TAX_SUMMARY_REPORTS,
  payload,
})

export const setTaxSummaryReport = (payload) => ({
  type: SET_TAX_SUMMARY_REPORTS,
  payload,
})

export const clearTaxSummaryReport = () => ({
  type: CLEAR_TAX_SUMMARY_REPORTS,
})

export const startFetchingReports = () => ({
  type: STAR_FETCHING_REPORTS,
})

export const stopFetchingReports = () => ({
  type: STOP_FETCHING_REPORTS,
})

export const updateTaxSummaryReport = (payload) => ({
  type: UPDATE_TAX_SUMMARY_REPORTS,
  payload,
})

export const exportCsvTaxSummaryReport = () => ({
  type: EXPORT_CSV_TAX_SUMMARY_REPORTS,
})

export const setQbPortalComparisonReports = (payload) => ({
  type: SET_QB_PORTAL_COMPARISON_REPORTS,
  payload,
})

export const clearQbPortalComparisonReports = () => ({
  type: CLEAR_QB_PORTAL_COMPARISON_REPORTS,
})

export const updateQbPortalComparisonReports = (payload) => ({
  type: UPDATE_QB_PORTAL_COMPARISON_REPORTS,
  payload,
})

export const getQbPortalComparisonReports = (payload) => ({
  type: GET_QB_PORTAL_COMPARISON_REPORTS,
  payload,
})

export const setProcessedDateTimeFrom = (payload) => ({
  type: SET_PROCESSED_DATE_TIME_FROM,
  payload,
})

export const setProcessedDateTimeTo = (payload) => ({
  type: SET_PROCESSED_DATE_TIME_TO,
  payload,
})

export const exportCsvQbPortalComparisonReports = () => ({
  type: EXPORT_CSV_QB_PORTAL_COMPARISON_REPORTS,
})

export const setIsShowInfoBoxIfEmptyDataTable = (payload) => ({
  type: SET_IS_SHOW_INFOBOX_IF_EMPTY_DATA_TABLE,
  payload,
})

export const startFetchingExportCsvReport = () => ({
  type: START_FETCHING_EXPORT_CSV,
})

export const stopFetchingExportCsvReport = () => ({
  type: STOP_FETCHING_EXPORT_CSV,
})
