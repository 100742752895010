import React, { useEffect, memo, useCallback } from 'react'

import Input from 'components/ui/input-component'
import CustomScrollBar from 'components/ui/custom-scroll-bar'
import { useToggleMenu } from 'custom-hooks/useToggleMenu'
import { usePrevious } from 'custom-hooks/usePrevious'

const Select = (props) => {
  const {
    placeholder = '',
    classNameSelectContainer = '',
    classNameInputContainer = '',
    classNameInput = '',
    type = 'text',
    name = '',
    maxHeightScrollBody = 200,
    dataOptions = [],
    handleSelect,
    handleClickInput,
    ...otherProps
  } = props

  const [isOpen, setIsOpen] = useToggleMenu(false)
  const isOpenPrev = usePrevious(isOpen)

  const handleOnClickSelect = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()

      if (e.target.tagName === 'INPUT') {
        if (!isOpen && !isOpenPrev) {
          handleClickInput(isOpen)
        }

        setIsOpen(!isOpen)
        if (isOpen) {
          e.target.blur()
        }
        return true
      }
    },
    [isOpen, handleClickInput, setIsOpen, isOpenPrev]
  )

  useEffect(() => {
    if (!isOpen && isOpenPrev) {
      handleClickInput(!isOpen)
    }
  }, [handleClickInput, isOpen, isOpenPrev])

  const buttonOpenClassName = isOpen ? 'open' : ''

  return (
    <span
      className={`select_container ${buttonOpenClassName} ${classNameSelectContainer}`}
      onClick={handleOnClickSelect}
    >
      <Input
        placeholder={placeholder}
        classNameContainer={`${classNameInputContainer} select_input_container`}
        classNameInput={`select_input ${classNameInput}`}
        type={type}
        name={name}
        autoComplete="nope"
        {...otherProps}
      />
      {isOpen && (
        <span className={'select_drop_down_menu_container'}>
          <CustomScrollBar style={{ height: maxHeightScrollBody }}>
            {dataOptions.map((state) => (
              <button
                key={state.name}
                data-state-name={state.name}
                data-state-label={state.label}
                className={'select_drop_down_menu_item'}
                onClick={handleSelect}
              >
                {`${state.name} (${state.label})`}
              </button>
            ))}
          </CustomScrollBar>
        </span>
      )}
    </span>
  )
}

Select.propTypes = {}

export default memo(Select)
