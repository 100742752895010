import React, { useEffect, memo, useCallback } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import MainRoutes from 'routes/main-routes'
import AlertStyled from 'components/ui/alert'
import Header from 'components/header'
import SignInCsiFormModal from 'components/sign-in-csi-form-modal'
import SignInConnectModal from 'components/sign-in-connect-modal'
import UserProfile from 'components/user-profile'
import SettingsModal from 'components/settings-modal'
import InvoiceDetailModal from 'components/invoice-detail-modal'
import TaxSummaryModal from 'components/tax-summary-modal'
import LoaderScreen from 'components/loading-screen'
import ConfirmModal from 'components/confirm-modal'
import ProcessedBatchSummaryModal from 'components/processed-batch-summary-modal'
import ProcessingInvoicesModal from 'components/processing-invoices-modal'
import ExportConfirmationModal from 'components/export-confirmation-modal'
import NewBatchCreateModal from 'components/new-batch-create-modal'
import SuccesConnectedQbCsiModal from 'components/succes-connected-qb-csi-modal'

import { getRolesLoadingSelector, getRoleUserSelector } from 'redusers/profile'
import {
  getIsShowQbConnectModalSelector,
  getisShowCsiFormModalSelector,
  getIsOpenInvoiceDetailModalSelector,
  getIsOpenInvoiceTaxSummaryModalSelector,
  getIsShowProfileMenuModalSelector,
  getIsSettingsModalSelector,
  getIsShownConfirmationModal,
  getIsOpenProcessedBatchModalSelector,
  getIsShownProcessingInvoicesModal,
  getIsOpenExportConfirmationModalSelector,
  getIsOpenCreatrNewBatchModalSelector,
  getIsShowSuccesConnectedCsiModalSelector,
} from 'redusers/modalsState'
import { getAlertIsShownAlertSelector } from 'redusers/alert'
import { getQbAuthUrlSelector } from 'redusers/quickbooks-auth'
import { getIsUsersFetchingSelector } from 'redusers/users'

import {
  hideQbConnectModal,
  hideProcessedBatchModal,
  openInvoiceDetailModal,
  openInvoiceTaxSummaryModal,
  hideProcessingInvoicesModal,
  hideExportConfirmationModal,
  hideNewBatchModal,
  showNewBatchModal,
  hideSuccesConnectedCsiModal,
} from 'actions/modals-state-actions'
import { getQbAuthUrl } from 'actions/qb-actions'
import { connectCsiAccount } from 'actions/users'
import { startGettingFirebaseNotifications } from 'actions/firebase-notification-actions'
import { getNotificationsFromBackEnd } from 'actions/notification-actions'
import { logoutAfterIdle } from 'actions/profile-actions'

import { CLIENT_ROLE } from 'constants/roles-constant'
import {
  SING_IN_QB_CONNECT_MODAL_TITLE_LN,
  SING_IN_QB_CONNECT_MODAL_TOP_MESSAGE_LN,
  SING_IN_QB_CONNECT_MODAL_BOTTOM_MESSAGE_LN,
  SING_IN_QB_CONNECT_MODAL_BUTTON_CONNECT_LN,
  SKIP_BUTTON_NAME_LN,
} from 'constants/language-key-constants'

import { pageVisibilityHoc } from 'hoc'
import IdleTimer from 'service/idle'

const Dashboard = (props) => {
  const {
    isShowQbConnectModal,
    hideQbConnectModal,
    isShowCsiFormModal,
    isRolesLoader,
    getQbAuthUrl,
    qbAuthUrl,
    isOpenInvoiceDetailModal,
    isOpenInvoiceTaxSummaryModal,
    isShowProfileMenuModal,
    connectCsiAccount,
    isShownAlert,
    isOpenSettingModal,
    isShownConfirmationModal,
    startGettingFirebaseNotifications,
    getNotificationsFromBackEnd,
    isUserFetching,
    isShownProcessedBatchModal,
    hideProcessedBatchModal,
    openInvoiceDetailModal,
    openInvoiceTaxSummaryModal,
    roleUser,
    isShownProcessingInvoicesModal,
    hideProcessingInvoicesModal,
    isShowExportConfirmationModal,
    hideExportConfirmationModal,
    iShowCreateNewBatchModal,
    hideNewBatchModal,
    showNewBatchModal,
    isShowSuccesConnectedCsiModal,
    hideSuccesConnectedCsiModal,
    logoutAfterIdle,
  } = props

  const { t } = useTranslation()

  const handleClickConnectQB = useCallback(() => {
    getQbAuthUrl()
  }, [getQbAuthUrl])

  const handleSubmitConnectCsi = useCallback(
    (formData) => {
      connectCsiAccount(formData.signInCsi)
    },
    [connectCsiAccount]
  )

  const handleCloseProcessedModal = useCallback(() => {
    hideProcessedBatchModal()
  }, [hideProcessedBatchModal])

  const handleCloseProcessingInvoicesModal = useCallback(() => {
    hideProcessingInvoicesModal()
  }, [hideProcessingInvoicesModal])

  const handleCloseExportConfirmationModal = useCallback(() => {
    hideExportConfirmationModal()
  }, [hideExportConfirmationModal])

  const handleCloseNewBatch = useCallback(() => {
    hideNewBatchModal()
  }, [hideNewBatchModal])

  const handleOpenNewBatch = useCallback(() => {
    showNewBatchModal()
  }, [showNewBatchModal])

  const handleClickSuccesConnectedCsiModal = useCallback(() => {
    hideSuccesConnectedCsiModal()
  }, [hideSuccesConnectedCsiModal])

  useEffect(() => {
    if (qbAuthUrl) {
      window.location.assign(qbAuthUrl)
    }
  }, [qbAuthUrl])

  useEffect(() => {
    if (roleUser === CLIENT_ROLE) {
      startGettingFirebaseNotifications()
      getNotificationsFromBackEnd()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNotificationsFromBackEnd, startGettingFirebaseNotifications])

  useEffect(() => {
    const timer = new IdleTimer({
      timeout:
        process.env.REACT_APP_CUSTOM_NODE_ENV === 'production'
          ? 60 * 15
          : 60 * 50, /// set up iddle time  out
      onTimeout: logoutAfterIdle,
      onExpired: logoutAfterIdle,
    })
    return () => {
      timer.cleanUp()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return !isRolesLoader || isUserFetching ? (
    <LoaderScreen />
  ) : (
    <div className="container dashboard_container">
      <Header />
      <MainRoutes />
      {isShowQbConnectModal && roleUser === CLIENT_ROLE && (
        <SignInConnectModal
          handleCloseModal={hideQbConnectModal}
          handleClickConnect={handleClickConnectQB}
          title={t(SING_IN_QB_CONNECT_MODAL_TITLE_LN)}
          topMiddleText={t(SING_IN_QB_CONNECT_MODAL_TOP_MESSAGE_LN)}
          bottomMiddleText={t(SING_IN_QB_CONNECT_MODAL_BOTTOM_MESSAGE_LN)}
          connectButtonText={t(SING_IN_QB_CONNECT_MODAL_BUTTON_CONNECT_LN)}
          skipButtonText={t(SKIP_BUTTON_NAME_LN)}
        />
      )}
      {isShowCsiFormModal && (
        <SignInCsiFormModal onSubmit={handleSubmitConnectCsi} />
      )}
      {isShowProfileMenuModal && <UserProfile />}
      {isOpenSettingModal && <SettingsModal />}
      {isOpenInvoiceDetailModal && <InvoiceDetailModal />}
      {isOpenInvoiceTaxSummaryModal && <TaxSummaryModal />}
      {isShownAlert && <AlertStyled />}
      {isShownConfirmationModal && <ConfirmModal />}
      {isShownProcessedBatchModal && (
        <ProcessedBatchSummaryModal
          handleCloseProcessedModal={handleCloseProcessedModal}
          handleClickInvoiceDetail={openInvoiceDetailModal}
          handlerClickTaxSummary={openInvoiceTaxSummaryModal}
        />
      )}
      {isShownProcessingInvoicesModal && (
        <ProcessingInvoicesModal
          handleCloseProcessingInvoicesModal={
            handleCloseProcessingInvoicesModal
          }
        />
      )}
      {isShowExportConfirmationModal && (
        <ExportConfirmationModal
          handleCloseExportConfirmationModal={
            handleCloseExportConfirmationModal
          }
          handleOpenNewBatch={handleOpenNewBatch}
        />
      )}
      {iShowCreateNewBatchModal && (
        <NewBatchCreateModal
          handleCloseNewBatch={handleCloseNewBatch}
          handleClickInvoiceDetail={openInvoiceDetailModal}
        />
      )}
      {isShowSuccesConnectedCsiModal && (
        <SuccesConnectedQbCsiModal
          handleClickOK={handleClickSuccesConnectedCsiModal}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  isShowQbConnectModal: getIsShowQbConnectModalSelector(state),
  isShowCsiFormModal: getisShowCsiFormModalSelector(state),
  isRolesLoader: getRolesLoadingSelector(state),
  isOpenInvoiceDetailModal: getIsOpenInvoiceDetailModalSelector(state),
  isOpenInvoiceTaxSummaryModal: getIsOpenInvoiceTaxSummaryModalSelector(state),
  isShowProfileMenuModal: getIsShowProfileMenuModalSelector(state),
  isShownAlert: getAlertIsShownAlertSelector(state),
  isOpenSettingModal: getIsSettingsModalSelector(state),
  isShownConfirmationModal: getIsShownConfirmationModal(state),
  isUserFetching: getIsUsersFetchingSelector(state),
  isShownProcessedBatchModal: getIsOpenProcessedBatchModalSelector(state),
  roleUser: getRoleUserSelector(state),
  qbAuthUrl: getQbAuthUrlSelector(state),
  isShownProcessingInvoicesModal: getIsShownProcessingInvoicesModal(state),
  isShowExportConfirmationModal:
    getIsOpenExportConfirmationModalSelector(state),
  iShowCreateNewBatchModal: getIsOpenCreatrNewBatchModalSelector(state),
  isShowSuccesConnectedCsiModal:
    getIsShowSuccesConnectedCsiModalSelector(state),
})

const actions = {
  hideQbConnectModal,
  connectCsiAccount,
  startGettingFirebaseNotifications,
  getNotificationsFromBackEnd,
  getQbAuthUrl,
  hideProcessedBatchModal,
  openInvoiceDetailModal,
  openInvoiceTaxSummaryModal,
  hideProcessingInvoicesModal,
  hideExportConfirmationModal,
  hideNewBatchModal,
  showNewBatchModal,
  hideSuccesConnectedCsiModal,
  logoutAfterIdle,
}

export default connect(
  mapStateToProps,
  actions
)(pageVisibilityHoc(memo(Dashboard)))
