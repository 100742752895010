import React, { useCallback, useEffect, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import DateRangeIcon from '@material-ui/icons/DateRange'

import { useShallowSelector } from 'custom-hooks/useShallowSelector'
import { FilterButton } from 'components/ui/filter-component'
import SearchInput from 'components/ui/search-input'
import Button from 'components/ui/button-component'
import {
  PLACEHOLDER_BY_CLIENT_BATCHES_SEARCH_INPUT_LN,
  REPORT_DATE_RANGE_BUTTON_LN,
  REPORT_EXPORT_TO_CSV_BUTTON_LN,
} from 'constants/language-key-constants'
import { showReportFilterModal } from 'actions/modals-state-actions'
import {
  exportCsvTaxSummaryReport,
  exportCsvQbPortalComparisonReports,
  setSearchValueQbPoratlComparisonReports,
  setSearchValueTaxSummaryReports,
  clearSearchValuesReports,
} from 'actions/reports-actions'
import {
  MONTHLY_TAX_SUMMARY_PATH,
  QB_TO_PORTAL_COMPARISON_PATH,
} from 'constants/reports-sub-path-constants'
import {
  getIsFetchinExportCsvSelector,
  //getIsFetchinReportsSelector,
} from 'redusers/reports'

const ReportSearchExportSection = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const [reportSubPath] = location?.pathname.split('/').reverse()
  const dispatch = useDispatch()
  const isFetching = useShallowSelector(getIsFetchinExportCsvSelector)
  //const isFetchingReports = useShallowSelector(getIsFetchinReportsSelector)

  const handleClickFilter = useCallback(() => {
    dispatch(showReportFilterModal())
  }, [dispatch])

  const handleClickExportCsv = useCallback(() => {
    if (reportSubPath === MONTHLY_TAX_SUMMARY_PATH) {
      dispatch(exportCsvTaxSummaryReport())
    } else {
      dispatch(exportCsvQbPortalComparisonReports())
    }
  }, [dispatch, reportSubPath])

  const handleChangeSearchValue = useCallback(
    (event) => {
      const value = event.target.value
      if (reportSubPath === MONTHLY_TAX_SUMMARY_PATH) {
        dispatch(setSearchValueTaxSummaryReports(value))
      } else {
        dispatch(setSearchValueQbPoratlComparisonReports(value))
      }
    },
    [dispatch, reportSubPath]
  )

  const handleClickClear = useCallback(() => {
    if (reportSubPath === MONTHLY_TAX_SUMMARY_PATH) {
      dispatch(setSearchValueTaxSummaryReports(''))
    } else {
      dispatch(setSearchValueQbPoratlComparisonReports(''))
    }
  }, [dispatch, reportSubPath])

  useEffect(() => {
    return () => {
      dispatch(clearSearchValuesReports())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="report_search_export_section">
      <div className="search_container">
        <SearchInput
          placeholder={t(PLACEHOLDER_BY_CLIENT_BATCHES_SEARCH_INPUT_LN)}
          classNameContainer="batches_page_search_input_container"
          onChange={handleChangeSearchValue}
          handleClickClear={handleClickClear}
          isClearButton={true}
          autoClearValue={reportSubPath}
          disabled={isFetching}
        />
      </div>
      <div className="batches_filter_group">
        <FilterButton
          onClick={handleClickFilter}
          text={
            reportSubPath === QB_TO_PORTAL_COMPARISON_PATH
              ? null
              : t(REPORT_DATE_RANGE_BUTTON_LN)
          }
          iconComponent={
            reportSubPath === QB_TO_PORTAL_COMPARISON_PATH
              ? null
              : DateRangeIcon
          }
        />
        <Button
          name={t(REPORT_EXPORT_TO_CSV_BUTTON_LN)}
          styletype="green"
          classNameButton={'new_batches_btn'}
          onClick={handleClickExportCsv}
          disabled={isFetching}
          isSpinner={isFetching}
        />
      </div>
    </div>
  )
}

export default memo(ReportSearchExportSection)
