import React, { memo } from 'react'

import CustomScroll from 'components/ui/custom-scroll-bar'

function MessageBlock(props) {
  const { message, type = '', isScroll } = props
  if (isScroll) {
    return (
      <CustomScroll autoHeight autoHeightMin={45} autoHeightMax={165}>
        <div className={`message_block_container ${type}`}>
          <span className="message_block_content">{message}</span>
        </div>
      </CustomScroll>
    )
  }
  return (
    <div className={`message_block_container ${type}`}>
      <span className="message_block_content">{message}</span>
    </div>
  )
}

export default memo(MessageBlock)
