import React, { useState, memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Field, formValues } from 'redux-form'
import InputMask from 'react-input-mask'
/*** */
import TextField from '@material-ui/core/TextField'
/*** */

import HidePassword from 'components/ui/svg-components/HidePassword'
import ShowPassword from 'components/ui/svg-components/ShowPassword'

const RenderTextField = (props) => {
  const {
    label,
    input,
    className,
    autoComplete,
    type,
    validateAfterSubmit,
    showValidMessage = false,
    bottomHelpersText,
    meta: { touched, error, submitFailed, invalid },
    serverError,
    mask,
    ...custom
  } = props

  let howToShownValidMessage = validateAfterSubmit
    ? submitFailed
    : touched && invalid

  if (showValidMessage && invalid) {
    howToShownValidMessage = true
  }

  const errorClassName =
    (howToShownValidMessage && error) || serverError ? 'valid_error' : ''

  return (
    <span className="text_field_container">
      <TextField
        label={label}
        className={`${className} ${errorClassName}`}
        autoComplete={autoComplete}
        type={type}
        InputProps={{
          inputComponent: InputMask,
          inputProps: { mask },
        }}
        {...input}
        {...custom}
      />
      {(howToShownValidMessage || serverError) && (
        <span className="valid_error_message">{error || serverError}</span>
      )}
      {bottomHelpersText && !howToShownValidMessage && invalid && (
        <span className="valid_error_message helper_text">
          {bottomHelpersText}
        </span>
      )}
    </span>
  )
}
const Input = (props) => {
  const {
    type = 'text',
    classNameContainer = '',
    classNameInput = '',
    isPassword = false,
    placeholder = '',
    bottomHelpersText = '',
    validateAfterSubmit,
    ...rest
  } = props

  //const inputRef = useRef(null)

  const [isVisible, setIsVisible] = useState(false)

  const handleOnClickVisiblePassword = useCallback(
    (event) => {
      event.preventDefault()
      event.stopPropagation()
      setIsVisible(!isVisible)
    },
    [isVisible]
  )

  const _type = isPassword ? (isVisible ? 'text' : 'password') : type

  return (
    <span className={`input_section_container ${classNameContainer}`}>
      <Field
        component={RenderTextField}
        className={`input ${classNameInput}`}
        type={_type}
        label={placeholder}
        validateAfterSubmit={validateAfterSubmit}
        bottomHelpersText={bottomHelpersText}
        {...rest}
      />
      <span className="input_password_button_container">
        {isPassword && (
          <button
            className="input_password_button"
            type="button"
            onClick={handleOnClickVisiblePassword}
          >
            {!isVisible ? <HidePassword /> : <ShowPassword />}
          </button>
        )}
      </span>
    </span>
  )
}

Input.propTypes = {
  type: PropTypes.string,
  classNameContainer: PropTypes.string,
  classNameTitle: PropTypes.string,
  classNameInput: PropTypes.string,
  isPassword: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default formValues((props) => ({ value: props.name }))(memo(Input))
