import { put, takeEvery, select } from 'redux-saga/effects'

import {
  OPEN_INVOICE_DETAIL_MODAL,
  CLOSE_INVOICE_DETAIL_MODAL,
  OPEN_INVOICE_TAX_SUMMARY_MODAL,
  CLOSE_INVOICE_TAX_SUMMARY_MODAL,
  CLOSE_CONNECT_CSI_FORM_MODAL,
} from 'constants/actions-constants'
import {
  getInvoiceDetailFromAvailableInvoices,
  getInvoiceDetailFromProcessedInvoices,
  clearInvoiceDetail,
  getInvoiceDetailFromBatch,
} from 'actions/invoice-detail-actions'
import {
  showInvoiceDetailModal,
  hideInvoiceDetailModal,
  showNewBatchModal,
  hideNewBatchModal,
  showProcessedBatchModal,
  hideProcessedBatchModal,
  showInvoiceTaxSummaryModal,
  hideInvoiceTaxSummaryModal,
  hideCsiFormModal,
  clearPrevShownModalName,
  setPrevShowModalName,
  showSuccesConnectedCsiModal,
} from 'actions/modals-state-actions'

import {
  NEW_BATCH_MODAL_NAME,
  PROCESSED_BATCH_MODAL_NAME,
} from 'constants/modal-names-constants'

function* openInvoiceDetailSaga({ payload: id }) {
  try {
    const { currentShownModalName } = yield select((state) => state.modalState)
    yield put(setPrevShowModalName())
    switch (currentShownModalName) {
      case NEW_BATCH_MODAL_NAME:
        yield put(getInvoiceDetailFromAvailableInvoices(id))
        yield put(hideNewBatchModal(true))
        break
      case PROCESSED_BATCH_MODAL_NAME:
        yield put(getInvoiceDetailFromProcessedInvoices(id))
        yield put(hideProcessedBatchModal())
        break
      default:
        yield put(clearPrevShownModalName())
        yield put(getInvoiceDetailFromBatch(id))
        break
    }
    yield put(showInvoiceDetailModal())
  } catch (error) {
    //to do
  } finally {
    //to do
  }
}

function* closeInvoiceDetailSaga() {
  try {
    const { prevShownModalName } = yield select((state) => state.modalState)
    yield put(hideInvoiceDetailModal())
    switch (prevShownModalName) {
      case NEW_BATCH_MODAL_NAME:
        yield put(showNewBatchModal())
        break
      case PROCESSED_BATCH_MODAL_NAME:
        yield put(showProcessedBatchModal())
        break
      default:
        break
    }
    yield put(clearInvoiceDetail())
  } catch (error) {
    //to do
  } finally {
    //to do
    yield put(clearPrevShownModalName())
  }
}

function* openInvoiceTaxSummarySaga({ payload: id }) {
  try {
    const { currentShownModalName } = yield select((state) => state.modalState)
    yield put(setPrevShowModalName())
    switch (currentShownModalName) {
      case PROCESSED_BATCH_MODAL_NAME:
        yield put(getInvoiceDetailFromProcessedInvoices(id))
        yield put(hideProcessedBatchModal())
        break
      default:
        yield put(clearPrevShownModalName())
        yield put(getInvoiceDetailFromBatch(id))
        break
    }
    yield put(showInvoiceTaxSummaryModal())
  } catch (error) {
    //to do
  } finally {
    //to do
  }
}

function* closeInvoiceTuxSummarySaga() {
  try {
    const { prevShownModalName } = yield select((state) => state.modalState)
    yield put(hideInvoiceTaxSummaryModal())
    switch (prevShownModalName) {
      case PROCESSED_BATCH_MODAL_NAME:
        yield put(showProcessedBatchModal())
        break
      default:
        break
    }
    yield put(clearInvoiceDetail())
  } catch (error) {
    //to do
  } finally {
    //to do
    yield put(clearPrevShownModalName())
  }
}

function* closeCsiConnectFormModal() {
  try {
    yield put(hideCsiFormModal())
    yield put(showSuccesConnectedCsiModal())
  } catch (error) {
    //to do
  } finally {
    //yield put(clearPrevShownModalName())
  }
}

export function* watchModalStateWorkers() {
  yield takeEvery(OPEN_INVOICE_DETAIL_MODAL, openInvoiceDetailSaga)
  yield takeEvery(OPEN_INVOICE_TAX_SUMMARY_MODAL, openInvoiceTaxSummarySaga)
  yield takeEvery(CLOSE_INVOICE_DETAIL_MODAL, closeInvoiceDetailSaga)
  yield takeEvery(CLOSE_INVOICE_TAX_SUMMARY_MODAL, closeInvoiceTuxSummarySaga)
  yield takeEvery(CLOSE_CONNECT_CSI_FORM_MODAL, closeCsiConnectFormModal)
}
