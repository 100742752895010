import sortBy from 'lodash/sortBy'

import {
  SET_IS_PRODUCTS_FETCHING,
  SET_PRODUCTS,
  CLEAR_PRODUCTS,
  SET_IS_FETCHING_CSI_PRODUCTS,
  SET_CSI_PRODUCTS,
  CLEAR_CSI_PRODUCTS,
  SET_IS_FETCHING_UNMAP_PRODUCTS,
  SHOW_MAPPED_PRODUCT_ALERT,
  SHOW_UNMAPPED_PRODUCTS_ALERT,
} from 'constants/actions-constants'

const initialState = {
  isFetchingProducts: false,
  isFetchingCsiProducts: false,
  isFetchingUnmapProducts: false,
  isShowMappedProductAlert: false,
  isShowUnmappedProductAlert: false,
  products: {
    documents: [],
    limit: 100,
    offset: 0,
  },
  csiProducts: {
    documents: [],
    limit: 100,
    offset: 0,
  },
}

export const products = (state = initialState, actions) => {
  switch (actions.type) {
    case SET_IS_PRODUCTS_FETCHING:
      return { ...state, isFetchingProducts: actions.payload }
    case SET_IS_FETCHING_CSI_PRODUCTS:
      return { ...state, isFetchingCsiProducts: actions.payload }
    case SET_PRODUCTS:
      return { ...state, products: actions.payload }
    case SET_CSI_PRODUCTS:
      return { ...state, csiProducts: actions.payload }
    case CLEAR_PRODUCTS:
      return { ...state, products: initialState.products }
    case CLEAR_CSI_PRODUCTS:
      return { ...state, csiProducts: initialState.products }
    case SET_IS_FETCHING_UNMAP_PRODUCTS:
      return { ...state, isFetchingUnmapProducts: actions.payload }
    case SHOW_MAPPED_PRODUCT_ALERT:
      return { ...state, isShowMappedProductAlert: actions.payload }
    case SHOW_UNMAPPED_PRODUCTS_ALERT:
      return { ...state, isShowUnmappedProductAlert: actions.payload }
    default:
      return state
  }
}

/** SELECTORS */
export const getIsShowUnMappedProductAlert = (state) =>
  state.products.isShowUnmappedProductAlert

export const getIsShowMappedProductAlert = (state) =>
  state.products.isShowMappedProductAlert

export const getIsFetchingProductsSelector = (state) =>
  state.products.isFetchingProducts

export const getIsfetchingCsiProductsSelector = (state) =>
  state.products.isFetchingCsiProducts

export const getIsfetchingUnmapProducts = (state) =>
  state.products.isFetchingUnmapProducts

export const getUnmappedProducstSelector = (state) => {
  const _unmappedProducts = state.products.products.documents.filter(
    (product) => !product.csi_mapping
  )

  return sortBy(_unmappedProducts, [
    function (o) {
      return o.quickbooks_data.Name
    },
  ])
}

export const getCsiProductsSelector = (state) => {
  return state.products.csiProducts.documents
}

export const getMappedProductsSelector = (state) => {
  const _csiProducts = state.products.csiProducts?.documents || []
  const _mappedProducts = state.products?.products?.documents?.reduce(
    (destructureMappedProducts, product) => {
      const { csi_mapping, _id, quickbooks_data = {} } = product || {}
      const { Name } = quickbooks_data || {}

      if (csi_mapping) {
        const { product_code, service_code } = csi_mapping || {}
        const sciProduct = _csiProducts?.reduce((res, csiProd) => {
          if (csiProd?.code === product_code) {
            const _service = csiProd?.services?.find(
              (item) => item?.code === service_code
            )
            res.productDescription = csiProd.description
            res.serviceDescription = _service.description
          }
          return res
        }, {})
        destructureMappedProducts.push({
          _id,
          productsName: Name,
          taxCode: `${product_code}-${service_code} | ${sciProduct?.productDescription} | ${sciProduct?.serviceDescription}`,
        })
      }
      return destructureMappedProducts
    },
    []
  )
  return sortBy(_mappedProducts, 'productsName')
}
