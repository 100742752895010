import {
  SHOW_ALERT,
  HIDE_ALERT,
  START_SHOW_ALERT,
  SET_MESSAGE,
} from 'constants/actions-constants'

export const showAler = (payload) => ({
  type: SHOW_ALERT,
  payload,
})

export const setMessage = (payload) => ({
  type: SET_MESSAGE,
  payload,
})

export const startShowAlert = () => ({
  type: START_SHOW_ALERT,
})

export const hideAlert = () => ({
  type: HIDE_ALERT,
})
