import React, { memo } from 'react'
import PropTypes from 'prop-types'

const Spinner = (props) => {
  const { className = '', styleSpinner = '' } = props
  return <div className={`lds-dual-ring ${styleSpinner} ${className}`}></div>
}

Spinner.propTypes = {
  className: PropTypes.string,
}

export default memo(Spinner)
