import {
  SET_INVOICE_DETAIL,
  CLEAR_INVOICE_DETAIL,
  SET_INVOICE_CSI_OPERATIONS,
} from 'constants/actions-constants'
import { generateId } from 'utils'

const initialState = {
  invoiceDetail: {
    csiOperations: [],
  },
}

export const invoiceDetail = (state = initialState, actions) => {
  switch (actions.type) {
    case SET_INVOICE_DETAIL:
      return {
        ...state,
        invoiceDetail: { ...state.invoiceDetail, ...actions.payload },
      }
    case CLEAR_INVOICE_DETAIL:
      return { ...state, invoiceDetail: initialState.invoiceDetail }
    case SET_INVOICE_CSI_OPERATIONS:
      return {
        ...state,
        invoiceDetail: {
          ...state.invoiceDetail,
          csiOperations: actions.payload,
        },
      }
    default:
      return state
  }
}

export const getInvoiceDetailNumberSelector = (state) =>
  state.invoiceDetail.invoiceDetail?.quickbooks_data?.DocNumber ?? ''

export const getInvoiceDetailSelector = (state) => {
  const invoice = state?.invoiceDetail?.invoiceDetail || {}
  const { quickbooks_data = {}, zip_code } = invoice || {}
  const { BillAddr = {}, ShipAddr = {}, Line = [], Id } = quickbooks_data || {}

  const makeAddress = () => {
    const _regExtV = /(\d{5}(?:-\d{4})?)/i

    if (ShipAddr?.PostalCode) {
      return `${ShipAddr?.Line3 || ''}\n${ShipAddr?.Line1 || ''}\n${
        ShipAddr?.City || ''
      }, ${ShipAddr?.CountrySubDivisionCode || ''} ${
        ShipAddr?.PostalCode || ''
      }\n${ShipAddr?.Country || ''}`
    } else if (
      (ShipAddr?.Line1?.search(_regExtV) !== -1 &&
        (ShipAddr?.Line1?.search(_regExtV) ||
          ShipAddr?.Line1?.search(_regExtV) === 0)) ||
      (ShipAddr?.Line2?.search(_regExtV) !== -1 &&
        (ShipAddr?.Line2?.search(_regExtV) ||
          ShipAddr?.Line2?.search(_regExtV) === 0)) ||
      (ShipAddr?.Line3?.search(_regExtV) !== -1 &&
        (ShipAddr?.Line3?.search(_regExtV) ||
          ShipAddr?.Line3?.search(_regExtV) === 0)) ||
      (ShipAddr?.Line4?.search(_regExtV) !== -1 &&
        (ShipAddr?.Line4?.search(_regExtV) ||
          ShipAddr?.Line4?.search(_regExtV) === 0)) ||
      (ShipAddr?.Line5?.search(_regExtV) !== -1 &&
        (ShipAddr?.Line5?.search(_regExtV) ||
          ShipAddr?.Line5?.search(_regExtV) === 0))
    ) {
      return `${ShipAddr?.Line1 || ''}\n${ShipAddr?.Line2 || ''}\n${
        ShipAddr?.Line3 || ''
      }\n${ShipAddr?.Line4 || ''}\n${ShipAddr?.Line5 || ''}`
    }
    if (BillAddr?.PostalCode) {
      return `${BillAddr?.Line3 || ''}\n${BillAddr?.Line1 || ''}\n${
        BillAddr?.City || ''
      }, ${BillAddr?.CountrySubDivisionCode || ''} ${
        BillAddr?.PostalCode || ''
      }\n${BillAddr?.Country || ''}`
    } else
      return `${BillAddr?.Line1 || ''}\n${BillAddr?.Line2 || ''}\n${
        BillAddr?.Line3 || ''
      }\n${BillAddr?.Line4 || ''}\n${BillAddr?.Line5 || ''}`
  }

  const _line = Line?.reduce((destructureLine, line) => {
    if (line?.DetailType === 'SalesItemLineDetail') {
      const {
        SalesItemLineDetail = {},
        Description: description = '',
        Amount: amount = '',
      } = line || {}
      const {
        ItemRef,
        Qty: quantity = '',
        UnitPrice: rate = '',
      } = SalesItemLineDetail || {}
      const { name: product = '', value = '' } = ItemRef || {}

      const productName = product?.split(':') ?? []

      if (value === 'SHIPPING_ITEM_ID') {
        destructureLine.push({
          product: 'Shipping',
          description: 'Shipping - added for reference, not a product',
          quantity: 1,
          rate: amount,
          amount: amount,
          id: generateId(),
        })
      } else {
        destructureLine.push({
          product: productName[productName.length - 1] || product,
          description: description,
          quantity: quantity,
          rate: rate,
          amount: amount,
          id: generateId(),
        })
      }
    }
    return destructureLine
  }, [])

  return {
    id: invoice?._id,
    is_deleted: invoice?.is_deleted,
    numInvoice: invoice?.quickbooks_data?.DocNumber || '',
    customer: invoice?.quickbooks_data?.CustomerRef?.name || '',
    email: invoice?.quickbooks_data?.BillEmail?.Address || '',
    invoice_date: invoice?.quickbooks_data?.TxnDate || '',
    total: invoice?.quickbooks_data?.TotalAmt || '',
    linesData: _line,
    address: makeAddress(),
    type: invoice?.type || '',
    qbTransactionId: Id,
    zipCode: zip_code,
  }
}

export const getInvoiceTaxSummarySelector = (state) => {
  const invoice = state?.invoiceDetail?.invoiceDetail
  const { _id, csi_data = {}, csi_status } = invoice || {}

  const tax_summary = csi_data?.tax_summary || {}

  const {
    summaries = [],
    total_tax_amount,
    billable_total_tax_amount,
  } = tax_summary || {}

  const _summ = summaries || []

  const country = (_summ && _summ[0] && _summ[0].country) || ''

  const _summaries = _summ.reduce((res, item) => {
    if (item?.tax_type && item?.tax_cat) {
      res.push({
        id: generateId(),
        tax_description: `${item?.tax_type}/${item?.tax_cat} - ${item?.description}`,
        tax_auth: item?.tax_auth,
        tax_amount_sum: item?.tax_amount_sum,
        is_billable: item?.is_billable,
        description: item?.description,
      })
    }
    return res
  }, [])

  return {
    id: _id,
    total_tax_amount: total_tax_amount || 0,
    billable_total_tax_amount: billable_total_tax_amount || 0,
    country: country,
    dataLines: _summaries,
    is_errors_exists: csi_status === 'ERROR',
  }
}

export const getInvoiceDetailIdSelector = (state) =>
  state.invoiceDetail.invoiceDetail._id

export const getInvoiceCsiTaxSummaryRawDetailSelector = (state) =>
  state.invoiceDetail.invoiceDetail.csiOperations

export const getRealmIdFromInvoiceDetailSelector = (state) =>
  state.invoiceDetail.invoiceDetail.realm_id
