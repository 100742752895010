import React, { memo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { hideSettingModal } from 'actions/modals-state-actions'
import { clearSelectedState } from 'actions/checkbox-actions'
import Modal from 'components/ui/modal-component'
import Title from 'components/ui/title-component'
import MapProducts from 'components/map-products'
import MappedProducts from 'components/mapped-products'
import PiuSection from './PiuSection'
import {
  SETTINGS_MODAL_TITLE_LN,
  SETTINGS_MODAL_TITLE_TOOLTIP_LN,
} from 'constants/language-key-constants'

const SettingsModal = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleClickClose = useCallback(() => {
    dispatch(hideSettingModal())
    dispatch(clearSelectedState())
  }, [dispatch])

  return (
    <Modal
      classNameContainer="settings_modal"
      classNameWrap={'settings_conatainer'}
      isCloseButton={true}
      handleClickClose={handleClickClose}
    >
      <div className="settings_header">
        <Title
          title={t(SETTINGS_MODAL_TITLE_LN)}
          extraClassName={''}
          withTooltip={true}
          textTooltip={t(SETTINGS_MODAL_TITLE_TOOLTIP_LN)}
          placementTooltip={'right'}
        />
      </div>
      <PiuSection />
      <MapProducts classNameContainer="map_products_section" />
      <MappedProducts
        classNameContainer="map_products_section"
        handleClickClose={handleClickClose}
      />
    </Modal>
  )
}

export default memo(SettingsModal)
