import React, { memo } from 'react'
import PropTypes from 'prop-types'

const CloseButton = (props) => {
  const { handleClickClose, classNameContainer = '' } = props
  return (
    <button
      className={`user_profile_header__closebtn ${classNameContainer}`}
      onClick={handleClickClose}
    ></button>
  )
}

CloseButton.propTypes = {
  handleClickClose: PropTypes.func.isRequired,
  classNameContainer: PropTypes.string,
}

export default memo(CloseButton)
