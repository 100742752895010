import React, { memo } from 'react'

import SearchInput from 'components/ui/search-input'
import CustomScrollBar from 'components/ui/custom-scroll-bar'
import CheckBoxStyled from 'components/ui/checkbox-styled'

const ProductsFormWrap = (props) => {
  const {
    classNameContainer,
    title,
    searchPlaceholder,
    searchClassName,
    children,
    onChange,
    onChangeCheckBoxAll = null,
    notEmptyCheckBoxState,
    isAllSelected,
    searchValue,
  } = props

  return (
    <div className={`${classNameContainer}`}>
      <span className="header_title">
        {onChangeCheckBoxAll ? (
          <CheckBoxStyled
            label={`${title}`}
            onChange={onChangeCheckBoxAll}
            className="product_form_checkbox_all"
            isHeaderStyle
            notEmpty={notEmptyCheckBoxState}
            checked={isAllSelected}
          />
        ) : (
          title
        )}
      </span>
      <SearchInput
        placeholder={searchPlaceholder}
        classNameContainer={`map_products_search_container ${searchClassName}`}
        onChange={onChange}
        value={searchValue}
      />
      <CustomScrollBar autoHeight autoHeightMin={210} autoHeightMax={210}>
        {children}
      </CustomScrollBar>
    </div>
  )
}

export default memo(ProductsFormWrap)
