import {
  START_FETCHING_LOGS,
  STOP_FETCHING_LOGS,
  GET_LOGS_TYPES,
  SET_LOGS_TYPES,
  EXPORT_LOGS_CSV,
  SET_TO_DATE_LOGS,
  SET_FROM_DATE_LOGS,
  CLEAR_LOGS,
  SET_SELECTED_TYPES,
} from 'constants/actions-constants'

export const startFetchingLogs = () => ({
  type: START_FETCHING_LOGS,
})

export const stopFetchingLogs = () => ({
  type: STOP_FETCHING_LOGS,
})

export const getLogsTypes = () => ({
  type: GET_LOGS_TYPES,
})

export const setLogsTypes = (payload) => ({
  type: SET_LOGS_TYPES,
  payload,
})

export const exportLogsCsv = () => ({
  type: EXPORT_LOGS_CSV,
})

export const setToDateLogs = (payload) => ({
  type: SET_TO_DATE_LOGS,
  payload,
})

export const setFromDateLogs = (payload) => ({
  type: SET_FROM_DATE_LOGS,
  payload,
})

export const clearLogs = () => ({
  type: CLEAR_LOGS,
})

export const setSelectedTypes = (payload) => ({
  type: SET_SELECTED_TYPES,
  payload,
})
