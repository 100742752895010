import {
  SHOW_QB_CONNECT_MODAL,
  HIDE_QB_CONNECT_MODAL,
  SHOW_CSI_CONNECT_MODAL,
  HIDE_CSI_CONNECT_MODAL,
  SHOW_CSI_FORM_MODAL,
  HIDE_CSI_FORM_MODAL,
  SHOW_CREATE_BATCH_MODAL,
  HIDE_CREATE_BATCH_MODAL,
  SHOW_CREATE_BATCH_AVILABLE_INVOICES_MODAL,
  SHOW_PROCESSED_BATCH_MODAL,
  HIDE_PROCESSED_BATCH_MODAL,
  SHOW_EXPORT_CONFIRMATION_MODAL,
  HIDE_EXPORT_CONFIRMATION_MODAL,
  OPEN_INVOICE_DETAIL_MODAL,
  CLOSE_INVOICE_DETAIL_MODAL,
  SHOW_INVOICE_DETAIL_MODAL,
  HIDE_INVOICE_DETAIL_MODAL,
  SHOW_INVOICE_TAX_SUMMARY_MODAL,
  HIDE_INVOICE_TAX_SUMMARY_MODAL,
  OPEN_INVOICE_TAX_SUMMARY_MODAL,
  CLOSE_INVOICE_TAX_SUMMARY_MODAL,
  SHOW_PROFILE_MENU_MODAL,
  HIDE_PROFILE_MENU_MODAL,
  OPEN_CONNECT_CSI_FORM_MODAL,
  CLOSE_CONNECT_CSI_FORM_MODAL,
  SHOW_SETTINGS_MENU_MODAL,
  HIDE_SETTINGS_MENU_MODAL,
  SHOW_CONFIRMATION_MODAL,
  HIDE_CONFIRMATION_MODAL,
  CLEAR_PREV_SHOWN_MODAL_NAME,
  SET_PREV_SHOWN_MODAL_NAME,
  SHOW_PROCESSING_INVOICES_MODAL,
  HIDE_PROCESSING_INVOICES_MODAL,
  CLEAR_CURRENT_SHOWN_MODAL_NAME,
  SET_PROFILE_MODAL_STATE,
  SHOW_REPORT_FILTER_MODAL,
  HIDE_REPORT_FILTER_MODAL,
  SHOW_LOGS_MODAL,
  HIDE_LOGS_MODAL,
  SHOW_SUCCES_CONNECTED_CSI_MODAL,
  HIDE_SUCCES_CONNECTED_CSI_MODAL,
  SHOW_INVOICES_FILTER_MODAL,
  HIDE_INVOICES_FILTER_MODAL,
} from 'constants/actions-constants'

export const showInvoicesFilterModal = () => ({
  type: SHOW_INVOICES_FILTER_MODAL,
})

export const hideInvoicesFilterModal = () => ({
  type: HIDE_INVOICES_FILTER_MODAL,
})

export const showSuccesConnectedCsiModal = () => ({
  type: SHOW_SUCCES_CONNECTED_CSI_MODAL,
})

export const hideSuccesConnectedCsiModal = () => ({
  type: HIDE_SUCCES_CONNECTED_CSI_MODAL,
})

export const showLogsModal = () => ({
  type: SHOW_LOGS_MODAL,
})

export const hideLogsModal = () => ({
  type: HIDE_LOGS_MODAL,
})

export const clearCurrentShownModalName = () => ({
  type: CLEAR_CURRENT_SHOWN_MODAL_NAME,
})

export const showProcessingInvoicesModal = () => ({
  type: SHOW_PROCESSING_INVOICES_MODAL,
})

export const hideProcessingInvoicesModal = () => ({
  type: HIDE_PROCESSING_INVOICES_MODAL,
})

export const setPrevShowModalName = () => ({
  type: SET_PREV_SHOWN_MODAL_NAME,
})

export const clearPrevShownModalName = () => ({
  type: CLEAR_PREV_SHOWN_MODAL_NAME,
})

export const showQbConnectModal = () => ({
  type: SHOW_QB_CONNECT_MODAL,
})

export const hideQbConnectModal = () => ({
  type: HIDE_QB_CONNECT_MODAL,
})

export const showCsiConnectModal = () => ({
  type: SHOW_CSI_CONNECT_MODAL,
})

export const hideCsiConnectModal = () => ({
  type: HIDE_CSI_CONNECT_MODAL,
})

export const showCsiFormModal = () => ({
  type: SHOW_CSI_FORM_MODAL,
})

export const hideCsiFormModal = () => ({
  type: HIDE_CSI_FORM_MODAL,
})

export const showNewBatchModal = () => ({
  type: SHOW_CREATE_BATCH_MODAL,
})

export const hideNewBatchModal = (payload = false) => ({
  type: HIDE_CREATE_BATCH_MODAL,
  payload,
})

export const showNewBatchAvailableInvoicesModal = () => ({
  type: SHOW_CREATE_BATCH_AVILABLE_INVOICES_MODAL,
})

export const showProcessedBatchModal = () => ({
  type: SHOW_PROCESSED_BATCH_MODAL,
})

export const hideProcessedBatchModal = () => ({
  type: HIDE_PROCESSED_BATCH_MODAL,
})

export const showExportConfirmationModal = () => ({
  type: SHOW_EXPORT_CONFIRMATION_MODAL,
})

export const hideExportConfirmationModal = () => ({
  type: HIDE_EXPORT_CONFIRMATION_MODAL,
})

export const showInvoiceDetailModal = () => ({
  type: SHOW_INVOICE_DETAIL_MODAL,
})

export const hideInvoiceDetailModal = () => ({
  type: HIDE_INVOICE_DETAIL_MODAL,
})

export const openInvoiceDetailModal = (payload) => ({
  type: OPEN_INVOICE_DETAIL_MODAL,
  payload,
})

export const closeInvoiceDetailModal = () => ({
  type: CLOSE_INVOICE_DETAIL_MODAL,
})

export const showInvoiceTaxSummaryModal = () => ({
  type: SHOW_INVOICE_TAX_SUMMARY_MODAL,
})

export const hideInvoiceTaxSummaryModal = () => ({
  type: HIDE_INVOICE_TAX_SUMMARY_MODAL,
})

export const openInvoiceTaxSummaryModal = (payload) => ({
  type: OPEN_INVOICE_TAX_SUMMARY_MODAL,
  payload,
})

export const closeInvoiceTaxSummaryModal = () => ({
  type: CLOSE_INVOICE_TAX_SUMMARY_MODAL,
})

export const showProfileModal = () => ({
  type: SHOW_PROFILE_MENU_MODAL,
})

export const hideProfileModal = () => ({
  type: HIDE_PROFILE_MENU_MODAL,
})

export const openConnectCsiFormModal = (payload) => ({
  type: OPEN_CONNECT_CSI_FORM_MODAL,
  payload,
})

export const closeConnectCsiFormModal = () => ({
  type: CLOSE_CONNECT_CSI_FORM_MODAL,
})

export const showSettingModal = () => ({
  type: SHOW_SETTINGS_MENU_MODAL,
})

export const hideSettingModal = () => ({
  type: HIDE_SETTINGS_MENU_MODAL,
})

export const showConfirmationModal = (payload) => ({
  type: SHOW_CONFIRMATION_MODAL,
  payload,
})

export const hideConfirmationModal = () => ({
  type: HIDE_CONFIRMATION_MODAL,
})

export const setModalProfileState = (payload) => ({
  type: SET_PROFILE_MODAL_STATE,
  payload,
})

export const showReportFilterModal = () => ({
  type: SHOW_REPORT_FILTER_MODAL,
})

export const hideReportFilterModal = () => ({
  type: HIDE_REPORT_FILTER_MODAL,
})
//
// export const showCsiConnectedPopUp = () => ({
//   type: HIDE_REPORT_FILTER_MODAL,
// })
