import React, { memo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { useShallowSelector } from 'custom-hooks/useShallowSelector'
import InfoBox from 'components/ui/infobox-component'
import Title from 'components/ui/title-component'
import { Table, TableCell } from 'components/ui/table'
import {
  NUM_INVOICE_SORTED_CONS,
  CUSTOMER_INVOICES_SORTED_CONS,
  ISSUE_DATE_INVOICE_SORTED_CONS,
  ZIP_CODE_INVOICE_SORTED_CONS,
  REVENUE_INVOICE_SORTED_CONS,
  EMAILED_INVOICES_SORTED_CONS,
  TACES_EXIST_INVOICES_SORTED_CONS,
} from 'constants/available-invoices-sorted-constant'

import { setNewValueInSelectedState } from 'actions/checkbox-actions'
import { getCreatebatchInvoicesSelector } from 'redusers/batches'
import {
  NEW_BATCH_MODAL_AVAILABLE_INVOICES_TITLE_LN,
  NEW_BATCH_MODAL_AVAILABLE_INVOICES_TITLE_TOOLTIP_LN,
  NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_COUNT_TITLE_LN,
  NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_1_LN,
  NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_2_LN,
  NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_3_LN,
  NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_4_LN,
  NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_5_LN,
  NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_6_LN,
  NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_7_LN,
  NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_8_LN,
  NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_9_LN,
  NEW_BATCH_INFO_BOX_MESSAGE_4_LN,
  TABLE_COLUMN_HEADER_MOUSE_OVER_INCLUDE_NEW_BATCH_LN,
  TABLE_COLUMN_HEADER_MOUSE_OVER_EMAILED_LN,
  TABLE_COLUMN_HEADER_MOUSE_OVER_TAXES_EXIST_LN,
  TABLE_COLUMN_HEADER_MOUSE_OVER_TAX_EXEMPTION_LN,
  TABLE_COLUMN_HEADER_MOUSE_OVER_POSTAL_CODE_LN,
  TABLE_COLUMN_HEADER_MOUSE_OVER_CUST_TYPE_LN,
} from 'constants/language-key-constants'

const InvoiceItem = React.lazy(() => import('./InvoiceItem'))

const AvailableInvoices = (props) => {
  const { handleClickInvoiceDetail, maxHeightInvoicesTable } = props
  const { t } = useTranslation()

  const invoices = useShallowSelector(getCreatebatchInvoicesSelector)

  return (
    <div className="new_batch_modal_availeble_invoices_container">
      <span className="new_batch_modal_title_container">
        <Title
          title={t(NEW_BATCH_MODAL_AVAILABLE_INVOICES_TITLE_LN)}
          extraClassName="new_batch_title subtitle"
          component="h3"
          withTooltip={true}
          textTooltip={t(NEW_BATCH_MODAL_AVAILABLE_INVOICES_TITLE_TOOLTIP_LN)}
          placementTooltip={'top'}
        />
        {invoices.length >= 800 && (
          <InfoBox
            textContent={t(NEW_BATCH_INFO_BOX_MESSAGE_4_LN)}
            extraClassNameContainer="info_box_new_batch show-animated"
            extraClassNameText={'info_box_unmaped_products_text'}
          />
        )}
      </span>
      <Table
        className="new_batch_modal_availeble_invoices_table_container"
        headerComponent={AvailableInvoicesTableHeader}
        bodyTableComponent={AvailableInvoicesTableBody}
        autoHeightMin={25}
        autoHeightMax={maxHeightInvoicesTable}
        data={invoices}
        defaultsortedkey={NUM_INVOICE_SORTED_CONS}
        checkboxKey={'include'}
        exemptDefaultCheckedKey={'taxesExist'}
        countTitleText={t(
          NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_COUNT_TITLE_LN
        )}
        selectedCountTitleText={'Selected'}
        bodyProps={{
          handleClickInvoiceDetail: handleClickInvoiceDetail,
        }}
      />
    </div>
  )
}

const AvailableInvoicesTableHeader = memo((headerProps) => {
  const {
    handleSortClick,
    sortedKey,
    defaultsortedkey,
    handlerOnChangeAllCheckBoxs,
    toggleSelectAllCheckBox,
    notEmpty,
  } = headerProps

  const { t } = useTranslation()

  return (
    <>
      <TableCell
        text={t(NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_1_LN)}
        className="include"
        isAllCheckBox
        handlerOnChangeAllCheckBoxs={handlerOnChangeAllCheckBoxs}
        checkBoxValue={toggleSelectAllCheckBox}
        notEmpty={notEmpty}
        tooltipText={t(TABLE_COLUMN_HEADER_MOUSE_OVER_INCLUDE_NEW_BATCH_LN)}
      />
      <TableCell
        text={t(NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_2_LN)}
        className="emailed"
        dataSortKey={EMAILED_INVOICES_SORTED_CONS}
        handleSortClick={handleSortClick}
        chosenSortedKey={sortedKey}
        isSort
        tooltipText={t(TABLE_COLUMN_HEADER_MOUSE_OVER_EMAILED_LN)}
      />
      <TableCell
        text={t(NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_3_LN)}
        className="taxes_exist"
        dataSortKey={TACES_EXIST_INVOICES_SORTED_CONS}
        handleSortClick={handleSortClick}
        chosenSortedKey={sortedKey}
        isSort
        tooltipText={t(TABLE_COLUMN_HEADER_MOUSE_OVER_TAXES_EXIST_LN)}
      />
      <TableCell
        text={t(NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_4_LN)}
        className="tax_exemption"
        tooltipText={t(TABLE_COLUMN_HEADER_MOUSE_OVER_TAX_EXEMPTION_LN)}
      />
      <TableCell
        text={t(NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_5_LN)}
        className="invoice"
        isSort
        dataSortKey={NUM_INVOICE_SORTED_CONS}
        defaultsortedkey={defaultsortedkey}
        handleSortClick={handleSortClick}
        chosenSortedKey={sortedKey}
        tooltipText={t(NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_5_LN)}
      />
      <TableCell text={'Type'} className="type" />
      <TableCell
        text={t(NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_6_LN)}
        className="customer"
        isSort
        dataSortKey={CUSTOMER_INVOICES_SORTED_CONS}
        handleSortClick={handleSortClick}
        chosenSortedKey={sortedKey}
        tooltipText={t(NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_6_LN)}
      />
      <TableCell
        text={'Cust Type'}
        className="cust_type"
        tooltipText={t(TABLE_COLUMN_HEADER_MOUSE_OVER_CUST_TYPE_LN)}
      />
      <TableCell
        text={t(NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_7_LN)}
        className="invoice_date"
        isSort
        dataSortKey={ISSUE_DATE_INVOICE_SORTED_CONS}
        handleSortClick={handleSortClick}
        chosenSortedKey={sortedKey}
        tooltipText={t(NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_7_LN)}
      />
      <TableCell
        text={t(NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_8_LN)}
        className="zip_code header"
        isSort
        dataSortKey={ZIP_CODE_INVOICE_SORTED_CONS}
        handleSortClick={handleSortClick}
        chosenSortedKey={sortedKey}
        tooltipText={t(TABLE_COLUMN_HEADER_MOUSE_OVER_POSTAL_CODE_LN)}
      />
      <TableCell
        text={t(NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_9_LN)}
        className="revenue"
        isSort
        dataSortKey={REVENUE_INVOICE_SORTED_CONS}
        handleSortClick={handleSortClick}
        chosenSortedKey={sortedKey}
        tooltipText={t(NEW_BATCH_MODAL_AVAILABLE_INVOICES_TABLE_CELL_9_LN)}
      />
    </>
  )
})

const AvailableInvoicesTableBody = memo((bodyProps) => {
  const { data, handleCheckBox, handleClickInvoiceDetail } = bodyProps

  const dispatch = useDispatch()

  const handleZipCodeEdit = useCallback(
    ({ value, id }) => {
      dispatch(setNewValueInSelectedState({ id, value, valueKey: 'zipCode' }))
    },
    [dispatch]
  )

  return data.map((invoice) => (
    <InvoiceItem
      key={invoice._id}
      {...invoice}
      handleZipCodeEdit={handleZipCodeEdit}
      handleClickInvoiceDetail={handleClickInvoiceDetail}
      handleCheckBox={handleCheckBox}
    />
  ))
})

export default memo(AvailableInvoices)
