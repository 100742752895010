import React, { useEffect, memo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Button from 'components/ui/button-component'
import SearchInput from 'components/ui/search-input'
import Users from 'components/users-component'
import {
  getUsersList,
  setSearchValueUsers,
  clearSearchValueUsers,
} from 'actions/users'
import {
  showProfileModal,
  setModalProfileState,
} from 'actions/modals-state-actions'
import {
  ADD_NEW_USER_MODAL_STATE,
  EDIT_USER_MODAL_STATE,
} from 'constants/profile-modal-state-constants'
import {
  PLACEHOLDER_BY_USERS_SEARCH_INPUT_LN,
  ADD_NEW_USER_BUTTON_LN,
} from 'constants/language-key-constants'

const UsersPage = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleClickAddNewUser = useCallback(() => {
    dispatch(setModalProfileState(ADD_NEW_USER_MODAL_STATE))
    dispatch(showProfileModal())
  }, [dispatch])

  const handleClickEditUser = useCallback(() => {
    dispatch(setModalProfileState(EDIT_USER_MODAL_STATE))
    dispatch(showProfileModal())
  }, [dispatch])

  const handleChangeSearchInput = (e) => {
    const value = e.target.value
    dispatch(setSearchValueUsers(value))
  }

  const handleClickClear = () => {
    dispatch(setSearchValueUsers(''))
  }

  useEffect(() => {
    dispatch(getUsersList())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      dispatch(clearSearchValueUsers())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="users_page_container container">
      <div className="users_page_search_section">
        <div className="search_container">
          <SearchInput
            placeholder={t(PLACEHOLDER_BY_USERS_SEARCH_INPUT_LN)}
            classNameContainer="users_page_search_input_container"
            onChange={handleChangeSearchInput}
            handleClickClear={handleClickClear}
            isClearButton={true}
          />
        </div>
        <div className="users_page_filter_group">
          <Button
            name={t(ADD_NEW_USER_BUTTON_LN)}
            styletype="green"
            classNameButton={'users_page_btn'}
            onClick={handleClickAddNewUser}
          />
        </div>
      </div>
      <div className="users_page_users_section">
        <Users handleClickEditUser={handleClickEditUser} />
      </div>
    </div>
  )
}

export default memo(UsersPage)
